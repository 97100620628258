import './Ticker.css'
import Marquee from "react-fast-marquee";
import partnerImage1 from '../../../assets/images/PartnersImages/partner1.webp'
import partnerImage2 from '../../../assets/images/PartnersImages/partner2.webp'
import partnerImage3 from '../../../assets/images/PartnersImages/partner3.webp'
import partnerImage4 from '../../../assets/images/PartnersImages/partner4.webp'
import partnerImage5 from '../../../assets/images/PartnersImages/partner5.webp'
import partnerImage6 from '../../../assets/images/PartnersImages/partner6.webp'
import partnerImage7 from '../../../assets/images/PartnersImages/partner7.webp'
import partnerImage8 from '../../../assets/images/PartnersImages/partner8.webp'
import partnerImage9 from '../../../assets/images/PartnersImages/partner9.webp'


const tickerImagesArr = [
    partnerImage1,
    partnerImage2,
    partnerImage3,
    partnerImage4,
    partnerImage5,
    partnerImage6,
    partnerImage7,
    partnerImage8,
    partnerImage9,
]

const Ticker = () => {
  return (
    <div className='main__ticker-wrapper'>
        <h2 className="main__ticker-title mont-r">Наші партнери</h2>
       <Marquee className='marquee' loop={0}>
   {
    tickerImagesArr.map((img, idx) => {
        return (
            <img key={idx} src={img} alt="фото партнерів ТОВ Агролада" className="main__ticker-image" />
        )
    })
   }
  </Marquee>
    </div>
  )
}

export default Ticker