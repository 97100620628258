import './Header.css'
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'
import { ABOUTE_ROUTE, CONTACTS_ROUTE } from '../../utils/routes'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setClearIsFormSended, setIsActive, setIsClose, setSelectedProduct } from '../../redux/ModalSlice/ModalSlice'
import Hamburger from 'hamburger-react'
import { setBurgerIsActive, setBurgerIsClose } from '../../redux/BurgerSlice/BurgerSlice'
import Search from '../Search/Search'

const Header = ({onClearSearch, search, setSearch}) => {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isOpen, setOpen] = useState(isActive);


  const handleToggle = () => {
    if (!isOpen) {
      dispatch(setIsClose());
      dispatch(setBurgerIsActive());
      dispatch(setClearIsFormSended());
      setOpen(true);
    } else {
      dispatch(setBurgerIsClose());
      setOpen(false);
    }
  };

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    setOpen(false);
    dispatch(setSelectedProduct(null));
  };

  useEffect(() => {
    setOpen(isActive);
  }, [isActive]);

  return (
    <header className='header'>
        <div className="header__wrapper">
            <div className="logo__nav">
                <Link to={"/"}>
                <img src={logo} alt="логотип ТОВ Агролада" className="header__logo" />
                </Link>
                <nav className="header__nav">
                    <Link className='header__nav-item mont-r' to={ABOUTE_ROUTE}>Про нас</Link>
                    <Link className='header__nav-item mont-r' to={CONTACTS_ROUTE}>Контакти</Link>
                </nav>
            </div>
            <div className="header__buttons">
            <Search search={search} setSearch={setSearch} onClearSearch={onClearSearch}/>
            <div onClick={handleModalOpen} className="header__button mont-m">Консультація</div>
            <div onClick={handleToggle} className="header__menu-button mont-m">
              меню
              <Hamburger
             size={16}
             color="#FFFFFF"
             toggled={isOpen}
            onToggle={() => {}}
            />
            </div>
            </div>
        </div>
    </header>
  )
}

export default Header