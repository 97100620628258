import "./Burger.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { ABOUTE_ROUTE, CONTACTS_ROUTE } from "../../utils/routes";
import arrowBlack from "../../assets/icons/ArrowBlack.png";
import { useEffect, useState } from "react";
import { navArr } from "../../utils/navArr";

const Burger = () => {
  const dispatch = useDispatch();
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isSecondLevelBurgerActive, setIsSecondLevelBurgerActive] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isThirdLevelBurgerActive, setIsThirdLevelBurgerActive] = useState(false);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  useEffect(() => {
    const body = document.body;
    if (isActive) {
      body.style.overflow = "hidden";
    } else if (!isActive) {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isActive, isSecondLevelBurgerActive, isThirdLevelBurgerActive, activeSubCategory]);

  const handleSecondLevelMenuOpen = () => {
    setIsSecondLevelBurgerActive(true);
  };
  const handleSecondLevelMenuClose = () => {
    setIsSecondLevelBurgerActive(false);
    setActiveCategory(null);
  };

  const handleThirdLevelMenuOpen = (category) => {
    setActiveCategory(category);
    setIsThirdLevelBurgerActive(true);
  };

  const handleThirdLevelMenuClose = () => {
    setIsThirdLevelBurgerActive(false);
    setActiveSubCategory(null); 
  };

  const handleFourthLevelMenuOpen = (subCategory) => {
    setActiveSubCategory(subCategory);
  };

  const handleFourthLevelMenuClose = () => {
    setActiveSubCategory(null);
  };

  const handleBurgerClose = () => {
    dispatch(setBurgerIsClose());
  };

  return (
    <div className={`burger__menu ${isActive ? "active" : ""}`}>
      <div className="burger__overlay">
        {/* Основное меню */}
        <div className="burger__body">
          <div onClick={handleSecondLevelMenuOpen} className="burger__item-catalog mont-r">
            Каталог товарів <img src={arrowBlack} alt="перейти до каталогу товарів" className="arrow__mobile" />
          </div>
          <Link onClick={handleBurgerClose} className="burger__link mont-r" to={"/"}>
            Головна
          </Link>
          <Link onClick={handleBurgerClose} className="burger__link mont-r" to={ABOUTE_ROUTE}>
            Про нас
          </Link>
          <Link onClick={handleBurgerClose} className="burger__link mont-r" to={CONTACTS_ROUTE}>
            Контакти
          </Link>
        </div>

        {/* Второй уровень меню */}
        <div className={`burger__body-second ${isSecondLevelBurgerActive ? "active" : ""}`}>
          <div onClick={handleSecondLevelMenuClose} className="burger__body-second-back mont-r">
            <span>{"< Назад"}</span> <span>Каталог товарів</span>
          </div>
          {navArr.map((category, idx) => (
            <div
              key={idx}
              onClick={() => handleThirdLevelMenuOpen(category)}
              className="burger__item-catalog mont-r"
            >
              {category.categoryTitle} <img src={arrowBlack} alt="перейти до подкатегорий" className="arrow__mobile" />
            </div>
          ))}
        </div>

        {/* Третий уровень меню */}
        <div className={`burger__body-third ${isThirdLevelBurgerActive ? "active" : ""}`}>
          <div onClick={handleThirdLevelMenuClose} className="burger__body-third-back mont-r">
            <span>{"< Назад"}</span> <span>{activeCategory?.categoryTitle}</span>
          </div>
          {activeCategory?.subCategoryes.map((subCategory, idx) => (
            <div
              key={idx}
              onClick={() => handleFourthLevelMenuOpen(subCategory)}
              className="burger__item-catalog mont-r"
            >
              {subCategory.subCategoryTitle} <img src={arrowBlack} alt="перейти к товарам" className="arrow__mobile" />
            </div>
          ))}
        </div>

        {/* Четвёртый уровень меню */}
        <div className={`burger__body-fourth ${activeSubCategory ? "active" : ""}`}>
          <div onClick={handleFourthLevelMenuClose} className="burger__body-fourth-back mont-r">
            <span>{"< Назад"}</span> <span>{activeSubCategory?.subCategoryTitle}</span>
          </div>
          {activeSubCategory?.products.map((product, idx) => (
            <Link
              key={idx}
              onClick={handleBurgerClose}
              className="burger__link mont-r"
              to={product.path}
            >
              {product.productTitle}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Burger;
