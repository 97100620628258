import './Footer.css'
import YouTubeIcon from '../../assets/icons/youTubeIcon.png'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className="footer__wrapper">
      <a className='footer__contact mont-r' href="tel:+380 (50) 259-14-88">+380 (50) 259-14-88</a>
      <a className='footer__contact mont-r' href="mailto:agroladamarket@gmail.com">agroladamarket@gmail.com</a>
      <a className='footer_link' href="https://www.youtube.com/@agroladamarket" target="_blank" rel="noopener noreferrer">
        <img src={YouTubeIcon} alt="посилання на ютуб тов Агролада" className="footer__icon" />
      </a>
      </div>
    </footer>
  )
}

export default Footer