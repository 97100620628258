import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { setIsOverlayClose } from "../../redux/OverlaySearchSlide/OverlaySearchSlide";
import AsideNavigation from "../AsideNavigation/AsideNavigation";
import Burger from "../Burger/Burger";
import CallButton from "../CallButton/CallButton";
import Header from "../Header/Header";
import ModalForm from "../ModalForm/ModalForm";
import "./AppRouter.css";

const AppRouter = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  const [search, setSearch] = useState("");
  const isMobileSerchActive = useSelector((state)=>state.overlay.isOverlayActive)


  const handleMobileSearchClose = () => {
    dispatch(setIsOverlayClose());
    setSearch("")
};


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="global__container">
      <Header search={search} setSearch={setSearch} />
      <div onClick={handleMobileSearchClose} className={`search__overlay ${isMobileSerchActive ? "active" : ""}`}></div>
      <main className="main">
        {
          <>
            <AsideNavigation />
            <Outlet />
          </>
        }
      </main>
      <Burger />
      <ModalForm />
      <CallButton setSearch={setSearch} />
    </div>
  );
};

export default AppRouter;
