import monopreparations1 from "../assets/images/MonopreparationsImages/mikro-mineralis-nitro.jpg";
import monopreparations2 from "../assets/images/MonopreparationsImages/mikro-mineralis-fosfor-kaliy.jpg";
import monopreparations3 from "../assets/images/MonopreparationsImages/mikro-mineralis-kaliy.jpg";
import monopreparations4 from "../assets/images/MonopreparationsImages/mikro-mineralis-kremniy-kaliy.webp";
import monopreparations5 from "../assets/images/MonopreparationsImages/mikro-mineralis-marganec.jpg";
import monopreparations6 from "../assets/images/MonopreparationsImages/mikro-mineralis-molibden.jpg";
import monopreparations7 from "../assets/images/MonopreparationsImages/micro-mineralis-cink.jpg";
import monopreparations8 from "../assets/images/MonopreparationsImages/mikro-mineralis-bor-me.jpg";

export const monopreparationsArr = [
  {
    productTitle: "Мікро-Мінераліс (Нітро)",
    path: "/mikro-mineralis-nitro",
    src: monopreparations1,
    volume: "10 л",
    price: 46.12,
    currency: "USD",
    text: (<>
    Опис: Мікро-Мінераліс (Нітро) - рідке комплексне мікродобриво для позакореневого обробітку широкого спектру польових і овочевих культур. 
    <span className="paragraph"></span>
СКЛАД мікродобрива Мікро-Мінераліс (Нітро), амонійно-карбоксилатних комплексонів, %:
	•	Mo – 0,06
	•	Mn – 1,58
	•	Cu – 1,5
	•	Co – 0,06
	•	Fe – 9,7
	•	Zn – 1,7
	•	B – 0,2
	•	N – 10,0
  <span className="paragraph"></span>
Переваги: 
	•	Може використовуватися на більшості сільськогосподарських культур;
	•	Містить комплекс мікроелементів, що забезпечує стабільний ріст та розвиток рослин, стимулює плодоношення, забезпечує профілактику і боротьбу з хлорозом;
	•	Позакоренева обробка мікродобривом Мікро-Мінераліс (Нітро) підвищує морозостійкість та зимостійкість рослин, стимулює їх ріст та розвиток, посилює резистентність та стресостійкість, збільшує урожайність та якість отриманої продукції;
	•	Підвищує стійкість до хвороб та подовжує період якісного збереження продукції.
  <span className="paragraph"></span>
Застосування.
<span className="paragraph"></span>
Культура: 
	•	Польові та овочеві культури.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	позакореневе підживлення в період вегетації.
Крапельний полив.
<span className="paragraph"></span>
Культура: 
	•	Овочі, фрукти, ягоди.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Крапельний полив культур.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 250-300 л/га для польових та овочевих культур. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Фосфор-Калій)",
    path: "/mikro-mineralis-fosfor-kaliy",
    src: monopreparations2,
    volume: "10 л",
    price: 43.28,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Фосфор-Калій) – це легкозасвоюване рідке фосфорно-калійне добриво для позакореневого підживлення сільськогосподарських культур. 
    <span className="paragraph"></span>
    Склад мікродобрива Мікро-Мінераліс (Фосфор-Калій), амонійно-карбоксилатних комплексонів, %:
      •	N – 10,0
      •	P2O5 – 7,5
      •	K2O – 10,0
      <span className="paragraph"></span>
    Переваги: 
      •	Збалансоване співвідношення легкодоступних форм фосфору та калію;
      •	Можливість забезпечення оптимального живлення рослин у критичні фази розвитку;
      •	Стимуляція розвитку кореневої системи (особливо на початкових фазах органогенезу);
      •	Підвищення зимо - та морозостійкості рослин;
      •	Забезпечення високої урожайності та поліпшення якості товарної продукції;
      •	Не містить хлору.
      <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Зернові; 
      •	Соняшник; 
      •	Кукурудза; 
      •	Ріпак; 
      •	Буряки; 
      •	Картопля; 
      •	Овочі.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	Фаза кущення; фаза відновлення вегетації;
      •	Фаза 2-3 пари листків; фаза 5-6 пар листків; 
      •	Фаза 3-5 листків 
      •	Формування розетки; формування стебла;  бутонізація;
      •	Фаза 4-8 листків;  фаза 10-12 листків; змиканням міжрядь;
      •	Формування стебла; змиканням міжрядь; формування зелених ягід;
      •	Формування 2-3 справжніх листків; наступні через 7-10днів (двічі).
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300л/га – по­льові культури. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Калій)",
    path: "/mikro-mineralis-kaliy",
    src: monopreparations3,
    volume: "10 л",
    price: 32.87,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Калій) – інноваційне мікродобриво, призначене для позакореневого підживлення сільськогосподарських культур, чутливих до нестачі калію. 
    <span className="paragraph"></span>
    Роль калію в житті рослин.
    <span className="paragraph"></span>
    Нестача калію позначається насамперед на розвитку репродуктивних органів. Затримується розвиток зародків насіння, а отримане насіння  характеризується поганими якісними показниками.
    Зовнішні ознаки калійного голодування у рослин виявляються на листі знизу вверх: починаючи з країв, передчасно жовкнуть, потім буріють (іноді з червоним чи іржавими вкрапленням), набирають вигляду обпалених та відмирають. При недостачі калію клітини ростуть нерівномірно, що є причиною гофрованості, куполоподібної форми листя. У картоплі на листках з'являється характерний бронзовий наліт. Недостача калію візуально стає помітною зазвичай у середині вегетації, коли підживлення вже малоефективне.
    Мікродобриво Мікро-Мінераліс (Калій) значно підвищує стійкість рослин до несприятливих погодних умов, тому є незамінним в зонах ризикового землеробства.
    <span className="paragraph"></span>
    Переваги: 
      •	Допомагає рослинам переносити тимчасову засуху;
      •	Підвищує морозостійкість;
      •	Позитивно впливає на процес фотосинтезу;
      •	Підвищує стійкість до вилягання.
      <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Пшениця озима, ярий ячмінь; 
      •	Соняшник; 
      •	Кукурудза;
      •	Ріпак; 
      •	Соя, горох; 
      •	Цукровий буряк; 
      •	Картопля; 
      •	Томати.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	фаза кущення, фаза виходу в трубку, початок наливу зерна;
      •	фаза 2-3 пари листків, фаза 5-6 пар листків;
      •	фаза 3-5 листків, фаза 7-9 листків;
      •	фаза стеблування, бутонізації;
      •	3-5 справжніх листків, фаза бутонізації, фаза початок наливу бобів;
      •	до змикання в рядках, до змикання в міжряддях;
      •	фаза стеблування, бутонізації; 
      •	фаза укорінення, бутонізації, початок масового утворення плодів.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300л/га – по­льові культури. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Кремній-Калій)",
    path: "/mikro-mineralis-kremniy-kaliy",
    src: monopreparations4,
    volume: "10 л",
    price: 41.86,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Кремній-Калій) -  високоефективне рідке мікродобриво, призначене для позакореневого підживлення сільськогосподарських культур. 
    <span className="paragraph"></span>
    СКЛАД мікродобрива Мікро-Мінераліс (Кремній-Калій), %:
      •	SiO2 – 8,0
      •	K2O – 4,0
      <span className="paragraph"></span>
    Роль складових препарату в рослині.
    <span className="paragraph"></span>
    Кремній підтримує водний баланс рослин, підвищує ефективність фотосинтезу, сприяє еректоїдному розміщенню листків, знижує інтенсивність випаровування вологи з листкової поверхні. Оптимальне живлення рослин кремнієм сприяє більш економному споживанню рослиною води для синтезу органічної речовини (зниження транспіраційного коефіцієнта).
    <span className="paragraph"></span>
    Калій зв’язаний з амінокислотами та білковим обміном, впливає на утворення вуглеводів при фотосинтезі, підсилює транспорт асимілянтів. Прискорює утворення цукрів та їх переміщення по тканинам. Підсилює стійкість до посухи, хвороб та морозів.
    <span className="paragraph"></span>
    Дефіцит кремнію призводить до порушення росту, розвитку і репродуктивної функції у багатьох рослин. Зокрема, зернові культури при низькій забезпеченості кремнієм більш схильні до вилягання.
    <span className="paragraph"></span>
    Нестача калію позначається, насамперед, на розвитку репродуктивних органів. Затримується розвиток зародків насіння, а отримане насіння характеризується поганими якісними показниками. При недостачі калію клітини ростуть нерівномірно, що є причиною гофрованості та куполоподібності форми листя.
    <span className="paragraph"></span>
    Переваги: 
    Розумне поєднання таких елментів, як кремній та калій, сприяє поліпшенню експозиції листової пластинки по відношенню до світла, сприяє накопиченню цукрів в рослині, підсилює стійкість до вилягання, покращує транспортні функцій у рослині, зменшує схильність до зараження патогенами і кореневими паразитами, збільшує стійкість до абіотичних стресів (забруднення важкими металами, засоленість ґрунтів, посуха, високі і низькі температури, УФ випромінювання та ін.). 
    <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Пшениця озима, ярий ячмінь; 
      •	Соняшник; 
      •	Кукурудза; 
      •	Ріпак; 
      •	Соя, горох; 
      •	Цукровий буряк; 
      •	Овочеві; 
      •	Плодово-ягідні культури.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	фаза кущення, фаза виходу в трубку, фаза прапорцевого листка;
      •	фаза 2-3 пари листків, фаза 5–6 пар листків;
      •	фаза 3-5 листків, фаза 7-9 листків;
      •	фаза 5-8 листків, фази стеблювання, бутонизації;
      •	3-5 повноцінних листки, фаза бутонізації, фаза формування бобів; 
      •	до змикання в рядах, до змикання в міжряддях;
      •	до цвітіння, повторне внесення через кожні 10-12 днів;
      •	до цвітіння, повторне внесення через кожні 10-12 днів.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300 л/га – польові культури та 300-800 л/га овочеві та плодово–ягідні культури. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Марганець)",
    path: "/mikro-mineralis-marganec",
    src: monopreparations5,
    volume: "10 л",
    price: 32.40,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Марганець) - це універсальне та легкозасвоюване рідке мікродобриво, призначене для позакореневого підживлення сільськогосподарських культур, чутливих до нестачі марганцю. 
    <span className="paragraph"></span>
    Склад мікродобрива Мікро-Мінераліс (Марганець) амонійно-карбоксилатних комплексонів, %:
      •	Mn – 10,0
      •	N – 1,0
      <span className="paragraph"></span>
    Роль марганцю в житті рослини.
    <span className="paragraph"></span>
    Марганець активізує ферменти у рослині, накопичується в листках і бере участь в фотолізі води, будучи компонентом фотосистеми, сприяє накопиченню і пересуванню цукру з листя у коренеплоди, стимулює наростання нових тканин в точках росту, покращує поглинання заліза з ґрунту і попереджає хлороз. При його відсутності різко знижується виділення кисню при фотосинтезі і вміст вуглеводів, особливо в корінні.
    <span className="paragraph"></span>
    Чутливими культурами до нестачі марганцю є озимі та ярі зернові, соя, буряк цукровий, кормовий і столовий, кукурудза, картопля, яблуня. Надходження марганцю в рослини знижується при низьких температурах і високій вологості ґрунту, що найчастіше спостерігається ранньою весною коли від цього в значній мірі страждають озимі.
    <span className="paragraph"></span>
    Внесення Мікро-Мінераліс (Марганець) посилює синтез вітаміну С, каротину, глютаміну у плодових і овочевих культурах; у цукрових буряків зростає цукристість коренеплодів; у бобових – фіксація атмосферного азоту бульбочковими бактеріями.
    <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Пшениця озима, ярий ячмінь; 
      •	Соняшник; 
      •	Кукурудза; 
      •	Ріпак; 
      •	Соя, горох; 
      •	Цукровий буряк; 
      •	Картопля; 
      •	Томати.
      <span className="paragraph"></span>
    Фаза розвитку:
      •	фаза кущення, фаза виходу в трубку, початок наливу зерна;
      •	фаза 2-3 пари листків, фаза 5-6 пар листків;
      •	фаза 3-5 листків, фаза 7-9 листків; 
      •	фаза стеблування, бутонізації; 
      •	3-5 справжніх листків, фаза бутонізації, фаза початку наливу бобів; 
      •	до змикання в рядках, до змикання в міжряддях; 
      •	фаза стеблування, бутонізації; 
      •	фаза укорінення, бутонізації, початок масового утворення плодів.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300л/га – по­льові культури. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Молібден)",
    path: "/mikro-mineralis-molibden",
    src: monopreparations6,
    volume: "10 л",
    price: 32.87,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Молібден) - це універсальне та легкозасвоюване рідке мікродобриво, призначене для позакореневого підживлення сільськогосподарських культур. 
    <span className="paragraph"></span>
    Склад мікродобрива Мікро-Мінераліс (Молібден) амонійно-карбоксилатних комплексонів, %: 
      •	Mo – 2,0
      •	N – 2,0
      <span className="paragraph"></span>
    Роль молібдену в житті рослини.
    <span className="paragraph"></span>
    Молібден часто називають мікроелементом азотного обміну, оскільки він входить до складу нітратредуктази і нітрогенази.
    <span className="paragraph"></span>
    При недостачі молібдену, що часто буває на кислих ґрунтах, в тканинах накопичується велика кількість нітратів і порушується нормальний обмін речовин у рослин. Затримується ріст рослин, гальмується синтез хлорофілу. Молібден відіграє важливу роль у фіксації молекулярного азоту з атмосфери бульбочковими азотфіксуючими бактеріями. При нестачі молібдену послаблюється ріст рослин, затримується цвітіння, листки стають світло-зеленого кольору, потім буріють і відмирають.
    <span className="paragraph"></span>
    Позакореневе внесення Мікро-Мінераліс (Молібден) сприятиме:
      •	Кращому засвоєнню атмосферного азоту бульбочковими бактеріями;
      •	Повноцінному азотному живленню рослин;
      •	Збільшенню вмісту білка в зерні, вітамінів і цукрів у овочах;
      •	Підвищенню стійкості рослин до заморозків та посухи.
      <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Пшениця озима, ярий ячмінь; 
      •	Соняшник; 
      •	Кукурудза; 
      •	Ріпак; 
      •	Соя, горох; 
      •	Цукровий буряк; 
      •	Картопля; 
      •	Томати.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	фаза кущення, фаза виходу в трубку, початок наливу зерна;
      •	фаза 2-3 пари листків, фаза 5-6 пар листків;
      •	фаза 3-5 листків, фаза 7-9 листків; 
      •	фаза стеблування, бутонізації; 
      •	3-5 справжніх листків, фаза бутонізації, фаза початку наливу бобів; 
      •	до змикання в рядках, до змикання в міжряддях; 
      •	фаза стеблування, бутонізації; 
      •	фаза укорінення, бутонізації, початок масового утворення плодів.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300л/га – по­льові культури.  </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Цинк)",
    path: "/micro-mineralis-cink",
    src: monopreparations7,
    volume: "10 л",
    price: 32.87,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Цинк) - це універсальне та легкозасвоюване рідке мікродобриво, призначене для позакореневого підживлення сільськогосподарських культур, чутливих до нестачі цинку. 
    <span className="paragraph"></span>
    Склад мікродобрива Мікро-Мінераліс (Цинк), амонійно-карбоксилатних комплексонів, %:
      •	Zn – 6,0
      •	N – 2,0
      <span className="paragraph"></span>
    Роль цинку в житі рослин.
    <span className="paragraph"></span>
    Цинк входить до складу багатьох ферментів, бере участь в утворенні хлорофілу, сприяє синтезу вітамінів, тому підживлення цинком посилює ріст рослин. Цинк відіграє важливу роль в окислювально-відновних процесах, що протікають в рослинних організмах.
    <span className="paragraph"></span>
    Дефіцит цинку призводить до порушення фосфорного обміну: зростає вміст неорганічного фосфату, сповільнюється його перетворення в органічні форми, що проявляється на рослинах у хлоротичних плямах на листках, які стають блідо-зеленими, а в деяких рослин майже білими. Ознаками нестачі цинку в рослині є розвиток міжжилкового хлорозу, затримка росту рослини, порушення процесу достигання насіння. У кукурудзи між жилками листка з’являються ясно жовті смуги (білі плями), нові листки блідо-жовті або білі. У фруктових дерев відмирають бруньки і пагони, опадає листя.
    <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Позакореневе внесення Мікро-Мінераліс (Цинк) дозволить:
      	Підвищити урожай всіх польових, овочевих і плодових культур;
      	Знизити ураженість рослин грибковими захворюваннями;
      	Підвищити цукристість плодових і ягідних культур;
      	Підвищити жаро-, посухо- та морозостійкість рослин;
      	Збільшити вміст вуглеводів, крохмалю та білкових речовин в рослинах.
      <span className="paragraph"></span>
    Культура: 
      •	Пшениця озима, ярий ячмінь; 
      •	Соняшник; 
      •	Кукурудза; 
      •	Ріпак; 
      •	Соя, горох; 
      •	Цукровий буряк; 
      •	Картопля; 
      •	Томати.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	фаза кущення, фаза виходу в трубку, початок наливу зерна;
      •	фаза 2-3 пари листків, фаза 5-6 пар листків;
      •	фаза 3-5 листків, фаза 7-9 листків; 
      •	фаза стеблування, бутонізації; 
      •	3-5 справжніх листків, фаза бутонізації, фаза початку наливу бобів; 
      •	до змикання в рядках, до змикання в міжряддях; 
      •	фаза стеблування, бутонізації; 
      •	фаза укорінення, бутонізації, початок масового утворення плодів.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300л/га – по­льові культури. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Бор) + МЕ",
    path: "/mikro-mineralis-bor-me",
    src: monopreparations8,
    volume: "10 л",
    price: 45.17,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Бор) + МЕ - застосування мікродобрива  усуває дефіцит бору в рослинах, регулює процеси запилення та вуглецевий і білковий обмін, стимулює синтез фітогормонів. Збільшує врожайність та якість отриманого врожаю. Підвищує імунітет та стійкість рослин до бактеріальних захворювань. 
    <span className="paragraph"></span>
    Склад мікродобрива Мікро-Мінераліс (Бор)+МЕ, амонійно-карбоксилатних комплексонів, %: 
      •	B – 10,0
      •	N – 2,0
      <span className="paragraph"></span>
    Дефіцит бору в рослинах спричиняє: 
      •	Нагромадження фенолів (токсичні сполуки життєдіяльності рослин);
      •	Гальмування розвитку генеративних органів: зменшення числа квіток та зав’язі, зменшення кількості стручків і насіння (ріпак), дуплистості плодів (буряк, картопля),  розвитку коричневої гнилі (капуста), буріння верхівки, припинення росту листків (соняшник);
      •	Деформування листків;
      •	Затримки в розвитку пилку.
      <span className="paragraph"></span>
    Переваги Мікро-Мінераліс (Бор)+МЕ:
      •	Завдяки інноваційній технології виробництва не справляє фітотоксичного впливу на рослину.
      •	Містить низку мікроелементів, які сприяють швидкому та повному засвоюванню бору рослиною.
      •	Сприяє розвитку генеративних органів.
      •	Збільшує кількість квіток та попереджає їх абортивність.
      •	Покращує здатність рослини протистояти бактеріальним хворобам, дуплистості плодів, гниллям.
      •	Підвищує вміст цукрів.
      •	Збільшує строки зберігання продукції.
      <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Культура: 
      •	Ріпак; 
      •	Цукровий буряк; 
      •	Кукурудза; 
      •	Бобові; 
      •	Соняшник; 
      •	Картопля; 
      •	Овочі;
      •	Сади, плодово-ягідні насадження та виноградники.
      <span className="paragraph"></span>
    Фаза розвитку: 
      •	фаза 4-6 листків, відновлення вегетації, фаза бутонізації; 
      •	фаза 6-8 листків, фаза 10-12 листків, перед змиканням рядів; 
      •	фаза 7-9 листків; 
      •	фаза бутонізації, початок налива зерна; 
      •	фаза 2-3 пари листків, фаза 5-6 пар листків; 
      •	фаза бутонізації; 
      •	через 2 тижні після сходів, через кожні 7-10 днів після першої обробки (двічі); 
      •	в період вегетації.
      <span className="paragraph"></span>
    Норма виливу робочої рідини: 200-300 л/га – польові культури та 600-1000 л/га для плодово-ягідних насаджень, садів та виноградників.</>),
  },
];
