import popular1 from "../assets/images/HerbicidesImages/osnova.webp";
import popular2 from "../assets/images/HerbicidesImages/traident.webp";
import popular3 from "../assets/images/HerbicidesImages/helios-ekstra.webp";
import popular4 from "../assets/images/HerbicidesImages/triatlon-prime.webp";
import popular5 from "../assets/images/FungicidesImages/fungimaks.webp";
import popular6 from "../assets/images/FungicidesImages/krosbi.webp";


export const popularProductsArr = [
  {
    productTitle: "Основа",
    path: "/osnova",
    src: popular1,
    price: 130.27,
    currency: "USD",
  },
  {
    productTitle: "Трайдент",
    path: "/traident",
    src: popular2,
    price: 66.62,
    currency: "USD",
  },
  {
    productTitle: "Геліос Екстра",
    path: "/helios-ekstra",
    src: popular3,
    price: 114.67,
    currency: "USD",
  },
  {
    productTitle: "Триатлон Прайм",
    path: "/triatlon-prime",
    src: popular4,
    price: 48.49,
    currency: "USD",
  },
  {
    productTitle: "Фунгімакс",
    path: "/fungimaks",
    src: popular5,
    price: 114.96,
    currency: "USD",
  },
  {
    productTitle: "Кросбі",
    path: "/krosbi",
    src: popular6,
    price: 80.11,
    currency: "USD",
  },
];
