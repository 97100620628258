import Fungicides1 from "../assets/images/FungicidesImages/asoksin.webp";
import Fungicides2 from "../assets/images/FungicidesImages/auris.webp";
import Fungicides3 from "../assets/images/FungicidesImages/vartis.webp";
import Fungicides4 from "../assets/images/FungicidesImages/karbesim.webp";
import Fungicides5 from "../assets/images/FungicidesImages/maestro.webp";
import Fungicides6 from "../assets/images/FungicidesImages/poligard.webp";
import Fungicides7 from "../assets/images/FungicidesImages/poligard-maksi.webp";
import Fungicides8 from "../assets/images/FungicidesImages/flutrivit.webp";
import Fungicides9 from "../assets/images/FungicidesImages/flutrivit-maksi.webp";
import Fungicides10 from "../assets/images/FungicidesImages/fungimaks.webp";
import Fungicides11 from "../assets/images/FungicidesImages/fungisil.webp";
import Fungicides12 from "../assets/images/FungicidesImages/cimoksil.webp";
import Fungicides13 from "../assets/images/FungicidesImages/berkut.webp";
import Fungicides14 from "../assets/images/FungicidesImages/brander.webp";
import Fungicides15 from "../assets/images/FungicidesImages/virtuoz.webp";
import Fungicides16 from "../assets/images/FungicidesImages/dezal.webp";
import Fungicides17 from "../assets/images/FungicidesImages/prenta.webp";
import Fungicides18 from "../assets/images/FungicidesImages/rinkoceb.webp";
import Fungicides19 from "../assets/images/FungicidesImages/scorasol.webp";
import Fungicides20 from "../assets/images/FungicidesImages/tinasol.webp";
import Fungicides21 from "../assets/images/FungicidesImages/tioma.webp";
import Fungicides22 from "../assets/images/FungicidesImages/fidelis.webp";
import Fungicides23 from "../assets/images/FungicidesImages/fontes.webp";
import Fungicides24 from "../assets/images/FungicidesImages/artis-plus.webp";
import Fungicides25 from "../assets/images/FungicidesImages/barret.webp";
import Fungicides26 from "../assets/images/FungicidesImages/dok-pro.webp";
import Fungicides27 from "../assets/images/FungicidesImages/bolivar-forte.webp";
import Fungicides28 from "../assets/images/FungicidesImages/korvizar-m.webp";
import Fungicides29 from "../assets/images/FungicidesImages/krosbi.webp";
import Fungicides30 from "../assets/images/FungicidesImages/relevant.webp";
import Fungicides31 from "../assets/images/FungicidesImages/tezis.webp";
import Fungicides32 from "../assets/images/FungicidesImages/telamus.webp";
import Fungicides33 from "../assets/images/FungicidesImages/fenix-duo.webp";
import Fungicides34 from "../assets/images/FungicidesImages/abakus.webp";
import Fungicides35 from "../assets/images/FungicidesImages/pictor.webp";
import Fungicides36 from "../assets/images/FungicidesImages/reks-duo.webp";
import Fungicides37 from "../assets/images/FungicidesImages/reks-plus.webp";
import Fungicides38 from "../assets/images/FungicidesImages/amistar-ekstra-280-SC.webp";
import Fungicides39 from "../assets/images/FungicidesImages/amistar-gold-250-se.webp";
import Fungicides40 from "../assets/images/FungicidesImages/elatus-ria-358-ke.webp";
import Fungicides41 from "../assets/images/FungicidesImages/miravis-duo.webp";
import Fungicides42 from "../assets/images/FungicidesImages/miravis-neo.webp";
import Fungicides43 from "../assets/images/FungicidesImages/miravis-prime.webp";
import Fungicides44 from "../assets/images/FungicidesImages/revus-top.webp";
import Fungicides45 from "../assets/images/FungicidesImages/ridomil-gold.webp";
import Fungicides46 from "../assets/images/FungicidesImages/svitch.webp";
import Fungicides47 from "../assets/images/FungicidesImages/uniform.webp";

export const fungicidesArr = [
  {
    productTitle: "Азоксин",
    path: "/asoksin",
    src: Fungicides1,
    volume: "5 л",
    price: 68.77,
    currency: "USD",
    text: (<>
    Опис: Азоксин - трансламінарний фунгіцид групи стробілуринів профілактично-лікувальної дії для захисту польових культур від збудників хвороб різних класів. Помічник у боротьбі зі складними хворобами.
    <span className="paragraph"></span>
Діюча речовина: Азоксистробін, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Контролює широкий спектр хвороб викликаних збудникамивищих та нижчих грибів;
	•	Один із наймобільніших стробілуринів – переміщується і розподіляється в межах листка, забезпечуючи повноцінний захист; 
	•	Потужні профілактичні та антиспоруляційні властивості запобігають проростанню спор, зараженню, і перешкоджають поширенню патогенів; 
	•	Має виражений фізіологічний та стимулюючий ефект подовжує період вегетації.
  <span className="paragraph"></span>
Механізм дії: 
Локально-системний фунгіцид захисної та лікувальної дії, переміщується трансламінарно та акропетально. Належить до інгібіторів мітохондріального дихання, блокує транспорт електронів. Володіє вираженими антиспорулянтними властивостями, запобігає утворенню спор та пригнічує ріст міцелію. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця;
	•	Соняшник.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Септоріоз листя і колоса, бура і жовта іржа,борошниста роса;
	•	Фомопсис.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +10...+25°C (нижній поріг + 8°C), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Азоксин, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні. Для цього рекомендовано додатково застосовувати ад'ювант Агропав Екстра у концентрації 0,05%-0,1%.
	•	Увага! Не застосовувати за підвищених температур (від +25°C), відносної вологості повітря нижче 40-50% та дефіциту ґрунтової вологи задля уникнення фітотоксичності.</>),
  },
  {
    productTitle: "Ауріс",
    path: "/auris",
    src: Fungicides2,
    volume: "5 л",
    price: 160.50,
    currency: "USD",
    text: (<>
    Опис: Ауріс - омбінований системний фунгіцид із рістрегулюючими властивостями для контролю фузаріозу колоса та комплексу найшкодочинніших хвороб у посівах зернових колосових і ріпака.
    <span className="paragraph"></span>
Діюча речовина: Тебуконазол, 240 г/л + пропіконазол, 60 г/л + протіоконазол, 60 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується. 
<span className="paragraph"></span>
Переваги: 
	•	Високоефективний контроль найбільш небезпечних хвороб листя та колоса (в т.ч. фузаріозу, видів іржі, піренофорозу та септоріозу); 
	•	Довготривала профілактична, лікувальна та викорінююча дії (захисний ефект до 4 тижнів); 
	•	Унікальна комбінація діючих речовин із різною мобільністю забезпечує повний захист всіх частин рослин;
	•	Суттєво знижує рівень мікотоксинів у зерні, які становлять серйозну небезпеку для галузі тваринництва та харчової промисловості; 
	•	Контролює основні хвороби ріпака: фомоз, альтернаріоз, борошнисту росу;
	•	Володіє вираженими рістрегулюючими властивостями, які запобігають переростанню та підвищують зимостійкість ріпака. 
  <span className="paragraph"></span>
Механізм дії: 
Збудники вищих грибів відрізняються від нижчих наявністю у складі клітинної стінки ергостеролу – стерину, без якого неможливе нормальне функціонування організму патогенів.Саме блокування виробництва ергостеролу є ключовим механізмом дії фунгіцидів групи триазолів та триазолінтіонів. 
Після проникнення тебуконазолу і пропіконазолу всередину рослини вони миттєво замінюють собою ланостерол (з якого утворюється ергостерол) та блокують цитохром Р450. А протіоконазол діє на інший цитохром – CYP51, що особливо важливо для запобігання розвитку резистентності. 
Внаслідок зв'язування цитохромів відбувається порушення процесу диметилювання та руйнація клітинних мембран, в результаті чого збудники гинуть. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 150-300 л/га (залежно від технічних показників обприскувача). 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима, ячмінь озимий;
	•	Ріпак озимий. 
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Піренофороз (жовта плямистість), борошниста роса, види іржі (бура, жовта та стеблова), септоріоз та фузаріоз листя, плямистості листя (темно-бура, сітчаста, облямована). 
	•	Фузаріоз, альтернаріоз та септоріоз колосу, оливкова плямистість колосу.
	•	Інгібування росту листя та підвищення стійкості до екстримальних погодних умов, фомоз, альтернаріоз, циліндроспоріоз, сіра та біла гнилі.
	•	Рістрегулююча дія, фомоз, склеротиніоз, циліндроспоріоз, борошниста роса.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
	•	Обприскування в період цвітіння-наливання колосу культури.
	•	Обприскування ріпаку восени в фази 3-6 листків.
	•	Обприскування посівів за висоти 15-20 см культури.
  <span className="paragraph"></span>
Рекомендації щодо застосування:
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +12…+25 ºС (нижній поріг + 10 ºС), вологість повітря – 65-70%, швидкість вітру - не більше 5 м/с у ранкові або вечірні години.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні робочим розчином, для цього варто застосовувати Агропав Екстра (0,05%-0,1%).
	•	Не рекомендується застосовувати Ауріс, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
  <span className="paragraph"></span>
Увага! Для контролю фузаріозу колосу обприскування необхідно проводити на початку цвітіння (при появі перших пиляків, у фазі BBCH від 61 до 63), краще використовувати двофакельні розпилювачі для максимального покриття всього колосу. </>),
  },
  {
    productTitle: "Вартіс",
    path: "/vartis",
    src: Fungicides3,
    volume: "5 л",
    price: 280.79,
    currency: "USD",
    text: (<>
    Опис: Вартіс - високоефективний SDHI-вмісний фунгіцид із потужною профілактично-лікувальною дією та трансламінарними властивостями для захисту соняшника і ріпака від склеротиніозу та інших хвороб.
    <span className="paragraph"></span>
Діюча речовина: Боскалід, 250 г/л + протіоконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги:
	•	Інноваційна комбінація високоефективних діючих речовин; 
	•	Потужна профілактична, лікувальна та викорінююча дії; 
	•	Довготривалий захисний ефект; 
	•	Краще рішення у контролі склеротиніозу та альтернаріозу; 
	•	Найнижчий ризик виникнення резистентності в сегменті;
	•	Менша залежність від доступної вологи в грунті; 
	•	Практично безпечний для бджіл (4 клас), що особливо важливо при застосуванні в період цвітіння.
  <span className="paragraph"></span>
Механізм дії: 
Боскалід - SDHI-фунгіцид групи піридинкарбоксамідів (інгібітор сукцинатдегідрогенази). Пригнічує проростання спор та ріст ростових трубок патогенів. Основна ціль боскаліду – мітохондрії, де відбувається клітинне дихання, блокується фермент SDH (в комплексі II), порушується функція перенесення електронів в дихальному ланцюжку та виробництво АТФ. Боскалід - локально-системна діюча речовина, тому мобільність у рослині дуже обмежена. Володіє вираженими трансламінарними властивостями, а також профілактичною та лікувальною дією. 
Протіоконазол – фунгіцид групи триазолінтіонів, локалізується в місці нанесення, забезпечує потужні профілактичні, лікувальні та викорінюючі властивості. Діюча речовина блокує синтез ергостеролу, але пригнічує зокрема цитохром – CYP51 (фермент), що призводить до морфологічних та функціональних змін клітинної стінки у патогенів. За рахунок метаболізаціїпротіоконазолу в рослині до стійкої сполуки протіоконазолдестіо – забезпечується довготривалий період захисної дії. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га (залежно від технічних показників обприскувача).
<span className="paragraph"></span>
Культура: 
	•	Соняшник;
	•	Ріпак.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Склеротиніоз, альтернаріоз, фомоз.
  <span className="paragraph"></span>
Способи та час обробки: 
	•	Обприскування в період вегетації культур, в т.ч. під час цвітіння (профілактично, та за перших ознак хвороб).</>),
  },
  {
    productTitle: "Карбезим",
    path: "/karbesim",
    src: Fungicides4,
    volume: "5 л",
    price: 30.10,
    currency: "USD",
    text: (<>
    Опис: Карбезим - системний фунгіцид профілактично-лікувальної дії для захисту польових культур від комплексу хвороб на початкових етапах їх розвитку.
    <span className="paragraph"></span>
Діюча речовина: Карбендазим, 500 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Висока ефективність на початкових етапах зараження запобігає шкодочинності патогенів;
	•	Виражені захисні та лікувальні властивості забезпечують контроль навіть після проникнення збудників;
	•	Працює за понижених температур, від +8º С, коли інші фунгіциди малоефективні; 
	•	Комбінується із продуктами інших хімічних класів.
  <span className="paragraph"></span>
Механізм дії: 
Системна діюча речовина із захисними та лікувальними властивостями. Блокує процес ділення ядра у патогенів шляхом зв'язування бета-тубуліну, за рахунок якого формуються мікротрубочки. Як наслідок – збудник гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Борошниста роса, септоріоз.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Практичний досвід застосування: 
<span className="paragraph"></span>
Культура: 
	•	Ріпак; 
	•	Соняшник; 
	•	Соя, горох; 
	•	Буряки цукрові.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Альтернаріоз, борошниста роса; 
	•	Септоріоз, фомоз, антракноз. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +10...+25°C (нижній поріг + 8°C), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Карбезим, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні. Для цього рекомендовано додатково застосовувати ад'ювант Агропав Екстра у концентрації 0,05%-0,1%.</>),
  },
  {
    productTitle: "Маестро",
    path: "/maestro",
    src: Fungicides5,
    volume: "5 л",
    price: 83.95,
    currency: "USD",
    text: (<>
    Опис: Маестро - системний фунгіцид групи триазолів для тривалого захисту зернових колосових культур від комплексу найбільш шкодочинних хвороб. Зернові колосові без плямистостей та іржі. 
    <span className="paragraph"></span>
Діюча речовина: Ципроконазол, 80 г/л, пропіконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується.
<span className="paragraph"></span>
Переваги: 
	•	Довготривалий захист зернових колосових культур від комплексу шкодочинних хвороб;
	•	Різна системність діючих речовин дозволяє контролювати збудники навіть на постінфекційних етапах (викорінююча дія);
	•	Вдосконалена формуляція забезпечує якісне змочування та пенетрацію; 
	•	Швидке проникнення нівелює негативний вплив факторів навколишнього середовища.  
  <span className="paragraph"></span>
Механізм дії: 
Пропіконазол та ципроконазол – інгібітори синтезу ергостеролу – ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок – патоген припиняє свій розвиток та гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Борошниста роса, септоріозу, види іржі, септоріоз листя та колосу, гельмінтоспоріоз.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +12...+25°C (нижній поріг + 8°C), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні.
	•	Не рекомендується застосовувати Маестро, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
  <span className="paragraph"></span>
Увага! Додавання Азоксину забезпечує фізіологічний та стимулюючий ефект на культури, проте варто уникати застосування при температурі вище +25°C та вологості повітря менше 40-50% (можливий прояв фітотоксичності). </>),
  },
  {
    productTitle: "Полігард",
    path: "/poligard",
    src: Fungicides6,
    volume: "5 л",
    price: 46.99,
    currency: "USD",
    text: (<>
    Опис: Полігард -системний фунгіцид групи триазолів лікувально-викорінюючої дії для захисту польових культурвід комплексу хвороб та рістрегуляції ріпака. Надійний триазол для захисту колоса та рістрегуляції ріпака.
    <span className="paragraph"></span>
Діюча речовина: Тебуконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується.
<span className="paragraph"></span>
Переваги: 
	•	Захищає культури на всіх стадіях інфікування;  
	•	Володіє вираженими рістрегулюючими властивостями; 
	•	Забезпечує довготривалий захист від комплексу хвороб; 
	•	Запобігає зараженню фузаріозом колоса. 
  <span className="paragraph"></span>
Механізм дії: 
Системний фунгіцид захисної та лікувальної дії, інгібітор синтезу ергостеролу – ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок – патоген припиняє свій розвиток та гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.  
<span className="paragraph"></span>
Культура: 
	•	Ріпак озимий та ярий; 
	•	Соняшник. 
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Альтернаріоз;
	•	Фомоз, септоріоз, альтернаріоз.
  <span className="paragraph"></span>
Спосіб та час обприскування: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування:
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +12...+25°C (нижній поріг + 10°C), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Полігард, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні.
  <span className="paragraph"></span>
Увага! Додавання Азоксину забезпечує фізіологічний та стимулюючий ефект на культури, проте варто уникати застосування при температурі вище +25°C та вологості повітря менше 40-50% (можливий прояв фітотоксичності).</>),
  },
  {
    productTitle: "Полігард Максі",
    path: "/poligard-maksi",
    src: Fungicides7,
    volume: "5 л",
    price: 55.57,
    currency: "USD",
    text: (<>
    Опис: Полігард Максі - системний фунгіцид лікувально-викорінюючої дії групи триазолів із підвищеною концентрацією для захисту польових культур від комплексу хвороб та рістрегуляції ріпака. Концентрований триазол для захисту колоса та рістрегуляції ріпака.
    <span className="paragraph"></span>
Діюча речовина: КС, тебуконазол, 430 г / л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	Краща сумісність в концентрованих робочих розчинах; 
	•	Захищає культури на різних стадіях інфікування; 
	•	Запобігає зараженню фузаріозом колоса; 
	•	Володіє вираженими рістрегулюючими властивостями; 
	•	Забезпечує довготривалий захист від комплексу хвороб.
  <span className="paragraph"></span>
Механізм дії: 
Системний фунгіцид захисної та лікувальної дії, інгібітор синтезу ергостеролу-ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок — патоген припиняє свій розвиток та гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура:
	•	Пшениця.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Фузаріози, септоріоз листя та колосу, борошниста роса.
  <span className="paragraph"></span>
Спосіб та час обприскування: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Максимальна ефективність досягається за умови своєчасного застосування фунгіциду з урахуванням даних прогнозів і перших ознак появи захворювань.
	•	Оптимальні умови для внесення Полігарду Максі від +12°С до +25 °С.
	•	Використання Полігарду Максі як рістрегулятора:
	•	Починати рістрегуляцію озимого ріпаку потрібно у фазі 3-4 справжніх листки.
	•	Норма внесення Полігарду Максі становить 0,09-0,14 л на один листок ріпаку. Вибір норми залежить від багатьох факторів: особливості розвитку гібриду, підживлення, температури повітря, вологості ґрунту, попередника.
	•	Для підсилення ретардантного ефекту Полігард Максі можна
	•	Комбінувати із Трінексом та хлормекватхлоридом.
	•	Норма витрат робочого розчину залежить від оброблюваної культури та способу внесення 200-400 л/га для польових і овочевих культур, 500-1000 л/га, для виноградників, для садів 500-1000 л/га.
  <span className="paragraph"></span>
Увага! Додавання Азоксину забезпечує фізіологічний та стимулюючий ефект на культури, проте варто уникати застосування при температурі вище +25°C та вологості повітря менше 40-50% (можливий прояв фітотоксичності).</>),
  },
  {
    productTitle: "Флутривіт",
    path: "/flutrivit",
    src: Fungicides8,
    volume: "5 л",
    price: 60.51,
    currency: "USD",
    text: (<>
    Опис: Флутривіт — системно-контактний фунгіцид профілактичної та лікувальної дії.
    <span className="paragraph"></span>
Діюча речовина: Флутриафол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Широкий спектр лікувальної та захисної дії на багатьох культурах;
	•	Діюча речовина препарату належить до найбільш мобільних із групи триазолів;
	•	Завдяки системній дії препарат забезпечує тривалий захисний ефект;
	•	Володіє фумігантними властивостями;
	•	Починає працювати за кілька годин після внесення;
	•	Гнучкість у застосуванні (незалежно від фази розвитку культури);
	•	Завдяки особливостям препаративної форми забезпечує відмінне змочування та швидке проникнення;
	•	Препарат стійкий до змивання дощем вже через 2 години після обприскування;
	•	Працює за низьких температур повітря (від +6°С).
  <span className="paragraph"></span>
Механізм дії: 
Діюча речовина флутриафол блокує синтез ергостерину, порушує процес поділу клітин, чим викликає повну загибель патогенів. Препарат швидко проникає в рослину через листя і переміщається акропетально від основи до вершини. Отже, він захищає ті частини листя, на які розчин не потрапив, а також молоді відростки, що з'явилися після обробки.Швидкість та тривалість дії пригнічення патогенів починається через 6-8 годин після внесення препарату. Флутривіт має тривалий захистний ефект до 4 тиждднів, в залежності від погодних умов та ступеня інфікування.
<span className="paragraph"></span> 
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Борошниста роса, септоріоз листя та колосу, бура іржа, альтернаріоз колоса.
  <span className="paragraph"></span>
Спосіб та час обприскування:
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування:
    Для озимих та ярих зернових культур:
	•	Максимальний ефект досягається при застосуванні на ранніх етапах розвитку хвороби у фазу кущення.
	•	Важливо захистити два верхніх листка, від яких залежить інтенсив- ність наливу зерна, його якість і врожайність культури.
	•	Повторна обробка для продовження захисної дії проводиться у період появи прапорцевого листка.
	•	Превентивні обробки більш обґрунтовані, оскільки в цьому разі рослини залишаються здоровими до кінця вегетації.
	•	Препарат вирізняється сильною фумігантною дією на збудників борошнистої роси.
  <span className="paragraph"></span>
Для цукрових буряків:
	•	Використання препарату можливе протягом усієї вегетації культури до або при появі перших ознак хвороби.
  <span className="paragraph"></span>
	•	Для ріпаку:
	•	Завдяки відмінним лікувальним властивостям використанн Флутривіту можливе протягом усієї вегетації культури.
	•	Важливою умовою для роботи препарату є гарне змочування листкової поверхні ріпаку. Тому зважаючи на фізіологічні особливості культури (потужний восковий наліт), слід додавати ад'ювант АгроПАВ Екстра в нормі 0,05-0,1 % розчину (100-200 мл/га).
	•	Норма витрат робочого розчину залежить від оброблюваної культури та способу внесення: 200-400 л/га для польових і овочевих культур, 500-1000 л/га для виноградників та садів.</>),
  },
  {
    productTitle: "Флутривіт Максі",
    path: "/flutrivit-maksi",
    src: Fungicides9,
    volume: "5 л",
    price: 102.31,
    currency: "USD",
    text: (<>
    Опис: Флутривіт Максі - концентрована та вдосконалена формуляція флутриафолу для швидкого зупинення розвитку хвороб у посівах польових культур. Турбо-триазол в максимальній концентрації.
    <span className="paragraph"></span>
Діюча речовина: Футриафол, 500 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	Контролює комплекс найбільш шкодочинних хвороб; 
	•	Найвища системність серед триазолів – швидка дія на збудник; 
	•	Виражена лікувальна дія дозволяє знищувати патоген після проникнення;
	•	Захищає навіть необроблені частини та нові прирости; 
	•	Відмінне змочування та проникнення через листову поверхню запобігає втратам продукту і спрямовує його у повному обсязі на боротьбу зі збудниками хвороб. 
  <span className="paragraph"></span>
Механізм дії: 
Системний фунгіцид захисної та лікувальної дії, інгібітор синтезу ергостеролу – ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок – патоген припиняє свій розвиток та гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Соняшник.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Борошниста роса, септоріоз листя та колосу, бурі іржа;
	•	Іржа, альтернаріоз, фомоз та фомопсис (превентивно).
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +10...+25°C, швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Флутривіт Максі, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні.</>),
  },
  {
    productTitle: "Фунгімакс",
    path: "/fungimaks",
    src: Fungicides10,
    volume: "10 л",
    price: 114.96,
    currency: "USD",
    text: (<>
    Опис: Фунгімакс -системний трикомпонентний фунгіцид захисної, лікувальної та викорінюючої дії для захисту польових культур від комплексу хвороб.
    <span className="paragraph"></span> 
Діюча речовина: Тіофанат-метил, 200 г/л, пропіконазол, 120 г/л, триадимефон, 50 г/л.
<span className="paragraph"></span>
Препаративна форма: Суспо-емульсія.
<span className="paragraph"></span>
Переваги: 
	•	Ефективне рішення для захисту зернових на початку вегетації (Т0/Т1);
	•	Контроль найбільш шкодочинних збудників хвороб – борошнистої роси, іржі, септоріозу та ін.;
	•	Різні механізми дії запобігають виникненню резистентності;
	•	Працює за понижених температур, на відміну від інших фунгіцидів .
  <span className="paragraph"></span>
Механізм дії: 
Тіофанат-метил — системна діюча речовина із захисними та лікувальними властивостями. Блокує процес ділення ядра у патогенів шляхом зв'язування бета-тубуліну, за рахунок якого формуються мікротрубочки.Пропіконазол та триадимефон — інгібітори синтезу ергостеролу, ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок — патогени припиняють свій ріст та гинуть. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра; 
	•	Ячмінь озимий та ярий.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Борошниста роса, септоріоз, фузаріоз листя;
	•	Борошниста роса, іржа, гельмінтоспоріоз.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +10...+25(нижній поріг +8°C), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Фунгімакс, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні.</>),
  },
  {
    productTitle: "Фунгісил",
    path: "/fungisil",
    src: Fungicides11,
    volume: "5 л",
    price: 172,
    currency: "USD",
    text: (<>
    Опис: Фунгісил - системний стробілуринвмісний фунгіцид захисної, лікувальної та викорінюючої дії із трансламінарними властивостями та фізіологічним ефектом. 
    <span className="paragraph"></span>
Діюча речовина: Піраклостробін, 200 г/л, пропіконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується. 
<span className="paragraph"></span>
Переваги: 
	•	Контроль найбільш шкодочинних хвороб у посівах с/г культур;
	•	Знищення збудників на різних етапах інфекційного процесу;
	•	Виражені фізіологічні властивості – подовжують процес фотосинтезу;
	•	Краще рішення для контролю плямистостей на зернових (в т.ч. піренофорозу) при обробці в Т2.
  <span className="paragraph"></span>
Механізм дії: 
Піраклостробін – локально-системний фунгіцид групи стробілуринів (метоксикарбамати). Механізм дії полягає у блокуванні транспорту електронів у дихальному ланцюжку мітохондрій, що призводить до припинення дихання та виробництва енергії. Як результат проростання ростових трубок не відбувається.Пропіконазол – інгібітор синтезу ергостеролу, ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок – патогени припиняють свій ріст та гинуть. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра;
	•	Ячмінь озимий та ярий.
  <span className="paragraph"></span>
 Цільові об’єкти: 
	•	Борошниста роса, септоріоз, фузаріоз листя, піренофороз; 
	•	Борошниста роса, іржа, гельмінтоспоріоз. 
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +10...+25°C, швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Фунгісил, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або ж посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні.
  <span className="paragraph"></span>
Увага! Не застосовувати за підвищених температур (від +25°C) та відносної вологості повітря нижче 40-50%, задля уникнення фітотоксичності. </>),
  },
  {
    productTitle: "Цимоксил",
    path: "/cimoksil",
    src: Fungicides12,
    volume: "2,5 кг",
    price: 27.56,
    currency: "USD",
    text: (<>
    Опис: Цимоксил - системний фунгіцид лікувально-викорінюючої дії із антиспорулянтними властивостями для контролю справжніх і несправжніх грибів.
    <span className="paragraph"></span>
Діюча речовина: Цимоксаніл, 300 г/кг, флутриафол, 210 г/кг.
<span className="paragraph"></span>
Препаративна форма: Порошок, що змочується.
<span className="paragraph"></span>
Переваги: 
	•	Контроль справжніх та несправжніх грибів;
	•	Захисна, лікувальна, викорінююча та антиспорулянтна дії;
	•	Швидке зупинення інфекції на будь-якому етапі розвитку;
	•	Вища ефективність у контролі плямистостей листя сої порівняно із конкурентами.
  <span className="paragraph"></span>
Механізм дії: 
Цимоксаніл – діюча речовина із невідомим механізмом дії. Володіє вираженими викорінюючими та антиспорулянтнимивластивостями. Проникає через клітини, перерозподіляється у паренхімі та зупиняє розвиток патогену.Флутриафол – системний фунгіцид лікувальної дії, інгібітор синтезу ергостеролу – ключового стерину, без якого не відбувається будівництво та функціонування клітинних стінок гриба. Як наслідок – патоген припиняє свій ріст та гине. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Соняшник;
	•	Соя.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Несправжня борошниста роса (пероноспороз), фомоз, фомопсис, альтернаріоз;
	•	Пероноспороз, альтернаріоз, антракноз, іржа.
  <span className="paragraph"></span>
Спосіб та час обробки:
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять в період вегетації культур профілактично та за перших ознак прояву хвороб.
	•	Оптимальна температура застосування +12...+25 ºС (нижній поріг +10 ºС), швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
	•	Не рекомендується застосовувати Цимоксил, коли культури перебувають у стресовому стані, при перепадах денних та нічних температур, в очікуванні приморозків або посухи.
	•	Для досягнення максимальної ефективності варто забезпечити якісне та рівномірне покриття листової поверхні. Для цього рекомендовано додатково застосовувати ад’ювант АгроПАВ Екстра у концентрації 0,05%-0,1%.
  <span className="paragraph"></span>
Увага!Для повноцінного розчинення пакетів Цимоксил необхідно попередньо замочити їх у воді (з розрахунку 1:10) на 10-15 хв без перемішування. Обов’язково дотримуйтесь інструкції з використання!
</>),
  },

  // Второй файл

  {
    productTitle: "Беркут",
    path: "/berkut",
    src: Fungicides13,
    volume: "5 л",
    price: 54.81,
    currency: "USD",
    text: (<>
    Опис: Беркут - фунгіцид системної дії для застосування на посівах ріпаку та озимої пшениці. На ріпаку застосовується також як ріст регулятор.
    <span className="paragraph"></span> 
Діюча речовина: Тебуконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Фунгіцид та регулятор росту в одному препараті;
	•	Фунгіцид як профілактичної, так і лікувальної дії; 
	•	Відрізняється добре вираженим стоп-ефектом; 
	•	Має широкий спектр біологічної активності; 
	•	Тривалий захист вегетативних та генеративних органів зернових культур від основних хвороб;
	•	Низький рівень фітотоксичності. 
  <span className="paragraph"></span>
Механізм дії: 
Тебуконазол ‒ діюча речовина системної дії, швидко надходить в рослину.
Як фунгіцид ‒ порушує процес синтезу ергостеролів в клітинах патогена, внаслідок цього мембрани втрачають свої біологічні та механічні властивості, і гриб гине.
Як рістрегулюючий засіб при застосуванні на посівах озимого ріпаку в осінній період ‒ препарат гальмує формування надземної маси рослин. Це сприяє більш інтенсивному накопиченню пластичних речовин, стимулює розвиток кореневої системи та кореневої шийки, що, в свою чергу, істотно покращує зимостійкість рослин.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра; 
	•	Ріпак;
	•	Ріпак озимий. 
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Бура, стеблова та жовта іржа; 
	•	Септоріоз, піренофороз, темно-бура плямистість, борошниста роса, хвороби колоса (фузаріоз, септоріоз, альтернаріоз);
	•	Альтернаріоз, циліндроспоріоз; 
	•	Інгібування росту листя, підвищення стійкості до екстремальних погодних умов.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять за допомогою наземних обприскувачів з нормою витрати робочого розчину 200‒300 л/га. У випадках густого стеблостою норму витрати робочого розчину доцільно збільшувати до 400 л/га для забезпечення повного та рівномірного покриття рослин. Але в будь-якому випадку норма витрати робочого розчину не повинна спричиняти його стікання з поверхні рослин під час внесення. </>),
  },
  {
    productTitle: "Брандер",
    path: "/brander",
    src: Fungicides14,
    volume: "5 л",
    price: 94.96,
    currency: "USD",
    text: (<>
    Опис: Брандер - фунгіцид системної дії для захисту зернових колосових культур, соняшника, сої від широкого спектру хвороб.  
    <span className="paragraph"></span>
Діюча речовина: Тебуконазол, 160 г/л; азоксистробін, 200 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	Унікальна комбінація двох діючих речовин з різних хімічних класів, які мають різні механізми дії на патогенні організми; 
	•	Висока ефективність дії на хвороби листків та стебел зернових колосових культур, хвороб соняшнику та сої;
	•	Подовжує період вегетації за рахунок інгібування процесу утворення етилену (гормону старіння) в рослині; 
	•	Лікувальна та довготривала профілактична дія.
  <span className="paragraph"></span>
Механізм дії: 
Азоксистробін – діюча речовина з класу стробілуринів. Поширюється в рослинах системно та трансламінарно. Блокує передачу електронів між цитохромами в мітохондріях клітин гриба. Інгібує проростання спор та апресоріїв, перешкоджає формуванню гіф гриба та утворенню нових спор.
Тебуконазол – діюча речовина системної дії з класу триазолів, швидко проникає в рослину, рухається по ксилемі у висхідному напрямку. Пригнічує біосинтез ергостерину в мембранах клітин гриба, внаслідок чого мембрани втрачають свої біологічні та механічні властивості, і патоген гине.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима;
	•	Пшениця яра;
	•	Ячмінь озимий; 
	•	Ячмінь ярий.
	•	Соняшник; 
	•	Соя.
  <span className="paragraph"></span>
Об’єкт проти якого обробляється: 
	•	Септоріоз, гельмінтоспоріозні плямистості листя, борошниста роса, іржа (види), альтернаріоз, фузаріоз;
	•	Несправжня борошниста роса, біла гниль, септоріоз, фомоз, фомопсис; 
	•	Пероноспороз, біла гниль, аскохітоз.
  <span className="paragraph"></span>
Спосіб внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять шляхом наземного та авіаобприскування в період вегетації профілактично, коли погодні умови сприяють розвитку хвороб, або при появі перших їх симптомів. У випадках густого стеблостою норму витрати робочого розчину доцільно збільшувати для забезпечення повного та рівномірного покриття рослин, проте ця норма не повинна спричиняти стікання робочого розчину з поверхні рослин під час внесення. </>),
  },
  {
    productTitle: "Віртуоз",
    path: "/virtuoz",
    src: Fungicides15,
    volume: "5 л",
    price: 90.95,
    currency: "USD",
    text: (<>
    Опис: Віртуоз - комбінований фунгіцид системної дії для захисту сільськогосподарських культур в період вегетації.  
    <span className="paragraph"></span>
Діюча речовина: Пропіконазол, 250 г/л; ципроконазол, 80 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії. 
<span className="paragraph"></span>
Переваги: 
	•	Захист зернових колосових від борошнистої роси, різних видів іржі (бура, жовта стеблова, корончаста) та плямистостей листя і стебел; 
	•	Захист зернових колосових від хвороб колоса — фузаріоз та септаріоз.
  <span className="paragraph"></span>
Механізм дії: 
Препарат поглинається надземною частиною рослин, швидко переміщується акропетально по ксилемі. Діючі речовини порушують процеси синтезу ергостеролів у фітопатогенів.  Внаслідок цього мембрани клітин гриба втрачають свої біологічні та механічні властивості. Ріст та розвиток гриба призупиняється, і згодом патоген гине. Пропіконазол виявляє профілактичну, лікувальну і винищувальну системну дію, пригнічує спороутворення у патогенів, за рахунок газової фази знижує розвиток борошнисторосяних грибів. Має також рістрегулюючу дію, що забезпечує краще засвоєння рослинами вуглекислого газу і підвищує активність фотосинтезу в рослинах. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Борошниста роса, іржа, септоріоз листя та колосу, церкоспорельоз, фузаріоз колосу, перенофороз.
  <span className="paragraph"></span>
Максимальна кратність обробки; 
	•	2.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням у період вегетації культурних рослин профілактично, коли умови сприяють розвитку та поширенню захворювань, або з лікувальною метою – при виявленні перших візуальних ознак ураження рослин. Максимальна ефективність препарату спостерігається при його застосуванні на початкових етапах інфікування, за появи перших поодиноких симптомів хвороби – плям, хлорозів, пустул тощо. </>),
  },
  {
    productTitle: "Дезал",
    path: "/dezal",
    src: Fungicides16,
    volume: "5 л",
    price: 29.47,
    currency: "USD",
    text: (<>
    Опис: Дезал — універсальний фунгіцид для захисту сільськогосподарських культур в період вегетації.
    <span className="paragraph"></span>
Діюча речовина: Карбендизим, 500 гЇл.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Препарат забезпечує ефективний захист від хвороб, що визиваються грибами з роду Fusarium (коренева гниль, снігова пліснява, фузаріоз, суха гниль), а також віж сажкових хвороб, борошнистої роси, септоріозу, церкоспорельозу, ризоктоніозу, білої та сірої гнилі; 
	•	За рахунок системної дії препарат забезпечує захист від хвороб усіх органів рослини; 
	•	Відсутність фітотоксичності по відношенню до культурних рослин за умов додержання регламенту застосування; 
	•	Порівняно невелика вартість препарату для обробки 1 га посівної площі; 
	•	Безпечний для навколишнього середовища.
  <span className="paragraph"></span>
Механізм дії: 
Карбендазим діє системно, проникає в рослину, переміщується акропетально по ксилемі, виявляє як захисну, так і лікувальну дію. Блокує процеси поділу клітин (мітоз) у фітопатогена, завдяки чому активно пригнічує утворення ростових трубочок при проростанні спор (конідій) грибів, а також зупиняє ріст міцелію гриба. Довгий час зберігається на поверхні рослин, забезпечуючи тим самим тривалий захист від хвороб. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Ячмінь.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Борошниста роса, септоріоз, гельмінтоспоріоз; 
	•	Плямистість листя.
  <span className="paragraph"></span>
Максимальна кратність обробки за сезон: 
	•	2.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять за допомогою наземних обприскувачів в період вегетації культурних рослин профілактично (коли погодні умови сприяють розвитку та поширенню хвороб) або за появи перших візуальних ознак захворювання. Внесення слід проводити дрібнокрапельним методом із застосуванням плоскоструменевих форсунок з тиском в системі 2,5‒3,0 кг/см2. Швидкість руху агрегату має бути 6‒7 км/год. </>),
  },
  {
    productTitle: "Прента",
    path: "/prenta",
    src: Fungicides17,
    volume: "5 л",
    price: 70,
    currency: "USD",
    text: (<>
    Опис: Прента — комбінований фунгіцид системної дії для захисту озимої пшениці, ячменю озимого та ярого.
    <span className="paragraph"></span>
Діюча речовина: Дифеноконазол, 125 г/л: триадимефон, 125 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Ефективний захист зернових колосових культур від комплексу ранньовесняних хвороб (плямистості, борошниста роса); 
	•	Тривала захисна дія.
  <span className="paragraph"></span>
Механізм дії: 
Дифеноконазол ‒ діюча речовина системної дії з класу триазолів, поглинається рослиною та поширюється на всі ділянки, що потребують захисту. Порушує процес синтезу ергостеролів в клітинах патогена, внаслідок чого мембрани клітин гриба втрачають свої механічні і біологічні властивості, має лікувальну та профілактичну дію, тривалий захисний період.
Триадимефон – діюча речовина системної дії з класу триазолів, переміщується акропетально по судинній системі, блокує фермент С-14-деметилазу і таким чином порушує синтез стеролу в клітинах вищих грибів, перешкоджає розвитку гаусторіїв та апресоріїв, росту міцелію та формуванню спор, має захисний, лікувальний та антиспорулянтний ефект.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га. 
<span className="paragraph"></span>
Регламент застосування препарату. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Ячмінь озимий та ярий.
  <span className="paragraph"></span>
Шкідливі об’єкти: 
	•	Септоріоз, піренофороз, борошниста роса, іржа; 
	•	Гельмінтоспоріозі плямистості, борошниста роса, іржа.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування посівів в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням в період вегетації профілактично, коли погодні умови сприяють розвитку та поширенню збудників захворювань або за появи перших симптомів захворювання.</>),
  },
  {
    productTitle: "Рінкоцеб",
    path: "/rinkoceb",
    src: Fungicides18,
    volume: "5 кг",
    price: 44.41,
    currency: "USD",
    text: (<>
    Опис: Рінкоцеб - контактно-системний фунгіцид для захисту виноградників та томатів від широкого спектру хвороб. 
    <span className="paragraph"></span>
Діюча речовина: Металаксил, 80 г/кг; манкоцеб, 640 г/кг.
<span className="paragraph"></span>
Препаративна форма: Порошок, що змочується.
<span className="paragraph"></span>
Переваги: 
	•	Подвійний механізм дії (контактно-системний) забезпечує надійний захист рослини від зовнішньої інфекції, а також знижує інфекцію в рослинні на ранніх етапах ураження; 
	•	Стійкий до змивання дощем; 
	•	Сумісний з іншими пестицидами, що мають нейтральну реакцію, однак у кожному конкретному випадку необхідна попередня перевірка бакових компонентів на сумісність.
  <span className="paragraph"></span>
Механізм дії: 
Металаксил належить до речовин системної дії, за 30‒60 хвилин після обприскування поглинається надземною частиною рослини. Інгібує синтез протеїнів в клітинах патогена за рахунок порушення процесу синтезу РНК в рибосомах. Переміщуючись акропетально, здійснює профілактичну, а на ранній стадії інфекції ‒ лікувальну та захисну дії. Манкоцеб належить до речовин контактної дії, інгібує активність ензимів за рахунок порушення процесу синтезу АТФ. Проникає в рослину за 30 хвилин, створюючи захисний шар на її поверхні, та забезпечує, тим самим, захист культури впродовж 10‒14 днів, навіть у дощову погоду.
<span className="paragraph"></span>
Норма витрати робочого розчину: 300‒500 л/га на томатах та 800‒1000 л/га на виноградниках.  
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Виноградники; 
	•	Томати.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Мілдью, чорна плямистість, сіра та біла гнилі;
	•	Альтернаріоз, фітофтороз.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням з нормою витрати робочого розчину 300‒500 л/га на томатах та 800‒1000 л/га на виноградниках. Обов'язковим є повне та рівномірне покриття рослин робочим розчином. Норма витрати робочого розчину не повинна спричиняти його стікання з поверхні рослин, що обробляються. </>),
  },
  {
    productTitle: "Скоразол",
    path: "/scorasol",
    src: Fungicides19,
    volume: "1 л",
    price: 25.75,
    currency: "USD",
    text: (<>
    Опис: Скоразол - системний фунгіцид з лікувально-профілактичними властивостями для боротьби з основними хворобами яблуні. 
    <span className="paragraph"></span>
Діюча речовина: Дифеноконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Ефективний захист не тільки листя, але й плодів; 
	•	Тривала захисна дія; 
	•	Відсутність негативного впливу дозволяє застосовувати препарат незалежно від фази розвитку культурних рослин; 
	•	Швидке поглинання рослинами; 
	•	Стійкий до змивання — дощ через 2 години після обробки не впливає на ефективність дії препарату; 
	•	Сумісність з іншими препаратами дозволяє застосовувати препарат у бакових сумішах з іншими пестицидами в комплексних системах захисту плодових насаджень, проте в кожному окремому випадку необхідна попередня перевірка партнерських компонентів бакової суміші на сумісність. 
  <span className="paragraph"></span>
Механізм дії: 
Дифеноконазол ‒ діюча речовина системної дії, швидко поглинається рослиною та поширюється на всі ділянки, що потребують захисту. Порушує процес синтезу ергостеролів в клітинах патогена, внаслідок чого мембрани клітин гриба втрачають свої механічні і біологічні властивості. Препарат має лікувальну та профілактичну дію. 
<span className="paragraph"></span>
Норма витрати робочого розчину:  800‒1000 л/га. 
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Яблуня.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Парша, борошниста роса.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат застосовують профілактично, коли погодні умови сприяють розвитку та поширенню збудника парші яблуні (температура повітря +18...+22°С та наявність крапельної вологи: дощ, роса, туман), або при появі перших ознак хвороби. Препарат вносять наземним обприскуванням з нормою витрати робочого розчину 800‒1000 л/га. Обов'язковим є повне та рівномірне покриття всієї поверхні рослин робочим розчином під час внесення препарату. Можлива обробка в період цвітіння.
<span className="paragraph"></span>
Мінімальну норму витрати робочого розчину
доцільно застосовувати на початку вегетації та/або при низькому рівні розвитку інфекції.
<span className="paragraph"></span>
Максимально рекомендовану норму витрати робочого розчину
доцільно застосовувати при повному розвитку листкової маси та/або за умов сильного інфекційного тиску.
У період від фази рожевого бутону до фази діаметру плоду 10 мм обробку проводять з інтервалом 7‒10 днів.
У більш пізні фази вегетації інтервал між обробками за умов сильного розвитку хвороби має становити 8 днів, за умов слабкого або помірного поширення захворювання цей інтервал можна збільшувати до 10‒12 днів.
<span className="paragraph"></span>
Інтервал між профілактичними обробками ‒ 10‒12 днів. Максимальна ефективність дії препарату спостерігається, якщо його було застосовано не пізніше 3 діб після появи перших візуальних ознак хвороби. У разі подовженої загрози масового поширення хвороби, а також для захисту сортів яблуні, чутливих до парші, доцільним є застосування бакової суміші: Скоразол (0,15 л/га) + Кон­тактний фунгіцид (75% від рекомендованої норми). Інтервал між обробками має становити 12 днів. У разі необхідності внесення фунгіциду контактної групи у чистому вигляді інтервал між останньою обробкою препаратом Скоразол та застосуванням фунгіциду контактної дії має становити не менше ніж 8 днів.</>),
  },
  {
    productTitle: "Тіназол",
    path: "/tinasol",
    src: Fungicides20,
    volume: "5 л",
    price: 74.49,
    currency: "USD",
    text: (<>
    Опис: Тіназол - фунгіцид системної дії з лікувально - профілактичними властивостями для захисту зернових колосових та буряку цукрового від хвороб, інгібітор росту озимого ріпаку.
    <span className="paragraph"></span>
Діюча речовина: Пропіконазол, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії. 
<span className="paragraph"></span>
Переваги: 
	•	Надійний захист сільськогосподарських культур від широкого спектру хвороб; 
	•	Тривалий період захисної дії ( від 3 до 5 тижнів) при застосуванні в оптимальні строки; 
	•	Препарат швидко поширюється в організми рослини, забезпечуючи, таким чином, повний та високоефективний захист усієї рослини; 
	•	Швидко поглинається культурними рослинами та стійкий до змивання дощем; 
	•	Низька норма витрати на 1 га посівної площі; 
	•	Сумісність з іншими пестицидами та рідкими добривами дозволяє застосовувати препарат в комплексних системах захисту сільськогосподарських культур, але в кожному випадку необхідно перевіряти партнерські компоненті на сумісність. 
  <span className="paragraph"></span>
Механізм дії: 
Діюча речовина проникає в рослину через її надземну частину і активно розноситься по всіх органах. Блокує розвиток грибної інфекції за рахунок порушення синтезу ергостеролів в мембранах клітин патогена. Внаслідок цього клітинні мембрани гриба втрачають свої фізичні та біологічні властивості, і патоген гине. Період захисної дії складає від 20 до 40 днів.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Ячмінь ярий; 
	•	Буряки цукрові;
	•	Ріпак озимий.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Септоріоз листя, борошниста роса, бура іржа листяна; 
	•	Сітчаста, темно-бура та облямівкова плямистості; 
	•	Церкоспороз; 
	•	Інгібітор росту листя, підвищувач стійкості до екстремальних погодних умов.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням профілактично (коли погодні умови сприяють поширенню захворювання) або при появі перших візуальних ознак захворювання (ураження 3‒5 % рослин). Максимальна ефективність дії препарату спостерігається при застосуванні для захисту прапорцевого листка зернових колосових, а також на початкових етапах розвитку захворювання цукрових буряків. </>),
  },
  {
    productTitle: "Тіома",
    path: "/tioma",
    src: Fungicides21,
    volume: "5 л",
    price: 49,
    currency: "USD",
    text: (<>
    Опис: Тіома - фунгіцид системної та профілактично-лікувальної дії для захисту рослин від хвороб в період вегетації.  
    <span className="paragraph"></span>
Діюча речовина: Тіофанат-метил, 500 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Період захисної дії становить 3-6 тижнів; 
	•	Проявляє ефективність при температурах від +5 до +7; 
	•	За рахунок лікувальної дії вважається найкращим препаратом для обробки механічно ушкоджених рослин (яблуневі сади після обрізання, пошкодженні шкідниками або градобоєм); 
	•	Швидке поглинання діючої речовини органами рослин.
  <span className="paragraph"></span>
Механізм дії: 
Препарат системний, переміщується акропетально по судинній системі, має лікувальні властивості. Тіофанат-метил - блокує дихання клітин гриба, затримуючи продукування мікотоксинів, а його метаболіт зв'язується з макромолекулами тубуліну (білку, що полімеризується у мікротрубочки) і, порушуючи їх утворення, перешкоджає поділу ядер, клітин гриба та синтезу ДНК. Препарат є одним із кращих для обробки механічно ушкоджених рослин (яблуневі сади після обрізки, пошкоджені шкідниками або градобоєм). 
<span className="paragraph"></span>
Норма витрати робочого розчину: 800‒1000 л/га, для обробки плодових 1000‒1200 л/га, польових культур 300–400 л/га. 
<span className="paragraph"></span>
Регламент застосування препарату: 
<span className="paragraph"></span>
Культура: 
	•	Виноградники; 
	•	Яблуня; 
	•	Зернові колосові; 
	•	Ріпак; 
	•	Соя.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Оїдіум, сіра гниль; 
	•	Борошниста роса, парша, філостіктоз, плодова гниль; 
	•	Снігова пліснява, церкоспорельоз, борошниста роса, фузаріоз; 
	•	Антракноз, фузаріоз.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат застосовують профілактично, за сприятливих для розвитку хвороб погодних умов та за наявності первинних ознак ураження. Препарат слід вносити дрібно крапельним методом з тиском в системі 2,5‒3,0 кг/см2. Швидкість руху агрегату має не перевищувати6‒7 км/год. Концентрація робочого розчину при прояві ознак хвороби має бути не меншою 0,1%. </>),
  },
  {
    productTitle: "Фіделіс",
    path: "/fidelis",
    src: Fungicides22,
    volume: "5 л",
    price: 58.19,
    currency: "USD",
    text: (<>
    Опис: Фіделіс - комбінований фунгіцид системної дії для захисту зернових колосових, соняшнику, ріпаку та сої.
    <span className="paragraph"></span>
Діюча речовина: Дифеноконазол, 104 г/л; тіофанат-метил, 333 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Універсальний препарат для захисту основних сільськогосподарських культур (зернові, соняшник, соя, ріпак);
	•	Ефективний захист зернових культур у другій половині вегетації (плямистості, фузаріоз колоса);
	•	Висока ефективність на олійних культурах проти гнилей та плямистостей впродовж вегетації;
	•	Тривала захисна дія.
  <span className="paragraph"></span>
Механізм дії: 
Дифеноконазол ‒ діюча речовина системної дії з класу триазолів, поглинається рослиною та поширюється на всі ділянки, що потребують захисту. Порушує процес синтезу ергостеролів в клітинах патогена, внаслідок чого мембрани клітин гриба втрачають свої механічні і біологічні властивості, має лікувальну та профілактичну дію, тривалий захисний період. Тіофанат-метил – діюча речовина з класу похідних бензимідазолу, переміщується акропетально по судинній системі, володіє лікувальними та захисними властивостями. Блокує дихання клітин гриба, затримуючи продукування мікотоксинів, а також процес поділу клітин гриба, стримуючи розвиток конідій та їх проникнення в тканини рослин.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові;
	•	Соняшник; 
	•	Ріпак; 
	•	Соя.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Септоріоз, борошниста роса, іржа, церкоспорельоз, фузаріоз колосу; 
	•	Біла гниль, сіра гниль, альтернаріоз, фомоз, фомопсис.
  <span className="paragraph"></span> 
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням та авіаобприскуванням в період вегетації профілактично, коли погодні умови сприяють розвитку та поширенню збудників захворювань або за появи перших симптомів захворювання. Авіаційно-хімічні роботи повинні виконуватися на висоті до 3 м над об'єктом обробки. Обов’язковим є повне та рівномірне покриття всієї поверхні робочим розчином. </>),
  },
  {
    productTitle: "Фонтес",
    path: "/fontes",
    src: Fungicides23,
    volume: "1 кг",
    price: 6.27,
    currency: "USD",
    text: (<>
    Опис: Фонтес - контактний фунгіцид для захисту винограду і яблуні від хвороб. 
    <span className="paragraph"></span>
Діюча речовина: Манкоцеб, 800 г/л.
<span className="paragraph"></span>
Препаративна форма: Порошок, що замочується.
<span className="paragraph"></span>
Переваги: 
	•	Контроль комплексу хвороб на винограді та яблуні; 
	•	Вміст в діючій речовині Zn та Mn, які виступають додатковими елементами позакореневого живлення;
	•	Відсутній ризик виникнення резистентності. 
  <span className="paragraph"></span>
Механізм дії: 
Манкоцеб інгібує активність ензимів за рахунок порушення процесу синтезу АТФ. Проникає в рослину за 30 хвилин, створюючи захисний шар на її поверхні, та забезпечує, тим самим, захист культури впродовж 10‒14 днів, навіть у дощову погоду. За сприятливих для розвитку хвороб умов середовища період захисту може скорочуватись до 7 – 10 днів. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Виноград; 
	•	Яблуня.
  <span className="paragraph"></span>
Шкідливий об’єкт:
	•	Мілдью;
	•	Парша.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Регламент щодо застосування: 
Препарат вносять наземним обприскуванням профілактично, коли погодні умови сприяють розвитку та поширенню збудників захворювань, до появи перших симптомів захворювання. Норма витрати робочого розчину 100–200 л/га для польових культур, 200-400 л/га для овочевих культур відкритого ґрунту, 800–1200 л/га для плодових культур залежно від об’єму крони дерева, 600–800 л/га – виноградники. </>),
  },

  // третий файл

  {
    productTitle: "Артіс Плюс",
    path: "/artis-plus",
    src: Fungicides24,
    volume: "5 л",
    price: 91.65,
    currency: "USD",
    text: (<>
    Опис: Артіс Плюс - трикомпонентний контактно-системний фунгіцид для інтенсивного захисту від комплексу поширених хвороб.
    <span className="paragraph"></span>
Діюча речовина: Міклобутаніл, 125 г/л + тебуконазол, 125 г/л + тіофанат-метил, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат  суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Синергетичне поєднання 3-х діючих речовин посилює захисний та лікувальний ефект.
	•	Висока фунгіцидна активність проти різних плямистостей та склеротиніозу.
	•	Швидка дія («стоп-ефект») за рахунок високої швидкості проникнення та переміщення до місця локалізації інфекції.
	•	Максимально реалізує потенціальний період вегетації культури, що сприяє підвищенню врожайності.
  <span className="paragraph"></span>
Властивості: 
Завдяки унікальному поєднанню діючих речовин з різними властивостями (розчинність, ліпофільність тощо) спостерігається посилення розповсюдження та підтримання ефективної концентрації препарату Артіс Плюс в рослині, що забезпечує прояв швидкої фунгіцидної дії («стоп-ефект») та пролонгованого захисту культури (до 4-х тижнів). Комбінація діючих речовин з принципово різними механізмами дії забезпечує високу ефективність проти широкого спектра збудників хвороб та виключає появу резистентності. Препарат володіє контактними та системними властивостями з вираженим лікувальним ефектом. 
<span className="paragraph"></span>
Механізм дії: 
Тебуконазол та міклобутаніл з хімічної групи триазолів блокують біосинтез ергостерину, який входить до складу клітинної мембрани гриба, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогену. Тіофанат-метил з хімічної групи похідних бензимідазолу блокує процеси поділу клітин гриба, попереджуючи розвиток конідій, утворення ростової трубки та проникнення в тканини рослини, а також блокує розвиток міцелію. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури – 200-300 л/га, сади: 600 - 1000 л/га (в залежності від фази розвитку культури, густоти стояння рослин, об’єму крони і погодних умов). 
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Соняшник;
	•	Соя;
	•	Яблуня.
  <span className="paragraph"></span>
Хвороба: 
	•	Іржа, альтернаріоз, борошниста роса, сіра та біла гниль, фомоз, септоріоз, фомопсис;
	•	Антракноз, борошниста роса, іржа, септоріоз, альтернаріоз, аскохітоз, церкоспороз, сіра і біла гнилі;
	•	Парша, борошниста роса, альтернаріоз, філостиктоз.
  <span className="paragraph"></span>
Строк обприскування: 
	•	Обприскування посівів в період вегетації.
  <span className="paragraph"></span>
Загальні рекомендації: 
Фунгіцид захищає від хвороб як профілактично, так і за появи перших ознак. Проте дуже важливо провести обприскування, перш ніж хвороби завдадуть незворотних втрат урожаю. Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. Для підсилення лікувальної дії препарату можливе додавання до робочого розчину ПАР Альфалип Екстра, 0.1% від об’єму, але не менше 0,2 л/га. Додавання до робочого розчину ПАР не є обов’язковим, але покращує змочування робочої поверхні, підвищуючи ефективність фунгіцидної обробки. </>),
  },
  {
    productTitle: "Баррет",
    path: "/barret",
    src: Fungicides25,
    volume: "5 л",
    price: 101.84,
    currency: "USD",
    text: (<>Опис: Баррет - новий 3-компонентний фунгіцид контактно-системної дії для захисту зернових культур і соняшнику від широкого спектра хвороб.
    <span className="paragraph"></span>
      Діюча речовина: Прохлораз, 340 г/л + тебуконазол, 160 г/л + пропіконазол, 100 г/л.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат емульсії.
      <span className="paragraph"></span>
      Переваги: 
        •	Посилена лікувальна і профілактична (дезінфікуюча) дія. 
        •	Висока ефективність навіть за низьких температур (від +5°С, середньодобова). 
        •	Контроль прикореневих гнилей зернових, у т.ч. фузаріозів. 
        •	Надійний захист соняшнику від фомозу, фомопсису та ін. Плямистостей.
        <span className="paragraph"></span>
      Властивості: 
      Поєднання трьох діючих речовин з двох хімічний класів забезпечує високу ефективність та тривалий захист (до 4-х тижнів) проти найбільш поширених хвороб зернових культур і соняшнику. Фунгіцид має контактну, системну та трансламінарну дію; лікувальні, профілактичні та викорінюючі властивості. 
      <span className="paragraph"></span>
      Механізм дії: 
      Тебуконазол – фунгіцид системної дії. Тебуконазол інгібує синтез ергостерину в клітинах патогенів, що призводить до повної загибелі збудників хвороб. Прохлораз належить до контактно-системних діючих речовин. Прохлораз – фунгіцид з трансламінарною та профілактично-лікувальною діями. Забезпечує довготривалий захист від грибних патогенів за рахунок інгібування біосинтезу стерину в клітинах збудника. Пропіконазол (хімічний клас триазоли) порушує процес біосинтезу стеролу в клітинній мембрані гриба, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогена. Проникає в рослину вже через 1-2 години після нанесення. Швидко рухається в рослині акропетально (від основи до верхівки), захищаючи всі наземні частини рослини. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: 200-300 л/га (залежно від фази розвитку культури, ступеня ураженості і погодних умов). 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Яблуня;
        •	Томати.
        <span className="paragraph"></span>
      Хвороба: 
        •	Борошниста роса, парша.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Препарат застосовувати в період вегетації культури превентивно або за появи перших ознак ураження хворобами. Важливо провести обробку завчасно, допоки хвороби не завдали значних втрат вегетативній масі та/або врожаю. Обов’язковою вимогою є рясне змочування рослин препаратом (робочим розчином) під час обробки, забезпечення суцільного покриття площі, але без стікання робочого розчину.Опади через 1 годину після внесення не впливають на ефективність препарату. Оптимальні температурні умови для внесення продукту – від +15°С до + 25°С. Баррет сумісний з багатьма гербіцидами, інсектицидами та регуляторами росту, але перед кожним приготуванням бакової суміші необхідно провести пробне змішування. </>),
  },
  {
    productTitle: "Док Про",
    path: "/dok-pro",
    src: Fungicides26,
    volume: "1 кг",
    price: 24.25,
    currency: "USD",
    text: (<>Опис: Док Про - двокомпонентний фунгіцид для контролю пероноспорозу та інших плямистостей листя.
    <span className="paragraph"></span>
      Діюча речовина: Цимоксаніл, 300 г/кг + міклобутаніл, 200 г/кг.
      <span className="paragraph"></span>
      Препаративна форма: Порошок, що змочується.
      <span className="paragraph"></span>
      Переваги: 
        •	Контроль комплексу грибних хвороб, у т.ч. збудників пероноспорозу, фітофторозу.
        •	Швидке проникнення та стійкість до змивання дощем.
        •	Захисна, лікувальна, антиспоруляційна дії та добре виражений «стоп-ефект».
        •	Запобігає розвитку склеротиніозу.
        <span className="paragraph"></span>
      Властивості: 
      ДОК Про завдяки активним речовинам (цимоксаніл та міклобутаніл) володіє надзвичайною лікувальною дією. Знищення патогена відбувається навіть через 1-3 дні після зараження за рахунок капсулювання інфікованих клітин рослини. Висока розчинність діючих речовин забезпечує швидке поширення фунгіциду по рослині й швидкий «стоп-ефект». Застосування фунгіциду забезпечує контроль збудників грибних захворювань з класів аскоміцетів (борошниста роса, біла гниль (склеротиніоз), чорна гниль), базидіоміцетів (іржа), дейтероміцетів (альтернаріоз, септоріоз, сіра гниль) та ооміцетів (пероноспороз, фітофтороз). 
      <span className="paragraph"></span>
      Механізми дії: 
      Діючі речовини фунгіциду ДОК Про – цимоксаніл та міклобутаніл – належать до різних хімічних класів, швидко адсорбуються тканинами та поширюються всією рослиною. Цимоксаніл впливає на синтез нуклеїнових кислот, білків, жирів, дихання міцелію та проникнення мембран патогена, що забезпечує надійне пригнічення збудника та попереджає прояв резистентності. Діюча речовина має ефект капсулювання інфікованих клітин, що зупиняє розвиток та поширення захворювання. Міклобутаніл блокує біосинтез ергостерину, який входить до складу клітинної стінки мембрани гриба. Це призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогену. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: польові культури: 200-300 л/га (в залежності від фази розвитку культури, густоти стояння рослин і погодних умов). 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Соняшник: 
        •	Соя; 
        •	Картопля.
        <span className="paragraph"></span>
      Хвороба: 
        •	Пероноспороз, септоріоз, альтернаріоз, фомоз, іржа, біла гниль;
        •	Пероноспороз, септоріоз, альтернаріоз, іржа, біла гниль;
        •	Фітофтороз, альтернаріоз.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обробка протягом вегетації культури.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Фунгіцид застосовують в період вегетації профілактично або при появі перших ознак захворювання. Обприскування потрібно провести перш ніж хвороби завдадуть незворотних втрат врожаю. Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Уникати застосування препарату під час спеки, засухи, заморозків, сильного вітру та інших несприятливих погодних умов. Оптимальні умови для обприскування посівів препаратом: температура від +15°С до +25°С. Препарат не можна застосовувати, якщо протягом 2 годин до обробки та після обробки очікується дощ. Для підсилення лікувальної дії препарату можливе додавання до робочого розчину ПАР Альфалип Екстра, 0,2-0,3 л/га. Додавання до робочого розчину ПАР не є обов’язковим, але покращує змочування робочої поверхні, чим підвищує ефективність фунгіцидної обробки. </>),
  },
  {
    productTitle: "Болівар Форте",
    path: "/bolivar-forte",
    src: Fungicides27,
    volume: "5 л",
    price: 93.57,
    currency: "USD",
    text: (<>
    Опис: Болівар Форте - двокомпонентний фунгіцид швидкої дії з health-ефектом.
    <span className="paragraph"></span>
Діюча речовина: Тебуконазол, 240 г/л + крезоксим-метил, 125 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	Широкий спектр дії завдяки поєднанню високосистемного триазолу з трансламінарною активністю стробілурину.
	•	Проявляє ефективність на різних фазах розвитку патогенів.
	•	Має профілактичну, лікувальну та антиспоруляційну дії.
	•	Відповідає антирезистентній стратегії захисту рослин.
  <span className="paragraph"></span>
Властивості: 
Крезоксим-метил утворює на восковому нальоті рослини стабільні запаси, які дуже стійкі до змивання опадами. Безперервне вивільнення і перерозподілення діючої речовини в процесі дифузії відбувається протягом декількох днів. У результаті цього крезоксим-метил рівномірно розподіляється як по поверхні рослини, так і трансламінарно всередині листка, що забезпечує подовжений захист рослини. Тебуконазол проникає в рослину через асимілюючі частини і рівномірно поширюється ксилемою впродовж 1-2 годин після застосування, що забезпечує швидку локалізацію інфекції та тривалий захист посівів. У результаті застосування Болівар Форте проявляється виражений ефект здорової клітини (health-ефект), що проявляється в призупинені старіння клітини за рахунок пригнічення біосинтезу етилену та збільшення ендогенних цитокінінів, пролонгації фотосинтезу та подальшому підвищенні якісних та кількісних показників врожайності. 
<span className="paragraph"></span>
Механізм дії: 
Тебуконазол з хімічного класу триазолів блокує біосинтез ергостерину, який входить до складу клітинної мембрани гриба, що призводить до порушень в процесі поділу клітин, а згодом — і до загибелі патогену. Крезоксим-метил з класу стробілурінів порушує дихання в мітохондріях клітин збудника. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури: 100-300 л/га (в залежності від фази розвитку культури, густоти стояння рослин і погодних умов). 
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Пшениця;
	•	Ріпак.
  <span className="paragraph"></span>
Хвороба: 
	•	Септоріоз листя та колосу, іржа, борошниста роса, альтернаріоз, піренофороз, фузаріоз, гельмінтоспоріоз;
	•	Фомоз, альтернаріоз, сіра гниль, склеротиніоз, циліндроспоріоз.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обприскування посіві в період вегетації.
  <span className="paragraph"></span>
Загальні рекомендації: 
Фунгіцид захищає від хвороб як профілактично, так і за появи перших їх ознак. Проте, дуже важливо провести обприскування, перш ніж хвороби завдадуть незворотних втрат урожаю. Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. Для підсилення лікувальної дії препарату можливе додавання до робочого розчину ПАР Альфалип Екстра, 0,2-0,3 л/га. Додавання до робочого розчину ПАР не є обов’язковим, але покращує змочування робочої поверхні, чим підвищує ефективність фунгіцидної обробки. </>),
  },
  {
    productTitle: "Корвізар М",
    path: "/korvizar-m",
    src: Fungicides28,
    volume: "5 л",
    price: 106.69,
    currency: "USD",
    text: (<>Опис: Корвізар М - трикомпонентний системний фунгіцид для раннього і  комплексного захисту зернових колосових культур.
    <span className="paragraph"></span>
      Діюча речовина: Фенпропідин, 300 г/л + тебуконазол, 150 г/л + міклобутаніл, 100 г/л.
      <span className="paragraph"></span>
      Препаративна форма:  Концентрат, що емульгується.
      <span className="paragraph"></span>
      Переваги: 
        •	Сучасна комбінація трьох діючих речовин з різними фунгіцидними властивостями для швидкої лікувальної дії та тривалого періоду захисту.
        •	Висока ефективність навіть за складних погодних умов (низькі температури, велика кількість опадів, посуха).
        •	Один з найефективніших фунгіцидів для контро­лю змішаних інфекцій.
        •	Широкий діапазон строків застосування.
        <span className="paragraph"></span>
      Властивості: 
      Завдяки унікальному поєднанню діючих речовин з різними властивостями (розчинність, ліпофільність тощо) спостерігається посилення розповсюдження та підтримання ефективної концентрації препарату Корвізар М в рослині. Це забезпечує прояв швидкої фунгіцидної дії («стоп-ефект») та пролонгованого захисту культури (до п’яти тижнів). Комбінація діючих речовин з різних хімічних класів забезпечує високу ефективність проти широкого спектра збудників хвороб та виключає появу резистентності. Препарат володіє системними властивостями з вираженим лікувальним ефектом. 
      <span className="paragraph"></span>
      Механізм дії: 
      Фенпропідин відносится до хімічної групи морфолінів, порушує у грибів утворення клітинної мембрани. Його висока розчинність додатково сприяє швидкому проникненню тебуконазолу та міклобутанілу в рослину. Тебуконазол та міклобутаніл відносяться до хімічного класу триазолів, які блокують біосинтез ергостерину у клітинах грибів, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогену. Синергія трьох діючих речовин забезпечує широкий спектр біологічної ефективності навіть за низьких температур навколишнього середовища. 
      <span className="paragraph"></span>
      Норма робочого розчину: польові культури: 100-300 л/га (в залежності від фази розвитку культури, густоти стояння рослин і погодних умов). 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця озима та яра.
        <span className="paragraph"></span>
      Хвороба: 
        •	Борошниста роса, альтернаріоз, септоріоз листя та колосу, бура листкова, жовта та стеблова іржа, церкоспорельоз, гельмінтоспоріози, піренофороз;
        •	Фузаріоз колоса.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування посівів в період вегетації;
        •	Обприскування на початку цвітіння.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Фунгіцид захищає від хвороб як профілактично, так і за появи перших ознак. Проте, дуже важливо провести обприскування, перш ніж хвороби завдадуть незворотних втрат урожаю. Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. Опади, які випали протягом 1 години після обробки, не впливають на ефективність дії препарату. Оптимальні температурні умови для обприскування посівів препаратом – від +15°С до + 25°С, але препарат починає ефективно діяти вже від +5°С. Для підсилення лікувальної дії препарату можливе додавання до робочого розчину ПАР Альфалип Екстра (0,2-0,3 л/га). Додавання до робочого розчину ПАР не є обов’язковим, але покращує змочування робочої поверхні, чим підвищує ефективність фунгіцидної обробки. </>),
  },
  {
    productTitle: "Кросбі",
    path: "/krosbi",
    src: Fungicides29,
    volume: "5 л",
    price: 80.11,
    currency: "USD",
    text: (<>Опис: Кросбі - двокомпонентний системний фунгіцид з широким спектром активності для захисту від комплексу поширених хвороб.
    <span className="paragraph"></span>
      Діюча речовина: Пропіконазол 187,5 г/л + тіофанат-метил 375 г/л.
      <span className="paragraph"></span>
      Препаративна форма: Суспензійна емульсія.
      <span className="paragraph"></span>
      Переваги:
        •	Інноваційна формуляція на олійній основі посилює фунгіцидну активність препарату.
        •	Швидка стартова дія та довготривалий захист (до 4-х тижнів).
        •	Широкий спектр фунгіцидної дії.
        •	Відмінна стійкість до опадів.
        <span className="paragraph"></span>
      Властивості: 
      Фунгіцид містить дві діючі речовини із різним механізмом дії, які розширюють спектр ефективності препарату та виключають можливість виникнення резистентності. Кросбі має захисну, профілактичну та лікувальну дію. Фунгіцид вже через декілька годин після застосування покращує фітосанітарний стан рослин. Опади через 1-2 години після застосування Кросбі не призводять до втрати його ефективності. 
      <span className="paragraph"></span>
      Механізм дії: 
      Пропіконазол - діюча речовина препарату, яка відноситься до хімічного складу триазолів та впродовж 1-2 годин після застосування проникає в рослину через асимілюючі частини та рівномірно поширюється ксилемою. Пропіконазол блокує біосинтез стеролів, які входять до складу клітинної стінки гриба. Це призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогену. Тіофанат-метил блокує процеси поділу клітин гриба, попереджуючи розвиток конідій, утворення ростової трубки та проникнення в тканини рослини, а також блокує розвиток міцелію. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: польові культури: 100-300 л/га (в залежності від фази розвитку культури, густоти стояння рослин і погодних умов).
      <span className="paragraph"></span> 
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця озима.
        <span className="paragraph"></span>
      Хвороба: 
        •	Борошниста роса, септоріоз листя та інші плямистості, іржа (бура, стеблова та інші), церкоспорельоз.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Препарат застосовувати в період вегетації превентивно або при появі перших ознак захворювання, повторно – через 3-4 тижні. Обприскування потрібно провести перш ніж хвороби завдадуть незворотних втрат врожаю. Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. Кросбі сумісний з багатьма гербіцидами, інсектицидами, фунгіцидами, добривами, регуляторами росту, але перед приготуванням бакової суміші необхідно провести пробне змішування. В бакових сумішах норми гербіцидів, інсектицидів та Кросбі не змінюються в сторону зменшення, а використовуються згідно рекомендацій для окремого застосування. 
      </>),
  },
  {
    productTitle: "Релевант",
    path: "/relevant",
    src: Fungicides30,
    volume: "5 л",
    price: 99.41,
    currency: "USD",
    text: (<>
    Опис: Релевант - трикомпонентний фунгіцид для контролю грибних та бактеріальних хвороб у посівах соняшнику та сої.
    <span className="paragraph"></span>
Діюча речовина: Тіофанат-метил, 375 г/л + цимоксаніл, 225 г/л + продукт ферментації Актиноміцеців, 5 г/л. 
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Контроль широкого спектру збудників хвороб: ефективний проти збудників грибної (включаючи збудників з класу несправжніх грибів) та бактеріальної етіології.
	•	Має сильну лікувальну, профілактичну та захисну дію.
	•	Поєднання діючих речовин з різним механізмом дії запобігає виникненню резистентності у збудників хвороб.
	•	Не фітотоксичний, не шкідливий для корисної ентомофауни.
  <span className="paragraph"></span>
Властивості: 
Завдяки поєднанню діючих речовин фунгіцид володіє високо ефективною лікувальною та бактерицидною дією. Загибель патогенна відбувається протягом 1 – 3 днів за рахунок капсулювання інфікованих клітин рослини.  Фунгіцид забезпечує контроль збудників найбільш поширених грибних  та бактеріальних хвороб сої та соняшнику, а такожефективний проти несправжніх грибів (переноспороз).
<span className="paragraph"></span>
Механізм дії: 
Тіофанат-метил зупиняє та блокує процеси поділу клітинного ядра патогенну не допускаючи розвитку конідій. Цимоксаніл впливає на синтез нуклеїнових кислот, білків, жирів, дихання міцелію та проникнення мембран в клітинах патогена, що забезпечує надійне пригнічення збудника та попереджає прояв резистентності. Діюча речовина має ефект капсулювання інфікованих клітин, що зупиняє розвиток та поширення захворювання. Продукт ферментації Актиноміцетів проявляє бактерицидну системну дію, яка зупиняє поділ та розмноження клітин бактерій блокуючи синтез білку впливаючи на РНК збудника. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури – 200 – 300 л/га (в залежності від фази та густоти стояння). 
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Соняшник; 
	•	Соя.
  <span className="paragraph"></span>
Хвороба: 
	•	Борошниста роса, переноспороз, біла та сіра гниль, фомоз, фомопсис, альтернаріоз, септоріоз, бактеріоз стебел;
	•	Борошниста роса, переноспороз, біла гниль, альтернаріоз, аскохітоз, септоріоз, кутаста плямистість.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обробка протягом вегетації культури.
  <span className="paragraph"></span>
Загальні рекомендації: 
Фунгіцид застосовують в період вегетації профілактично або при появі перших ознак захворювання. Обприскування потрібно провести перш ніж хвороби завдадуть незворотних втрат врожаю. Уникати застосування препарату під час спеки, засухи, сильного вітру та інших несприятливих погодних умов. Оптимальні умови для обприскування посівів препаратом: температура від +15°С до +25°С. Для підсилення лікувальної дії препарату можливе додавання до робочого розчину ПАР Альфалип Екстра, 0,2-0,3 л/га. Додавання до робочого розчину ПАР не є обов’язковим, але покращує змочування робочої поверхні, чим підвищує ефективність фунгіцидної обробки. </>),
  },
  {
    productTitle: "Тезис",
    path: "/tezis",
    src: Fungicides31,
    volume: "5 л",
    price: 58.20,
    currency: "USD",
    text: (<>Опис: Тезис - системний фунгіцид лікувальної дії з рістрегулюючими властивостями.
    <span className="paragraph"></span>
      Діюча речовина: Тебуконазол, 500 г/л.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії. 
      <span className="paragraph"></span>
      Переваги: 
        •	Швидке проникнення в рослину (через 1–2 години) і перерозподіл в уражених тканинах («стоп-ефект»).
        •	Можливість використання на багатьох культурах .
        •	Висока ефективність контролю хвороб колоса, зокрема фузаріозу, зменшує ймовірність накопичення небезпечних мікотоксинів у зерні.
        •	Регулювання росту ріпаку підвищує зимостійкість, зменшує ймовірність вилягання, посилює гілкування.
        <span className="paragraph"></span>
      Властивості: 
      Тезис має захисну, профілактичну та лікувальну дію. Фунгіцид вже через декілька годин після застосування покращує фітосанітарний стан рослин. В осінній період при обприскуванні озимого ріпаку у фазі 3-5 листочків культури припиняється наростання наземної маси, в той час як процес фотосинтезу продовжується, що сприяє накопиченню пластичних речовин в кореневій системі та прискорює ріст довгого і добре розгалуженого коріння, що покращує зимостійкість. Застосування на пшениці у фазу цвітіння дозволяє захистити рослини від фузаріозу та інших хвороб колоса та попереджає накопичення у зерні небезпечних мікотоксинів. 
      <span className="paragraph"></span>
      Механізм дії: 
      Діюча речовина препарату відноситься до хімічного складу триазолів та впродовж 1-2 годин після застосування проникає в рослину через асимілюючі частини і рівномірно поширюється ксилемою. Тебуконазол блокує біосинтез ергостерину, який входить до складу клітинної стінки гриба, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогена. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: польові культури: 200-400 л/га. 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця; 
        •	Озимий ріпак.
        <span className="paragraph"></span>
      Хвороба: 
        •	Іржа (бура, стеблова, жовта), борошниста роса, септоріоз листя і колосу та інші плямистості, фузаріоз колосу;
        •	Фузаріоз колоса;
        •	Альтернаріоз, циліндроспоріоз, борошниста роса, фомоз, сіра гниль;
        •	Для припинення активного наростання наземної маси, накопичення пластичних речовин та прискорення росту кореневої системи восени.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування в період вегетації;
        •	Обприскування на початку цвітіння, за потреби повторно через 7-14 днів;
        •	Обприскування у фазу 3-5 листків культури.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Препарат застосовувати в період вегетації превентивно або при появі перших ознак захворювання, повторно – через 2-4 тижні. Обприскування потрібно провести перш ніж хвороби завдадуть незворотних втрат врожаю. З метою ефективного захисту від фузаріозу колоса Тезис потрібно застосовувати у фазу початку цвітіння пшениці (ВВСН 61) з нормою витрати не менше 0,5 л/га. Препарат сумісний з багатьма гербіцидами, інсектицидами, фунгіцидами, добривами, регуляторами росту, але перед приготуванням бакової суміші необхідно провести пробне змішування. Додаткове додавання до бакових сумішей поверхнево-активної речовини Альфалип Екстра (0,2-0,3 л/га) не є обов’язковим, але покращує змочуваність рослин та прискорює проникнення діючих речовин до рослини. </>),
  },
  {
    productTitle: "Теламус",
    path: "/telamus",
    src: Fungicides32,
    volume: "5 л",
    price: 160.06,
    currency: "USD",
    text: (<>Опис: Теламус - трикомпонентний фунгіцид на основі SDHI для надійного захисту посівів зернових культур від комплексу грибних захворювань.
    <span className="paragraph"></span>
      Діюча речовина: Пропіконазол 200 г/л + піраклостробін 167 г/л + боскалід 133 г/л.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат емульсії.
      <span className="paragraph"></span>
      Переваги: 
        •	Відмінний контроль плямистостей листя в посівах зернових культур
        •	Потужна лікувальна, захисна та профілактична дія
        •	Швидке проникнення та пролонгований захисний ефект
        •	Яскраво виражений фізіологічний ефект
        •	Поєднання 3-х діючих речовин з різним механізмом дії запобігає виникненню резистентності у збудників хвороб
        •	Рівномірний контроль хвороб в усіх частинах рослини за рахунок системності та трансламінарного переміщення
        •	Підвищення стійкості до стресових умов.
        <span className="paragraph"></span>
      Властивості: 
      Поєднання 3-х діючих речовин з різних хімічних класів, з різними властивостями (розчинність, ліпофільність) сприяє накопиченню ефективної концентрації фунгіциду клітинах культури, за рахунок чого досягається довготривалий захисний ефект (до 4-х тижнів) та швидкий «стоп-ефект». Завдяки унікальному поєднанню діючих речовин зберігається стратегія анти резистентного захисту рослин.Препарат володіє системною, контактною та трансламінарною діями з яскраво вираженим лікувальним та фізіологічним ефектом. 
      <span className="paragraph"></span>
      Механізм дії: 
      Пропіконазол (хімічний клас триазоли) порушує процес біосинтезу стеролу в клітинній мембрані гриба, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогенна, проникає в рослину вже через 1-2 години після внесення. Швидко рухається в рослині акропетально від основи до верхівки, захищаючі всі наземні частини рослини.Піраклостробін (хімічний клас стробілуріни) пригнічує мітохондріальне дихання патогенну, що призводить до загибелі спор та міцелію гриба, володіє захисною та лікувальною дією та позитивно впливає на фізіологічні процеси в рослині.Боскалід (хімічний клас карбоксаміди) блокує ключовий етап мітохондріального дихання клітин збудника. Інгібує проростання спор та ростових трубок, запобігаючи утворенню апресоріїв. Володіє трансламінарною дією, і як і пропіконазол рухається в рослині акропетально від основи до верхівки, захищаючі всі наземні частини рослин. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: польові культури 200 – 300 л/га (в залежності від фази розвитку та густоти стояння рослин). 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця озима.
        <span className="paragraph"></span>
      Хвороба: 
        •	Борошниста роса, бура, стеблова та жовта іржа, гельмінтоспоріозна плямистості листя, септоріоз.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Загальні рекомендації: 
      Препарат застосовувати в період вегетації культури превентивно або при прояві перших ознак ураження культури хворобами, повторно – через 3-4 тижні. Обробку проводити перш ніж хвороби призведуть до незворотних втрат врожаю. Обов’язковою вимогою є рясне змочування рослин під час обробки препаратом та забезпечення суцільного покриття площі. Для посилення лікувального ефекту Теламуса можливе додавання до робочого розчину ПАВ Альфалип Екстра, 0,2-0,3 л/га. Додавання ПАР до робочого розчину є не обов’язковим, але покращує змочуваність, за рахунок чого підвищується ефективність фунгіцидної обробки.Теламус сумісний з багатьма гербіцидами, інсектицидами та регуляторами росту, але перед тим як приготувати бакову суміш необхідно провести пробне змішування. </>),
  },
  {
    productTitle: "Фенікс Дуо",
    path: "/fenix-duo",
    src: Fungicides33,
    volume: "5 л",
    price: 80.04,
    currency: "USD",
    text: (<>
    Опис: Фенікс Дуо - двокомпонентний системний фунгіцид з широким спектром активності для захисту від комплексу поширених хвороб. 
    <span className="paragraph"></span>
Діюча речовина: Флутріафол, 187 г/л + тіофанат-метил, 310 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Активна системність і пролонгована дія (3-4 тижні) дають змогу ефективно контролювати хвороби на новому прирості рослин.
	•	Широкий спектр біологічної ефективності.
	•	Має профілактичну, лікувальну, фумігаційну дію та добре виражений «стоп-ефект».
	•	Поєднання двох діючих речовин з різних хімічних класів запобігає виникненню резистентності у патогенів.
  <span className="paragraph"></span>
Властивості: 
Фунгіцид містить дві діючі речовини із різним механізмом дії, які розширюють спектр ефективності препарату та виключають можливість виникнення резистентності. Фенікс Дуо має профілактичні, лікувальні, фумігаційні властивості та добре виражений «стопефект». Робочий розчин препарату, нанесений на культуру, випаровуючись, формує фумігаційну хмару, яка покриває всю рослину та забезпечує додаткову фунгіцидну дію. 
<span className="paragraph"></span>
Механізм дії: 
Тіофанат-метил блокує процеси поділу клітин гриба, попереджуючи розвиток конідій, утворення ростової трубки та проникнення в тканини рослини, а також блокує розвиток міцелію. Флутріафол інгібує процес диметилювання під час біосинтезу стеролів та порушує вибірковість проникності клітинних мембран патогена. Володіє здатністю швидкого проникнення в рослину та переміщення по тканинах. Завдяки цьому діюча речовина переміщується до місця локалізації інфекції, викорінюючи захворювання та забезпечуючи тривалий захист посівів. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури: 200-400 л/га.
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Пшениця, ячмінь.
  <span className="paragraph"></span>
Хвороба:
	•	Борошниста роса, септоріоз колосу, листя та інші плямистості, іржа (бура, стеблова та інші), фузаріоз, церкоспорельоз.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Загальні рекомендації: 
Фунгіцид захищає від хвороб як профілактично, так і за появи перших ознак. Проте дуже важливо провести обприскування, перш ніж хвороби завдадуть незворотних втрат урожаю. Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. </>),
  },

  // четвёртый файл

  {
    productTitle: "Абакус",
    path: "/abakus",
    src: Fungicides34,
    volume: "10 л",
    price: 182.72,
    currency: "USD",
    text: (<>Опис: Абакус — високоефективний фунгіцид для контролю найнебезпечніших хвороб зернових колосових, кукурудзи, сої та цукрових буряків. 
    <span className="paragraph"></span>
      Діюча речовина: 62,5 г/л Епоксиконазол, 62,5 г/л Піраклостробін.
      <span className="paragraph"></span>
      Препаративна форма: Суспо-емульсія.
      <span className="paragraph"></span>
      Переваги: 
        •	Тривала профілактична та надійна лікувальна дія проти широкого спектра хвороб;
        •	Забезпечує AgCelence®ефект;
        •	Пiдвищення врожайностi та покращення його якісних показників;
        •	Збільшення маси тисячі зерен;
        •	Стимулювання процесів фотосинтезу, подовження вегетаційного періоду;
        •	Активізація нітрогеназної активності рослин;
        •	Пiдвищення стiйкостi рослин до стресових погодних умов протягом вегетації;
        •	За рахунок піраклостробіну ефективний вже від +5°С.
        <span className="paragraph"></span>
      Регламент застосування: 
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця озима та яра;
        •	Ячмінь озимий та ярий; 
        •	Кукурудза;
        •	Цукрові буряки; 
        •	Соя.
        <span className="paragraph"></span>
      Терміни застосування: 
        •	В період вегетації.
        <span className="paragraph"></span>
      Спектр дії: 
        •	септоріоз листя, бура листкова іржа, септоріоз колосу, борошниста роса;
        •	сітчаста плямистість, борошниста роса;
        •	фузаріоз, іржа, гельмінтоспоріоз; 
        •	церкоспороз, пероноспороз;
        •	борошниста роса, септоріоз, антракноз, пероноспороз.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 200-400 л/га.
      <span className="paragraph"></span>
      Рекомендації щодо застосування: 
      <span className="paragraph"></span>
      Зернові
      За умов оптимального вологозабезпечення найвища ефективність та економічна віддача в посівах зернових колосових досягається за внесення по прапорцевому листку (ВВСН 37–39). В умовах недостатнього зволоження оптимальною фазою внесення буде ВВСН 31–32 (фаза першого або другого вузла), коли в ґрунті ще містяться достатні запаси продуктивної вологи.
      <span className="paragraph"></span>
      Кукурудза
        •	Однократне застосування: у фазу 8–10 листкiв 1,5 – 1,75 л/га або у фазу початку викидання волотi 1,5–1,75 л/га
        •	Двократне застосування: 1-е у фазу 8–10 листкiв 1,5 л/га, 2-е у фазу початку викидання волотi 1,5 л/га
        <span className="paragraph"></span>
      Соя
        •	Однократне застосування: у фазу початку цвітіння, профілактично, норма внесення 1,0 –1,5 л/га
        •	Двократне застосування: перше: у фазу бутонізації 0,75 л/га, друге: у фазу формування бобів 0,75 л/га
        <span className="paragraph"></span>
      Уникайте внесення фунгіциду за високих температур – вище +25 °С і низької вологості повітря – нижче 40% та коли рослини перебувають у стресі.</>),
  },
  {
    productTitle: "Піктор",
    path: "/pictor",
    src: Fungicides35,
    volume: "5 л",
    price: 441.80,
    currency: "USD",
    text: (<>Опис: Піктор — незамінний фунгіцид для успішного вирощування ріпаку та соняшнику. 
    <span className="paragraph"></span>
      Діюча речовина: 200 г/л Дімоксістробін, 200 г/л Боскалід.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	висока активнiсть проти збудникiв склеротинiозу, альтернарiозу та iнших хвороб рiпака та соняшнику;
        •	має яскраво виражений «фiзiологiчний ефект» AgCelence;
        •	висока фунгiцидна та фiзiологiчна ефективнiсть завдяки наявностi двох iнновацiйних дiючих речовин з рiзних хiмiчних класiв;
        •	забезпечує тривалу профiлактичну дiю;
        •	впливає на зниження втрат перед та пiд час збирання врожаю;
        •	висока бiологiчна та економiчна ефективнiсть;
        •	безпечний для бджiл.
        <span className="paragraph"></span>
      Регламент застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Ріпак ярий та озимий; 
        •	Соняшник.
        <span className="paragraph"></span>
      Терміни застосування: 
        •	В період вегетації.
        <span className="paragraph"></span>
      Спектр дії: 
        •	Альтернаріоз, склеротиніоз, фомоз.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 100-400 л/га.
      <span className="paragraph"></span>
      Рекомендації щодо застосування: 
      <span className="paragraph"></span>
      Ріпак: середина цвітіння (пелюстки починають опадати).
      <span className="paragraph"></span>
      Соняшник: у фазі від 2 до 10 листків або від початку бутонізації до середини цвітіння. </>),
  },
  {
    productTitle: "Рекс ДУО",
    path: "/reks-duo",
    src: Fungicides36,
    volume: "10 л",
    price: 312.84,
    currency: "USD",
    text: (<>
    Опис: Рекс ДУО — системний фунгіцид проти комплексу хвороб зернових культур і цукрових буряків.
    <span className="paragraph"></span>
Діюча речовина: 187 г/л Епоксиконазол, 310 г/л Тіофанат-метил.
<span className="paragraph"></span>
Препаративна форма: Концентрат сузпенсії.
<span className="paragraph"></span>
Переваги: 
	•	стоп-ефект завдяки швидкому проникненню в рослину;
	•	висока системнiсть сприяє захисту нових частин рослини;
	•	надiйно працює за умов низьких температур та високої вологостi повiтря;
	•	гарантований захист колосу зернових та листя цукрових бурякiв.
  <span className="paragraph"></span>
Регламент застосування.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра; 
	•	Ячмінь озимий та ярий;
	•	Цукрові буряки.
  <span className="paragraph"></span>
Терміни застосування: 
	•	В період вегетації.
  <span className="paragraph"></span>
Спектр дії: 
	•	септоріоз листя, іржа, фузаріоз та септоріоз колосу, борошниста роса;
	•	іржа, фузаріоз колосу, гельмінтоспоріозні плямистості, борошниста роса;
	•	борошниста роса, церкоспороз, рамуляріоз. 
  <span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Рекомендації щодо застосування:
Уникайте внесення фунгіциду за високих температур — вище +25 і низької вологості повітря  - нижче 40%.
Найкращий контроль збудників хвороб досягається при застосуванні за температурного режиму +12...+25.</>),
  },
  {
    productTitle: "Рекс Плюс",
    path: "/reks-plus",
    src: Fungicides37,
    volume: "10 л",
    price: 194.13,
    currency: "USD",
    text: (<>Опис: Рекс Плюс — вдале поєднання діючих речовин, що гарантує ефективний захист посівів від хвороб на початку вегетації.
    <span className="paragraph"></span>
      Діюча речовина: 84 г/л Епоксиконазол, 250 г/л Фенпропіморф.
      <span className="paragraph"></span>
      Препаративна форма: Суспо-емульсія.
      <span className="paragraph"></span>
      Переваги: 
        •	Швидке поглинання та розподіл у рослині;
        •	Висока ефективність як за низьких, так і високих температур;
        •	Більша площа покриття завдяки новітній формуляції;
        •	Контроль широкого спектра хвороб: борошнистої роси, септоріозу листя, різних видів іржі тощо;
        •	Захисний період – від 3 до 5 тижнів.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 150-300 л/га.
      <span className="paragraph"></span>
      Регламент застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Пшениця озима та яра;
        •	Ячмінь озимий та ярий;
        •	Жито озиме та яре;
        •	Цукрові буряки.
        <span className="paragraph"></span>
      Терміни застосування: 
        •	обприскування в період вегетації.
        <span className="paragraph"></span>
      Спектр дії: 
        •	борошниста роса, септоріоз, піренофороз, іржа;
        •	борошниста роса, гельмінтоспоріоз, ринхоспоріоз, іржа;
        •	ринхоспоріоз, іржа, борошниста роса, септоріоз листя;
        •	борошниста роса, церкоспороз, рамуляріоз.
        <span className="paragraph"></span>
      Рекомендації щодо застосування:
      Уникайте внесення фунгіциду за високих температур — вище +25 і низької вологості повітря — нижче 40%. </>),
  },

  // пятий файл

  {
    productTitle: "Амістар Екстра 280 SC",
    path: "/amistar-ekstra-280-SC",
    src: Fungicides38,
    volume: "5 л",
    price: 165.40,
    currency: "USD",
    text: (<>Опис: Амістар Екстра - системний комбінований фунгіцид для захисту зернових культур, соняшнику, ріпаку, кукурудзи та цукрових буряків.
    <span className="paragraph"></span> 
      Діюча речовина: 80 г/л ципроконазолу, 200 г/л азоксистробіну.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	Превентивна дія проти широкого спектра хвороб;
        •	Забезпечує подовження вегетації рослини, що збільшує урожайність;
        •	Відмінна фотостабільність і тривалий період захисту. 
        <span className="paragraph"></span>
      Застосування препарату.
      <span className="paragraph"></span>
      Культура: 
        •	Кукурудза; 
        •	Пшениця озима; 
        •	Ячмінь ярий; 
        •	Буряки цукрові; 
        •	Ріпак; 
        •	Соняшник; 
        •	Соя; 
        •	Горох і горох овочевий.
        <span className="paragraph"></span>
      Спектр дії: 
        •	Гельмінтоспоріозна й інші плямистості листя, іржа, фузаріоз, пухирчаста сажка;
        •	Септоріоз, борошниста роса, бура листова іржа, фузаріоз і септоріоз колосу, альтернаріоз;
        •	Борошниста роса, сітчаста, темно-бура, смугаста, облямівкова плямистість, септоріоз; 
        •	Церкоспороз, борошниста роса, пероноспороз;
        •	Фомоз, альтернаріоз, біла й сіра гнилі, пероноспороз; 
        •	Фомоз, альтернаріоз, септоріоз, фомопсис, іржа, пероноспороз, борошниста роса;
        •	Аскохітоз.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: Штанговий обприскувач — 150–200 л/га. 
      <span className="paragraph"></span>
      Сумісність: 
      Сумісний із більшістю засобів захисту рослин. Безпечно для культури можна використовувати в бакових сумішах з гербіцидами Дербі®, Пріма ™ Форте, Пік ®, Логран ®, інсектицидами Енжіо ®, Карате ® Зеон, регулятором росту Моддус ®. При використанні бакових сумішей засобів захисту рослин слід дотримуватися рекомендацій компанії-виробника щодо сумісності кожного препарату комонента бакової суміші. 
      <span className="paragraph"></span>
      Рекомендована температура: Використовувати в діапазоні температур від +12 °С до +25 °С. 
      <span className="paragraph"></span> 
      Особливості застосування: 
      Найвища ефективність Амістар ® Екстра досягається при застосуванні його на початкових стадіях розвитку хвороби. </>),
  },
  {
    productTitle: "Амістар Голд 250 SE",
    path: "/amistar-gold-250-se",
    src: Fungicides39,
    volume: "5 л",
    price: 173.45,
    currency: "USD",
    text: (<>Опис: Амістар Голд - вокомпонентний фунгіцид системної та трансламінарної дії Амістар Голд призначений для обробки сільськогосподарських культур від широкого спектру грибкових захворювань. 
    <span className="paragraph"></span>
      Діюча речовина: Азоксистробін – 125 г/л, дифеноконазол – 125 г/л.  
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	Надійний захист соняшнику, сої та цукрових буряків проти широкого спектра хвороб;
        •	Тривалий захисний період;
        •	Неперевершений профілактичний і лікувальний ефект завдяки трансламінарній і системній дії.
      Застосування препарату.
      <span className="paragraph"></span>
      Культура: 
        •	Соняшник; 
        •	Цукровий буряк; 
        •	Соя; 
        •	Ріпак озимий.
        <span className="paragraph"></span>
      Спектр дії: 
        •	Альтернаріоз, фомоз, іржа, септоріоз, фомопсис, біла гниль; 
        •	Борошниста роса, церкоспороз, рамуляріоз, фомос; 
        •	Септоріоз, антракноз, аскохітоз, альтернаріоз, церкоспороз;
        •	Циліндроспоріоз, склеротиніоз, сіра гниль.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 200-300 л при наземній обробці.
      <span className="paragraph"></span>
      Сумісність: 
      Амістар ® Голд можна змішувати з іншими загальновживаними засобами захисту рослин на відповідній культурі. Проте в кожному конкретному випадку слід перевіряти препарати на сумісність.
      <span className="paragraph"></span> 
      Спектр дії: 
      Контролює всі основні грибкові хвороби соняшнику, ріпаку, сої та цукрового буряку.
      <span className="paragraph"></span> 
      Температура застосування: 
      Використовувати при температурі не вище за +25 °С.
      <span className="paragraph"></span> 
      Особливості застосування: 
      фунгіцид із системною дією для застосування на соняшнику, ріпаку, сої та цукровому буряку, має захисну і лікувальну дію. Обприскування найкраще проводити профілактично або при появі перших ознак хвороби. Не рекомендується проводити обробки по вологій листовій поверхні або якщо очікуються опади протягом 2,5–3 годин після обробки. Робочий розчин треба використати протягом 24 годин після приготування. Терміни виходу працівників на оброблені території: для проведення механізованих робіт — 3 доби, для ручних робіт — 7 діб. </>),
  },
  {
    productTitle: "Елатус Ріа, 358 КЕ",
    path: "/elatus-ria-358-ke",
    src: Fungicides40,
    volume: "5 л",
    price: 241.03,
    currency: "USD",
    text: (<>
    Опис: Елатус Ріа — фунгіцид обприскування краще проводити профілактично або при появі перших ознак хвороби. 
    <span className="paragraph"></span> 
Діюча речовина: Солатенол — 83,33 г/л, 66,67 г/л ципроконазолу, 208,33 г/л пропіконазолу.
<span className="paragraph"></span> 
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span> 
Переваги: 
	•	Найвища активність у контролі септоріозу та іржі; 
	•	Тривалий період захисної дії; 
	•	Гарантована прибавка врожайності; 
	•	Висока ефективність проти широкого спектра хвороб; 
	•	Чітко виражена лікувальна дія; 
	•	Безпечне використання в бакових сумішах.
  <span className="paragraph"></span> 
Застосування препарату.
<span className="paragraph"></span> 
Культура: 
	•	Пшениця озима; 
	•	Ячмінь озимий і ярий; 
	•	Жито озиме.
  <span className="paragraph"></span> 
Спектр дії: 
	•	Іржа, септоріоз, піренофороз листя, борошниста роса; 
	•	Сітчаста, темно-бура та облямівкова плямистість; 
	•	Ринхоспоріоз, аскохітоз, гельмінтоспоріоз.
  <span className="paragraph"></span> 
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span> 
Норма витрати робочого розчину: 100–300 л/га.
<span className="paragraph"></span> 
Сумісність: 
Препарат можна змішувати з іншими загальновживаними засобами захисту рослин на відповідній культурі. Сумісний із більшістю фунгіцидів та інсектицидів. У кожному конкретному випадку слід проводити тести на сумісність. 
Безпечно для культури можна використовувати в бакових сумішах з гербіцидами Дербі®, Пріма™ Форте, Пік®, Логран®, інсектицидами Енжіо®, Карате® Зеон, регулятором росту Моддус®.При використанні бакових сумішей засобів захисту рослин слід дотримуватися рекомендацій компанії-виробника щодо сумісності кожного препарату компонента бакової суміші. 
<span className="paragraph"></span> 
Температура застосування: 
Використовувати при діапазоні температур +12...24 °С.
<span className="paragraph"></span> 
Особливості застосування: 
Обприскування краще проводити профілактично або при появі перших ознак хвороби. Не рекомендується здійснювати обробку по вологій листовій поверхні або якщо очікуються опади протягом 1,5–2 годин після обробки. 
Робочий розчин слід використати протягом 24 годин після приготування. Обприскування проводити в ранкові або вечірні години в безвітряну погоду. </>),
  },
  {
    productTitle: "Міравіс Дуо",
    path: "/miravis-duo",
    src: Fungicides41,
    volume: "5 л",
    price: 209.33,
    currency: "USD",
    text: (<>
    Опис: Міравіс Дуо - спеціалізований фунгіцид для захисту сої широкого спектра дії.
    <span className="paragraph"></span>
Діюча речовина: 75 г/л Адепідину, 125 г/л дифеноконазолу.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Міравіс Дуо — це спеціалізований фунгіцид для захисту сої, що містить унікальний N-метокси карбоксамід з якісно новим рівнем ефективності; 
	•	Поєднує тривалу профілактичну та лікувальну дії, має виражений фізіологічний ефект; 
	•	Містить дві діючі речовини з різним механізмом впливу на патоген; 
	•	Висока спорідненість із восковим шаром, фотостабільність та стійкість до змивання опадами; 
	•	Широкий спектр контрольованих хвороб. 
  <span className="paragraph"></span> 
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Соя.
  <span className="paragraph"></span>
Хвороба: 
	•	Септоріоз, аскохітоз, церкоспороз, альтернаріоз, корінеспороз, склеротиніоз, фомопсис.
  <span className="paragraph"></span>
Спосіб застосування: 
	•	В період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 100 – 300 л\га.
<span className="paragraph"></span>
Сумісність: 
Препарат можна змішувати з іншими загальновживаними засобами захисту рослин на відповідній культурі. Сумісний із більшістю фунгіцидів та інсектицидів. У кожному конкретному випадку слід проводити тести на сумісність. 
<span className="paragraph"></span>
Температура застосування: 
Не слід застосовувати фунгіцид за температури вище ніж +25 °С та при вологості повітря нижче як 40 %.
<span className="paragraph"></span>
Особливості застосування: 
Міравіс Дуо — фунгіцид із системною дією для застосування на сої проти комплексу захворювань, що має захисну й лікувальну дії.
<span className="paragraph"></span>
Обприскування краще проводити профілактично або при появі перших ознак хвороби. Не рекомендується проводити обробку по вологій листовій поверхні або якщо очікуються опади протягом 1,5–2,0 години після обробки.
Робочий розчин слід використати протягом 24 годин після приготування. Обприскування проводити в ранкові або вечерні години в безвітряну погоду.</>),
  },
  {
    productTitle: "Міравіс Нео",
    path: "/miravis-neo",
    src: Fungicides42,
    volume: "5 л",
    price: 241.03,
    currency: "USD",
    text: (<>
    Опис: Міравіс Нео - це унікальний SDHI-фунгіцид із найширшим спектром дії для захисту від хвороб пшениці та ячменю.
    <span className="paragraph"></span>
Діюча речовина: 75 г/л АДЕПІДИНУ, 100 г/л азоксистробіну, 125 г/л пропіконазолу.
<span className="paragraph"></span>
Препаративна форма: Суспензійна емульсія.
<span className="paragraph"></span>
Переваги: 
	•	Містить унікальний карбоксамід із нової підгрупи N-метокси-піразолкарбоксамідів;
	•	Має три різні механізми дії на збудника хвороби;
	•	Рівномірний розподіл у листі для оптимального захисту протягом довгого часу;
	•	Рівномірний розподіл у листі для оптимального захисту протягом довгого часу;
	•	Унікальна ефективність у контролі піренофорозу, плямистостей (сітчастої, темно-бурої, смугастої), фузаріозів та септоріозів;
	•	Відмінна стратегія боротьби з резистентністю — унікальний препарат, який не потребує партнерів;
	•	Висока спорідненість із восковим шаром листя і фотостабільність, а також стійкість до змиванням дощем.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Озима пшениця; 
	•	Озимий і ярий ячмінь.
  <span className="paragraph"></span>
Спектр дії: 
	•	Борошниста роса, септоріоз листя, септоріоз колосу, бура листова іржа, піренофороз, альтернаріоз; 
	•	Сітчаста плямистість, смугаста плямистість, темно-бура плямистість, ринхоспороз, іржа, альтернаріоз.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
Норма витрати робочого розчину: 100-300 л/га.
<span className="paragraph"></span>
Сумісність: 
Препарат можна змішувати з іншими загальновживаними засобами захисту рослин на відповідній культурі. Сумісний із більшістю фунгіцидів та інсектицидів. У кожному конкретному випадку слід проводити тести на сумісність.
<span className="paragraph"></span>
Температура застосування: 
Оптимальна температура застосування — +12...25 °С. 
<span className="paragraph"></span>
Особливості застосування: 
Міравіс® Нео — фунгіцид із системною дією для застосування на озимій пшениці, ярому й озимому ячмені, має захисну й лікувальну дію. Обприскування краще проводити профілактично або при появі перших ознак хвороби. Термін застосування препарату Міравіс® Нео — від фази другого міжвузля до цвітіння культури (ВВСН 32–69). 
Не рекомендується проводити обробку по вологій листовій поверхні або якщо очікуються опади протягом 1,5–2,0 години після обробки. 
Робочий розчин слід використати протягом 24 годин після приготування. 
Термін виходу на оброблені площі для догляду за посівами: для проведення механічних робіт — 3 дні, для проведення ручних робіт не регламентуються. Не слід застосовувати фунгіцид за температури вище ніж +25 °С та при вологості повітря нижче як 40 %. 
Обприскування проводити в ранкові або вечерні години в безвітряну погоду. Не допускати знесення препарату на сусідні культури. Після роботи з препаратом слід ретельно промити бак, штанги й форсунки обприскувача. </>),
  },
  {
    productTitle: "Міравіс Прайм",
    path: "/miravis-prime",
    src: Fungicides43,
    volume: "5 л",
    price: 538.21,
    currency: "USD",
    text: (<>Опис: Міравіс Прайм — вища ліга виноградарства.
    <span className="paragraph"></span>
      Діюча речовина: 250 г/л флудиоксонілу + 150 г/л АДЕПІДИНУ.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	Найвищий біохімічний потенціал серед класу SDHI-продуктів
        •	Унікальний карбоксамід з хімічної групи N-метокси-піразол карбоксамідів
        •	Новий унікальний механізм дії, трансламінарний ефект із локально-системною дією, що забезпечує пролонгований період захисту 
        •	Тривалий захист від ключових хвороб винограду
        •	Універсальний склад забезпечує захист від унікального спектра хвороб: сірої гнилі, оїдіуму, антракнозу, альтернаріозу
        •	Новий механізм дії
        •	Основа антирезистентної стратегії для контролю хвороб винограду
        •	Стійкість до змивання опадами
        •	Ефективний у складних погодних умовах — до 35 °С і за вологості понад 80 %
        •	Не викликає фітотоксичності. 
        <span className="paragraph"></span>
      Застосування препарату.
      <span className="paragraph"></span>
      Культура: 
        •	Виноград.
        <span className="paragraph"></span>
      Спектр дії: 
        •	Сіра гниль, оїдіум, антракноз, альтернаріоз.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	У період вегетації.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 600–800 л/га.
      <span className="paragraph"></span>
      Сумісність: 
      Препарат можна змішувати з іншими загальновживаними засобами захисту рослин на відповідній культурі. 
      <span className="paragraph"></span>
      Оптимальна температура для застосування: 
      +12–24 ⁰С. 
      <span className="paragraph"></span>
      Особливості застосування: 
      Адепідин™ відноситься до нової групи класу карбоксамідів і є новітньою розробкою та унікальною молекулою з високим біохімічним потенціалом. Діюча речовина Адепідин™ швидко проникає у восковий наліт та рівномірно розподіляється в ньому, а також  у тканину листка, де виявляє системну активність. Флудиоксоніл має контактно-поверхневу дію, що забезпечує надійний захист від проростання міцелію. Завдяки такому поєднанню значно розширюється спектр контролю основних захворювань винограду, а також унеможливлюється виникнення резистентності. 
      Міравіс® Прайм рекомендується вносити у фазу початок скидання чохликів і до фази початок змикання ягід у гроні. 
      <span className="paragraph"></span>
      Використовуйте Міравіс® Прайм профілактично, це дозволить збільшити термін високої ефективності препарату. </>),
  },
  {
    productTitle: "Ревус Топ",
    path: "/revus-top",
    src: Fungicides44,
    volume: "5 л",
    price: 448.48,
    currency: "USD",
    text: (<>Опис: Ревус Топ - ефективний системно-ламінарний фунгіцид. Забезпечує надійний захист томата і картоплі від таких захворювань як від фітофтороз і альтернаріоз. 
    <span className="paragraph"></span>
      Діюча речовина: 250 г/л мандіпропаміду, 250 г/л дифеноконазолу.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	Поєднання двох діючих речовин гарантує найвищу ефективність проти альтернаріозу і фітофторозу;
        •	Висока стійкість до змивання — захищає врожай навіть в умовах, сприятливих для розвитку хвороб;
        •	Відмінна профілактична і лікувальна дія завдяки високій трансламінарній та системній активності.
        <span className="paragraph"></span>
      Застосування препарату.
      <span className="paragraph"></span>
      Культура: 
        •	Томати; 
        •	Картопля.
        <span className="paragraph"></span>
      Спектр дії: 
        •	Альтернаріоз, фітофтороз.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 200-300 л/га. 
      <span className="paragraph"></span>
      Рекомендації застосування: 
      Ревус® Топ має лікувальну і превентивну дії, тому рекомендується застосовувати препарат профілактично або при перших симптомах хвороб, щоб уникнути поширення хвороби і зараження всього врожаю. 
      <span className="paragraph"></span>
      Особливості застосування: 
      В інтегрованих системах захисту рекомендується застосовувати на початку цвітіння картоплі та при масовому цвітінні томата. Сумісний із більшістю фунгіцидів та інсектицидів, проте в кожному конкретному випадку слід перевіряти препарати на сумісність. 
      <span className="paragraph"></span>
      Особливості застосування на картоплі. Мандіпропамід має профілактичний і лікувальний ефект у боротьбі з фітофторозом. Завдяки трансламінарній активності він закріплюється у восковому нальоті листка, забезпечуючи максимальний захист проти зараження фітофторозом. Зупиняє проростання зооспор і спорогенів, пригнічує утворення гаусторій, ріст міцелію і спороношення. 
      Дифеноконазол швидко поширюється по всій рослині і знищує збудника альтернаріозу завдяки своїй системності, забезпечуючи захистну і лікувальну дії. Зупиняє ріст міцелію. </>),
  },
  {
    productTitle: "Ридоміл Голд",
    path: "/ridomil-gold",
    src: Fungicides45,
    volume: "5 кг",
    price: 93.16,
    currency: "USD",
    text: (<>
    Опис: Ридоміл Голд - комбінований фунгіцид для захисту картоплі, овочевих культур і винограду від комплексу хвороб.
    <span className="paragraph"></span>
Діюча речовина: 640 г/л манкоцеб+40 г/кг мефеноксам.
<span className="paragraph"></span>
Препаративна форма: Вододисперсні гранули.
<span className="paragraph"></span>
Переваги: 
	•	Біологічно найефективніша комбінація системного і контактного фунгіцидів: надійний захист проти хвороб навіть за несприятливих погодних умов;
	•	Профілактична та лікувальна дія;
	•	Захист нового приросту і бульб.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Виноград; 
	•	Картопля і томати;
	•	Огірки; 
	•	Хміль; 
	•	Ріпак озимий; 
	•	Тютюн; 
	•	Цибуля (крім на перо); 
	•	Часник.
  <span className="paragraph"></span>
Спектр дії: 
	•	Мілдью, чорна плямистість; 
	•	Фітофтороз, альтернаріоз; 
	•	Пероноспороз; 
	•	Несправжня борошниста роса.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: Виноград, хміль – 600–800 л/га. Картопля, овочі – 300–450 л/га. Польові культури 150–200 л/га.
<span className="paragraph"></span>
Сумісність: 
Сумісний із більшістю фунгіцидів та інсектицидів, проте в кожному конкретному випадку слід перевіряти препарати на сумісність. 
<span className="paragraph"></span>
Особливості застосування: 
Рекомендується застосовувати в комплексній системі захисту з фунгіцидами Квадріс®, Топаз®, Ревус®, Скор®. 
У разі виникнення ризику сильного розвитку хвороби витримувати коротші інтервали між обробками (10 днів). </>),
  },
  {
    productTitle: "Світч",
    path: "/svitch",
    src: Fungicides46,
    volume: "1 кг",
    price: 146.34,
    currency: "USD",
    text: (<>Опис: Світч — неперевершений фунгіцид у боротьбі із сірою гниллю.
    <span className="paragraph"></span>
      Діюча речовина: 375 г/л Ципродиніл, 250 г/л Флудиоксоніл. 
      <span className="paragraph"></span>
      Препаративна форма: Вододисперсні гранули. 
      <span className="paragraph"></span>
      Переваги: 
        •	Двокомпонентний фунгіцид контактно–проникаючої дії;
        •	Контролює головних збудників хвороб, які спричиняють гниття плодів і ягід при зберіганні (плодова гниль, сіра гниль, інші хвороби);
        •	Короткий термін очікування;
        •	Препарат, який вдало захищає плоди і ягоди від комплексу хвороб (моніліоз, клястероспоріоз, кокомікоз, кучерявість листя персика, парша та різні види плямистості) під час вегетації;
        •	Покращує лежкість плодів при зберіганні.
        <span className="paragraph"></span>
      Застосування препарату.
      <span className="paragraph"></span>
      Культура:
        •	Груша, яблуня, персик, абрикос, слива, черешня, груша; 
        •	Полуниця; 
        •	Чорна садова (лохина);
        •	Виноградники; 
        •	Томати й огірки закритого і закритого ґрунту; 
        •	Троянди закритого і відкритого ґрунту. 
        <span className="paragraph"></span>
      Спектр дії: 
        •	Моніліоз, сиза пліснява, гниль, фузаріозна гниль, альтернаріоз, сіра гниль, клястероспоріоз;
        •	Хвороби плодів під час їхнього зберігання у сховищах, парша, альтернаріоз, кокомікоз;
        •	Сіра гниль, мілдью;
        •	Моніліоз, сиза пліснява, гниль, фузаріозна гниль, альтернаріоз, сіра гниль, клястероспоріоз;
        •	Хвороби плодів під час їхнього зберігання у сховищах, парша, альтернаріоз, кокомікоз;
        •	Хвороби при зберіганні (альтернаріоз, антракноз, фузаріоз, мокра й сіра гнилі);
        •	Хвороби при зберіганні (альтернаріоз, антракноз, фузаріоз, мокра й сіра гнилі);
        •	Моніліоз, сиза пліснява, гниль, фузаріозна гниль, альтернаріоз, сіра гниль, клястероспоріоз;
        •	Хвороби плодів під час їхнього зберігання у сховищах, парша, альтернаріоз, кокомікоз;
        •	Антракноз, моніліоз, плямистості, хвороби ягід під час зберігання.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	Обприскування в період вегетації;
        •	Обприскування в період вегетації до збору урожаю.
        <span className="paragraph"></span>
      Терміни застосування: 
        •	Виноград: початок змикання ягід у грона, початок дозрівання плодів, перед збиранням.
        •	Полуниця: період цвітіння, після масового цвітіння, перед збиранням.
        •	Яблуня, груша, персик: перед збиранням, після граду та механічних пошкоджень.
        •	Томати, огірки, троянди відкритого й закритого ґрунту: в період вегетації. </>),
  },
  {
    productTitle: "Юніформ",
    path: "/uniform",
    src: Fungicides47,
    volume: "1 л",
    price: 94.64,
    currency: "USD",
    text: (<>Опис: Юніформ — універсальна фунгіцидна вакцина для здорового врожаю.
    <span className="paragraph"></span>
      Діюча речовина: 322 г/л азоксистробіну, 124 г/л металаксилу-М.
      <span className="paragraph"></span>
      Препаративна форма: Суспензійна емульсія.
      <span className="paragraph"></span>
      Переваги: 
        •	Просте вирішення проблем із кореневими хворобами;
        •	Пригнічення широкого спектра патогенів;
        •	Захищаючи кореневу систему, захищає всю рослину;
        •	Має системно-лікувальну дію;
        •	Підвищує стресостійкість рослини в посушливих умовах.
        <span className="paragraph"></span>
      Застосування препарату.
      <span className="paragraph"></span>
      Культура: 
        •	Томати відкритого ґрунту; 
        •	Томати закритого ґрунту; 
        •	Огірки  відкритого ґрунту;
        •	Огірки  закритого ґрунту;
        •	Кавун;
        •	Цибуля; 
        •	Картопля. 
        <span className="paragraph"></span>
      Спектр дії: 
        •	Комплекс кореневих захворювань ( фузаріоз, пітіум, ризоктоніоз та ін.);
        •	Ризоктоніоз, срібляста парша, антракноз, фітофтороз.
        <span className="paragraph"></span>
      Фаза внесення: 
        •	У період вегетації з поливною водою через системи крапельного зрошення;
        •	Обприскування дня борозни під час засідання.
        <span className="paragraph"></span>
      Норма витрати робочого розчину: 80-200 л/га — картоплі.
      <span className="paragraph"></span>
      Сумісність: 
      Сумісний із більшістю інсектицидів, регуляторів росту рослин. У кожному конкретному випадку слід проводити тести на сумісність. 
      <span className="paragraph"></span>
      Рекомендації з ґрунтового внесення: 
        •	Обприскування дна борозни при посадці бульб за допомогою двох форсунок, установлених на картоплеcаджалку.
        •	Заробка препарату по всьому ґрунтовому профілю гребеня.
        •	Перший розпилювач обприскує ґрунт на дні борозни перед падінням бульби.
        •	Другий розпилювач обприскує стінки борозни після падіння бульби при її закритті.
        •	Факел розпилу форсунок слід відрегулювати так, щоб ширина захвату оброблюваної поверхні дна борозни становила 15–20 см.
        <span className="paragraph"></span>
      Особливості застосування: 
      Вчасна подача робочого розчину в поливному циклі забезпечує правильне розміщення фунгіциду в кореневій зоні. На легких ґрунтах його треба вносити протягом третьої чверті, на важких — протягом другої третини поливного циклу. 
      Застосування на томатах починається від фази другого справжнього листка. 
      </>),
  },
];
