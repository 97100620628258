import { configureStore } from "@reduxjs/toolkit";
import BurgerSlice from "./BurgerSlice/BurgerSlice";
import ModalSlice from "./ModalSlice/ModalSlice";
import OverlaySearchSlide from "./OverlaySearchSlide/OverlaySearchSlide";

const store = configureStore({
  reducer: {
    modal: ModalSlice,
    burger: BurgerSlice,
    overlay: OverlaySearchSlide,
  },
});

export default store;
