import "./CallButton.css";
import phoneIcon from "../../assets/icons/phone-icon.png";
import { useDispatch } from "react-redux";
import { setClearIsFormSended, setIsActive, setSelectedProduct } from "../../redux/ModalSlice/ModalSlice";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import { setIsOverlayClose } from "../../redux/OverlaySearchSlide/OverlaySearchSlide";

const CallButton = ({setSearch}) => {
  const dispatch = useDispatch();
  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setClearIsFormSended());
    dispatch(setBurgerIsClose());
    dispatch(setIsOverlayClose())
    dispatch(setSelectedProduct(null));
    setSearch("")
  };

  return (
    <div onClick={handleModalOpen} className={`btn-call`}>
      <div className={`btn-call__ico`}>
        <img
          src={phoneIcon}
          alt="іконка при натискнні на яку відкриється форма зворотнього зв'язку"
          className="fas fa-phone-alt"
        />
      </div>
    </div>
  );
};

export default CallButton;
