import Insecticides1 from "../assets/images/InsecticidesImages/asistent-maksi.webp";
import Insecticides2 from "../assets/images/InsecticidesImages/atriks.webp";
import Insecticides3 from "../assets/images/InsecticidesImages/dimefos.webp";
import Insecticides4 from "../assets/images/InsecticidesImages/emiprid-pro.webp";
import Insecticides5 from "../assets/images/InsecticidesImages/kanonir-duo.webp";
import Insecticides6 from "../assets/images/InsecticidesImages/lagotrin.webp";
import Insecticides7 from "../assets/images/InsecticidesImages/pirisoks.webp";
import Insecticides8 from "../assets/images/InsecticidesImages/tiaklotrin-m.webp";
import Insecticides9 from "../assets/images/InsecticidesImages/fosorhan-duo.webp";
import Insecticides10 from "../assets/images/InsecticidesImages/bokser.webp";
import Insecticides11 from "../assets/images/InsecticidesImages/veteran.webp";
import Insecticides12 from "../assets/images/InsecticidesImages/gotika.webp";
import Insecticides13 from "../assets/images/InsecticidesImages/intern.webp";
import Insecticides14 from "../assets/images/InsecticidesImages/kontador-duo.webp";
import Insecticides15 from "../assets/images/InsecticidesImages/president.webp";
import Insecticides16 from "../assets/images/InsecticidesImages/spiker.webp";
import Insecticides17 from "../assets/images/InsecticidesImages/resident.webp";
import Insecticides18 from "../assets/images/InsecticidesImages/torsida.webp";
import Insecticides19 from "../assets/images/InsecticidesImages/fatrin.webp";
import Insecticides20 from "../assets/images/InsecticidesImages/shaman.webp";
import Insecticides21 from "../assets/images/InsecticidesImages/viares.webp";
import Insecticides22 from "../assets/images/InsecticidesImages/zalp.webp";
import Insecticides23 from "../assets/images/InsecticidesImages/logus.webp";
import Insecticides24 from "../assets/images/InsecticidesImages/napoval.webp";
import Insecticides25 from "../assets/images/InsecticidesImages/nokaut-ekstra.webp";
import Insecticides26 from "../assets/images/InsecticidesImages/razit.webp";
import Insecticides27 from "../assets/images/InsecticidesImages/flaip.webp";
import Insecticides28 from "../assets/images/InsecticidesImages/ampligo-150-zc.webp";
import Insecticides29 from "../assets/images/InsecticidesImages/enjio.webp";
import Insecticides30 from "../assets/images/InsecticidesImages/karate-zeon-050-cs.webp";
import Insecticides31 from "../assets/images/InsecticidesImages/lufox-105-es.webp";
import Insecticides32 from "../assets/images/InsecticidesImages/proklaim-5-sg.webp";
import Insecticides33 from "../assets/images/InsecticidesImages/vertimek-neo-018-sl.webp";
import Insecticides34 from "../assets/images/InsecticidesImages/aktellik.webp";

export const insecticidesArr = [
  {
    productTitle: "Асистент Максі",
    path: "/asistent-maksi",
    src: Insecticides1,
    volume: "1 л",
    price: 21,
    currency: "USD",
    text: (<>
    Опис: Асистент Максі - системний інсектицид контактно-кишкової дії проти широкого спектру шкідників на посівах ріпаку, соняшнику, у тому числі під час цвітіння культур. Флагман висококонцентрованих інсектицидів у фазі цвітіння. 
    <span className="paragraph"></span>Діюча речовина: Ацетаміприд, 400 г/л.
    <span className="paragraph"></span>
Препаративна форма: Концентрат суспезії.
<span className="paragraph"></span>
Переваги: 
	•	Безпечний для бджіл, джмелів та корисної ентомофауни — може використовуватись у фазу цвітіння;  
	•	Ефективний при підвищених температурах, у порівнянні із більшістю інсектицидів; 
	•	Препарат має трансламінарну та системну дії (рухається актропетально), тому захищає необроблені частини рослини та контролює шкідників, що живуть приховано; 
	•	Препарат розроблений для використання агродронами; 
	•	Ефективний проти імаго і личинок комах — запобігає пошкодженню культури та накопиченню шкідників; 
	•	Низька норма використання – зменшення на логістику.
  <span className="paragraph"></span>
Механізм дії: 
Ацетаміприд блокує постсинаптичні нікотинові ацетилхолінові рецептори, перериваючи тим самим передачу нервових імпульсів, що веде до ураження нервової системи і загибелі комах від перезбудження і паралічу. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура:
	•	Соняшник;
	•	Ріпак.
  <span className="paragraph"></span>
Спектр дії:
	•	Трипс, попелиця, клопи;
	•	Хрестоцвіті блішки, попелиця, прихованохоботник, ріпаковий квіткоїд.
  <span className="paragraph"></span>
Фаза внесення:
	•	Обприскування під час вегетації.
  <span className="paragraph"></span>
Планується реєстрація на такі культури:
<span className="paragraph"></span>
Культура:
	•	Зернові;
	•	Овочеві;
	•	Соя, горох, льон;
	•	Буряк цукрові та столові. 
  <span className="paragraph"></span>
Спектр дії: 
	•	Клоп шкідлива черепашка, попелиці, цикадки, трипси, злакові мухи, блішки, довгоносики, щитоноска.
  <span className="paragraph"></span>
Фаза внесення:
	•	Обприскування під час вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Максимальна ефективність досягається за умови своєчасного застосування препарату з урахуванням даних прогнозів і перших ознак появи шкідників.
	•	Кращу ефективність Асистент Максі демонструє при застосуванні по активно вегетуючих рослинах. Запобігати внесення препарату по рослинам що знаходяться в стресі, оскільки це знижує ефективність препарату.
	•	Допустима температура внесення +8...+25°C.
	•	Для поліпшення покриття рослин та шкодочинних об'єктів слід разом з Асистент Максі застосовувати АгроПАВ Екстра у концентрації 0,05%-0,1% робочого розчину.
	•	Обприскування допускається при швидкості вітру до 3 м/с.
    </>),
  },
  {
    productTitle: "Атрікс",
    path: "/atriks",
    src: Insecticides2,
    volume: "5 л",
    price: 25.67,
    currency: "USD",
    text: (<>
    Опис: Атрікс - контактно-кишковий інсектицид із групи синтетичних піретроїдів для захисту сільськогосподарських культур від широкого спектру шкідників. 
    <span className="paragraph"></span>
Діюча речовина: Альфа-циперметрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується.
<span className="paragraph"></span>
Переваги:
	•	Швидка дія — шкідники перестають живитися через 10-15 хвилин;
	•	Виражений «нокаут-ефект» — загибель наступає через 2-3 години; 
	•	Контролює широкий спектр шкідників; 
	•	Ефективний проти імаго і личинок комах — запобігає пошкодженню культури та накопиченню шкідників; 
	•	Можливість використання на багатьох культурах — оптимізація управління продуктом у господарстві; 
	•	Низька норма використання — зменшення витрат на логістику. 
  <span className="paragraph"></span>
Механізм дії:
Альфа-циперметрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до порушення функцій нервової системи, загального паралічу та швидкої їх загибелі. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура:
	•	Пшениця;
	•	Ріпак.
  <span className="paragraph"></span>
Цільовий об'єкт дії: 
	•	Клоп шкідлива черепашка, попелиці, цикадки, трипси, п’явиці;
	•	Ріпаковий квіткоїд, хрестоцвіті блішки. 
   <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Допустима температура внесення +5...+22°C.
	•	Максимальна ефективність досягається за умови своєчасного застосування препарату при досягненні шкідником ЕПШ.
	•	Для поліпшення покриття рослин та шкодочинних об'єктів слід разом з Атрікс застосовувати АгроПАВ Екстра у концентрації 0,05%-0,1% робочого розчину.
	•	З метою запобігання виникненню резистентності рекомендується чергувати Атрікс із інсектицидами інших хімічних класів (фосфорорганічні або неонікотиноїди).
	•	Обприскування допускається при швидкості вітру до 3 м/с.
	•	Атрікс токсичний для бджіл, тому у період цвітіння його використання заборонено. У період цвітіння використовуйте Асистент Максі.</>),
  },
  {
    productTitle: "Димефос",
    path: "/dimefos",
    src: Insecticides3,
    volume: "10 л",
    price: 72.31,
    currency: "USD",
    text: (<>
    Опис: Димефос - системний, контактно-кишковий фосфорорганічний інсектоакарицид для захисту сільськогосподарських культур від широкого спектру шкідників.Комплексний підхід у боротьбі зі шкідниками.
    <span className="paragraph"></span> 
Діюча речовина: Диметоат, 400 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується.
<span className="paragraph"></span>
Переваги:
	•	Ефективний проти багатьох видів шкідливих комах; 
	•	Можливість використання на багатьох культурах — оптимізація управління продуктом у господарстві; 
	•	Завдяки швидкому проникненню у тканини рослин знищує шкідників, що живуть приховано ( в т.ч. мінерів); 
	•	Тривала захисна дія; 
	•	Залишається ефективним в широкому температурному діапазоні;
	•	Застосовується у програмах боротьби з популяціями комах, стійких до піретроїдів та неонікотиноїдів. 
  <span className="paragraph"></span>
Механізм дії: 
Диметоат впливає на холінестеразу, в результаті чого накопичується ацетилхолін в синапсах, що призводить до значного ураження нервової системи, паралічу всього організму та загибелі шкідника. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура:
	•	Озима пшениця; 
  <span className="paragraph"></span>
Цільовий об'єкт дії: 
	•	Попелиці, трипси, злакові мухи, цикадки. 
  <span className="paragraph"></span>
Рекомендації щодо застосування: 

	•	Допустима температура внесення +8...+25°C, відносна вологість повітря не нижче 40%.
	•	Уникайте застосування інсектициду в очікуванні або відразу ж після заморозків. Не рекомендується застосовувати препарат за умов високої температури і низької вологості повітря.
	•	Димефос токсичний для бджіл тому у період цвітіння його використання заборонено. У період цвітіння використовуйте Асистент Максі.
	•	Обприскування допускається при швидкості вітру до 3 м/с.</>),
  },
  {
    productTitle: "Еміприд ПРО",
    path: "/emiprid-pro",
    src: Insecticides4,
    volume: "5 л",
    price: 89.15,
    currency: "USD",
    text: (<>
    Опис: Еміприд Про — контактно-системний інсектицид з трансламінарними властивостями. Швидкий та тривалий захист ріпака, кукурудзи, соняшника від лускокрилих та інших супутніх шкідників. Три рівні захисту. 
    <span className="paragraph"></span>
Діюча речовина: Емамектину бензоат, 40 г/л, ацетаміприд, 60 г/л, біфентрин, 30 г/л.
<span className="paragraph"></span>
Препаративна форма: Мікроемульсія. 
<span className="paragraph"></span>
Переваги: 
	•	Унікальна комбінація діючих речовин створена для контролю головних та супутніх шкідників ріпака, кукурудзи, соняшника; 
	•	Має ові-ларвіцидну дію, зупиняє живлення протягом декількох годин після контакту зі шкідником, запобігаючи в подальшому пошкодженню рослини;
	•	Контроль західного кукурудзяного жука (імаго);   
	•	Контролює лускокрилих у різних стадіях розвитку (яйце, гусінь, імаго); 
	•	Захищає від приховано- та відкритоживучих шкідників; 
	•	Пролонгований період захисту (до 3 тижнів);
	•	Спеціально розроблена формуляція для внесення дронами; 
	•	Відсутність резистентності. 
  <span className="paragraph"></span>
Механізм дії:
Емамектину бензоат при потраплянні в організм шкідника впливає на дві ділянки в центральній нервовій системі комахи: пов'язує рецептори гамма-аміномасляної кислоти (ГАМК) в синапсіі h-глутаматні рецептори в м'язових клітинах.Це призводить до розслаблення м'язів(вони не можуть скорочуватися).Ацетаміприд блокує постсинаптичні нікотинові ацетилхолінові рецептори, перериваючи тим самим передачу нервових імпульсів, що веде до ураження нервової системи і загибелі комах від перезбудженняі паралічу.Біфентрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до деполяризації мембран нервових клітин та порушує функції нервової системи, загального паралічу та швидкої їх загибелі шкідників. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура: 
Ріпак озимий та ярий; 
Кукурудза;
Соняшник. 
<span className="paragraph"></span>
Цільовій об’єкт: 
	•	Капустяна міль, хрестоцвіті блішки, совки, білянки, луговий метелик, попелиця, квіткоїд; 
	•	Бавовняна совка, кукурудзяний стебловий метелик, західний кукурудзяний жук діабротика (імаго), попелиця;
	•	Листогризні совки, луговий метелик, попелиця, трипс, клопи, соняшникова вогнівка, соняшникова шипоноска.
  <span className="paragraph"></span>
Практичний досвід застосування: 
	•	Соя; 
	•	Льон. 
  <span className="paragraph"></span>
Цільовій об’єкт:
	•	Люцернова совка, луговий метелик, лляна плодожерка, льоновий трипс, польові клопи.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводять у період вегетації рослин, з появою перших ознак ураження шкідниками.
	•	Робочий розчин готують перед застосуванням.
	•	Обприскування проводять у суху безвітряну погоду, при температурі повітря не вище 25°C.
	•	Найкраще обробляти культури вранці чи ввечері.
	•	При попаданні на яйця комах не дає їм вирости і дозріти (необхідний моніторинг на підставі пасток).</>),
  },
  {
    productTitle: "Канонір Дуо",
    path: "/kanonir-duo",
    src: Insecticides5,
    volume: "5 л",
    price: 78.79,
    currency: "USD",
    text: (<>
    Опис: Канонір Дуо - інсектицид системно-контактної дії для захисту сільськогосподарських культур від широкого спектру шкідників.
    <span className="paragraph"></span>
Діюча речовина: Імідаклоприд, 300 г/л, лямбда-цигалотрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span> 
Переваги: 
	•	Надійний контроль широкого спектру шкідників; 
	•	Захищає новий приріст завдяки системній дії препарату; 
	•	Можливість використання на багатьох культурах — оптимізація управління продуктом у господарстві;
	•	Низька норма витрати і зручна препаративна форма;
	•	Виражений «нокаут-ефект» — швидка дія на шкідників. 
  <span className="paragraph"></span>
Механізм дії:
Імідаклоприд блокує постсинаптичні нікотинові ацетилхолінові рецептори, перериваючи тим самим передачу нервових імпульсів, що веде до ураження нервової системи і загибелі комах від перезбудження і паралічу.Лямбда-цигалотрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до порушення функцій нервової системи, загального паралічу та швидкої їх загибелі.
<span className="paragraph"></span> 
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура:
	•	Пшениця озима.
  <span className="paragraph"></span>
Цільові об'єкти; 
	•	Клоп шкідлива черепашка, п’явиця, трипси, попелиці, злакові мухи;
	•	Хлібні жуки, хлібний пильщик.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Практика застосування аналогічних препаратів:
<span className="paragraph"></span>
Культура: 
	•	Ріпак;
	•	Горох.
  <span className="paragraph"></span>
Цільові об'єкти;
	•	Квіткоїд, хрестоцвіті блішки, попелиці,прихованохоботники, пильщик, білан капустяний; 
	•	Попелиці, трипси, горохова зернівка, довгоносики, гороховий комарик.
  <span className="paragraph"></span>
Спосіб та час обробки:
	•	Обприскування в період вегетації.</>),
  },
  {
    productTitle: "Лаготрин",
    path: "/lagotrin",
    src: Insecticides6,
    volume: "1 л",
    price: 31.47,
    currency: "USD",
    text: (<>
    Опис: Лаготрин - контактно-кишковий висококонцентрований інсектицид стійкий до УФ-випромінювання для тривалого контролю шкідників в посівах с/г культур. Стійкий до УФ-променів. 
    <span className="paragraph"></span>
Діюча речовина: Лямбда-цигалотрин, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Капсульна суспензія.  
<span className="paragraph"></span>
Переваги: 
	•	Інноваційна формуляція (капсульна суспензія), стійка до УФ-променів забезпечує більш тривалий захист;
	•	Виражений «нокаут-ефект» — загибель наступає через 2-3 години;
	•	Можливість використання на багатьох культурах — оптимізація управління продуктом у господарстві; 
	•	Стабільний в широкому діапазоні рН — найкращий партнер для бакових сумішей;
	•	Можливе використання наземним методом та агродронами; 
	•	Має акарицидну дію проти імаго. 
  <span className="paragraph"></span>
Механізм дії: 
Лямбда-цигалотрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до порушення функцій нервової системи, загального паралічу та швидкої їх загибелі.
<span className="paragraph"></span> 
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця; 
	•	Ріпак; 
	•	Кукурудза;
	•	Соняшник. 
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Злакові мухи, попелиці, цикадки, трипси, п'яниці, клоп шкідлива черепашка; 
	•	 Хрестоцвіті блішки, для, потайнохоботник, клопи;
	•	Шипоноска, клопи, попелиці.
  <span className="paragraph"></span>
Практика використання препаратів з аналогічною діючою речовиною:
<span className="paragraph"></span>
Культура:
	•	Ячмінь;
	•	Цукровий буряк;
	•	Бобові;
	•	Овочеві.
  <span className="paragraph"></span>
Цільові об’єкти:
	•	Блішки, п'яниці, хлібні клопи, злакові попелиці; 
	•	зерноїди, довгоносики, павутинний кліщ; 
	•	Тютюновий трипс, мухи, попелиці, цикадки, листоблішки.
  <span className="paragraph"></span>
Фаза внесення:
Обприскування під час вегетації.
 <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Перед застосуванням препарату обов'язково збовтати тару.
	•	Застосовувати Лаготрин потрібно у максимальній нормі витрати, за умови високої чисельності шкідників та при обробці проти імаго і личинок старшого віку.
	•	
	•	Обприскування робити у ранкові або у вечірні години у безвітряну погоду, не допускаючи зносу препарату на сусідні культури. Робочий розчин має бути використанийу день приготування.
	•	
	•	Допустима температура внесення +8…+25°C.
	•	
	•	Забороняється проводити обробку під час цвітіння культур, щоб не завдати шкоди запилювачам. У період цвітіння використовуйте Асистент Максі.
	•	
	•	Для поліпшення покриття рослин та шкодочинних об'єктів слід разом з інсектицидом Лаготрин застосовувати АгроПАВ Екстра у концентрації 0,05%-0,1% робочого розчину.</>),
  },
  {
    productTitle: "Піризокс",
    path: "/pirisoks",
    src: Insecticides7,
    volume: "1 кг",
    price:  48.10,
    currency: "USD",
    text: (<>
    Опис: Піризокс - контактний, кишковий, трансламінарний акарицид для контролю кліщів в усіх стадіях розвитку. Ефективний проти всіх стадій кліща. 
    <span className="paragraph"></span>
Діюча речовина: Гекситіазокс, 140 г/кг, піридабен, 300 г/кг. 
<span className="paragraph"></span>
Препаративна форма: Порошок, що змочується.
<span className="paragraph"></span> 
Переваги:  
	•	Ефективний проти всіх стадій кліщів: яйце-личинка-німфа- дорослі кліщі; 
	•	Знищення шкідника із зворотньої сторони листка завдяки трансламінарній активності;
	•	Піризокс викликає стерильність яєць у наступного покоління;
	•	Швидка дія — починає працювати через 20 хвилин;  
	•	Дві діючі речовини дозволяють зменшити ризик виникнення резистентності; 
	•	Тривалий період захисної дії.
  <span className="paragraph"></span>
Механізм дії: 
Гекситіазокс є інгібітором метаморфозу, порушуючи перехід кліщів з однієї стадії розвитку в іншу. Піридабен вражає центральну нервову систему шкідника за рахунок блокування транспорту електронів в мітохондріях. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура: 
	•	Соя.
  <span className="paragraph"></span>
Спектр дії:
	•	Кліщі.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації. 
   <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Оптимальна температура застосування акарициду Піризокс становить +15...+25.
	•	
	•	Для досягнення максимальної ефективності препарату потрібне рівномірне покриття поверхні рослин, особливо з нижнього боку листа, адже кліщі живуть і живляться саме там.
	•	Для поліпшення покриття рослин та шкодочинних об'єктів слід разом з Піризоксом застосовувати АгроПАВ Екстра у концентрації 0,1% робочого розчину.
	•	
	•	Обприскування допускається при швидкості вітру до 3 м/с. </>),
  },
  {
    productTitle: "Тіаклотрин-М",
    path: "/tiaklotrin-m",
    src: Insecticides8,
    volume: "5 л",
    price: 88.92,
    currency: "USD",
    text: (<>
    Опис: Тіаклотрин-М - високоефективний інсектицид системно-контактної дії для захисту сільськогосподарських культур від шкідників. Унікальний трикомпонентний інсектицид.
    <span className="paragraph"></span>
Діюча речовина: КС, тіаметоксам 200 г/л, клотіанідин 50 г/л, бета-цифлутрин 50 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспезії. <span className="paragraph"></span>
Переваги:  
	•	Надійний контроль широкого спектру шкідників;
	•	Препарат має трансламінарну та системну дії (рухається актропетально), тому захищає необроблені частини рослини та контролює шкідників, що живуть приховано;
	•	Можливість використання на багатьох культурах — оптимізація управління продуктом у господарстві; 
	•	Пролонгована захисна дія; 
	•	Швидкий стоп-ефект за рахунок бета-цифлутрин. 
  <span className="paragraph"></span>
Механізм дії: 
Тіаметоксам і клотіанідин блокують постсинаптичні нікотинові ацетилхолінові рецептори, перериваючи тим самим передачу нервових імпульсів, що веде до ураження нервової системи і загибелі комах від перезбудження і паралічу.Бета-цифлутрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до порушення функцій нервової системи, загального паралічу та швидкої їх загибелі.  
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура: 
	•	Соняшник; 
	•	Пшениця.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Попелиці, шипоноска, клопи, тютюновий трипс, луговий і стебловий метелик; 
	•	Хлібна блішка, попелиці, п'явиця, трипси, хлібні жуки, клоп шкідлива черепашка, злакові мухи, жужелиця.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування та час обробки.  
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Обприскування проводити при температурі від +8 до +25°С. Робочий розчин потрібно використовувати в день його приготування, слід дотримуватись всіх норм (препарату, робочого розчину), умов (температура повітря, води, рН води) зазначених на етикетці для оптимального контролю шкідників.
	•	
	•	При застосуванні Тіаклотрин-М у посушливих і жарких умовах, необхідно збільшити норму витрати робочого розчину до300 л/га й додати АгроПАВ Екстра 0,1% розчин для кращого змочування поверхні листя і на комах.
	•	
	•	Обприскування допускається при швидкості вітру до 3 м/с.
	•	
	•	Тіаклотрин-М токсичний для бджіл, тому у період цвітіння його використання заборонено. У період цвітіння використовуйте Асистент Максі.</>),
  },
  {
    productTitle: "Фосорган ДУО",
    path: "/fosorhan-duo",
    src: Insecticides9,
    volume: "5 л",
    price: 43.80,
    currency: "USD",
    text: (<>
    Опис: Фосорган ДУО - комбінований інсектицид контактно-кишкової та фумігантної дії з акарицидною активністю для захисту сільськогосподарських культур від широкого спектру шкідників.
    <span className="paragraph"></span>
Діюча речовина: Хлорпірифос, 500 г/л, циперметрин, 50 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується. 
<span className="paragraph"></span>
Переваги: 
	•	Незамінний для контролю шкідників сходів (довгоносики, піщаний медляк, чорнотілки, саранові); 
	•	Найкращий інсектицид проти капустяного стеблового прихованохоботника, завдяки високій ефективності навіть за низький температур; 
	•	Контролює шкідників які знаходяться в поверхневому шарі грунту. (озима совка, турун); 
	•	Потрійна дія препарату — контактна, кишкова, фумігантна;
	•	Знищує імаго кліщів — забезпечує стримування розвитку популяцій кліщів.
  <span className="paragraph"></span>
Механізм дії: 
Хлорпірифос впливає на холінестеразу, в результаті чого накопичується ацетилхолін в синапсах, що призводить до значного ураження нервової системи, паралічу всього організму та загибелі шкідника.Циперметрин порушує роботу натрієвих каналів нервових клітин комах, що призводить до деполяризації клітинної мембрани та порушує функції нервової системи, загального паралічу та швидкої їх загибелі. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Клоп шкідлива черепашка, п’явиці, хлібна жужелиця, хлібні жуки, попелиці.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
 Рекомендації щодо застосування: 
	•	Фосорган Дуо слід використовувати у вечірні години та вночі при зменшених висхідних потоках повітря, для кращого контакт з ґрунтом і контролю ґрунтових шкідників (озима совка, хлібна жужелиця). Допустима температура внесення +5...+25°C.
	•	При багаторазовому перевищенні ЕПШ бавовникової та озимої совки, саранових необхідно збільшити норму до 1,5-2,0 л/га.
	•	Слід використовувати проти гусениць лускокрилих 1-3 віку.
	•	Фосорган Дуо токсичний для бджіл, тому у період цвітіння його використання заборонено. У період цвітіння використовуйте Асистент Максі.</>),
  },

  // второй файл

  {
    productTitle: "Боксер",
    path: "/bokser",
    src: Insecticides10,
    volume: "5 л",
    price: 76.15,
    currency: "USD",
    text: (<>
    Опис: Боксер - високоефективний інсектицид системної та контактно-кишкової дії для боротьби проти широкого спектру сисних і листогризучих шкідників
    <span className="paragraph"></span>.
Діюча речовина: Ацетаміприд, 115 г/л: лямбда-цигалотрин, 106 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	інсектицид вирізняється швидкою початковою дією та тривалим періодом захисту (до 25 діб);
	•	Препарат має системну дію та, за рахунок високої розчинності ацетаміприду, швидко проникає і переміщується по рослині, проникаючи в необроблені ділянки;
	•	За рахунок поєднання двох діючих речовин препарат забезпечує високу біологічну ефективність проти різних видів шкідливих комах, в тому числі і проти тих, які ведуть прихований спосіб життя;
	•	Відсутня фітотоксичність для культур.
  <span className="paragraph"></span>
Механізм дії: 
Лямбда-цигалотрин впливає на обмін кальцію в синапсах та порушує роботу натрій-калієвих каналів, що призводить до порушення функцій нервової системи, загального паралічу та швидкої загибелі шкідливих комах.
Ацетаміприд блокує постсинаптичні нікотинілові рецептори у шкідливих комах, перериваючи тим самим передачу нервових імпульсів, що призводить до надмірного нервового збудження і паралічу, а згодом і їх загибелі.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Ріпак озимий та ярий; 
	•	Зернові озимі та ярі; 
	•	Соняшник; 
	•	Соя.
  <span className="paragraph"></span>
Шкідливі об’єкти: 
	•	Ріпаковий квіткоїд, хрестоцвіті блішки, ріпаковий пильщик, білани, попелиці, капустяна білокрилка, тютюновий трипс, хрестоцвіті клопи, прихованохоботники, капустяна стручкова галиця; 
	•	Клоп шкідлива черепашка, смугаста хлібна блішка, злакові блішки, злакові попелиці, пшеничний трипс, п’явиці, цикадки; 
	•	Попелиці, тютюновий трипс, лучний метелик, бурякові довгоносики; 
	•	Американський білий метелик, сонцевик будяковий, тютюновий трипс, попелиці, імаго бульбочкових довгоносків, клопи.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування посівів в період вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять лише наземним обприскуванням з нормою 0,1-0,2л/га в залежності від шкідливого об’єкта. Максимальна ефективність проведення обробок спостерігається при досягненні чисельності шкідників економічного порогу шкідливості.
<span className="paragraph"></span>
ЗАСТЕРЕЖЕННЯ: 
Високотоксичний для бджіл. У агропромисловому комплексі при використанні препарату обов'язковим є додержання ряду загальноприйнятих організаційно-попереджувальних заходів для препаратів І класу токсичності згідно чинного законодавства. </>),
  },
  {
    productTitle: "Ветеран",
    path: "/veteran",
    src: Insecticides11,
    volume: "1 л",
    price: 12.95,
    currency: "USD",
    text: (
      <>
      Опис: Ветеран - інсектицид системної та контактно-шлункової дії проти широкого спектру шкідників.
      <span className="paragraph"></span>
Діюча речовина: Ацетаміприд, 200 г/л.
<span className="paragraph"></span>
Препаративна форма: Розчинний концентрат.
<span className="paragraph"></span>
Переваги: 
	•	«Нокдаун-ефект» дія препарату на шкідників помітна вже за годину після обприскування; 
	•	Пролонгована знищувальна дія на комах (до 2-х тижнів);
	•	Безпечний для корисної ентомофауни — може застосовуватись у період цвітіння; 
	•	Зберігає біологічну ефективність при підвищених температурах повітря. 
  <span className="paragraph"></span>
Механізм дії: 
Ацетаміприд володіє системною та трансламінарною дією, завдяки чому поглинається рослиною та розноситься по всіх її частинах, блокує постсинаптичні нікотинілові рецептори, перериваючи тим самим передачу нервових імпульсів. Шкідники гинуть внаслідок безпосереднього контакту з препаратом, а також за рахунок живлення обробленими рослинами. Препарат впливає на діяльність нервової системи комах, що призводить до надмірного нервового збудження і паралічу, а згодом і їх загибелі.  Тривалість захисної дії становить 14-21 день. Повний ефект на шкідників настає через 1 – 1,5 години після застосування. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га, для обробки садів 800-1000 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Яблуня; 
	•	Ріпак ярий і озимий; 
	•	Пшениця озима, ячмінь озимий та ярий.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Попелиці, яблунева і мінуючі молі, розанова і сітчаста листовійки, яблуневий пильщик;
	•	Яблунева плодожерка(по відродженню I-II генерації);
	•	Каліфорнійська і інші види щитівок, Оленка волохата;
	•	Квіткоїд ріпаковий, хрестоцвіті блішки, попелиці, хрестоцвіті клопи, прихованохоботники, капустяна стручкова галлиця, оленка волохата.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять лише наземним обприскуванням, під час обробки слід уникати перекриття смуг внесення. 
      </>
    ),
  },
  {
    productTitle: "Готіка",
    path: "/gotika",
    src: Insecticides12,
    volume: "5 л",
    price: 58.84,
    currency: "USD",
    text: (<>
    Опис: Готіка - високоефективний інсектицид системної та контактно-кишкової дії для боротьби з широким спектром сисних і листогризучих шкідників. 
    <span className="paragraph"></span>
Діюча речовина: Лямбда-цигалотрин, 106 г/л; тіаметоксам, 141 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Препарат вирізняється швидкою початковою дією та тривалим періодом захисту до 30 діб; 
	•	Низька норма витрати на гектар; 
	•	За рахунок поєднання двох діючих речовин препарат забезпечує високу біологічну ефективність проти різних видів шкідливих комах, в тому числі і проти тих, які ведуть прихований спосіб життя; 
	•	Відсутня фітотоксичність для культур.
  <span className="paragraph"></span>
Механізм дії: 
Лямбда-цигалотрин має контактну, кишкову, відлякувальну (репелентну) дію, але не проявляє фумігантної і системної дії. Впливає на обмін кальцію в синапсах та порушує роботу натрій-калієвих каналів, що призводить до порушення функцій нервової системи, загального паралічу та швидкої загибелі шкідливих комах. Тіаметоксам має системну та контактно-шлункову дію. Контактуючи з комахою, швидко потрапляє в організм шкідника, руйнує нервову систему через рецептори нікотинацетилхоліна, в результаті комаха перестає харчуватись і гине.
Препарат ефективний проти широкого спектру листогризучих та сисних шкідників сільськогосподарських культур з рядів Рівнокрилі, Напівтвердокрилі, Трипси, Твердокрилі, Лускокрилі, Перетинчастокрилі, Двокрилі.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
Культура: 
	•	Пшениця озима і яра; 
	•	Пшениця озима; 
	•	Ячмінь ярий.
  <span className="paragraph"></span>
Об’єкт проти якого обробляється: 
	•	Хлібні клопи (в т. ч. клоп шкідлива черепашка), попелиці, трипси, цикадки;
	•	Хлібний турун;
	•	Злакові мухи, цикадки, п’явиці, попелиці, трипси, блішки, ячмінний мінер.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Регламент щодо застосування: 
Препарат вносять лише наземним обприскуванням. Норма витрати робочого розчину при наземному застосуванні штанговими обприскувачами 200 – 300 л/га.
Строки виходу на оброблені ділянки: для механізованих робіт - 4 дні, ручних робіт – не потребує.</>),
  },
  {
    productTitle: "Інтерн",
    path: "/intern",
    src: Insecticides13,
    volume: "5 л",
    price:  148.52,
    currency: "USD",
    text: (<>
    Опис: Інтерн - універсальний фосфорорганічний інсектоакарицид для боротьби зі шкідниками культурних рослин в період вегетації та захисту запасів зерна.
    <span className="paragraph"></span>
Діюча речовина: Піриміфос-метил, 500 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Має високу швидкість дії;
	•	Знищує сисних, гризучих шкідників, галиці та інші види комах й попереджує їхню повторну появу;
	•	Забезпечує захист польових та плодових культур протягом 7-14 днів з моменту обприскування;
	•	 Тривалий захисний період проти основних шкідників в місцях зберігання запасів – до 8-12 місяців;
	•	Сумісний з більшістю інсектицидів та фунгіцидів;
	•	Не фітотоксичний в рекомендованих дозах;
	•	Не змінює органолептичних властивостей оброблених культур.
  <span className="paragraph"></span>
Механізм дії: 
Піриміфос-метил, є високоефективним фосфорорганічним інсектицидом. Його дія ґрунтується на дестабілізації функціонування гідролітичного ферменту ацетилхолінестерази, за рахунок чого порушується механізм метаболізму ацетилхоліну. Накопичення піриміфос-метилу в тканинах ЦНС веде до збою в роботі різноманітних органів, що в подальшому сприяє до ураження всього організму комахи. Завдяки поглинанню рослиною препарату та після живлення нею шкідником препарат здатний проникати в організм шкідника кишковим шляхом, контактним шляхом - через краплі робочого розчину. Крім того присутня фумігаційна дія - вплив на комаху через дихальну систему. Період захисної дії становить 7-14 днів для польових і плодових культур, до 8-12 місяців при боротьбі з шкідниками запасів. 
<span className="paragraph"></span>
Норма витрати робочого розчину: при обробці польових культур – 200- 400 л/га, для обробки садів – 800-1000 л/га.
<span className="paragraph"></span> 
Рекламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Черешня, вишня.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Вишнева муха.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням з нормою витрати 0,8 – 1,2 л/га в залежності від шкідника, що підлягає обробці. </>),
  },
  {
    productTitle: "Контадор Дуо",
    path: "/kontador-duo",
    src: Insecticides14,
    volume: "5 л",
    price: 85.77,
    currency: "USD",
    text: (<>
    Опис: Контадор Дуо - високоефективний інсектицид системної та контактно-кишкової дії для боротьби проти широкого спектру сисних і листогризучих шкідників.
    <span className="paragraph"></span>
Діюча речовина: Імідаклоприд, 300 г/л; лямбда-цигалотрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Дві діючі речовини, що входять до складу препарату, забезпечують його високу біологічну ефективність без ризику виникнення резистентності у шкідників.
	•	Має високу початкову активність з подальшим тривалим періодом захисної дії.
	•	Має системну дію — проникає через коріння, листя, стебло.
	•	 Ефективний проти шкідників, що ведуть прихований спосіб життя (трипси, личинки мух, мінери).
	•	 Не має фітотоксичного впливу на культуру.
	•	 Низька норма витрати на 1 га.
  <span className="paragraph"></span>
Механізм дії: 
Імідаклоприд блокує постсинаптичні нікотінілові рецептори у комах і перериває фізіологічну іннервацію нервів, що призводить до ураження нервової системи і загибелі комахи.
Лямбда-цигалотрин впливає на окремі ділянки постсинаптичних рецепторів у комах і порушує нормальне функціонування нервової системи, що призводить до загибелі комахи.
<span className="paragraph"></span>
Норма витрати робочого розчину: 150-300 л/га.
<span className="paragraph"></span>
Регламент застосування препарату. 
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Соя;
	•	Ріпак; 
	•	Кукурудза; 
	•	Яблуня, груша. 
  <span className="paragraph"></span>
Шкідливий організм: 
	•	Клоп шкідлива черепашка, п'явиці, попелиці, злакові мухи, хлібні жуки, трипси, цикадки, блішки; 
	•	Акацієва вогнівка, білани; 
	•	Ріпаковий квіткоїд, хрестоцвіті блішки, прихованохоботник;
	•	Стебловий кукурудзяний метелик; 
	•	Комплекс сисних шкідників, довгоносики, східна плодожерка.
  <span className="paragraph"></span>
Рекомендації щодо застосовування: 
Препарат вносять як наземним, так і авіа обприскуванням з нормою витрати 0,06 - 0,2 л/га в залежності від шкідливого об'єкта, що підлягає обробці. </>),
  },
  {
    productTitle: "Президент",
    path: "/president",
    src: Insecticides15,
    volume: "5 л",
    price: 89.50,
    currency: "USD",
    text: (<>
    Опис: Президент - високоефективний інсектицид системної та контактно-кишкової дії для боротьби з широким спектром сисних і листогризучих шкідників.
    <span className="paragraph"></span>
Діюча речовина: Імідаклоприд, 140 г/л; лямбда-цигалотрин, 100 г/л; ацетаміприд, 160 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії. 
<span className="paragraph"></span>
Переваги: 
	•	Три діючі речовини, що входять до складу препарату, забезпечують його високу ефективність без ризику виникнення резистентності; 
	•	Комбінація контактної та системних діючих речовин забезпечує високу початкову активність та тривалий захист; 
	•	Ефективний проти шкідників, що ведуть прихований спосіб життя.
  <span className="paragraph"></span>
Механізм дії: 
Лямбда-цигалотрин має контактну, кишкову, залишкову (шкідник контактує з обробленою культурою) і відлякувальну (репелентну) дію, але не проявляє фумігантної і системної дії. Bпливає на обмін кальцію в синапсах та порушує роботу натрій-калієвих каналів, що призводить до порушення функцій нервової системи, загального паралічу та швидкої загибелі шкідливих комах. Імідаклоприд блокує постсинаптичні нікотінілові рецептори у комах і перериває фізіологічну іннервацію нервів, що призводить до ураження нервової системи і загибелі комахи. Ацетаміприд блокує постсинаптичні нікотинілові рецептори у шкідливих комах, перериваючи тим самим передачу нервових імпульсів, що призводить до надмірного нервового збудження і паралічу, а згодом і їх загибелі.
Препарат ефективний проти більшості шкідників сільськогосподарських культур з рядів Рівнокрилі, Напівтвердокрилі, Трипси, Твердокрилі, Лускокрилі, Перетинчастокрилі, Двокрилі.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га. 
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра, ячмінь озимий; 
	•	Ріпак озимий та ярий; 
	•	Соняшник.
  <span className="paragraph"></span>
Об’єкт проти якого обробляється: 
	•	Хлібні клопи (в т. ч. клоп шкідлива черепашка), блішки, попелиці, цикадки, п’явиці, трипси; 
	•	Хрестоцвіті блішки, ріпаковий квіткоїд, ріпаковий пильщик, капустяний та ріпаковий білани, клопи, попелиці; 
	•	Вогнівки, клопи, лучний метелик, соняшникова шипоноска, попелиці; 
	•	Довгоносики. 
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Рекомендації щодо застосовування:
Препарат вносять лише наземним обприскуванням. 
<span className="paragraph"></span>
ЗАСТЕРЕЖЕННЯ: 
Високотоксичний для бджіл.  </>),
  },
  {
    productTitle: "Спікер",
    path: "/spiker",
    src: Insecticides16,
    volume: "5 л",
    price: 38.38,
    currency: "USD",
    text: (<>
    Опис: Спікер - високоефективний двокомпонентний контактно-кишковий інсектицид для боротьби зі шкідниками сільськогосподарських культур. 
    <span className="paragraph"></span>
Діюча речовина:  Діметоат, 300 г/л; бета-циперметрин, 40 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Посилена токсична дія на шкідників завдяки синергізму двох діючих речовин.
	•	За рахунок наявності фосфорорганічного та піретроїдного компонентів відсутній ризик виникнення резистентності у комах.
	•	Висока початкова токсичність.
	•	Висока активність проти гризучих та сисних шкідників.
	•	Тривала захисна дія — до 16 діб.
	•	Сумісний з більшістю інсектицидів та фунгіцидів, за винятком сильнолужних препаратів.
  <span className="paragraph"></span>
Механізм дії: 
Інсектицид нейротоксичної дії, в складі якого присутні фосфорорганічний та піретроїдний компоненти.
Токсична дія діметоату зумовлена інгібуванням ацетилхолін естерази в холінергічних синапсах нервової системи комах.
У свою чергу, бета-циперметрин порушує процес обміну іонів натрію і калію в персинаптичній мембрані, що призводить до надмірного виділення ацетилхоліну при проходженні нервових імпульсів через синаптичний ланцюг нервової системи комах.
За рахунок порушення діяльності нервової системи відбувається загибель комах. Завдяки синергізму двох компонентів посилюється токсична дія препарату і знижується ризик виникнення резистентності.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця; 
	•	Ячмінь.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Трипси, клоп шкідлива черепашка, п'явиці, злакові попелиці, злакові цикадки;
	•	П’явиці, злакові попелиці, трипси.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Максимальна кількість обробок — 1 за сезон. Вихід людей для виконання механізованих робіт дозволяється через 4 доби після обробки препаратом, для виконання ручних робіт — через 10 діб. </>),
  },
  {
    productTitle: "Резидент",
    path: "/resident",
    src: Insecticides17,
    volume: "5 л",
    price: 127.82,
    currency: "USD",
    text: (<>
    Опис: Резидент - селективний двокомпонентний акарицид контактної дії для боротьби з рослиноїдними кліщам на сільськогосподарських культурах.
    <span className="paragraph"></span>
Діюча речовина: Фенпіроксимат, 120 г/л; піридабен, 300 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Контролює яйця і всі рухомі стадії розвитку кліща, особливо личинки і німфи; 
	•	Активна дія — 2-3 тижні; залишкова дія, що дозволяє контролювати личинки кліщів впродовж тривалого часу після застосування; 
	•	Побічна дія попелиць. Білокрилок, цикадок і трипсів; 
	•	Відсутність негативного впливу на ентомофагів, в т.ч. хижих кліщів і корисних комах, в т.ч. бджіл.
  <span className="paragraph"></span>
Механізм дії: 
Піридабен – діюча речовина контактної дії, після обробки уражує центральну нервову систему шкідника за рахунок інгібування транспорту електронів в мітохондріях. Діє на всі стадії розвитку кліща (яйце – личинка – німфа – дорослі кліщі). Особливо ефективний проти личинок та німф. Також властива побічна дія проти білокрилок, попелиць, трипсів.
  Фенпіроксимат – діюча речовина контактної дії потрапляючи на шкідника спричиняє негайний параліч усіх рухомих стадій кліща за рахунок інгібування коферменнта НАД-Н порушується транспорт електронів в мітохондріях. Фенпіроксимат також є інгібітором линьки на ранніх стадіях розвитку личинок кліщів.
<span className="paragraph"></span>
Тривалість захисної дії препарату до 30-40 днів.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га, для садів та виноградників 800-1000 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Соя; 
	•	Соняшник; 
	•	Виноградники; 
	•	Яблуня.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Павутинній кліщ;
	•	Павутинний і садовий кліщі;
	•	Червоний плодовий, глодовий, бурий плодовий, звичайний павутинній та інші кліщі.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Резидент - акарицид контактної дії при внесенні необхідне забезпечення рівномірності розподілу на поверхні рослин з усіх боків. Бажане додавання органо - силікованих ПАР в робочий розчин для підвищення якостей закріплення його на поверхні рослин (особливо з нижньої сторони листків та важкодоступних для обробки місцях). </>),
  },
  {
    productTitle: "Торсіда",
    path: "/torsida",
    src: Insecticides18,
    volume: "5 л",
    price: 143.85,
    currency: "USD",
    text: (<>
    Опис: Торсіда - високоефективний інсектицид контактно-кишкової дії для боротьби з широким спектром сисних і листогризучих шкідників. 
    <span className="paragraph"></span>
 Діюча речовина: Лямбда-цигалотрин, 300 г/л.
 <span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Високий рівень початкової токсичності для шкідників (стоп-ефект); 
	•	Низька норма витрати на гектар; 
	•	Контролює широкий спектр шкідників; 
	•	Сумісний з більшістю пестицидів. 
  <span className="paragraph"></span>
Механізм дії: 
Лямбда-цигалотрин має контактну, кишкову, репелентну дію, але не проявляє фумігантної та системної дії. Впливає на обмін кальцію в синапсах та порушує роботу натрій-калієвих каналів, що призводить до порушення функцій нервової системи, загального паралічу та швидкої загибелі шкідливих комах. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима та яра, ячмінь озимий та ярий; 
	•	Ріпак.
  <span className="paragraph"></span>
Об’єкт проти якого обробляється: 
	•	Хлібні жуки, блішки, трипси, п’явиці, клоп шкідлива черепашка, попелиці, озима совка, цикадки; 
	•	Хлібний турун;
	•	Хрестоцвіті блішки, ріпаковий квіткоїд, білани, клопи, попелиці.
  <span className="paragraph"></span>
Спосіб та час обприскування: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять лише наземним обприскуванням. Норма витрати робочого розчину при наземному застосуванні штанговими обприскувачами 200 – 400 л/га.
Строки виходу людей на оброблені площі для проведення механізованих робіт - 4 дні, термін для відновлення ручних робіт встановлювати не потрібно.
<span className="paragraph"></span>
ЗАСТЕРЕЖЕННЯ:
Високотоксичний для бджіл. </>),
  },
  {
    productTitle: "Фатрін",
    path: "/fatrin",
    src: Insecticides19,
    volume: "5 л",
    price: 32,
    currency: "USD",
    text: (<>
    Опис: Фатрін - високоефективний контактно-шлунковий інсектицид для захисту зернових колосових від широкого спектру шкідників.
    <span className="paragraph"></span>
Діюча речовина: Альфа-циперметрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Тривала дія та стійкість до змивання дощем забезпечують надійний захист сільськогосподарських культур.
	•	Мінімальні норми витрати роблять препарат економічно вигідним, а також зменшують хімічне навантаження на 1 га посівної площі.
	•	Сумісність препарату ФАТРІН® з іншими пестицидами та рідкими добривами дозволяє застосовувати препарат в комплесних системах захисту сільськогосподарських культур, але в кожному окремому випадку слід перевіряти партнерські компоненти на сумісність.
  <span className="paragraph"></span>
Механізм дії:
Діюча речовина має контактно-шлункову дію з високим рівнем початкової токсичності, має репелентні та антифідантні властивості. Діє на шкідників, які входять в безпосередній контакт з препаратом, а також при їх живленні росли­нами, що були оброблені інсектицидом. Порушує процеси обміну іонів натрію та калію в присинаптичній мембрані, що призводить до надмірного виділення ацетилхоліну при проходженні нервових імпульсів через синаптичний ланцюжок. У результаті паралічу нервової системи шкідники гинуть. Діюча речовина не мігрує по профілю ґрунту. Період захисної дії препарату становить до 15 - 20 діб.
<span className="paragraph"></span> 
Норма робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура:  
	•	Пшениця озима; 
	•	Ячмінь ярий.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Клоп шкідлива черепашка, п'явиці, злакові мухи, попелиці, трипси;
	•	Попелиці, п'явиці, блішки.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат вносять наземним обприскуванням, обов'язковим є повне та рівномірне покриття рослин, що обробляються робочим роз­чином препарату. Застосування препарату вранці або ввечері сприяє підвищенню ефективності його дії. Максимальна ефективність проведення обробок спостерігається при досягненні чисельності шкідників економічного порогу шкодочинності. У випадку коли культурні рослини знаходяться у стресовому стані (прохолодні погодні умови, приморозки, посуха та підвищенні температури) системна дія інсектициду на шкідників істотно зменшується. </>),
  },
  {
    productTitle: "Шаман",
    path: "/shaman",
    src: Insecticides20,
    volume: "5 л",
    price: 44.50,
    currency: "USD",
    text: (<>
    Опис: Шаман - високоефективний двокомпонентний інсектоакарицид контактно-шлункової дії проти широкого спектру шкідників сільськогосподарських культур.
    <span className="paragraph"></span>
Діюча речовина: Хлорпірифос, 500 г/л; циперметрин, 50 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Ефективне знищення широкого спектру шкідників сільськогосподарських культур за рахунок комбінованої дії двох речовин.
	•	Тривалий період активності препарату забезпечує надійний захист сільськогосподарських культур протягом 14 діб.
	•	Швидке поглинання рослинами (упродовж 1–1,5 годин).
	•	Сумісний з більшістю пестицидів крім препаратів, що мають лужну реакцію середовища та містять в своєму складі мідь.
  <span className="paragraph"></span>
Механізм дії: 
Хлорпірифос — інгібує активність ацетилхолінестерази та холінестерази у комах, що викликає параліч нервової системи, а згодом і загибель шкідника.
Циперметрин — діє на нервову систему, викликаючи її надмірне збудження і, як наслідок, загибель шкідника. Може блокувати відкладку яєць у імаго (овіцидний ефект) та процес живлення личинок.
Препарат діє контактно — на шкідників, що входять в безпосередній контакт з інсектицидом при його внесенні, а також має шлункову дію в разі поїдання шкідниками рослин, що були оброблені препаратом. Загибель комах настає впродовж 48 годин після застосування препарату, за рахунок тривалого зберігання на поверхні оброблених рослин період захисної дії препарату може становити до 15 – 20 діб.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для польових культур і 800-1000 л/га для садів.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима;
	•	Буряки цукрові; 
	•	Яблуня;
	•	Ріпак;
	•	Соя.
  <span className="paragraph"></span>
Шкідливий об’єкт: 
	•	Злакові мухи, хлібний турун;
	•	Клоп шкідлива черепашка, п'явиці, попелиці;
	•	Звичайний та сірий буряковий довгоносики, щитоноски, бурякові блішки;
	•	Плодожерки, листовійки, молі, кліщі, попелиці;
	•	Білани;
	•	Ріпаковий квіткоїд, хрестоцвіті клопи, хрестоцвіті блішки, листогризучі совки;
	•	Паросткові мухи, довгоно­сики, клопи, листовійки, гусінь метеликів, трипси, акацієві вогнівки, люцернові совки, павутинний кліщ.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування в період вегетації; 
	•	В період масового нальоту.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Максимальна ефективність препарату спостерігається при застосуванні його під час активної вегетації рослин. У випадку, коли культурні рослини знаходяться у стресовому стані (про­холодні погодні умови, заморозки, посуха та підвищені температури) системна дія інсектициду на шкідників істотно зменшується. Препарат вносять лише наземним обприскуванням. Застосування препарату рано вранці або в ввечері сприяє підвищенню ефективності його дії. Максимальна ефективність проведення обробок спостерігається при досягненні чисельності шкідників економічного порогу шкодочинності. 
</>),
  },

  // третий файл

  {
    productTitle: "Віарес",
    path: "/viares",
    src: Insecticides21,
    volume: "1 л",
    price: 14,
    currency: "USD",
    text: (<>
    Опис: Віарес - системний інсектицид проти широкого спектра шкідників на ріпаку і яблуні.
    <span className="paragraph"></span>
Діюча речовина: Ацетаміприд, 300 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Інноваційна рідка формуляція з покращеними властивостями покриття, утримання і проникнення.
	•	Малотоксичний для бджіл та інших корисних комах за умови дотримання регламентів застосування.
	•	Висока біологічна ефективність за підвищених температур.
	•	Швидка дія, результат помітний вже за годину після обробки.
  <span className="paragraph"></span>
Властивості:
Ацетаміприд належить до класу інсектицидів-неонікотиноїдів та має системну та контактно-кишкову дії. Препарат ефективний проти комах-шкідників, що належать до рядів: напівтвердокрилі, трипси, твердокрилі та рівнокрилі. Тривалість захисної дії в оптимальних нормах витрати становить 14-21 день. 
<span className="paragraph"></span>
Механізм дії: 
Препарат характеризується високою системною та трансламінарною діями, швидко поглинається рослиною та поширюється по всіх її частинах. Тому ефект від застосування препарату виявляється також і на необроблених частинах рослин. Шкідники гинуть внаслідок безпосереднього контакту з препаратом, а також у результаті поїдання оброблених рослин. Інсектицидна дія препарату проявляється шляхом його впливу на нервову систему комах, що призводить до їх загибелі від надмірного нервового збудження і паралічу. Залежно від виду комах препарат виявляє токсичну дію на різні стадії розвитку шкідників: імаго, личинки та яйця. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури: 200-400 л/га. 
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Ріпак.
  <span className="paragraph"></span>
Шкідники: 
	•	Ріпаковий квіткоїд, ріпаковий клоп, попелиці, хрестоцвітні блішки, прихованохоботники, ріпаковий довгоносик, капустяний стручковий комарик, пильщик.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обприскування в період вегетації (до, під час і після цвітіння).
  <span className="paragraph"></span>
Загальні рекомендації.
Препарат сумісний з багатьма пестицидами, за винятком сильнолужних. Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. Не рекомендується проводити обприскування при швидкості вітру більше 3-4 м/с та за температури повітря вище +30˚С. Додавання до бакової суміші поверхнево-активних речовин Альфалип (0,2 л/га) та Альфалип Екстра (0,1-0,2 л/га) не є обов’язковим, але покращує змочуваність рослин робочим розчином.</>),
  },
  {
    productTitle: "Залп",
    path: "/zalp",
    src: Insecticides22,
    volume: "5 л",
    price: 37.82,
    currency: "USD",
    text: (<>Опис: Залп - високоефективний двокомпонентний інсектицид широкого спектра дії.
    <span className="paragraph"></span>
      Діюча речовина:  Хлорпірифос, 500 г/л + циперметрин, 50 г/л.
      <span className="paragraph"></span>
      Препаративна форма: Концентрат, що емульгується.
      <span className="paragraph"></span>
      Переваги: 
        •	Універсальний інсектицид з широким спектром ефективності на багатьох культурах.
        •	Потрійна дія на шкідників: контактна, шлункова і фумігантна.
        •	Незамінний при ранніх обробках за знижених температур.
        •	Додаткова акарицидна дія.
        <span className="paragraph"></span>
      Властивості: 
      Залп має широкий спектр інсектицидної дії, знищує комплекс сисних і гризучих комах, обмежує розвиток рослиноїдних кліщів. Сумісний з іншими інсектицидами, акарицидами, фунгіцидами, водні розчини яких мають нейтральну реакцію. Не фітотоксичний для оброблюваних культур при дотриманні регламентів застосування. Токсичний для бджіл. Забороняється обприскування у фазу цвітіння. Застосування до цвітіння не обмежується, оскільки препарат не проникає в нектар та пилок квітів. Інсектицид швидко проникає в кутикулу листків та плодів, тому через 2 години після його внесення опади чи сильна роса не знижують ефективність препарату. 
      <span className="paragraph"></span>
      Механізм дії: 
      Інсектицид має контактно-шлункову, трансламінарну, фумігантну, репелентну дії. Відрізняється швидкою початковою дією і має широкий діапазон активності при винятковій тривалості. Препарат проявляє початковий інсектицидний ефект при контакті, попаданні всередину й у вигляді парів. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: польові культури: 200-400 л/га.
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Землі несільсько- господарського призначення;
        •	Пшениця озима.
        <span className="paragraph"></span>
      Шкідник: 
        •	Саранові та інші види шкідливих комах; 
        •	Клоп шкідлива черепашка, попелиці, п’явиці, трипси, хлібна жужелиця, хлібні жуки.
        <span className="paragraph"></span>
      Строк обробки:
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Загальні рекомендації:
      Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. </>),
  },
  {
    productTitle: "Логус",
    path: "/logus",
    src: Insecticides23,
    volume: "1 л",
    price: 36.86,
    currency: "USD",
    text: (<>Опис: Логус - двокомпонентний інсектицид нового покоління для контролю лускокрилих та інших шкідників.
    <span className="paragraph"></span>
      Діюча речовина:  Емамектин бензоат, 100 г/л + альфа-циперметрин, 100 г/л.
      <span className="paragraph"></span>
      Препаративна форма:  Концентрат суспензії.
      <span className="paragraph"></span>
      Переваги: 
        •	Комбінація 2-х діючих речовин з різним механізмом дії забезпечує надійний контроль практично всіх шкідників, особливо із ряду лускокрилих.
        •	Контролює шкідників на всіх стадіях розвитку: яйце, гусінь (личинка), імаго.
        •	Ефективна дія як при високих температурах (+35ºС) так і при великій кількості опадів.
        •	Швидка дія – результат помітний вже за годину після обробки.
        <span className="paragraph"></span>
      Властивості: 
      Препарат характеризується доброю контактною та трансламінарною діями. За рахунок унікального поєднання діючих речовин з різними властивостями (розчинність, ліпофільність, стійкість до високих температур тощо) забезпечує прояв швидкого нокдаун-ефекту та пролонгованого захисту культури. Поєднання двох діючих речовин з принципово відмінними механізмами дії забезпечує високу ефективність проти шкідників, в т.ч. резистентних до традиційних інсектицидів, та запобігає появі стійкості до препарату. Володіє високою активністю до абсолютної більшості комах-фітофагів із різних систематичних груп, включаючи прихованоживучих шкідників та володіє виключною дією проти комах із ряду лускокрилих. Шкідники гинуть внаслідок безпосереднього контакту з препаратом, а також поїдання оброблених рослин. Залежно від виду комах препарат виявляє токсичну дію на різні стадії розвитку шкідників: яйця, личинки та імаго. Тривалість захисної дії залежить від часу застосування, погодних умов та віку комах та складає до 15 днів.
      <span className="paragraph"></span>
      Спектр дії: 
      Бавовникова совка, капустяна совка, лучний метелик, попелиця, вогнівки (види), соняшникова шипоноска, капустяна міль, хрестоцвіті блішки, попелиці, квіткоїди, попелиці, листовійки (види), трипси, капустяний та ріпаковий білани, плодожерка (види), чортополохівка, клопи (види). 
      <span className="paragraph"></span>
      Механізм дії: 
      Емамектин бензоат – діюча речовина належить до хімічного класу авермектинів, яка характеризується високою трансламінарною дією та належить до діючих речовин природнього походження. Діє на нервову систему комах, внаслідок чого комахи протягом декількох годин перестають рухатись та гинуть. Альфа-циперметрин належить до хімічного класу піретроїдів та відрізняється чітко вираженою швидкою контактно-шлунковою дією. Впливає на нервову систему комах-шкідників, порушуючи проникність клітинних мембран та блокує натрієві канали. Викликає параліч центральної та периферійної нервових систем. 
      <span className="paragraph"></span>
      Норма витрати робочого розчину: 100-300 л\га (залежить від обладнання, фази розвитку культури, густоти стояння рослин і погодних умов). 
      <span className="paragraph"></span>
      Сфера застосування.
      <span className="paragraph"></span>
      Культура: 
        •	Соняшник; 
        •	Ріпак.
        <span className="paragraph"></span>
      Шкідник: 
        •	Бавовникова совка, лучний метелик, попелиця, вогнівки (види), соняшникова шипоноска; 
        •	Капустяна міль, хрестоцвіті блішки, совки, попелиці, квіткоїди.
        <span className="paragraph"></span>
      Строк обробки: 
        •	Обприскування в період вегетації.
        <span className="paragraph"></span>
      Загальні рекомендації:
      Препарат сумісний з багатьма пестицидами, за винятком сильнолужних. Не застосовувати в бакових сумішах з фунгіцидами на основі фосетил алюмінію та рідкими мінеральними добривами. Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. Не рекомендується проводити обприскування при швидкості вітру більше 3-4 м/с наявності туману, дощу та під час високої сонячної активності. Додаткове додавання до бакової суміші поверхнево-активних речовин Альфалип (0,2 л/га), Альфалип Екстра (0,1-0,2 л/га) та Бустер (0,1 л/га) не є обов’язковим, але покращує змочуваність рослин робочим розчином. </>),
  },
  {
    productTitle: "Наповал",
    path: "/napoval",
    src: Insecticides24,
    volume: "5 л",
    price: 67.91,
    currency: "USD",
    text: (<>
    Опис: Наповал - двокомпонентний інсектицид контактносистемної дії для захисту від комплексу шкідників.
    <span className="paragraph"></span>
Діюча речовина: Імідаклоприд, 300 г/л + альфа-циперметрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Відсутність резистентності у шкідників завдяки поєднанню двох діючих речовин з принципово різними механізмами дії.
	•	Поєднання швидкого «нокаут-ефекту» з пролонгованим захистом.
	•	Високоактивний проти абсолютної більшості комах-шкідників.
	•	Надійно захищає новий приріст завдяки вираженій системній дії.
  <span className="paragraph"></span>
Властивості: 
Поєднання двох діючих речовин з принципово різними механізмами дії забезпечує високу ефективність проти популяцій шкідників, резистентних до традиційних інсектицидів та запобігає появі стійкості до препарату. Наповал володіє контактними і системними властивостями, діє як при гострому контактному, шлунковому отруєнні, так і в газовій фазі. В рослині імідаклоприд перетворюється в хлорнікотинілову кислоту (природний антидепресант), що забезпечує додаткову витривалість до несприятливих погодних умов. 
<span className="paragraph"></span>
Механізм дії: 
Альфа-циперметрин впливає на нервову систему шкідників, порушуючи проникненість клітинних мембран та блокуючи натрієві канали. Викликає параліч центральної та периферійної нервових систем. Імідаклоприд впливає на ацетилхоліновий рецептор постсинаптичної мембрани, але як конкурент ацетилхоліну. Викликає порушення нормальної провідності нервового імпульсу через синапс. У підсумку в комах-фітофагів розвивається враження нервової системи, що призводить до їх загибелі. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Картопля;
	•	Пшениця озима.
  <span className="paragraph"></span>
Шкідник: 
	•	Колорадський жук; 
	•	Клоп шкідлива черепашка, трипси, п’явиці, попелиці, блішки, цикадки.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обприскування в період вегетації. 
  <span className="paragraph"></span>
Загальні рекомендації: 
Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. Інсектицид токсичний для бджіл. Не можна проводити обприскування в період цвітіння. При застосуванні на культурах з щільним восковим нальотом необхідно додавати до робочого розчину поверхнево-активну речовину Альфалип Екстра (0,2-0,25 л/га). </>),
  },
  {
    productTitle: "Нокаут Екстра",
    path: "/nokaut-ekstra",
    src: Insecticides25,
    volume: "5 л",
    price: 41.20,
    currency: "USD",
    text: (<>
    Опис: Нокаут Екстра - високоефективний інсектицид контактношлункової дії із групи синтетичних піретроїдів в новій препаративній формі.
    <span className="paragraph"></span>
Діюча речовина: Альфа-циперметрин, 200 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат cуспензії.
<span className="paragraph"></span>
Переваги: 
	•	Вдосконалена препаративна форма, яка дозволяє застосовувати препарат за температури повітря до 24˚С включно.
	•	Захист широкого спектра культур від найважливіших шкідників.
	•	Володіє антифідантними і репелентними властивостями.
	•	Виражений «нокаут-ефект».
  <span className="paragraph"></span>
Властивості: 
Нокаут Екстра має широкий спектр інсектицидної дії, знищує комплекс сисних і гризучих відкритоживучих комах із різних систематичних груп. Сумісний з багатьма інсектицидами, фунгіцидами, мікро- та макродобривами, що не мають лужної реакції. При контакті токсичний для бджіл та інших корисних комах, стійкий до змивання опадами. 
<span className="paragraph"></span>
Механізм дії: 
Діюча речовина відносится до хімічного класу піретроїдів та відрізняється чітко вираженою швидкою контактно-шлунковою дією. Впливає на нервову систему комах-шкідників, порушуючи проникність клітинних мембран та блокує натрієві канали. Викликає параліч центральної та периферійної нервових систем. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-400 л/га.
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Соя; 
	•	Ріпак.
  <span className="paragraph"></span>
Шкідник: 
	•	Клоп шкідлива черепашка, блішки, попелиці, цикадки, п’явиці, трипси;
	•	Акацієва вогнівка, клопи, трипси, личинки чортополохівки;
	•	Ріпаковий квіткоїд, хрестоцвітні блішки.
  <span className="paragraph"></span>
Загальні рекомендації: 
Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості. Обов’язковою вимогою є забезпечення суцільного покриття площі та рясного змочування рослин під час проведення обприскування. Не рекомендується проводити обприскування при швидкості вітру більше 3-4 м/с та за температури повітря вище +30˚С. Додаткове додавання до бакової суміші поверхнево-активної речовини Альфалип Екстра (0,2-0,25 л/га) не є обов’язковим, але покращує змочуваність рослин робочим розчином. Для запобігання виникненню резистентності рекомендується чергувати застосування препарату із інсектицидами інших хімічних класів, наприклад фосфорорганічних. Нокаут Екстра при дотриманні рекомендованих доз застосування є безпечним для ссавців і птахів. </>),
  },
  {
    productTitle: "Разит",
    path: "/razit",
    src: Insecticides26,
    volume: "5 л",
    price: 77.57,
    currency: "USD",
    text: (<>
    Опис: Разит - інноваційний трикомпонентний інсектицид контактно-системної дії для швидкого і пролонгованого захисту від комплексу шкідників.
    <span className="paragraph"></span>
Діюча речовина: Ацетаміприд, 160 г/л + імідаклоприд, 140 г/л + альфа-циперметрин, 100 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат cуспензії.
<span className="paragraph"></span>
Переваги: 
	•	Синергія трьох діючих речовин із різними властивостями забезпечує високу активність проти максимального спектра шкідників.
	•	Відсутність резистентності за рахунок різних механізмів дії трьох компонентів.
	•	Швидка дія та тривалий період захисту.
	•	Ефективний на різних стадіях розвитку фітофагів.
  <span className="paragraph"></span>
Властивості: 
За рахунок унікального поєднання діючих речовин з різними властивостями (розчинність, ліпофільність тощо) спостерігається посилення розповсюдження та підтримання високої ефективної концентрації препарату Разит в рослині, що забезпечує прояв швидкого «нокаутефекту» та пролонгованого захисту культури. Поєднання трьох діючих речовин з принципово відмінними механізмами дії забезпечує високу ефективність проти шкідників, в т.ч. резистентних до традиційних інсектицидів, та запобігає появі стійкості до препарату. Інсектицид Разит володіє високою активністю до абсолютної більшості комах-фітофагів із різних систематичних груп, включаючи прихованоживучих шкідників. Препарат володіє контактними і системними властивостями: діє при гострому контактному, шлунковому отруєнні, в газовій фазі та проявляє репеленту дію. В тканинах імідаклоприд та ацетаміприд перетворюються в хлорнікотинілову кислоту (природний антидепресант), що посилює додаткову стійкість рослини до стресових ситуацій.
<span className="paragraph"></span> 
Механізм дії: 
Альфа-циперметрин із класу синтетичних піретроїдів впливає на нервову систему шкідників, порушуючи проникненість клітинних мембран та блокуючи натрієві канали. Викликає параліч центральної та периферійної нервових систем. Імідаклоприд та ацетаміприд, що належать до класу неонікотиноїдів, впливають на ацетилхоліновий рецептор постсинаптичної мембрани, але як конкурент ацетилхоліну. Вони викликають порушення нормальної провідності нервового імпульсу через синапс. Синергізм комбінації полягає в надмірному збудженні нервової системи комах-фітофагів, що проявляється в подальшій їх загибелі. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польова культури: 200-400 л/га, сади: 600-1000л/га.
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима;
	•	Картопля в т.ч. присадибні ділянки; 
	•	Яблуня.
  <span className="paragraph"></span>
Шкідники: 
	•	Клоп шкідлива черепашка, блішки, попелиці, цикадки, п’явиці, трипси;
	•	Колорадський жук;
	•	Яблуневі попелиця, квіткоїд, пильщик, мінуючі моли, листокрутки.
  <span className="paragraph"></span>
Строк обробки: 
	•	Обприскування в період вегетації;
	•	Обприскування до та після цвітіння.
  <span className="paragraph"></span>
Загальні рекомендації: 
Разит сумісний з більшістю інсектицидів, фунгіцидів, мікро- та макродобрив, що не мають лужної реакції. Не фітотоксичний для оброблюваних культур при дотриманні регламентів застосування. Обробку доцільно починати за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню рослини, застосовуючи добре відрегульоване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. Інсектицид токсичний для бджіл. Не можна проводити обприскування в період цвітіння. Не рекомендується проводити обприскування при швидкості вітру більше 3-4 м/с та за температури повітря вище +28˚С. Додаткове додавання до бакової суміші поверхнево-активної речовини Альфалип Екстра (0,2-0,25 л/га) не є обов’язковим, але покращує змочуваність рослин робочим розчином. </>),
  },
  {
    productTitle: "Флайп",
    path: "/flaip",
    src: Insecticides27,
    volume: "5 л",
    price: 87.32,
    currency: "USD",
    text: (<>
    Опис: Флайп - нсектицид системної дії для контролю широкого спектра шкідників.
    <span className="paragraph"></span>
Діюча речовина: Тіаклоприд, 245 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Ефективний проти багатьох шкідників.
	•	Швидка дія та тривалий захисний ефект.
	•	Ефективний за підвищених температур.
	•	Не токсичний для бджіл і навколишнього середовища (за дотримання регламентів застосування).
  <span className="paragraph"></span>
Властивості: 
Інсектицид ефективно працює як під час безпосереднього контакту з шкідниками — контактна дія, так і внаслідок поїдання ними оброблених рослин (включаючи висмоктування рослинного соку сисними шкідниками) — кишкова та системна дії. 
<span className="paragraph"></span>
Механізм дії: 
Тіаклоприд належить до хімічного класу неонікотиноїдів. Як і інші неонікотиноїди, він контактує з постсинаптичними нікотиновими ацетилхоліновими рецепторами центральної нервової системи комах, внаслідок чого у них розвиваються паралічі та конвульсії, що призводять до їхньої загибелі. Тіаклоприд акропетально пересувається по рослині та має трансламінарну дію. Після контакту зі препаратом комахи-шкідники припиняють живитися та через короткий проміжок часу гинуть. 
<span className="paragraph"></span>
Норма витрати робочого розчину: польові культури: 200-400 л/га, авіаційна обробка: 50-70 л/га.
<span className="paragraph"></span>
Сфера застосування.
<span className="paragraph"></span>
Культура: 
	•	Ріпак.
  <span className="paragraph"></span>
Шкідники: 
	•	Хрестоцвіті блішки, клопи.
  <span className="paragraph"></span>
Строк обробки:
	•	Обробка в період вегетації, включаючи цвітіння (в т.ч. авіаційна обробка).
  <span className="paragraph"></span>
Загальні рекомендації:
Обробку слід проводити за чисельності шкідників, що перевищує економічний поріг шкідливості (залежно від виду фітофага). Препарат необхідно наносити рівномірно на листову поверхню, використовуючи відкаліброване обладнання. Об’єм робочого розчину має бути достатнім для повного покриття всієї листової поверхні культури. 
</>),
  },

  // четвертий файл

  {
    productTitle: "Ампліго 150 ZC",
    path: "/ampligo-150-zc",
    src: Insecticides28,
    volume: "5 л",
    price: 461.25,
    currency: "USD",
    text: (<>
    Опис: Ампліго — блискавична дія проти шкідників.
    <span className="paragraph"></span>
Діюча речовина: 100 г/л хлорантраніліпролу, 50 г/л лямбда-цигалотрину.
<span className="paragraph"></span>
Препаративна форма: Змішана препаративна форма КС і СК.
<span className="paragraph"></span>
Переваги: 
	•	Висока ефективність проти шкідників у ширшому діапазоні температур, ніж в інших інсектицидів
	•	Інноваційна формуляція забезпечує стійкість до УФ-випромінювання та запобігає змиванню дощем уже навіть через годину після обробки
	•	Овіцидна дія
	•	Висока ефективність на гусениць незалежно від їхнього віку протягом усього періоду захисної дії
	•	Висока початкова токсичність, так званий нокдаун-ефект проти гусениць лускокрилих
	•	Різний механізм дії запобігає виникненню резистентності.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Кукурудза; 
	•	Сорго; 
	•	Яблуня; 
	•	Соняшник; 
	•	Томати відкритого ґрунту; 
	•	Капуста; 
	•	Картопля; 
	•	Соя;
	•	Ріпак озимий.
  <span className="paragraph"></span>
Спектр дії: 
	•	Бавовникова совка, стебловий кукурудзяний метелик; 
	•	Трипси, попелиці; 
	•	Плодожерки, листовійки, квіткоїд, букарка, казарка, пильщик; 
	•	Лучний метелик, цикадки; 
	•	Хрестоцвіті блішки, капустяна попелиця, білани, капустяна міль, капустяна совка; 
	•	Колорадський жук; 
	•	Люцернова совка, акацієва вогнівка, чортополохівка; 
	•	Капустяна міль, листогризучі совки, озима совка, білани.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 200–400 л/га — наземним обприскувачем, для польових і овочевих культур. 50–100 л/га — авіаційним методом. 800–1200 л/га для яблуні залежно від об'єму крони дерева. 
<span className="paragraph"></span>
Сумісність: 
Препарат сумісний з більшістю засобів захисту рослин, але в кожному окремому випадку препарати, які змішуються, слід перевіряти на сумісність. 
<span className="paragraph"></span>
Особливості застосування: 
Для досягнення максимальної ефективності проти лускокрилих шкідників обприскування необхідно проводити в період яйцекладки — початку відродження личинок (при появі перших гусениць). З метою досягнення максимальної ефективності Ампліго на ріпаку до робочого розчину рекомендовано додавати ад’юванти органосиліконової групи.</>),
  },
  {
    productTitle: "Енжіо",
    path: "/enjio",
    src: Insecticides29,
    volume: "5 л",
    price: 207.59,
    currency: "USD",
    text: (<>
    Опис: Енжіо — подвійна впевненість у результаті.
    <span className="paragraph"></span>
Діюча речовина: 141 г/л тіаметоксаму, 106 г/л лямбда-цигалотрину.
<span className="paragraph"></span>
Препаративна форма: Концентрат суспензії.
<span className="paragraph"></span>
Переваги: 
	•	Поєднання потужного нокдаун-ефекту з тривалим періодом захисту внаслідок вираженої системної дії та якісної токсикації рослин;
	•	Ефективний проти широкого спетру шкідників, у тому числі хлібного туруна та інших прихованоживучих, незалежно від погодних умов;
	•	Зручний і безпечний у бакових сумішах;
	•	Дві діючих речовини з різними механізмами дії унеможливлюють виникнення резистентності.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Ячмінь озимий;
	•	Ячмінь ярий; 
	•	Буряки цукрові; 
	•	Виноградники.
  <span className="paragraph"></span>
Спектр дії: 
	•	Клоп шкідлива черепашка, попелиці, трипси, цикадки, хлібний турун; 
	•	Попелиці, хлібні клопи, п’явиці, блішки, трипси, блішки, ячмінь мінер; 
	•	Злакові мухи, цикадки, п’явиці, попелиці, трипси, блішки, ячмінь мінер; 
	•	Звичайний і сірий бурякові довгоносики, щитоноски, блішки, листова бурякова попелиця; 
	•	Листкова філоксера, багатоїдний трубкокрут, скосар кримський.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 100–200 л/га для польових культур;
200–400 л/га для овочевих культур відкритого ґрунту; 400–800 л/га для культур захищеного ґрунту; 800–1200 л/га для плодово–ягідних культур залежно від об’єму крони дерева; 600–800 л/га для виноградників.
<span className="paragraph"></span>
Сумісність: 
Енжіо® можна змішувати з загальновживаними гербіцидами, фунгіцидами, регуляторами росту рослин на відповідних культурах. При використанні бакових сумішей засобів захисту рослин, слід дотримуватись рекомендацій компанії-виробника щодо сумісності кожного препарату-компонента бакової суміші.
<span className="paragraph"></span> 
Особливості застосування: 
Не застосовувати в екстремальних погодних умовах, коли комахи перебувають у стресовому стані. Енжіо® дуже ефективний за низьких температур, на відміну від інших інсектицидів. Оптимальна температура застосування Енжіо® — від +8 °С до +25 °С. Для досягнення високої ефективності проти видів попелиць на польових культурах потрібно забезпечити завчасну токсикацію рослин до початку заселення. З огляду на це Енжіо® слід застосовувати профілактично, за 5–7 днів до моменту появи попелиць на рослинах (для визначення ймовірності й часу заселення посівів зернових злакових культур у конкретних регіонах рекомендовано користуватися сервісом прогнозу вірогідності появи шкідливих організмів від компанії «Сингента» agroprognoz.com.ua) або при появі перших крилатих самиць-переселенок попелиць. </>),
  },
  {
    productTitle: "Карате Зеон 050 CS",
    path: "/karate-zeon-050-cs",
    src: Insecticides30,
    volume: "5 л",
    price: 110.46,
    currency: "USD",
    text: (<>
    Опис: Карате Зеон — мікрокапсули з макроефектом.
    <span className="paragraph"></span>
Діюча речовина: 50 г/л лямбда-цигалотрину.
<span className="paragraph"></span>
Препаративна форма: Мікрокапсульована водна суспензія.
<span className="paragraph"></span>
Переваги: 
	•	Контролює широкий спектр шкідників, серед яких певні види кліщів;
	•	Має високу початкову ефективність — нокдаун-ефект;
	•	Унікальна технологія ЗЕОН-капсуляції забезпечує кращу стабільність за будь-яких погодних умов і тривалішу дію за рахунок фотостабільності діючої речовини.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима; 
	•	Пшениця яра; 
	•	Ячмінь озимий та ярий; 
	•	Ріпак; 
	•	Буряки цукрові; 
	•	Горох; 
	•	Кукурудза; 
	•	Сорго; 
	•	Рис; 
	•	Цибуля.
  <span className="paragraph"></span>
Спектр дії: 
	•	Хлібні жуки, блішки, трипси, п’явиці, клоп шкідлива черепашка, попелиці, озима совка; 
	•	Пшеничний трипс; 
	•	Хлібні клопи, злакові попелиці; 
	•	Хрестоцвіті блішки, ріпаковий квіткоїд, білани, клопи, попелиці;
	•	Щитоноски, блішки;
	•	Горохова попелиця, гороховий зерноїд;
	•	Стебловий кукурудзяний метелик, західний кукурудзяний жук; 
	•	Рисовий комарик, ячмінь мінер;
	•	Цибулева муха.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 200–400 л/га для польових і овочевих культур;
800–1000 л/га для виноградників; 600–1000 л/га для садів.
<span className="paragraph"></span>
Сумісність: 
Сумісний з більшістю засобів захисту рослин. При використанні бакових сумішей засобів захисту рослин слід дотримуватись рекомендацій компанії-виробника щодо сумісності кожного препарату-компонента бакової суміші. Партнер до фосфорорганічних сполук: використання Карате® Зеон в суміші з препаратами на основі фосфорорганічних сполук наприклад, з Актеллік® має певну синергічну дію і підвищує ефективність використання препаратів порівняно з окремим їх внесенням. Використання в саду. Карате® Зеон, на відміну від інших піретроїдних сполук, має акарицидну дію на певні види кліщів. 
<span className="paragraph"></span>
Мікрокапсуляція за зеон-тезнологією: 
Мікрокапсуляція за Зеон–технологією зумовлює більшість переваг препарату, адже за рахунок полімерних капсул Карате® Зеон стає безпечнішим для користувачів і фотостабільнішим, а його захисний термін подовжується до 7–8 днів. </>),
  },
  {
    productTitle: "Люфокс 105 ЕС",
    path: "/lufox-105-es",
    src: Insecticides31,
    volume: "5 л",
    price: 179.71,
    currency: "USD",
    text: (<>
    Опис: Люфокс — широкий крок до якості.
    <span className="paragraph"></span>
Діюча речовина: 75 г/л феноксикарбу, 30 г/л люфенурону.
<span className="paragraph"></span> 
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span>
Переваги: 
	•	Висока ефективність проти шкідників за рахунок поєднання двох діючих речовин
	•	Потужна овіцидна дія (на яйцекладку до 5–7 днів)
	•	Має ларвіцидну дію – порушує процеси линьки гусені
	•	Має стерилянтний ефект на імаго (при безпосередньому контакті)
	•	Не має фітотоксичної дії (не утворює «сітки» навіть на плодах високочутливих сортів)
	•	Безпечний для людей і корисної ентомофауни
	•	Неперевершена дія на першу генерацію плодожерок і листовійок.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Груша; 
	•	Яблуня; 
	•	Виноград.
  <span className="paragraph"></span>
Спектр дії:
	•	Яблунева плодожерка, грушева плодожерка, грушева медяниця, щитівки, кліщі; 
	•	Плодожерка, листовійка, кліщі, щитівки;
	•	Гронова листовійка, 1 генерація;
	•	Гронова листовійка, 2-3 генерації.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації, у фазі розпускання бруньок — рожевий бутон;
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 1000 л/га – сади. У разі зменшення використання рекомендованого об’єму води (залежно від об’єму крони) норму використання препарату зменшувати не рекомендується; 600–800 л/га для виноградників.
<span className="paragraph"></span>
Оптимальний термін застосування: 
Для досягнення найвищої ефективності Люфокс® рекомендується вносити під час піку льоту шкідників (яблунева і грушева плодожерки), тобто за 3–5 днів до початку масової яйцекладки.</>),
  },
  {
    productTitle: "Проклейм 5 SG",
    path: "/proklaim-5-sg",
    src: Insecticides32,
    volume: "1 кг",
    price: 100,
    currency: "USD",
    text: (<>
    Опис: Проклейм — надійний інсектицид за будь-яких умов.
    <span className="paragraph"></span>
Діюча речовина: 50 г/кг емамектину бонзоату.
<span className="paragraph"></span>
Препаративна форма: Водорозчинні гранули.
<span className="paragraph"></span>
Переваги: 
	•	Захист від приховано- і відкритоживучих лускокрилих шкідників;
	•	Починає діяти з фази яйця – має пряму овіцидну дію;
	•	Проникає в рослину протягом двох годин, утворюючи резервуари з діючою речовиною;
	•	Ефективність не залежить від високих температур, дощу та кислотності робочого розчину;
	•	Короткий термін очікування;
	•	Сумісний з біозахистом;
	•	Найефективніша дія на 2-гу і 3-тю генерації плодожерки та листовійки.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Яблуня; 
	•	Виноград; 
	•	Капуста; 
	•	Томати відкритого й закритого ґрунту; 
	•	Персик; 
	•	Черешня.
  <span className="paragraph"></span>
Спектр дії: 
	•	Плодожерки, листовійки, мінуючі молі; 
	•	Гронова листовійка; 
	•	Капустяна міль, капустяна совка, капустяний і ріпаковий білани; 
	•	Бавовникова совка, листогризучі совки, трипси; 
	•	Плодожерки, листовійка, мінуючі молі; 
	•	Вишнева муха, листовійка, молі.
  <span className="paragraph"></span>
Фаза внесення: 
	•	Обприскування в період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 250–300 л/га для овочевих культур відкритого ґрунту;
400–800 л/га для овочевих культур закритого ґрунту; 800–1200 л/га для садів залежно від об’єму крони дерева; 600–800 л/га для виноградників. 
<span className="paragraph"></span>
Особливості застосування: 
Трансламінарний інсектицид природного походження, проникаючи всередину рослинних тканин, утворює резервуари, які містять емамектин бензоат. Завдяки цьому ефективність препарату не залежить від високих температур і дощу та забезпечує захист рослин від пошкоджень до 15 діб. 
Найкращий період застосування проти плодожерки та листовійки — початок відродження гусениць.</>),
  },
  {
    productTitle: "Вертимек Нео 018 SL",
    path: "/vertimek-neo-018-sl",
    src: Insecticides33,
    volume: "1 л",
    price: 82.09,
    currency: "USD",
    text: (<>
    Опис: Вертимек Нео - трансламінарний інсекто-акарицид з інноваційною формуляцією, яка забезпечує високу фотостабільність та швидку проникність препарату в рослину з подовженим захистом від усіх видів кліщів для отримання якісної та безпечної продукції.
    <span className="paragraph"></span>
Діюча речовина: 18 г/л Абамектин.
<span className="paragraph"></span>
Препаративна форма: Розчинний концентрат.
<span className="paragraph"></span>
Переваги: 
	•	Інноваційна формуляція забезпечує стійкість до ультрафіолету і розширює часове використання препарату;
	•	Швидка трансламінарна проникність з утворенням «резервуарів» всередині листка;
	•	Вища ефективність при зниженій нормі витрати порівняно з іншими абамектинами;
	•	Подовжений термін захисту — не менше ніж 14 днів;
	•	Після висихання препарат не становить загрози для корисної ентомофауни та хижих кліщів;
	•	Відсутність фітотоксичності, не утворює сітку на плодах або інші види опіків; 
	•	Простий у використанні — не потрібні додаткові ад’юванти, відсутність запаху, термостабільний;
	•	Не погіршує смакові якості плодів;
	•	Дозволений до використання в ЄС.
  <span className="paragraph"></span>
Застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Яблуня;
	•	Соя.
  <span className="paragraph"></span>
Спектр дії: 
	•	Кліщі.
  <span className="paragraph"></span>
Фаза внесення: 
	•	У період вегетації.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га для сої; 600-1000 л/га для яблуні.
<span className="paragraph"></span>
Сумісність :
Препарат можна змішувати з іншими загальновживаними засобами захисту рослин, на відповідній культурі, проте в кожному конкретному випадку необхідно перевіряти препарати на сумісність. При застосуванні на яблуні не рекомендовано використовувати у бакових сумішах з фунгіцидами на основі сірки та з класу стробілуринів.
<span className="paragraph"></span>
Температура застосування :Препарат застосовують при температурі від +10 ⁰С. Оптимальні години для проведення захисних робіт з 6:00 до 9:00 або з 19:00 до 21:00 в безвітряну суху погоду за температури не вище ніж 25 ⁰С.
<span className="paragraph"></span> 
Особливості застосування:
	1.	Вертимек Нео — акарицид широкого спектра дії з додатковим контролем листових мінерів, трипсів та медяниць. 
	2.	Вертимек Нео у своєму складі вже має ультрафіолетові протектори, термостабільні ПАРи та ад'юванти для швидкого проникнення в рослину. 
	3.	Вертимек Нео — трансламінарний акарицид, який забезпечує захист від шкідників, як з верхнього боку листка, так і з нижнього.
	4.	Вертимек Нео — високоефективний акарицид проти дорослих кліщів та їхніх личинок / німф, але не діє на яйце шкідника. Тому, щоб перервати життєвий цикл розвитку кліщів та комах, необхідно провести подвійне обприскування (інтервал визначається видом шкідника) або додати в суміш акарицид з овіцидною дією.</>),
  },
  {
    productTitle: "Актеллік",
    path: "/aktellik",
    src: Insecticides34,
    volume: "5 л",
    price: 168.88,
    currency: "USD",
    text: (<>
    Опис: Актеллік — шкідникам вхід заборонено.
    <span className="paragraph"></span> 
Діюча речовина: 500 г/л піриміфос-метилу.
<span className="paragraph"></span> 
Препаративна форма: Концентрат емульсії.
<span className="paragraph"></span> 
Переваги: 
	•	Ефективно і швидко контролює широкий спектр шкідників;
	•	За рахунок фумігантної і трансламінарної активності знищує приховано живучих шкідників;
	•	Акарицидні властивості стримують розвиток кліщів;
	•	Найбільш безпечний фосфорорганічний інсектицид для довкілля і людей.
  <span className="paragraph"></span> 
Застосування препарату.
<span className="paragraph"></span> 
Соя 
Норма витрати Актеллік®: 1,2–2,0 л/га.
Спектр дії: Кліщі, попелиці.
Кратність обробок: 2
<span className="paragraph"></span> 
Буряк цукровий 
Норма витрати Актеллік®: 1,2–2,0 л/га.
Спектр дії: Довгоносики, мертвоїди, блішки, листова попелиця.
Кратність обробок: 2
<span className="paragraph"></span> 
Черешня 
Норма витрати Актеллік®: 0,8–1,2 л/га.
Спектр дії: Вишнева муха.
Кратність обробок: 1
<span className="paragraph"></span> 
Персик 
Норма витрати Актеллік®: 0,8–1,2 л/га.
Спектр дії: Попелиця, східна плодожерка.
Кратність обробок: 1
<span className="paragraph"></span> 
Баклажан 
Норма витрати Актеллік®: 0,8 л/га.
Спектр дії: Попелиця, колорадський жук.
Кратність обробок: 2
<span className="paragraph"></span> 
Перець солодкий 
Норма витрати Актеллік®: 0,3–1,5 л/га.
Спектр дії: Попелиця, колорадський жук.
Кратність обробок: 2
<span className="paragraph"></span> 
ОГІРКИ І ТОМАТИ ВІДКРИТОГО ГРУНТУ 
Норма витрати Актеллік®: 0,3–1,5 л/га.
Спектр дії:Білокрилка, кліщі, попелиці, трипси.
Кратність обробок: 2
<span className="paragraph"></span> 
ОГІРКИ І ТОМАТИ ЗАКРИТОГО ГРУНТУ 
Норма витрати Актеллік®: 15,0–20,0 мл / 10 л води.
Спектр дії: Білокрилка, кліщі, попелиці, трипси, мінуючі мухи, комарик.
Кратність обробок: 3
<span className="paragraph"></span> 
 
Полуниця, малина 
Норма витрати Актеллік®: 0,6 л/га.
Спектр дії: Попелиці, жуки, вогнівки, листовійки, п'єр ядуни, галиці, пильщики.
Кратність обробок: 2
<span className="paragraph"></span> 
 
ЧОРНА СМОРОДИНА, АҐРУС 
Норма витрати Актеллік®: 1,5 л/га.
Спектр дії: Попелиці, жуки, вогнівки, листовійки, п'єр ядуни, галиці, пильщики.
Кратність обробок: 2
<span className="paragraph"></span> 
 
КУКУРУДЗА (АВИАМЕТОДОМ) 
Норма витрати Актеллік®: 1,2–2,0 л/га.
Спектр дії: Кукурудзяний стебловий метелик, попелиц.
Кратність обробок: 2
<span className="paragraph"></span> 
ВИНОГРАДНИКИ (МАТОЧНИКИ ПІДЩЕПНИХ СОРТІВ) 
Норма витрати Актеллік®: 3,0 л/га.
Спектр дії: Листова філоксера.
Кратність обробок: 2
<span className="paragraph"></span> 
ДЕКОРАТИВНІ КУЛЬТУРИ ВІДКРИТОГО ГРУНТУ 
Норма витрати Актеллік®: 0,5–1,5 л/га.
Спектр дії: Листовійки, кліщі, попелиці, трипси.
Кратність обробок: 4
 <span className="paragraph"></span> 
ДЕКОРАТИВНІ КУЛЬТУРИ ЗАКРИТОГО ГРУНТУ 
Норма витрати Актеллік®: 2,4–3,6 л/га.
Спектр дії: Листовійки, кліщі, попелиці, трипси.
Кратність обробок: 4
 <span className="paragraph"></span> 
БАГАТОРІЧНІ ТРАВИ (НАСІННЄВІ ПОСІВИ), ЛЮЦЕРНА ФУРАЖНА, СЕЛЕРА, ШАВЛІЯ МУСКАТНА 
Норма витрати Актеллік®: 1,0–1,5 л/га.
Спектр дії: Довгоносики, товстоніжки, клопи, попелиці, трипси, шавлієві довгоносик, вогнівки, лучний метелик, совки, галиці.
Кратність обробок: 1-2
<span className="paragraph"></span> 
Норма застосування: 200–400 л/га для наземного обприскування польових культур, 50–100 л/га для застосування авіаметодом, 400–800 л/га для культур захищеного ґрунту, 800–1200 л/га для плодово-ягідних культур залежно від об’єму крони дерева. 
<span className="paragraph"></span> 
Рекомендації з дезінсекції зерна: 
Обробку проводять при стандартній вологості зерна. Товщина шару зерна не повинна перевищувати 0,4 м. 
<span className="paragraph"></span> 
Коментарі: 
Допуск людей та завантаження складів – після провітрювання протягом 2 діб по закінченні експозиції. Реалізація зерна на продовольчі й фуражні цілі – за наявності залишків препарату не вище МДР, у зерні для приготування продуктів дитячого харчування – за відсутності піриміфос-метилу. 
</>),
  },
];
