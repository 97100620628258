import ProductCard from '../../../components/ProductCard/ProductCard'
import { popularProductsArr } from '../../../utils/popularProductsArr'
import './PopularProducts.css'


const PopularProducts = ({usdRate, eurRate}) => {
  return (
    <section className='popular__products'>
        <h2 className="popular__products-title mont-r">Популярні товари</h2>
        <div className="popular__products-wrapper">
            {
                popularProductsArr.map((product, idx) => {
                    return (
                    <ProductCard key={idx} path={product.path} price={product.price} title={product.productTitle} src={product.src} usdRate={usdRate} eurRate={eurRate} currency={product.currency}/>
                    )
                })
            }
        </div>
    </section>
  )
}

export default PopularProducts