import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { navArr } from '../../utils/navArr';
import './Search.css'
import searchIconMobile from '../../assets/icons/searchIconMobile.png'
import { useDispatch, useSelector } from 'react-redux';
import { setIsOverlayActive, setIsOverlayClose } from '../../redux/OverlaySearchSlide/OverlaySearchSlide';
import { setBurgerIsClose } from '../../redux/BurgerSlice/BurgerSlice';
import { setIsClose } from '../../redux/ModalSlice/ModalSlice';
import closeIcon from '../../assets/icons/plusIcon.png'

const Search = ({onClearSearch, search, setSearch}) => {
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const isMobileSerchActive = useSelector((state)=>state.overlay.isOverlayActive)

    const filteredProducts = navArr
    .flatMap((category) => category.subCategoryes || [])
    .flatMap((subCategory) => subCategory.products || []) 
    .filter((product) =>
      search.length > 0 &&
      product.productTitle.slice(0).toLowerCase().includes(search.toLowerCase())
    );

    const handleClose = () => {
        setSearch("")
        dispatch(setIsOverlayClose())
        if (onClearSearch) onClearSearch();
    }

    const handleMobileSearchActive = () => {
        dispatch(setIsOverlayActive())
        dispatch(setBurgerIsClose())
        dispatch(setIsClose())
    }

    useEffect(() => {
        if (isMobileSerchActive) {
            inputRef.current?.focus();
        }
    }, [isMobileSerchActive]);


  return (
    <div className={`search ${isMobileSerchActive ? "active" : ""}`}>
        <img onClick={handleMobileSearchActive} src={searchIconMobile} alt="натисніть, щоб відкрити вікно пошуку продукції" className={`search__icon-mobile ${isMobileSerchActive ? "hidden" : ""}`} />
    <input
      type="text"
      ref={inputRef}
      className={`search__input ${isMobileSerchActive ? "active" : ""} mont-r`}
      placeholder="Я шукаю..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />
    <img onClick={handleClose} className='close__search-icon' src={closeIcon} alt="Закрити вікно пошуку" />
     {search.length > 0 && (
        <div className={`searching__list ${isMobileSerchActive ? "active" : ""}`}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <Link
                onClick={handleClose}
                className="searching__list-item mont-r"
                to={product.path}
                key={index}
              >
                {product.productTitle}
              </Link>
            ))
          ) : (
            <div className="searching__list-empty mont-r">{"Нічого не знайдено"}</div>
          )}
        </div>
      )}
  </div>
  )
}

export default Search