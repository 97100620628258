import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import AppRouter from "./components/AppRouter/AppRouter";
import AboutPage from "./pages/AboutPage/AboutPage";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import MainPage from "./pages/MainPage/MainPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import { bioinsecticidesArr } from "./utils/bioinsecticidesArr";
import { biopreparationsArr } from "./utils/biopreparationsArr";
import { complexMicronutrientFertilizersArr } from "./utils/complexMicronutrientFertilizersArr";
import { cornArr } from "./utils/cornArr";
import { desiccantsArr } from "./utils/desiccantsArr";
import { disinfectantsArr } from "./utils/disinfectantsArr";
import { fertilizersArr } from "./utils/fertilizersArr";
import { fungicidesArr } from "./utils/fungicidesArr";
import { growthRegulatorsArr } from "./utils/growthRegulatorsArr";
import { HerbicidesArr } from "./utils/herbicidesArr";
import { insecticidesArr } from "./utils/insecticidesArr";
import { monopreparationsArr } from "./utils/monopreparationsArr";
import { plantAdhesivesArr } from "./utils/plantAdhesivesArr";
import { rodenticidesArr } from "./utils/rodenticidesArr";
import { ABOUTE_ROUTE, CONTACTS_ROUTE } from "./utils/routes";
import { sunflowerArr } from "./utils/sunflowerArr";


function App() {
  const [usdRate, setUsdRate] = useState(null);
  const [eurRate, setEurRate] = useState(null);
useEffect(() => {
  const fetchCurrencyRates = async () => {
    try {
      const response = await fetch(
        "https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json"
      );
      const data = await response.json();

      const usd = data.find((item) => item.cc === "USD")?.rate;
      const eur = data.find((item) => item.cc === "EUR")?.rate;

      setUsdRate(usd);
      setEurRate(eur);
    } catch (error) {
      console.error("Ошибка при получении курса валют:", error);
    }
  };

  fetchCurrencyRates();
}, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<AppRouter />}>
            <Route index element={<MainPage usdRate={usdRate} eurRate={eurRate} />} />
            <Route path={ABOUTE_ROUTE} element={<AboutPage />} />
            <Route path={CONTACTS_ROUTE} element={<ContactsPage />} />
              {
                HerbicidesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate}  />} />
                  )
                })
              }
              {
                insecticidesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                fungicidesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                disinfectantsArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                desiccantsArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                plantAdhesivesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                growthRegulatorsArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                rodenticidesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                bioinsecticidesArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                biopreparationsArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                fertilizersArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                complexMicronutrientFertilizersArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                monopreparationsArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                sunflowerArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
              {
                cornArr.map((page, idx) => {
                  return (
                    <Route key={idx} path={page.path} element={<ProductPage path={page.path} text={page.text} volume={page.volume} currency={page.currency} price={page.price} title={page.productTitle} src={page.src} usdRate={usdRate} eurRate={eurRate} />} />
                  )
                })
              }
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
