import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProductCard.css";

const ProductCard = ({
  src,
  title,
  path,
  price,
  currency,
  usdRate,
  eurRate,
}) => {
 
  const [priceInUAH, setPriceInUAH] = useState(null);
  useEffect(() => {
    if (currency === "USD" && usdRate) {
      setPriceInUAH(price * usdRate);
    } else if (currency === "EUR" && eurRate) {
      setPriceInUAH(price * eurRate);
    }
  }, [currency, price, usdRate, eurRate]);
  return (
    <div className="product__card">
      <div className="product__card-image-wrapper">
        <img
          src={src}
          alt={`на фотографії зображено ${title}`}
          className="product__card-image"
        />
      </div>
      <div className="product__card-content">
        <h3 className="product__card-title mont-r">{title}</h3>
        <div className="product__card-footer">
          <p className="product__card-price mont-m">{priceInUAH ? `${priceInUAH.toFixed(2)} грн` : "Уточнюйте"}</p>
          <Link className="product__card-link mont-m" to={path}>
            Детальніше
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
