import Herbicides1 from "../assets/images/HerbicidesImages/helios.webp";
import Herbicides2 from "../assets/images/HerbicidesImages/helios-ekstra.webp";
import Herbicides3 from "../assets/images/HerbicidesImages/helios-maksi.webp";
import Herbicides4 from "../assets/images/HerbicidesImages/grenader.webp";
import Herbicides5 from "../assets/images/HerbicidesImages/grenader-maksi.webp";
import Herbicides6 from "../assets/images/HerbicidesImages/disulam.webp";
import Herbicides7 from "../assets/images/HerbicidesImages/dilar.webp";
import Herbicides8 from "../assets/images/HerbicidesImages/etastil.webp";
import Herbicides9 from "../assets/images/HerbicidesImages/klodeks-pro.webp";
import Herbicides10 from "../assets/images/HerbicidesImages/metrisan.webp";
import Herbicides11 from "../assets/images/HerbicidesImages/milafort.webp";
import Herbicides12 from "../assets/images/HerbicidesImages/milafort-ekstra.webp";
import Herbicides13 from "../assets/images/HerbicidesImages/oreol-maksi.webp";
import Herbicides14 from "../assets/images/HerbicidesImages/osnova.webp";
import Herbicides15 from "../assets/images/HerbicidesImages/promeks.webp";
import Herbicides16 from "../assets/images/HerbicidesImages/sulfonil.webp";
import Herbicides17 from "../assets/images/HerbicidesImages/tanais.webp";
import Herbicides18 from "../assets/images/HerbicidesImages/tolasin.webp";
import Herbicides19 from "../assets/images/HerbicidesImages/tolasin-ultra.webp";
import Herbicides20 from "../assets/images/HerbicidesImages/traident.webp";
import Herbicides21 from "../assets/images/HerbicidesImages/unisulam.webp";
import Herbicides22 from "../assets/images/HerbicidesImages/argument.webp";
import Herbicides23 from "../assets/images/HerbicidesImages/advokat.webp";
import Herbicides24 from "../assets/images/HerbicidesImages/gerb-900.webp";
import Herbicides25 from "../assets/images/HerbicidesImages/grizniy.webp";
import Herbicides26 from "../assets/images/HerbicidesImages/grizniy-ekspert.webp";
import Herbicides27 from "../assets/images/HerbicidesImages/dekabrist-480.webp";
import Herbicides28 from "../assets/images/HerbicidesImages/evro-lend.webp";
import Herbicides29 from "../assets/images/HerbicidesImages/kapral.webp";
import Herbicides30 from "../assets/images/HerbicidesImages/konvoy.webp";
import Herbicides31 from "../assets/images/HerbicidesImages/legenda.webp";
import Herbicides32 from "../assets/images/HerbicidesImages/lemur.webp";
import Herbicides33 from "../assets/images/HerbicidesImages/maitus.webp";
import Herbicides34 from "../assets/images/HerbicidesImages/mikado.webp";
import Herbicides35 from "../assets/images/HerbicidesImages/milafuron.webp";
import Herbicides36 from "../assets/images/HerbicidesImages/motor.webp";
import Herbicides37 from "../assets/images/HerbicidesImages/premium-gold.webp";
import Herbicides38 from "../assets/images/HerbicidesImages/prius.webp";
import Herbicides39 from "../assets/images/HerbicidesImages/saber.webp";
import Herbicides40 from "../assets/images/HerbicidesImages/sentinel.webp";
import Herbicides41 from "../assets/images/HerbicidesImages/serp.webp";
import Herbicides42 from "../assets/images/HerbicidesImages/sonhus.webp";
import Herbicides43 from "../assets/images/HerbicidesImages/cetodim-par-fofir.webp";
import Herbicides44 from "../assets/images/HerbicidesImages/alfa-dikamba.webp";
import Herbicides45 from "../assets/images/HerbicidesImages/alfa-mais.webp";
import Herbicides46 from "../assets/images/HerbicidesImages/alfa-prometrin.webp";
import Herbicides47 from "../assets/images/HerbicidesImages/antizlak.webp";
import Herbicides48 from "../assets/images/HerbicidesImages/veritan.webp";
import Herbicides49 from "../assets/images/HerbicidesImages/etalon.webp";
import Herbicides50 from "../assets/images/HerbicidesImages/kaiman.webp";
import Herbicides51 from "../assets/images/HerbicidesImages/kampus.webp";
import Herbicides52 from "../assets/images/HerbicidesImages/konkur.webp";
import Herbicides53 from "../assets/images/HerbicidesImages/lobera.webp";
import Herbicides54 from "../assets/images/HerbicidesImages/mistard.webp";
import Herbicides55 from "../assets/images/HerbicidesImages/oskar-pauer.webp";
import Herbicides56 from "../assets/images/HerbicidesImages/otaman.webp";
import Herbicides57 from "../assets/images/HerbicidesImages/otaman-ekstra.webp";
import Herbicides58 from "../assets/images/HerbicidesImages/sokar.webp";
import Herbicides59 from "../assets/images/HerbicidesImages/santal.webp";
import Herbicides60 from "../assets/images/HerbicidesImages/triatlon.webp";
import Herbicides61 from "../assets/images/HerbicidesImages/triatlon-prime.webp";
import Herbicides62 from "../assets/images/HerbicidesImages/hammer.webp";
import Herbicides63 from "../assets/images/HerbicidesImages/hammer-duo.webp";
import Herbicides64 from "../assets/images/HerbicidesImages/basagran-pro.webp";
import Herbicides65 from "../assets/images/HerbicidesImages/evro-laiting-plus.webp";
import Herbicides66 from "../assets/images/HerbicidesImages/evro-laiting.webp";
import Herbicides67 from "../assets/images/HerbicidesImages/pulsar-flex.webp";
import Herbicides68 from "../assets/images/HerbicidesImages/stellar-plus.webp";
import Herbicides69 from "../assets/images/HerbicidesImages/stomp-330.webp";
import Herbicides70 from "../assets/images/HerbicidesImages/stomp-akva.webp";
import Herbicides71 from "../assets/images/HerbicidesImages/kaptora.webp";
import Herbicides72 from "../assets/images/HerbicidesImages/kaptora-plus.webp";
import Herbicides73 from "../assets/images/HerbicidesImages/derbi-175.webp";
import Herbicides74 from "../assets/images/HerbicidesImages/dual-gold-960-es.jpg";
import Herbicides75 from "../assets/images/HerbicidesImages/elymis.jpg";
import Herbicides76 from "../assets/images/HerbicidesImages/gesagard-500-fw.webp";
import Herbicides77 from "../assets/images/HerbicidesImages/pik-75-wg.webp";
import Herbicides78 from "../assets/images/HerbicidesImages/heliantex-ks-corteva.jpg";

export const HerbicidesArr = [
  {
    productTitle: "Геліос",
    path: "/helios",
    src: Herbicides1,
    volume: "20 л",
    price: 79.5,
    currency: "USD",
    text: (
      <>
        Опис: Геліос - системний гербіцид суцільної дії для знищення однорічних
        і багаторічних злакових та дводольних видів бур'янів. Тотальне знищення
        бур'янів. <span className="paragraph"></span>
        Діюча речовина: Ізопропіламінна сіль гліфосату, 480 г/л, у кислотному
        еквіваленті – 360 г/л. Препаративна форма: Розчинний концентрат.{" "}
        <span className="paragraph"></span> Переваги:  • Знищення вегетативної та
        кореневої частини рослин; • Відсутність післядії на культурах —
        можливість застосування до сівби; • Контроль однорічних та багаторічних
        бур'янів; • Ефективне рішення для очищення полів від важкоконтрольованих
        видів бур'янів.  <span className="paragraph"></span> Механізм дії:  Гліфосат
        є інгібітором синтезу ароматичних кислот, які необхідні для будівництва
        білків. Швидко проникає в середину рослин і переміщується до точок
        росту, блокуючи ферментативні процеси. Це призводить до відмирання як
        надземних так і підземних частин бур'янів.
        <span className="paragraph"></span> Норма витрати робочого
        розчину:  100-200 л/га (залежно від технічних показників обприскувача).{" "}
        <span className="paragraph"></span>  Культура: • Землі не
        сільськогосподарського призначення; • Пари; • Поля, призначені під
        посіви сільськогосподарських культур.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні дводольні та злакові бур’яни.{" "}
        <span className="paragraph"></span>
        Фаза застосування препарату: • Обприскування бур’янів в період їх
        активного росту; • Обприскування вегетуючих бур’янів; • Обприскування
        вегетуючих бур’янів восени після збирання попередника або весною по
        вегетуючих бур’янах за 2 тижні до висівання.
        <span className="paragraph"></span>  Рекомендації щодо застосування:  •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів. • Оптимальними умовами застосування гербіциду Геліос є
        температура +12...+25°С, відносна вологість повітря – 60%, швидкість
        вітру - не більше 5 м/с у ранкові або вечірні години. Відсутність опадів
        протягом 5-6 годин після внесення. • Не рекомендовано застосовувати
        Геліос, коли бур'яни перебувають у стресовому стані (перепади денних і
        нічних температур, тривала посуха інші абіотичні чинники), оскільки при
        уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії. • Заборонено проводити механічний обробіток ґрунту
        раніше ніж через 10-14 діб після внесення Геліос. • Наявність надмірної
        кількості роси знижує ефективність дії гліфосату. • Для якісного
        контролю лободи дочекатись відкриття листків, оскільки у ранковий і
        вечірній час вони приймають вертикальне положення.
      </>
    ),
  },
  {
    productTitle: "Геліос Екстра",
    path: "/helios-ekstra",
    src: Herbicides2,
    volume: "20 л",
    price: 114.67,
    currency: "USD",
    text: (
      <>
        Опис: Геліос Екстра — системний гербіцид суцільної дії для знищення
        однорічних і багаторічних злакових та дводольних бур’янів. Суцільне
        знищення бур’янів. <span className="paragraph"></span> Діюча речовина:
        Калійна сіль гліфосату, 663 г/л, у кислотному еквіваленті – 540 г/л.{" "}
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат. <span className="paragraph"></span> Переваги: • Ефективний
        контроль комплексу однорічних та багаторічних видів бур'янів; •
        Покращена формуляція, що пришвидшує поглинання робочого розчину; •
        Підвищена концентрація діючої речовини; • Ширший температурний діапазон
        застосування ( +8...+25°C) порівняно із ізопропіламінною формою.{" "}
        <span className="paragraph"></span> Механізм дії:  Гліфосат є інгібітором
        синтезу ароматичних кислот, які необхідні для будівництва білків. Швидко
        проникає в середину рослин і переміщується до точок росту, блокуючи
        ферментативні процеси. Це призводить до відмирання як надземних, так і
        підземних частин бур'янів. <span className="paragraph"></span>Норма витрати робочого розчину: 100-200 л/га
        (залежно від технічних показників обприскувача){" "}
        <span className="paragraph"></span>Культура: • Землі не
        сільськогосподарського призначення, поля, призначені під посіви
        сільськогосподарських культур. <span className="paragraph"></span>
        Цільовий об’єкт: • Однорічні та багаторічні бур’яни.
        <span className="paragraph"></span> Фаза застосування препарату: •
        Обприскування вегетуючих бур’янів восени після збирання попередника або
        весною по вегетуючих бур’янах за 2 тижні до висівання.{" "}
        <span className="paragraph"></span>Рекомендації щодо застосування:  •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів. • Оптимальна температура застосування +12...+25°С. Відсутність
        опадів протягом 3-4 годин після внесення. • Не рекомендовано
        застосовувати, коли бур'яни перебувають у стресовому стані (перепади
        денних і нічних температур, тривала посуха), оскільки при уповільненому
        метаболізмі знижується швидкість переміщення діючих речовин до сайту
        дії. • Заборонено проводити механічний обробіток ґрунту раніше ніж через
        10-14 діб після внесення Геліос Екстра. • Для досягнення максимальної
        ефективності необхідно забезпечити рівномірне покриття листової поверхні
        робочим розчином. • Наявність надмірної кількості роси знижує
        ефективність дії гліфосату. • Для якісного контролю лободи дочекатись
        відкриття листків, оскільки у ранковий і вечірній час вони приймають
        вертикальне положення.
      </>
    ),
  },
  {
    productTitle: "Геліос Максі",
    path: "/helios-maksi",
    src: Herbicides3,
    volume: "10 кг",
    price: 78,
    currency: "USD",
    text: (
      <>
        Опис: Геліос Максі - системний гербіцид суцільної дії із підвищеною
        концентрацією гліфосату для знищення однорічних і багаторічних злакових
        та дводольних видів бур'янів. Максі потужність суцільного гербіциду.
        <span className="paragraph"></span> Діюча речовина: Амонійна сіль гліфосату
        757 г/кг, в кислотному еквіваленті – 680 г/кг.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Одна із найвищих
        концентрацій гліфосату серед конкурентів — нижчі норми витрати; •
        Покращена біодоступність та гербіцидна активність за рахунок амонійної
        форми; • Швидке проникнення запобігає змиванню дощем вже через 2 години;
        • Ефективний контроль бур’янів уже від 1,0 кг/га; • Зручна для
        використання препаративна форма, що не утворює пилу.
        <span className="paragraph"></span> Механізм дії: Гліфосат є інгібітором
        синтезу ароматичних кислот, які необхідні для будівництва білків. Швидко
        проникає в середину рослин і переміщується до точок росту, блокуючи
        ферментативні процеси. Це призводить до відмирання як надземних так і
        підземних частин бур'янів.<span className="paragraph"></span> Норма витрати робочого розчину: 100-200 л/га
        (залежно від технічних показників обприскувача)
        <span className="paragraph"></span> Культура: • Поля під посіви с/х культур;
        • Пари; • Пшениця, ячмінь; • Ріпак; • Соняшник;  • Кукурудза. Цільовий
        об’єкт: • Однорічні та багаторічні злакові та дводольні бур'яни; •
        Десикація. Спосіб та час обробки: • Обприскування вегетуючих бур'янів •
        Обприскування за 2 тижні до збирання за вологості зерна не більше 30%; •
        Обприскування при побурінні 70% насіння в стручках за вологості 26-30%;
        • Обприскування у фазі початку побуріння кошиків та вологості зерна
        24-30%; • Обприскування посівів при вологості зерна 30-36%.
        <span className="paragraph"></span> Рекомендації щодо застосування:  •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів. • Оптимальна температура застосування +12...+25°С. Відсутність
        опадів протягом 2-3 годин після внесення. • Заборонено проводити
        механічний обробіток ґрунту раніше ніж через 10-14 діб після внесення
        Геліос Максі. • Для досягнення максимальної ефективності необхідно
        забезпечити рівномірне покриття листової поверхні робочим розчином. • Не
        рекомендовано застосовувати, коли бур'яни перебувають у стресовому стані
        (перепади денних і нічних температур, тривала посуха), оскільки при
        уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії. • Наявність надмірної кількості роси знижує
        ефективність дії гліфосату. • Для якісного контролю лободи дочекатись
        відкриття листків, оскільки у ранковий і вечірній час вони приймають
        вертикальне положення.
      </>
    ),
  },
  {
    productTitle: "Гренадер",
    path: "/grenader",
    src: Herbicides4,
    volume: "0,5 кг",
    price: 14.67,
    currency: "USD",
    text: (
      <>
        Опис: Гренадер - системний післясходовий гербіцид для контролю
        дводольних бур'янів у посівах зернових колосових культур. Незамінний
        елемент захисту зернових і сульфо-стійкого соняшнику
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 750
        г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Контроль комплексу
        дводольних видів бур'янів, в т.ч. стійких до 2,4-Д; • Широке
        технологічне вікно застосування: від двох листків — до прапорцевого
        листка включно; • Діюча речовина має високий ступінь очистки, що
        запобігає фітотоксичності на зернових та надає можливість застосовувати
        на соняшнику, стійкому до трибенурон-метилу; • Відсутність післядії на
        наступні культури у сівозміні.
        <span className="paragraph"></span> Механізм дії:
        Трибенурон-метил поглинається переважно листям і переміщується до точок
        росту бур'янів, пригнічуючи фермент ацетолактат-синтазу. Поділ клітин
        зупиняється і ріст рослин припиняється вже через кілька годин після
        проведення обробки.<span className="paragraph"></span> Норма витрати робочого розчину: 150-300 л/га
        (залежно від технічних показників обприскувача).  Культура: • Пшениця
        зима; • Пшениця ярова; • Ячмінь озимий.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні двоздовжні бур'яни. Спосіб і час оброблення: • Обприскування
        у фазу 3-4 листя до появи флагового листа.
        <span className="paragraph"></span> Рекомендації щодо застосування:  •
        Відсутність опадів протягом 3-4 годин після внесення. • Може
        застосовуватись при +5°С, але швидкість дії сповільнюється порівняно з
        оптимальними умовами для внесення. • Для досягнення максимальної
        ефективності необхідно забезпечити рівномірне покриття листової поверхні
        робочим розчином. • Обов'язково рекомендовано додавати ад'ювант Агропав
        Екстра у концентрації 0,05%-0,1%. • Не рекомендовано застосовувати
        Гренадер, коли бур'яни перебувають у стресовому стані (перепади денних і
        нічних температур, тривала посуха), оскільки при уповільненому
        метаболізмі знижується швидкість переміщення діючих речовин до сайту
        дії. • Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: у ранні фази розвитку для однорічних видів, і за висоти до 15
        см — для багаторічних. Оптимальна температура застосування +8...+25°С.
      </>
    ),
  },
  {
    productTitle: "Гренадер Максі",
    path: "/grenader-maksi",
    src: Herbicides5,
    volume: "0,5кг",
    price: 25.82,
    currency: "USD",
    text: (
      <>
        Опис: Гренадер Максі - истемний двокомпонентний післясходовий гербіцид
        для контролю дводольних бур'янів, в т.ч. стійких до 2,4-Д у посівах
        зернових колосових культур. Фундамент захисту зернових колосових.
        <span className="paragraph"></span> Діюча речовина: трибенурон-метил, 562,5
        г/кг + тифенсульфурон-метил, 187,5 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, які
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Посилена дія на підмаренник
        чіпкий (до 4 кілець); • Контроль певних видів перерослих бур'янів (
        лобода, підмаренник чіпкий 2-4 кільця та ін.); • Широке «технологічне
        вікно» застосування — до прапорцевого листка включно; • Відсутність
        післядії на наступні культури у сівозміні.
        <span className="paragraph"></span> Механізм дії: Трибенурон-метил і
        тифенсульфурон-метил – інгібітори ацетолактатсинтази – ключового ензиму,
        необхідного для біосинтезу незамінних амінокислот (ізолейцин, лейцин і
        валін). Всередині рослини швидко переміщується до місць найбільшої
        меристематичної активності – точок росту. В результаті цього уже через
        кілька годин припиняється поділ клітин, ріст пагонів та кореневої
        системи бур'янів, а через 2-3 тижні вони остаточно гинуть.<span className="paragraph"></span> Норма витрати
        робочого розчину: 150-300 л/га (залежно від технічних показників
        обприскувача).
        <span className="paragraph"></span> Культура: • Пшениця озима та ярова,
        ячмінь яровий та озимий; • Овес, жито. Цільовий об’єкт: • Однорічні та
        багаторічні злакові, зокрема стійкі до 2,4-Д, бур'яни, чорниця
        соняшника, стійкого до трибенурон-металу.
        <span className="paragraph"></span> Спосіб і час оброблення: • Обприскування
        посівівів починаючи з фази 2-3 листя до появи флагового листа культури
        включно, на ранніх фазах росту бур'янів; • Обприскування посівівів у
        фазу купання до виходу в трубку на ранніх фазах росту бур'янів.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: у ранні фази розвитку для однорічних видів, і за висоти до 15
        см — для багаторічних. • Відсутність опадів протягом 3-4 годин після
        внесення. • Може застосовуватись при +5°С, але швидкість дії
        сповільнюється порівняно з оптимальними умовами для внесення. • Для
        досягнення максимальної ефективності необхідно забезпечити рівномірне
        покриття листової поверхні робочим розчином. Рекомендовано додавати
        ад'ювант Агропав Екстра у концентрації 0,05%-0,1%. • Не рекомендовано
        застосовувати Гренадер Максі, коли бур'яни перебувають у стресовому
        стані (перепади денних і нічних температур, тривала посуха), оскільки
        при уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії.
      </>
    ),
  },
  {
    productTitle: "Дисулам",
    path: "/disulam",
    src: Herbicides6,
    volume: "5л",
    price: 30.51,
    currency: "USD",
    text: (
      <>
        Опис: Дисулам — системний післясходовий гербіцид для контролю однорічних
        та деяких багаторічних дводольних бур’янів у посівах зернових колосових
        культур та кукурудзи. Подвійний і швидкий удар по бур'янах.
        <span className="paragraph"></span> Діюча речовина: 2-етилгексиловий ефір
        2,4-Д, 452,42 г/л + флорасулам, 6,25 г/л.
        <span className="paragraph"></span> Препаративна форма: Суспо-емульсія.
        <span className="paragraph"></span> Переваги: • Швидке проникнення через
        воскові нальоти бур'янів за рахунок ефіру (впродовж години); • Знищення
        найбільш шкодочинних дводольних бур'янів, а також видів падалиці ріпака
        та соняшника (в т. ч. ALS-стійких); • Широке технологічне вікно
        застосування (до другого міжвузля у зернових); • Гнучкий температурний
        режим для обприскування (+8..+25°C); • Відсутня післядія на наступні
        культури в сівозміні.
        <span className="paragraph"></span> Механізм дії: 2,4-Д — системна діюча
        речовина ауксинового типу. Швидко проникає через листову поверхню,
        переміщується у рослині, накопичуючись в значно більших кількостях, ніж
        натуральні гормони, завдяки тривалому періоду напіврозпаду. Порушує
        біосинтез структурних і ферментних білків, що призводить до порушень
        метаболізму рослини. Під впливом явища епінастії (прискореного росту
        тканин верхнього боку листя, стебла, черешків), відбувається їх
        викривлення донизу, потовщюються провідні тканини із підвищенням
        тургору. Внаслідок цього пагони і коріння тріскаються, зменшується
        надходження в рослину азоту, фосфору, калію і зупиняється синтезуюча
        діяльність коріння. Порушується водний обмін, втрачається тургор і
        рослина гине. Уже через добу помітні перші ознаки гербіцидної
        активності, а повноцінне знищення наступає через 10-14 діб залежно від
        виду і фази розвитку бур'янів. Флорасулам — інгібітор ацетолактатсинтази
        — ключового ензиму, необхідного для біосинтезу незамінних амінокислот
        (ізолейцин, лейцин і валін). Всередині рослини швидко переміщується до
        місць найбільшої меристематичної активності – точок росту. В результаті
        цього уже через кілька годин припиняється поділ клітин, ріст пагонів та
        кореневої системи бур'янів, а загибель наступає через 2-3 тижні. <span className="paragraph"></span>Норма
        витрати робочого розчину: 150-200 л/га (залежно від технічних показників
        обприскувача).
        <span className="paragraph"></span> Культура: • Пшениця, ячмінь (озимі,
        ярі); • Кукурудза; • Сорго; • Просо.
        <span className="paragraph"></span> Цільовий об’єкт • Однорічні дводольні
        бур'яни, в. т. ч. стійкі до 2,4-Д і МЦПА та деякі багаторічні дводольні
        бур'яни • Спосіб та час обробки • Обприскування від початку фази кущення
        до утворення 1-2 міжвузлів культури • Обприскування з фази 3 до фази 7
        (включно) листків культури • Обприскування у фазі 3-5 листків культури •
        Обприскування від початку фази кущення до виходу в трубку культури
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних дводольних видів — фаза сім'ядоль-2-4 листків
        (осоти — фаза розетки), для багаторічних – висота до 15 см. • Оптимальна
        температура застосування +10...+25°C (нижній поріг — +8°C), відносна
        вологість повітря від 65%, швидкість вітру — не більше 5 м/с у ранкові
        або вечірні години. • Для досягнення максимальної ефективності необхідно
        забезпечити рівномірне покриття листової поверхні робочим розчином. • Не
        рекомендовано застосовувати Дисулам, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала повітряна
        і ґрунтова посуха), оскільки при уповільненому метаболізмі знижується
        швидкість переміщення діючих речовин до сайту дії.
      </>
    ),
  },
  {
    productTitle: "Ділар",
    path: "/dilar",
    src: Herbicides7,
    volume: "2кг",
    price: 125.22,
    currency: "USD",
    text: (
      <>
        Опис: Ділар - системний післясходовий гербіцид із ґрунтовою дією для
        знищення бур'янів з родин айстрових, бобових, пасльонових, селерових у
        посівах польових культур. Стандарт боротьби з осотами.
        <span className="paragraph"></span> Діюча речовина: клопіралід, 750 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, які
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Обов'язковий елемент
        технології з контролю осотів із знищенням кореневої системи; • Швидке
        переміщення до сайту дії — зупинка росту бур'янів уже через годину; •
        Краще рішення для знищення видів падалиці соняшника та амброзії з
        контролем наступних хвиль за рахунок грунтової дії; • Застосовується на
        багатьох польових культурах.
        <span className="paragraph"></span> Механізм дії: Клопіралід – системна
        діюча речовина, яка проникає через листову поверхню, швидко
        розподіляється по всій рослині, при цьому здатна рухатись як флоемою,
        так і ксилемою, забезпечуючи знищення навіть кореневої системи. Оскільки
        клопіралід є синтетичною формою природного гормону росту (індол-оцтова
        кислота), при заміщенні ним спостерігаються порушення ростових процесів,
        неконтрольований поділ клітин, уповільнення метаболізму та подальша
        загибель бур'янів. Уже через добу помітні перші ознаки гербіцидної
        активності, а повноцінне знищення багаторічних бур'янів наступає через
        2-3 тижні залежно від їх виду і фази розвитку.
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-200
        л/га (залежно від технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Кукуруза; • Зернові.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні злакові (гума, пирей) і поширені однорічні злакові бур'яни;
        • Однорічні та багаторічні.
        <span className="paragraph"></span> Спосіб і час оброблення: • Обприскування
        посівівів у фазі від 3 до 10 листя культури включно. Рекомендації щодо
        застосування: • Обприскування рекомендовано проводити під час активної
        вегетації бур'янів: для однорічних дводольних видів у фази від
        сім'ядоль, до 2-4 листків (осоти — фаза розетки), для багаторічних —
        висота до 15 см. • Оптимальна температура застосування +15...+25°C
        (нижній поріг — +8°C), відносна вологість повітря від 60%, швидкість
        вітру — не більше 5 м/с у ранкові або вечірні години. Відсутність опадів
        протягом 4 годин після внесення. • Для досягнення максимальної
        ефективності необхідно забезпечити рівномірне покриття листової поверхні
        робочим розчином. Для цього використовують ад'ювант Агропав Екстра у
        концентрації 0,05%-0,1%. • Не рекомендовано застосовувати Ділар, коли
        бур'яни перебувають у стресовому стані (перепади денних і нічних
        температур, тривала повітряна і ґрунтова посуха), оскільки при
        уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії. • Обмеження сівозміни: після застосування Ділару
        не рекомендовано протягом наступних 10 місяців висівати чутливі
        культури: соняшник, бобові (соя, горох, квасоля, сочевиця, нут) та
        пасльонові. Використання норм, що перевищують рекомендовані, а також
        відсутність достатньої кількості опадів протягом вегетаційного сезону
        (менше 380 мм за 10 місяців), можуть сприяти посиленню післядії на
        чутливих культурах.
      </>
    ),
  },
  {
    productTitle: "Етасил",
    path: "/etastil",
    src: Herbicides8,
    volume: "0,25 кг",
    price: 60.39,
    currency: "USD",
    text: (
      <>
        Опис: Етасил - системний післясходовий гербіцид для контролю однорічних
        дводольних бур'янів, в т.ч. комплексу хрестоцвітих видів у посівах
        ріпака та соняшника. Скажи СТОП хрестоцвітим бур'янам.
        <span className="paragraph"></span> Діюча речовина: РГ, етаметсульфурон-
        метил, 750 г/кг.
        <span className="paragraph"></span> Препаративна форма: Водорозчинна
        гранула.
        <span className="paragraph"></span> Переваги: • Високоселективне знищення
        бур'янів у посівах ріпака (гірчиця польова, грицики звичайні, талабан
        польовий, кучерявець Софії та ін.); • Оптимальний баковий партнер для
        розширення спектру контролю бур'янів у посівах традиційного соняшника; •
        Широкий температурний режим застосування, в т.ч. в осінній період (від
        +8°С).
        <span className="paragraph"></span> Механізм дії: Етаметсульфурон-метил —
        інгібітор ацетолактатсинтази — ключового ензиму, необхідного для
        біосинтезу незамінних амінокислот (ізолейцин, лейцин і валін). За
        достатнього рівня зволоження ґрунту володіє частковою ґрунтовою дією,
        абсорбується також і корінням. Проте, основна частина препарату
        поглинається листям і всередині рослини швидко переміщується до місць
        найбільшої меристематичної активності — точок росту. В результаті цього
        уже через кілька годин припиняється поділ клітин, зупиняється ріст
        пагонів та кореневої системи бур'янів, а повна загибель наступає через
        2-3 тижні. Норма витрати робочого розчину: 150-300 л/га (залежно від
        технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Соняшник.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні дводольні
        бур'ян.
        <span className="paragraph"></span> Фаза розвитку культури: • Оприскування
        культури у фазу 4...8 листків; • Бур'яни - у фазі до симядолі, 2
        справжніх листки.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних дводольних видів — фаза сім'ядоль-2-4 листків.
        • Оптимальна температура застосування +10...+25°С (нижній поріг — +8°С),
        відносна вологість повітря від 65%, швидкість вітру — не більше 5 м/с у
        ранкові або вечірні години. • Для досягнення максимальної ефективності
        необхідно забезпечити рівномірне покриття листової поверхні робочим
        розчином. Для цього обов'язково використовують ад'ювант Агропав Екстра у
        концентрації 0,05%-0,1%. • Не рекомендовано застосовувати Етасил, коли
        бур'яни перебувають у стресовому стані (перепади денних і нічних
        температур, тривала повітряна і ґрунтова посуха), оскільки при
        уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії.
      </>
    ),
  },
  {
    productTitle: "Клодекс ПРО",
    path: "/klodeks-pro",
    src: Herbicides9,
    volume: "5 л",
    price: 56.17,
    currency: "USD",
    text: (
      <>
        Опис: Клодекс ПРО - системний селективний гербіцид для ґрунтового та
        ранньопіслясходового захисту сої від комплексу злакових і дводольних
        видів бур'янів. Звільнить поле від конкурентів.
        <span className="paragraph"></span> Діюча речовина: Кломазон, 480 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span> Переваги: • Надійний контроль широкого
        спектру злакових і дводольних видів бур'янів; • Тривалий період
        грунтової захисної дії (до 60 діб) дозволяє стримувати наступні хвилі
        бур'янів; • Низька залежність від наявності ґрунтової вологи порівняно з
        іншими продуктами; • Гнучкі терміни застосування – до і після появи
        сходів сої; • Ідеально комбінується із баковими партнерами забезпечуючи
        безконкурентне середовище для культур.
        <span className="paragraph"></span> Механізм дії: Діюча
        речовина кломазон через проростки і коріння проникає в рослини і
        рухається по ксилемі. Відбувається блокування процесів біосинтезу
        основних пігментів хлорофілу та кератину, що призводить до зупинки
        фотосинтезу. Норма витрати робочого розчину: 200-300 л/га (залежно від
        технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Ріпак, соя.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні дводольні
        та деякі злакові бур'яни.
        <span className="paragraph"></span> Фаза розвитку рослини: • Після посіву,
        до сходів кульутри; • Оприскування посівів у фазу 1...3 трійчастих
        листка культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: • Перед
        застосуванням Клодекс Про рекомендується провести якісну підготовку
        ґрунту, забезпечивши максимально вирівняну поверхню без великих грудок
        та рослинних решток. • Обприскування ґрунту рекомендовано виконати до,
        відразу після або протягом перших 3-х діб після сівби, залежно від фази
        активної вегетації бур'янів. • На легких ґрунтах норма витрати Клодекс
        Про становить 0,15 л/га, на важких – 0,2 л/га. • Оптимальна температура
        застосування Клодекс Про по вегетуючій сої +12...+25°С, вологість
        повітря від 65%, швидкість вітру не більше 1 м/с. • Рекомендується
        використовувати ад'ювант Агропав Екстра у концентрації 0,05%-0,1% для
        рівномірного покриття листової поверхні робочим розчином. • Не
        рекомендується застосовувати Клодекс Про при стресовому стані бур'янів,
        оскільки це може знизити ефективність дії речовини. • Рекомендується
        проводити боронування та міжрядний обробіток ґрунту протягом 30 діб
        після обприскування, щоб уникнути руйнування гербіцидного екрана. Період
        між обробками грамініцидів повинен бути не менше 7-10 діб, якщо
        необхідно їх застосування. • Зверніть увагу, що на листках ріпаку можуть
        виникати тимчасові симптоми побіління, але це не впливає на урожайність
        в майбутньому.
      </>
    ),
  },
  {
    productTitle: "Метризан",
    path: "/metrisan",
    src: Herbicides10,
    volume: "1 кг",
    price: 28.98,
    currency: "USD",
    text: (
      <>
        Опис: Метризан - системний ґрунтовий гербіцид для знищення комплексу
        дводольних і деяких злакових видів бур'янів у посівах сої та картоплі.
        Обгрунтований захист сої.
        <span className="paragraph"></span> Діюча речовина: Метрибузин, 700 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Контроль широкого спектру
        однорічних дводольних видів бур'янів (в т.ч. амброзії, лободи, щириці,
        гірчаків); • Поглинається листям і корінням, забезпечуючи знищення як
        проростаючих так і вегетуючих бур'янів (до появи справжніх листків); •
        Тривалий період захисту посівів (від 30 до 60 діб); • Високий рівень
        сумісності із іншими ґрунтовими гербіцидами для розширення спектру дії
        (Промекс, Клодекс Про); • Відсутня післядія на наступні культури.
        <span className="paragraph"></span> Механізм дії: Метрибузин — системна
        діюча речовина, абсорбується корінням і листям. Проникаючи в рослини,
        блокує транспорт електронів, які приймають участь у процесі фотосинтезу
        (фотосистема I). Як наслідок у бур'янів порушується енергетичний обмін,
        транспірація – тому протягом 2-3 тижнів вони гинуть. Норма витрати
        робочого розчину: 200-300 л/га (залежно від технічних показників
        обприскувача).
        <span className="paragraph"></span> Культура: • Картопля; • Соя; •
        Кукурудза.
        <span className="paragraph"></span> Об'єкт: • Однорічні дводольні та
        злакові.
        <span className="paragraph"></span>Рекомендації щодо застосування: • Перед
        застосуванням потрібно забезпечити якісну підготовку ґрунту: поверхня
        повинна бути максимально вирівняною, без великих грудок та рослинних
        решток. • Обприскування ґрунту рекомендовано проводити до, відразу після
        або протягом перших 3-х діб після сівби під час активної вегетації
        бур'янів: для злакових видів – фаза 3-4 листків, для однорічних
        дводольних – фаза сім'ядоль-2-4 листків, а для багаторічних – за висоти
        до 15 см. • У посівах картоплі післясходово застосовують за висоти
        культури до 10-15 см. • На легких ґрунтах норма витрати мінімальна, на
        важких – максимальна. На піщаних ґрунтах із вмістом гумусу менше 1%
        використовувати препарат не рекомендується. • Не рекомендовано
        застосовувати Метризан, коли бур'яни перебувають у стресовому стані
        (перепади денних і нічних температур, тривала повітряна і ґрунтова
        посуха), оскільки при уповільненому метаболізмі знижується швидкість
        переміщення діючих речовин до сайту дії. • Увага! Протягом 30 діб після
        обприскування не рекомендується проводити боронування та міжрядний
        обробіток ґрунту, щоб не зруйнувати гербіцидний екран. У разі
        необхідності застосування грамініцидів період між обробками повинен
        становити не менше 7-10 діб. • Важливо! Деякі сорти сої та картоплі
        мають індивідуальну чутливість до діючої речовини, тому за несприятливих
        умов на рослинах можуть спостерігатись симптоми фітотоксичності
        (пожовтіння та призупинення росту). Дану інформацію потрібно уточнювати
        у виробника насіннєвого/садивного матеріалу.
      </>
    ),
  },
  {
    productTitle: "Мілафорт",
    path: "/milafort",
    src: Herbicides11,
    volume: "10 л",
    price: 62.93,
    currency: "USD",
    text: (
      <>
        Опис: Мілафорт - системний післясходовий високоселективний гербіцид для
        захисту кукурудзи від однорічних і багаторічних злакових та деяких
        дводольних видів бур'янів. Ваша фортеця на захисті кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Нікосульфурон, 40 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат суспезії.
        <span className="paragraph"></span> Переваги: • Висока селективність щодо
        культури незалежно від фази застосування (3-10 листків); • Знищення
        комплексу шкодочинних злакових видів бур'янів (пирій, мишій (види),
        плоскуха звичайна, метлюг, вівсюг та ін.); • Не персистентний в ґрунті,
        тому не проявляє післядії на наступні культури; • Ідеальний баковий
        партнер для протидводольних гербіцидів у посівах.
        <span className="paragraph"></span> Механізм дії: Нікосульфурон — інгібітор
        ацетолактатсинтази — ключового ензиму, необхідного для біосинтезу
        незамінних амінокислот (ізолейцин, лейцин і валін). Поглинається листям
        і всередині рослини швидко переміщується до місць найбільшої
        меристематичної активності — точок росту. В результаті цього уже через
        кілька годин припиняється поділ клітин, зупиняється ріст пагонів та
        кореневої системи бур'янів, а повна загибель наступає через 2-3 тижні.
        Норма витрати робочого розчину: 150-250 л/га (залежно від технічних
        показників обприскувача).
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічнізлакові та
        деякі дводольні бур'яни; • Багаторічні злакові та деякі дводольні. Фаза
        розвитку рослини: • 3...10 листків культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних злакових видів — фаза 3-4 листки, для
        багаторічних – за висоти до 10-15 см, а для однорічних дводольних – у
        фазі сім'ядоль – 2-4 листків. • Оптимальна температура застосування
        +15...+25°С (нижній поріг +12°С). • Не рекомендовано застосовувати
        Мілафорт, коли бур'яни перебувають у стресовому стані (перепади денних і
        нічних температур, тривала повітряна і ґрунтова посуха), оскільки при
        уповільненому метаболізмі знижується швидкість переміщення діючих
        речовин до сайту дії. • При випаданні значної кількості опадів впродовж
        10 діб після обприскування можливе повторне відростання кореневої
        системи злакових бур'янів, що може призвести до зниження ефективності •
        У разі високого ступеня забур'яненості та переростання злакових бур'янів
        необхідно використовувати максимальну норму витрати – 1,25 л/га. • Під
        час тривалого зберігання можливе осідання і розшарування продукту, що не
        призводить до зниження ефективності препарату. Тому перед застосуванням
        обов'язково ретельно збовтати вміст каністри!
      </>
    ),
  },
  {
    productTitle: "Мілафорт Екстра",
    path: "/milafort-ekstra",
    src: Herbicides12,
    volume: "10 л",
    price: 127.23,
    currency: "USD",
    text: (
      <>
        Опис: Мілафорт Екстра - системний післясходовий гербіцид із ґрунтовою
        дією для захисту кукурудзи від однорічних і багаторічних злакових та
        дводольних видів бур'янів. Екстра захист кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Мезотріон, 75 г/л +
        нікосульфурон, 30 г/л.
        <span className="paragraph"></span> Препаративна форма: Масляна дисперсія.
        <span className="paragraph"></span> Переваги: • Знищення комплексу
        найпроблемніших видів злакових і дводольних бур'янів (пирій, осотів,
        латуків, лободи та падалиці соняшника і ріпака); • Контролює
        найпроблемніші бур'яни: пирій повзучий, гумай, види осотів, латук
        татарський; • Стримує наступні хвилі дводольних бур'янів та засмічувачів
        за рахунок ґрунтової дії; • Широке вікно внесення (2-10 листків
        кукурудзи); • Мінімальна фітотоксичність у пізніх фазах кукурудзи (8-10
        листків кукурудзи); • Контролює широкий спектр сегетальної рослинності у
        посівах кукурудзи; • Унікальна формуляція покращує проникність діючих
        речовин через восковий шар листків бур'яну.
        <span className="paragraph"></span> Механізм дії: Мезотріон – інгібітор
        синтезу фотосинтетезуючих пігментів, які необхідні для нормального
        проходження процесу фотосинтезу. Швидко поглинається листям та корінням
        і здатен рухатись як акропетально так і базипетально до точок росту,
        проявляючи ґрунтову активність. В результаті блокування фотосинтезуючих
        пігментів (каротину і хлорофілу) спостерігаються симптоми «бличингу» –
        поява слабких сходів білого або напівпрозорого кольору, які протягом 2
        тижнів гинуть. Нікосульфурон – інгібітор ацетолактатсинтази – ключового
        ензиму, необхідного для біосинтезу незамінних амінокислот (ізолейцин,
        лейцин і валін). Поглинається листям і всередині рослини швидко
        переміщується до місць найбільшої меристематичної активності – точок
        росту. В результаті цього уже через кілька годин припиняється поділ
        клітин, зупиняється ріст пагонів та кореневої системи бур'янів, а повна
        загибель наступає через 2-3 тижні. Поєднання двох діючих речовин дає
        можливість знищувати бур'яни, стійкі до гербіцидів групи 2,4-Д,
        сульфонілсечовин, триазинів, а також запобігає розвитку резистентності.
        Норма витрати робочого розчину: 150-250 л/га (залежно від технічних
        показників обприскувача).
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні дводольні та злакові бур'яни.
        <span className="paragraph"></span> Фаза застосування: • Оприскування
        посівів в період вегітації, у фазу від 2 до 8 листків культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для злакових видів – фаза 2-3 листки, для однорічних
        дводольних – фаза сім'ядоль – 2-4 листків, для багаторічних – за висоти
        до 15 см. • Оптимальна температура застосування +15...+25°C (нижній
        поріг +12°C), відносна вологість повітря від 65%, швидкість вітру – не
        більше 5 м/с у ранкові або вечірні години. • Для досягнення максимальної
        ефективності необхідно забезпечити рівномірне покриття листової поверхні
        робочим розчином. • Не рекомендується впродовж 7 діб до або після
        застосування препарату проводити міжрядний обробіток ґрунту! • Не
        рекомендовано застосовувати Мілафорт Екстра, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала повітряна
        і ґрунтова посуха), оскільки при уповільненому метаболізмі знижується
        швидкість переміщення діючих речовин до сайту дії. • При випаданні
        значної кількості опадів впродовж 10 діб після обприскування можливе
        повторне відростання кореневої системи злакових бур'янів, що може
        призвести до зниження ефективності. • У разі високого ступеня
        забур'яненості та переростання злакових і дводольних видів бур'янів, а
        також за наявності ваточника сирійського, необхідно збільшити норму
        витрати препарату до 2,0 л/га. • Під час зберігання можливе осідання і
        розшарування продукту, що не призводить до зниження ефективності
        препарату. Тому перед застосуванням варто обов'язково ретельно збовтати
        вміст каністри!
      </>
    ),
  },
  {
    productTitle: "Ореол Максі",
    path: "/oreol-maksi",
    src: Herbicides13,
    volume: "10 л",
    price: 94.98,
    currency: "USD",
    text: (
      <>
        Опис: Ореол Максі -системний післясходовий гербіцид для захисту польових
        культур від комплексу однорічних і багаторічних злакових видів бур'янів.
        Жодного шансу злаковим бур'янам.
        <span className="paragraph"></span> Діюча речовина: Хізалофоп-п-етил, 125
        г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Повністю знищує однорічні та
        багаторічні злакові бур'яни, падалицю зернових і кукурудзи; • Широке
        технологічне вікно застосування у посівах багатьох польових культур; •
        Висока ліпофільність сприяє швидкому проникненню продукту через воскові
        нальоти (за 1-2 год.); • Не потребує додаткового застосування
        ад'ювантів.
        <span className="paragraph"></span> Механізм дії: Хізалофоп-П-етил —
        інгібітор ферменту ацетил-КоА-карбоксилази, ензиму, що відповідає в
        рослинах за синтез жирних кислот. Швидко проникає через воскові нальоти,
        переміщується до точок росту, де в результаті блокування ферменту
        припиняється синтез фосфоліпідів, які необхідні для будівництва нових
        мембран та росту клітин. Характерні симптоми гербіцидної активності
        проявляються у вигляді пожовтіння центрального пагона та його легкого
        висмикування. Повна загибель однорічних бур'янів наступає через 7-10
        діб, а багаторічних — через 14-20 діб (залежно від фаз розвитку
        бур'янів). Норма витрати робочого розчину: 150-250 л/га (залежно від
        технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Ріпак озимий; • Соняшник; •
        Цукровий буряк.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорчні злакові
        бур'яни; • Багаторічні злакові бур'яни.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних злакових видів – фаза 3-4 листки, для
        багаторічних – за висоти до 15 см. Мінімальну норму витрати
        використовувати лише проти однорічних злакових у фазі до 2-х листків.
        Для контролю падалиці зернових і кукурудзи використовувати максимально
        дозволену норму для культури. • Оптимальна температура застосування
        +15...+25°C (нижній поріг +12°C), відносна вологість повітря від 65%,
        швидкість вітру - не більше 5 м/с у ранкові або вечірні години.
        Наявність роси може знижувати ефективність препарату і призводити до
        його стікання. • Для досягнення максимальної ефективності необхідно
        забезпечити рівномірне покриття листової поверхні робочим розчином. • Не
        рекомендовано застосовувати Ореол Максі, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала повітряна
        і ґрунтова посуха), оскільки при уповільненому метаболізмі знижується
        швидкість переміщення діючих речовин до сайту дії. • У разі високого
        ступеня забур'яненості та переростання злакових бур'янів необхідно
        збільшити норми витрати до максимальних. • Заборонено впродовж 7 діб до
        або після застосування препарату проводити міжрядний обробіток ґрунту.
        Не використовувати Ореол Максі у посівах соняшника після 4 пар листків,
        задля уникнення фітотоксичності.
      </>
    ),
  },
  {
    productTitle: "Основа",
    path: "/osnova",
    src: Herbicides14,
    volume: "20 л",
    price: 130.27,
    currency: "USD",
    text: (
      <>
        Опис: Основа - системний ґрунтовий гербіцид для досходового захисту
        просапних культур від комплексу однорічних злакових і деяких дводольних
        видів бур'янів. Ваша основа ґрунтового захисту.
        <span className="paragraph"></span> Діюча речовина: Ацетохлор, 900 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Довготривалий період
        захисної дії (до 2-х місяців) залежно від погодних умов; • Захист
        культур у гербокритичний період; • Відмінний баковий партнер для
        протидводольних ґрунтових гербіцидів; • Не проявляє післядії на наступні
        культури у сівозміні.
        <span className="paragraph"></span> Механізм дії: Ацетохлор — інгібітор
        довголанцюгових жирних кислот. Поглинається із верхнього шару ґрунту
        молодими проростками та корінням. Блокує процес поділу клітин та синтез
        білків. Це призводить по порушення ростових процесів та загибелі
        бур'янів. Норма витрати робочого розчину: 300-400 л/га (залежно від
        технічних показників обприскувача та наявності вологи в ґрунті).
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза; •
        Соя.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні злакові та
        дводольні бур'яни. Фаза використання; • До посіву, після посіву, але до
        сходів культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: • Перед
        застосуванням Основи потрібно забезпечити якісну підготовку ґрунту:
        поверхня повинна бути максимально вирівняною, без великих грудок та
        рослинних решток. • Обприскування ґрунту рекомендовано проводити до,
        відразу після сівби. • На легких ґрунтах норма витрати мінімальна – 1,5
        л/га, на важких – максимальна – 3,0 л/га. На піщаних ґрунтах або із
        вмістом гумусу менше 1% використовувати препарат не рекомендується. •
        Оптимальна температура застосування +12...+25°C (нижній поріг – +5°C),
        відносна вологість повітря від 65%, швидкість вітру – не більше 5 м/с у
        ранкові або вечірні години. • Не рекомендується вносити препарат Основа
        під час, або напередодні приморозків. • Обов'язково використовувати не
        жорстку, чисту, звільнену від сторонніх домішок і мулистих частинок
        воду, оскільки це може призвести до зниження ефективності препарату. •
        Протягом 30 діб після обприскування не рекомендується проводити
        боронування та міжрядний обробіток ґрунту, щоб не зруйнувати гербіцидний
        екран. • В умовах надмірної вологості ґрунту та понижених температур
        повітря можливий прояв фітотоксичності у вигляді гофрованості листя та
        перетяжки стебла біля поверхні ґрунту, в окремих випадках – часткова
        загибель посівів культур. • Симптоми фітотоксичності можуть посилювати:
        проливні дощі (через помірну розчинність та мобільність ацетохлору в
        ґрунті), нерівномірна глибина залягання насіння (при більшій глибині
        зростає ймовірність поглинання кореневою системою гербіцидів у випадку
        промивання) та низький рівень гумусу.
      </>
    ),
  },
  {
    productTitle: "Промекс",
    path: "/promeks",
    src: Herbicides15,
    volume: "20 л",
    price: 114.81,
    currency: "USD",
    text: (
      <>
        Опис: Промекс - системний ґрунтовий гербіцид для знищення комплексу
        дводольних і деяких злакових видів бур'янів у посівах польових культур.
        <span className="paragraph"></span> Діюча речовина: Прометрин, 500 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Контроль широкого спектру
        однорічних дводольних видів бур'янів (в т.ч. амброзії, лободи, щириці,
        гірчаків); • Тривалий період захисту посівів (від 30 до 60 діб); •
        Високоселективний до більшості культур; • Поглинається листям і
        корінням, забезпечуючи знищення як проростаючих так і вегетуючих
        бур'янів (в рекомендованих фазах); • Сумісний із іншими ґрунтовими
        гербіцидами для розширення спектру дії (Метризан, Пропазокс, Основа,
        Клодекс Про); • Відсутні обмеження в сівозміні.
        <span className="paragraph"></span> Механізм дії: Прометрин — системна діюча
        речовина, абсорбується корінням і листям. Проникаючи в рослини, блокує
        транспорт електронів, які приймають участь у процесі фотосинтезу. Як
        наслідок у бур'янів порушується енергетичний обмін, транспірація-тому
        протягом 2-3 тижнів вони гинуть.  Норма витрати робочого розчину:
        250-400 л/га (залежно від технічних показників обприскувача та наявності
        ґрунтової вологи).
        <span className="paragraph"></span> Культура: • Соняшник; • Горох овочевий;
        • Горох на зерно; • Соя.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні дводольні
        та злакові бур'яни.
        <span className="paragraph"></span> Фаза використання: • Обприскування
        ґрунту до появи сходів.
        <span className="paragraph"></span>  Рекомендації щодо застосування: • Перед
        застосуванням потрібно забезпечити якісну підготовку ґрунту: поверхня
        повинна бути максимально вирівняною, без великих грудок та рослинних
        решток. • Обприскування ґрунту рекомендовано проводити до, відразу після
        або протягом перших 3-х діб після сівби під час активної вегетації
        бур'янів: для злакових видів – фаза 3-4 листків, для однорічних
        дводольних – фаза сім'ядоль – 2-4 листків, а для багаторічних – за
        висоти до 15 см. • На легких ґрунтах норма витрати мінімальна, на важких
        - максимальна. На піщаних ґрунтах із вмістом гумусу менше 1%
        використовувати препарат не рекомендується. • Оптимальна температура
        застосування +12...+25°C (нижній поріг – +5°C ), відносна вологість
        повітря від 65%, швидкість вітру – не більше 5 м/с у ранкові або вечірні
        години. • Не рекомендується вносити препарат Пропазокс під час, або
        напередодні приморозків • Обов'язково використовувати не жорстку, чисту,
        звільнену від сторонніх домішок і мулистих частинок воду, оскільки це
        може призвести до зниження ефективності препарату. • Протягом 30 діб
        після обприскування не рекомендується проводити боронування та міжрядний
        обробіток ґрунту, щоб не зруйнувати гербіцидний екран. • В умовах
        надмірної вологості ґрунту та понижених температур повітря можливий
        тимчасовий прояв фітотоксичності у вигляді пожовтіння і появи
        коричневих, інколи бронзових плям. Але це не впливає на майбутній
        урожай.
      </>
    ),
  },
  {
    productTitle: "Сульфоніл",
    path: "/sulfonil",
    src: Herbicides16,
    volume: "0,5 кг",
    price: 40.07,
    currency: "USD",
    text: (
      <>
        Опис: Сульфоніл — гранульований системний післясходовий гербіцид для
        захисту кукурудзи від однорічних і багаторічних злакових та деяких
        дводольних видів бур'янів.
        <span className="paragraph"></span> Діюча речовина: Нікосульфурон, 750 г/кг.
        <span className="paragraph"></span> Препаративна форма: гранули, які
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Висока селективність щодо
        культури незалежно від фази застосування (3-10 листків); • Знищення
        комплексу шкодочинних злакових видів бур'янів (пирій, мишій (види),
        плоскуха звичайна, метлюг, вівсюг та ін.); • Не персистентний в ґрунті,
        тому не проявляє післядії на наступні культури; • Висока концентрація та
        препаративна форма забезпечують зручність у логістиці та зберіганні; •
        Ідеальний баковий партнер для протидводольних гербіцидів у посівах
        кукурудзи.
        <span className="paragraph"></span> Механізм дії: Нікосульфурон — інгібітор
        ацетолактатсинтази – ключового ензиму, необхідного для біосинтезу
        незамінних амінокислот (ізолейцин, лейцин і валін). Поглинається листям
        і всередині рослини швидко переміщується до місць найбільшої
        меристематичної активності — точок росту. В результаті цього уже через
        кілька годин припиняється поділ клітин, зупиняється ріст пагонів та
        кореневої системи бур'янів, а повна загибель наступає через 2-3 тижні.
        Норма витрати робочого розчину: 150-250 л/га (залежно від технічних
        показників обприскувача).
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні злакові, деякі дводольні бур'яни.
        <span className="paragraph"></span> Фаза використання: • 3...10 листків
        кукурудзи.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних злакових видів – фаза 3-4 листки, для
        багаторічних – за висоти до 10-15 см, а для однорічних дводольних – у
        фазі сім'ядоль – 2-4 листків. • Оптимальна температура застосування
        +15...+25°C (нижній поріг +12°C), відносна вологість повітря від 65%,
        швидкість вітру - не більше 5 м/с у ранкові або вечірні години. • Для
        досягнення максимальної ефективності необхідно забезпечити рівномірне
        покриття листової поверхні робочим розчином. Для цього обов'язково
        використовувати ад'ювант Агропав Екстра у концентрації 0,1%. • Не
        рекомендовано застосовувати Сульфоніл, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала повітряна
        і ґрунтова посуха), оскільки при уповільненому метаболізмі знижується
        швидкість переміщення діючих речовин до сайту дії. • При випаданні
        значної кількості опадів впродовж 10 діб після обприскування можливе
        повторне відростання кореневої системи злакових бур'янів, що може
        призвести до зниження ефективності.
      </>
    ),
  },
  {
    productTitle: "Танаїс",
    path: "/tanais",
    src: Herbicides17,
    volume: "0,5 кг",
    price: 47.56,
    currency: "USD",
    text: (
      <>
        Опис: Танаїс - гранульований системний післясходовий гербіцид для
        захисту кукурудзи від однорічних і багаторічних злакових та деяких
        дводольних видів бур’янів.
        <span className="paragraph"></span> Діюча речовина: Римсульфурон 250 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, які
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Широкий спектр
        контрольованих бур'янів, як злакових так і дводольних; • Контролює
        проблемні види злакових бур'янів: пирій, гумай, пальчатку та ін; • Не
        персистентний в ґрунті, тому не проявляє післядії на наступні культури;
        • Ідеальний баковий партнер для протидводольних гербіцидів у посівах
        кукурудзи.
        <span className="paragraph"></span> Механізм дії: Римсульфурон — інгібітор
        ацетолактатсинтази — ключового ензиму, необхідного для біосинтезу
        незамінних амінокислот (ізолейцин, лейцин і валін). Поглинається листям
        і всередині рослини швидко переміщується до місць найбільшої
        меристематичної активності — точок росту. В результаті цього уже через
        кілька годин припиняється поділ клітин, зупиняється ріст пагонів та
        кореневої системи бур'янів, а повна загибель наступає через 2-3 тижні.
        Норма витрати робочого розчину: 200-300 л/га (залежно від погодних умов
        та технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та
        багаторічні злакові та деякі дводольні.
        <span className="paragraph"></span> Рекомендації щодо використання: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: для однорічних злакових видів – фаза до 3-х листків, для
        багаторічних – за висоти 15-25 см, а для однорічних дводольних – у фазі
        сім'ядоль – 2-4 листків. • Оптимальна температура застосування
        +15...+21°C (нижній поріг +12°C), відносна вологість повітря від 65%,
        швидкість вітру – не більше 5 м/с у ранкові або вечірні години.
        Наявність роси може знизити ефективність препарату. • Уникати обробок
        під час активної інсоляції – це може спричинити розпад діючої речовини
        під дією ультрафіолету до проникнення всередину бур'янів. • Для
        досягнення максимальної ефективності необхідно забезпечити рівномірне
        покриття листової поверхні робочим розчином. Для цього обов'язково
        використовувати ад'ювант Агропав Екстра у концентрації 0,1%. • Не
        рекомендовано застосовувати, коли бур'яни перебувають у стресовому стані
        (перепади денних і нічних температур, тривала повітряна і ґрунтова
        посуха), оскільки при уповільненому метаболізмі знижується швидкість
        переміщення діючих речовин до сайту дії. • При випаданні значної
        кількості опадів впродовж 10 діб після обприскування можливе повторне
        відростання кореневої системи злакових бур'янів, що може призвести до
        зниження ефективності.
      </>
    ),
  },
  {
    productTitle: "Толазин",
    path: "/tolasin",
    src: Herbicides18,
    volume: "20 л",
    price: 145.83,
    currency: "USD",
    text: (
      <>
        Опис: Толазин - комбінований ґрунтовий та післясходовий гербіцид
        системної дії.
        <span className="paragraph"></span> Діюча речовина: СЕ, s-метолахлор, 312,5
        г/л + тербутилазин, 187,5 г/л .<span className="paragraph"></span>{" "}
        Препаративна форма: Суспензійна емульсія.
        <span className="paragraph"></span> Переваги: • Широкий спектр контролю
        однорічних видів бур'янів; • Період захисної дії – 6-8 тижнів; • Стримує
        появу нової хвилі бур'янів за рахунок ґрунтової дії препарату; •
        Гнучкість у застосуванні - можливість використання на кукурудзі і
        ґрунтового досходово, так і післясходово; • Відсутність резистентності і
        післядії препарату; • Відсутня фітотоксичність на культурні рослини при
        дотриманні рекомендацій; • Можливість застосування на батьківських
        формах кукурудзи.
        <span className="paragraph"></span> Механізм дії: Толазин – гербіцид
        системної дії, інгібітор процесів біосинтезу і фотосинтезу. Дві діючі
        речовини переважно контролюють однорічні бур'яни, які
        проростають. Тербутилазин – інгібітор фотосинтезу – поглинається як
        корінням (при грунтовому застосуванні), так і листям бур'янів (при
        післясходовому внесенні). Переміщуючись по ксилемі рослини, блокує
        процес фотосинтезу, у результаті чого чутливі бур'яни жовтіють і гинуть.
        Краще контролює дводольні бур'яни. S-метолахлор – інгібітор процесів
        біосинтезу ліпідів та жирних кислот – діє на етапі проростання бур'янів,
        порушуючи процеси поділу клітин і блокуючи початкові стадії мітозу. У
        результаті бур'яни гинуть ще в момент проростання. При застосуванні під
        час вегетації гербіцид поглинається проростками (особливо колеоптилем) і
        частково кореневою системою, переміщується по рослині, що призводить до
        загибелі бур'янів. Завдяки комбінації двох діючих речовин відсутня
        резистентність, значно подовжений гербіцидний ефект та ширший спектр
        контрольованих бур'янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л / га.
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза; •
        Соя.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні злакові та
        дводольні бур'яни.   <span className="paragraph"></span> Рекомендації щодо
        застосування: • Технологію обробки з передпосівною заробкою препарату
        доцільно проводити, коли очікується посушлива погода або якщо є загроза
        вітрової ерозії. • Після появи сходів препарат можна застосовувати на
        кукурудзі у фазі 3-5 листків. • Важливим моментом при післясходовому
        внесенні є фаза розвитку бур'янів. На момент внесення гербіциду
        однорічні злакові бур'яни повинні бути у фазі 1-2 листків, однорічні
        дводольні – від фази сім'ядоль до фази 2 справжніх листків. •
        Застосування препарату за несприятливих погодних умов (приморозки,
        висока температура повітря, посуха) знижує його ефективність.
      </>
    ),
  },
  {
    productTitle: "Толазин Ультра",
    path: "/tolasin-ultra",
    src: Herbicides19,
    volume: "20 л",
    price: 188.51,
    currency: "USD",
    text: (
      <>
        Опис: Толазин Ультра - комбінований ґрунтовий та післясходовий гербіцид
        системної дії з підвищеною концентрацією діючих речовин для контролю
        злакових і дводольних бур'янів в посівах польових культур. Подвійний
        удар сконцентрованою силою.   <span className="paragraph"></span>
        Діюча речовина: S-метолахлор 468 г/л; тербутилазин - 281 г/л.  {" "}
        <span className="paragraph"></span> Препаративна форма: Суспензійна
        емульсія.   <span className="paragraph"></span> Переваги: • Нижча норма
        застосування за рахунок підвищеної концентрації діючих речовин —
        економія коштів на логістиці; • Широкий спектр контролю однорічних
        злакових та дводольних видів бур'янів; • Період захисної дії до 6-8
        тижнів; • Гнучкість у застосуванні — можливість використання на
        кукурудзі як до сходів, так і у фази 3-5 листків кукурудзи; • Можливість
        застосування на батьківських формах кукурудзи; • Не впливає на наступні
        культури в сівозміні.   <span className="paragraph"></span>
        Механізм дії: S-метолахлор — інгібітор процесів біосинтезу ліпідів та
        жирних кислот — діє на етапі проростання бур'янів, порушуючи процеси
        поділу клітин і блокуючи початкові стадії мітозу. У результаті бур'яни
        гинуть ще в момент проростання. При застосуванні під час вегетації
        гербіцид поглинається проростками (особливо колеоптилем) і частково
        кореневою системою, переміщається по рослині, що призводить до загибелі
        бур'янів. Тербутилазин — інгібітор фотосинтезу — поглинається як
        корінням (при ґрунтовому застосуванні), так і листям бур'янів (при
        післясходовому внесенні). Переміщуючись по ксилемі рослини, блокує
        процес фотосинтезу, у результаті чого чутливі бур'яни жовтіють і гинуть.
          <span className="paragraph"></span>
        Норма витрати робочого розчину: 200-300 л/га.  {" "}
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза.
        Цільовий об’єкт: • Однорічні злакові та дводольні бур'яни.  {" "}
        <span className="paragraph"></span>
        Фаза внесення: • Обприскування ґрунту до появи сходів культури; •
        Обприскування ґрунту до появи сходів культури або у фазі 3-5 листків.  {" "}
        <span className="paragraph"></span> Рекомендації щодо застосування: • Після
        появи сходів кукурудзи препарат можна застосовувати у фазі 3-5 листків.
        • Важливим моментом при післясходовому внесенні є фаза розвитку
        бур'янів. На момент внесення гербіциду однорічні злакові бур'яни повинні
        бути не більше фази 1-2 листків, однорічні дводольні – від фази
        сім'ядоль до фази 2 справжніх листків. • Застосування препарату за
        несприятливих погодних умов (приморозки, висока температура повітря,
        посуха) знижує його ефективність. • Після появи сходів препарат можна
        застосовувати на кукурудзі у фазі 3-5 листків. • Під час зберігання
        продукту можливе розшарування, яке не впливає на його ефективність.
        Перед внесенням обов'язково ретельно збовтати вміст каністри! • Найвищу
        ефективність Толазин Ультра проявляє за умов внесення на вологий грунт з
        дрібногрудкуватою структурою з мінімальним розривом між посівом і
        обприскуванням. • Промивання препарату при надмірній кількість опадів
        або потрапляння гербіциду на поверхню листя разом із краплями дощу може
        призвести до пошкодження рослин сої та соняшнику.
      </>
    ),
  },
  {
    productTitle: "Трайдент",
    path: "/traident",
    src: Herbicides20,
    volume: "0,5 кг",
    price: 66.62,
    currency: "USD",
    text: (
      <>
        Опис: Трайдент - трикомпонентний гербіцид для контролю дводольних
        бур'янів у посівах зернових культур.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил 428
        г/кг, флорасулам 160 г/кг, флуметсулам 137 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, які
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Контроль основних дводольних
        бур'янів у посівах пшениці і ячменю; • Контроль лободи білої, видів
        осотів, пасльону чорного та підмаренника чіпкого (до 14 мутовок).
        Пригнічення ваточника сирійського, молочая лозяного; • Відсутні
        обмеження у сівозміні; • Контроль падалиці соняшника стійкого до
        імідазолінонів та сульфонілсечовин і падалиці ріпаку класичного; •
        Відсутність фітотоксичної дії на культуру при дотриманні регламенту
        застосування, не потребує антидоту; • Можливість застосування, як
        весною, так і в осінній період вегетації; • Широке вікно застосування.
        <span className="paragraph"></span> Механізм дії: Трайдент — комбінований
        системний гербіцид, діючі речовини якого відносяться до двох різних
        хімічних підгруп: сульфонілсечовини і
        триазолпірамідини. Трайдент впливає на фермент АЛС, внаслідок чого
        блокується синтез амінокислот лейцин, ізолейцин і валін. Це призводить
        до блокування поділу клітин у точці росту бур'янів і їх загибелі. Норма
        витрати робочого розчину: 150-300 л/га (залежно від технічних показників
        обприскувача).
        <span className="paragraph"></span> Культура: • Пшениця озима, ячмінь
        озимий; • Пшениця яра, ячмінь ярий.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні та деякі
        багаторічні дводольні бур'яни, падалиця соняшника, стійкого до
        трибенурон-метилу та імідазолінів.
        <span className="paragraph"></span> Спосіб обробки: • Обприскування під час
        вегетації від початку кущіння до прапорового аркуша культури включно.
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: у ранні фази розвитку для однорічних видів, і за висоти до 15
        см – для багаторічних. • Оптимальна температура застосування +8...+25°С.
        Відносна вологість повітря від 65%, швидкість вітру - не більше 5 м/с у
        ранкові або вечірні години. Відсутність опадів протягом 3-4 годин після
        внесення. • Може застосовуватись при +5°С, але швидкість дії
        сповільнюється порівняно з оптимальними умовами для внесення. • Для
        досягнення максимальної ефективності необхідно забезпечити рівномірне
        покриття листової поверхні робочим розчином. • Обов'язково рекомендовано
        додавати ад'ювант Агропав Екстра у концентрації 0,05%-0,1%. • Не
        рекомендовано застосовувати Трайдент, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала посуха),
        оскільки при уповільненому метаболізмі знижується швидкість переміщення
        діючих речовин до сайту дії.
      </>
    ),
  },
  {
    productTitle: "Унісулам",
    path: "/unisulam",
    src: Herbicides21,
    volume: "0,5 кг",
    price: 17.44,
    currency: "USD",
    text: (
      <>
        Опис: Унісулам - системний післясходовий гербіцид для контролю
        однорічних та деяких багаторічних дводольних бур'янів в посівах зернових
        колосових і кукурудзи.
        <span className="paragraph"></span> Діюча речовина: КС, флорасулам 100 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Контроль підмаренника
        чіпкого навіть на пізніх стадіях його розвитку (14 мутовок); • Контроль
        падалиці соняшника усіх видів в т.ч. стійкої до трибенурон-метилу та
        імідазолінонів; • Ідеальний баковий партнер; • Відсутня фітотоксичність
        при дотриманні регламенту застосування; • Придатний до осіннього
        застосування; • Швидко розпадається в грунті – відсутність обмежень на
        наступні культури у сівозміні; • Низька норма застосування.
        <span className="paragraph"></span> Механізм дії: Флорасулам інгібітор
        ацетолактатсинтази – ключового ензиму, необхідного для біосинтезу
        незамінних амінокислот (ізолейцин, лейцин і валін). проникає в рослину
        через листя і коріння. Всередині рослини швидко переміщується до місць
        найбільшої меристематичної активності – точок росту. В результаті цього
        уже через кілька годин припиняється поділ клітин, ріст пагонів та
        кореневої системи бур'янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-300
        л/га (залежно від технічних показників обприскувача).
        <span className="paragraph"></span> Культура: • Пшениця озима; • Ячмінь.
        <span className="paragraph"></span> Спектр дії: • Однорічні та деякі
        багаторічні дводольні бур'яни. 
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Обприскування рекомендовано проводити під час активної вегетації
        бур'янів: у ранні фази розвитку для однорічних видів, і за висоти до 15
        см – для багаторічних. • Оптимальна температура застосування +8...+25°C.
        Відносна вологість повітря від 65%, швидкість вітру - не більше 5 м/с у
        ранкові або вечірні години. Відсутність опадів протягом 3-4 годин після
        внесення. • Може застосовуватись при +5°C, але швидкість дії
        сповільнюється порівняно з оптимальними умовами для внесення. • Для
        досягнення максимальної ефективності необхідно забезпечити рівномірне
        покриття листової поверхні робочим розчином. • Обов'язково рекомендовано
        додавати ад'ювант Агропав Екстра у концентрації 0,05%-0,1%. • Не
        рекомендовано застосовувати Унісулам, коли бур'яни перебувають у
        стресовому стані (перепади денних і нічних температур, тривала посуха),
        оскільки при уповільненому метаболізмі знижується швидкість переміщення
        діючих речовин до сайту дії.  
      </>
    ),
  },

  // второй файл

  {
    productTitle: "Аргумент",
    path: "/argument",
    src: Herbicides22,
    volume: "20 л",
    price: 82.2,
    currency: "USD",
    text: (
      <>
        Опис: Аргумент - неселективний гербіцид системної дії для знищення
        однорічних та багаторічних злакових та дводольних бур'янів.
        Застосовується як десикант сільськогосподарських культур.
        <span className="paragraph"></span> Діюча речовина: Ізопропіламінна сіль
        гліфосату, 480 г/л; у кислотному еквіваленті, 360 г/л.
        <span className="paragraph"></span> Препаративна форма: Водний розчин.
        <span className="paragraph"></span> Переваги: • Завдяки системній дії
        препарат знищує не тільки надземну частину рослин, але й їхню кореневу
        систему, що надзвичайно актуально при боротьбі з багаторічними
        коренепаростковими бур’янами. •  Препарат АРГУМЕНТ® дозволяє ефективно
        боротись як з однорічними, так і з багаторічними бур’янами на полях,
        призначених під посів; тим самим досягається збереження вологи і
        забезпечення дружних та своєчасних сходів культури.  • Системне
        застосування препарату дозволяє значно зменшити загальну забур’яненість
        поля і, як наслідок, звести до мінімуму механічні обробки ґрунту.  •
        Застосування препарату як десиканта дозволяє значно прискорити та
        полегшити процес збирання сільськогосподарських культур, зменшити втрати
        врожаю за рахунок поширення хвороб та впливу несприятливих погодних
        умов, в значній мірі заощадити паливо на проведення робіт по збиранню.
        <span className="paragraph"></span> Механізм дії: Діюча речовина
        поглинається надземною частиною рослини і розноситься по всіх органах,
        порушуючи процеси білкового синтезу. Блокування фотосинтезу та дихання у
        рослин, призво­дить до повної їх загибелі, в тому числі і кореневої
        системи.
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-220
        л/га.
        <span className="paragraph"></span> Культура: • Плодові та виноградники; •
        Поля, призначені під посіви кукурудзи, цукрових буряків, картоплі,
        баштанових, соняшникових; • Пари; • Картопля; • Землі
        несільськогосподарського користування (смуги відчуження ліній
        електропередач, газо- та нафтопроводів, узбіччя доріг, залізничні
        насипи).
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні та
        багаторічні бур’яни.
        <span className="paragraph"></span> Фаза розвитку бур’янів: • Висота більше
        10-15 см
        <span className="paragraph"></span> Рекомендації щодо застосування: •
        Оптимальна для обробки фаза розвитку бур’янів: багаторічні злакові – 4 -
        5 листків (10-15 см), багаторічні дводольні – розетка 10 - 20 см,
        однорічні злакові – за довжини листків не менше 5 см, однорічні
        дводольні – 2 - 3-х справжніх листків.
        <span className="paragraph"></span> Внесення препарату: Препарат вносять як
        наземними обприскувачами, так і за допомогою авіації по вегетуючим
        бур'янам, з нормою витрати 2-8 л/га в залежності від видового складу
        бур'янів та їх фази розвитку. Для ефективного знищення бур'янів
        необхідно виключити всі механічні роботи (культивація, дискування,
        оранка) впродовж 12-14 днів до та після внесення препарату.
        <span className="paragraph"></span>■ Як гербіцид навесні Препарат вносять на
        полях, призначених під посів сільськогосподарських культур за 2 тижні до
        посіву при масовій появі бур'янів з нормою 2 - 5 л/га. На посівах
        картоплі для боротьби з однорічними бур'янами препарат застосовують з
        нормою 2 л/га не менше як за 2 дні до появи сходів культурних рослин.
        <span className="paragraph"></span>■ Як гербіцид восени Препарат вносять з
        нормою 2 - 5 л/га після збирання попередника при масовому відростанні
        бур'янів. Для ефективного знищення бур'янів необхідно виключити
        механічні обробки ґрунту впродовж 2 тижнів до та після внесення
        препарату.
        <span className="paragraph"></span>■ Як десикант Препарат застосовують для
        десикації соняшнику та зернових культур з нормою витрати 3 л/га. Крім
        того, в світовій практиці препарати на основі діючої речовини
        ізопропіламінна сіль гліфосату широко застосовуються для десикації
        зернобобових культур, ріпаку та насіннєвих посівів деяких овочевих
        культур (морква, буряки та інші).
      </>
    ),
  },
  {
    productTitle: "Адвокат",
    path: "/advokat",
    src: Herbicides23,
    volume: "5 л",
    price: 101.77,
    currency: "USD",
    text: (
      <>
        Опис: Адвокат - системний гербіцид ґрунтової дії для боротьби з
        однорічними дводольними та злаковими бур’янами в посівах овочевих
        культур.
        <span className="paragraph"></span> Діюча речовина: Метрибузин, 600 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Широкий спектр бур’янів, які
        можна проконтролювати в посівах сільськогосподарських культур; •
        Тривалий захисний ефект — знищення сходів бур’янів упродовж декількох
        тижнів; • Можливість застосування як до сходів, так і після появи сходів
        культурних рослин; • Зручна препаративна форма полегшує приготування
        робочого розчину препарату.
        <span className="paragraph"></span> Механізм дії: Діюча речовина препарату
        надходить в рослини бур'янів переважно через кореневу систему та
        частково через надземну вегетативну масу. По рослині поширюється з
        висхідним током речовин (по ксилемі). Метрибузин є інгібітором
        фотосинтезу системи II. Чутливі рослини бур'янів гинуть унаслідок
        порушення процесу фотосинтезу. На чутливі рослини бур’янів препарат не
        діє, доки в проростках цих рослин не починається процес фотосинтезу.
        <span className="paragraph"></span> Норма витрати робочого розчину: При
        досходовому внесенні препарату 300 - 400 л/га. При післясходовому
        внесенні препарату 200 - 300 л/га.
        <span className="paragraph"></span> Культура: • Картопля; • Томати
        безрозсадні; • Томати розсадні; • Соя; • Кукурудза одноразове внесення;
        • Кукурудза дворазове внесення.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні дводольні
        та злакові бур’яни.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять як досходово, за допомогою наземних оприскувачів одразу після
        посіву до появи сходів культурних рослин, так і після появи сходів
        культурних рослин коли є небезпека появи нової хвилі бур'янів. Не
        використовувати препарат на легких ґрунтах, що містять гумусу менше 2%
      </>
    ),
  },
  {
    productTitle: "Герб 900",
    path: "/gerb-900",
    src: Herbicides24,
    volume: "20 л",
    price: 128.73,
    currency: "USD",
    text: (
      <>
        Опис: Герб 900 - селективний ґрунтовий гербіцид для боротьби з
        найпоширенішими однорічними злаковими та деякими двосім'ядольними
        бур'янами в посівах сільськогосподарських культур.
        <span className="paragraph"></span> Діюча речовина: Ацетохлор, 900 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span> Переваги: • Препарат знищує проростаючи
        бур’яни, створюючи тим самим максимально сприятливі умови для культурних
        рослин на початкових етапах росту та розвитку; • Тривала активність
        препарату в ґрунті забезпечує знищення нових хвиль проростаючи бур’янів.
        Період захисної дії — від 8 до 11 тижнів; • Відсутність негативного
        впливу на наступні культури сівозміни.
        <span className="paragraph"></span> Механізм дії: Діюча речовина препарату
        погли­нається з вологою ґрунту проростками бур'янів та первинними
        коренями, інгібує процеси синтезу білку, а також порушує процеси поділу
        клітин, що призводить до пригнічення та загибелі рослин. В рослини
        однорічних злакових бур'янів препарат, як правило, надходить через
        верхівку (епікотиль), а у рослини однорічних дводольних бур'янів
        пре­парат надходить через первинні корені.
        <span className="paragraph"></span> Норма витрати робочого розчину: 300 -
        400 л/га..
        <span className="paragraph"></span> Культура: • Кукурудза; • Соняшник; •
        Соя.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові та
        дводольні бур’яни.
        <span className="paragraph"></span> Застереження: Застосування препарату
        Герб 900 після появи сходів культури, або безпосередньо перед появою
        сходів призводить до токсикації культурних рослин. В окремих випадках
        можлива їх загибель.{" "}
      </>
    ),
  },
  {
    productTitle: "Грізний",
    path: "/grizniy",
    src: Herbicides25,
    volume: "0,25 кг",
    price: 12,
    currency: "USD",
    text: (
      <>
        Опис: Грізний - післясходовий системний гербіцид для боротьби з широким
        спектром двосім'ядольних бур'янів у посівах зернових колосових культур.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 750
        г/кг.
        <span className="paragraph"></span> Препаративна форма: Вододисперсні
        гранули.
        <span className="paragraph"></span> Переваги: • Зручна формуляція та низька
        норма витрати препарату на 1 га роблять його надзвичайно зручним для
        використання у виробничих умовах, при транспортуванні та зберіганні. •
        Ефективне знищення таких стійких бур’янів, як підмаренник чіпкий, осоти,
        ромашка непахуча, мак дикий та інші. • Відсутність післядії препарату на
        наступні культури сівозміни. • Мінімальний негативний вплив на культурні
        рослини дає можливість застосовувати препарат від фази 2–3 листків
        культури. • Препарат ефективно працює вже при температурах від +5 °С ,
        що значно розширює період його використання як в осінній період, так і
        навесні. • Сумісність препарату з іншими пестицидами робить його
        ідеальним партнером для бакових сумішей в комплексних системах захисту
        рослин.
        <span className="paragraph"></span> Механізм дії: Діюча речовина, що входять
        до складу препарату, проникає в рослину через її надземну частину,
        рухається через листя до точки росту, блокує фермент ацетолактатсинтазу,
        який необхідний для синтезу незамінних амінокислот - валіну, лейцину та
        ізолейцину. Внаслідок припинення процесів поділу клітин оброблені
        рослини зупиняються в рості.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200 -
        300 л/га. При високій густоті стояння рослин норма витрати робочого
        розчину збільшити до 400 л/га. Термін використання готового робочого
        розчину 24 години.
        <span className="paragraph"></span> Культура: • Ячмінь ярий; • Пшениця
        озима.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні та
        багаторічні дводольні бур’яни, у т.ч. стійкі до 2,4-Д.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вноситься наземним обприскуванням у фазі від 2-3 листків до входу в
        трубку ярого ячменю та до появи прапорцевого листка в озимої пшениці.
        Найвища ефективність дії препарату спостерігається на ранніх етапах
        росту та розвитку бур’янів: 3-6 справжніх листків для однорічних
        дводольних, фаза розетки для багаторічних та фаза 3-4 кільця — для
        підмаренника чіпкого.{" "}
      </>
    ),
  },
  {
    productTitle: "Грізний Експерт",
    path: "/grizniy-ekspert",
    src: Herbicides26,
    volume: "0,25 кг",
    price: 13.88,
    currency: "USD",
    text: (
      <>
        Опис: Грізний Експерт - гербіцид системної дії, спеціально призначений
        для боротьби з одно­річними та багаторічними дводольними бур'янами в
        посівах соняшника, після появи сходів культурних рослин.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 750 г
        /кг.
        <span className="paragraph"></span> Препаративна форма: Вододисперсні
        гранули.
        <span className="paragraph"></span> Переваги: • Можливість боротьби з
        однорічними та багаторічними дводольними бур’янами в період після появи
        сходів соняшника. • Єдиний інструмент для знищення осотів в посівах
        соняшника. • Широке вікно застосування — у період від 2 до 6 листків у
        культурних рослин. • Гнучкі норми та схеми застосування препарату у
        відповідності до типу забур’яненості поля, а також кількості бур’янів.
        <span className="paragraph"></span> Механізм дії: Гербіцид Грізний
        Експерт® застосовується тільки на посівах соняшника, який стійкий до дії
        даного гербіциду. Діюча речовина проникає в рослину через її надземну
        частину, переміщується в зони апікальних меристем рослини, де блокує
        процеси поділу клітин в рослинах чутливих бур'янів. В результаті цього
        ріст бур'янів припиняється через декілька годин після внесення
        препарату, згодом рослини бур'янів гинуть.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200 -
        300 л/га.
        <span className="paragraph"></span> Культура: • Соняшник.
        <span className="paragraph"></span> Цільові об’єкти: • Однорічні та
        багаторічні дводольні бур’яни.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять за допомогою наземних обприскувачів тільки на гібридах
        соняшнику, які стійкі до дії даного гербіциду.{" "}
      </>
    ),
  },
  {
    productTitle: "Декабрист 480",
    path: "/dekabrist-480",
    src: Herbicides27,
    volume: "5 л",
    price: 67.39,
    currency: "USD",
    text: (
      <>
        Опис: Декабрист - післясходовий гербіцид системної дії для знищення
        однорічних та багаторічних дводольних бур’янів в посівах
        сільськогосподарських культур.
        <span className="paragraph"></span> Діюча речовина: Дикамба, 480 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Контролює близька 200 видів
        дводольних бур’янів. • Знищує кореневу систему багаторічних дводольних
        бур’янів, в тому числі таких злісних, як березка польова та види осотів.
        • За рахунок синергізму є ідеальним партнером для бакових сумішей
        гербіцидів. • Відсутність фітотоксичності для культурних рослин при
        дотриманні регламенту застосування. • Не накладає обмежень щодо
        висівання наступних культур сівозміни.
        <span className="paragraph"></span> Механізм дії: Препарат проникає через
        надземну частину рослин та частково через корені, швидко по­ширюється по
        всій рослині. Знищує не тільки вегетативну масу, але й кореневу систему
        багато­річних дводольних бур'янів. Препарат одночасно впливає на
        декілька процесів в організмі рослини: порушує процеси синтезу білків,
        пригнічує фотосинтез, порушує процеси поділу та росту клітин рослини.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–400
        л/га. У ситуаціях із щільним стоянням рослин норма витрати робочого
        розчину має становити не менше ніж 300 л/га.
        <span className="paragraph"></span> Культура: • Пшениця озима; • Ячмінь
        ярий; • Кукурудза.
        <span className="paragraph"></span> Рекомендації щодо застосування:
        Максимальна ефективність дії препарату спостерігається при внесенні його
        під час активного росту бур’янів: фаза 2-4 листки для однорічних
        дводольних, фаза розетки (для осотів) та довжина пагонів до 10-15 см для
        березки польової.
      </>
    ),
  },
  {
    productTitle: "Євро-Ленд",
    path: "/evro-lend",
    src: Herbicides28,
    volume: "5 л",
    price: 61.68,
    currency: "USD",
    text: (
      <>
        Опис: Євро-Ленд - гербіцид системної та ґрунтової дії для знищення
        однорічних дводольних та злакових бур’янів у посівах соняшнику (сорти,
        стійкі до речовин групи імідазолінонів).
        <span className="paragraph"></span> Діюча речовина: Імазамокс, 33 г/л,
        імазапір, 15 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Системна та ґрунтова дія для
        знищення однорічних дводольних та злакових бур’янів в посівах соняшнику
        (сорти, стійкі до речовин групи імідазолінонів). • Боротьба з практично
        усіма расами вовчка в посівах соняшника. • Довготривалий період дії та
        контроль нових сходів бур’янів в посівах соняшника.
        <span className="paragraph"></span> Механізм дії: Діючі речовини препарату
        проникають в бур'яни як через пагони і листя, так і через кореневу
        систему з ґрунту. Транспортуються по флоемі та ксилемі до точок росту,
        де порушують процес синтезу амінокислот (валіну, лейцину, ізолейцину),
        що призводить до затримки розвитку, від­мирання точок росту подальшої
        загибелі чутливих бур'янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га. 
        <span className="paragraph"></span> Культура: • Соняшник .
        <span className="paragraph"></span>
        Цільові об’єкти: • Однорічні дводольні та злакові бур’яни.
        <span className="paragraph"></span> Час обробки: • Обприскування у фазі 4
        листків культури та на початкових стадіях розвитку бур’янів.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять лише наземним обприскуванням, під час обробки слід уникати
        перекриття смуг внесення. За умов підвищеної густоти стояння рослин
        та/або великій кількості рослинних решток, норму витрати робочої рідини
        слід збільшити.
      </>
    ),
  },
  {
    productTitle: "Капрал",
    path: "/kapral",
    src: Herbicides29,
    volume: "20 л",
    price: 114.52,
    currency: "USD",
    text: (
      <>
        Опис: Капрал - гербіцид ґрунтової дії для боротьби з однорічними
        дводольними та деякими злаковими бур’янами в посівах
        сільськогосподарських культур. 
        <span className="paragraph"></span> Діюча речовина: Прометрин, 500 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Широкий спектр однорічних
        дводольних бур’янів, що знищуються. • Довготривала ґрунтова дія. •
        Ідеальний партнер для бакових сумішей. • Безпечний для культурних рослин
        за умов дотримання регламенту застосування. • Відсутність післядії на
        наступні культури сівозміни.
        <span className="paragraph"></span> Механізм дії: Препарат поглинається
        проростками та коренями бур'янів, а також надземною масою бур'янів, що
        зійшли. Діюча речовина блокує процес фотосинтезу у чутливих бур'янів, а
        також утворює сполуки, які руйнують мембрани клітин рослини. Внаслідок
        цього відбувається загибель бур'янів. Препарат вносять наземним
        обприскуванням ґрунту до посіву, під час посіву або безпосе­редньо після
        посіву.
        <span className="paragraph"></span> Норма витрати робочого розчину: 300-400
        л/га.
        <span className="paragraph"></span> Культура: • Морква; • Горох на зерно; •
        Горох овочевий; • Картопля; • Коріандр; • Петрушка, кріп, селера; •
        Квасоля, віка; • Чина, кормові боби; • Кукурудза з підсівом соняшника.
        <span className="paragraph"></span> Спосіб та час обробки: • Обприскування
        ґрунту до висівання, до сходів або у фазі 2 справжніх листків культури;
        • До появи сходів культурних рослин.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять обприскування грунту до посіву, під час посіву або безпосередньо
        після посіву.
        <span className="paragraph"></span> ЗАСТЕРЕЖЕННЯ: • Препарат впливає лише на
        деякі види злакових бур’янів. Тому для ефективної боротьби з однорічними
        злаковими бур’янами потрібно застосовувати грамініциди; • Не
        рекомендовано проводити обробіток ґрунту після внесення препарату,
        оскільки це може призвести до зниження ефективності його дії на бур’яни.
        • Під час внесення препарату необхідно уникати перекриття.
      </>
    ),
  },
  {
    productTitle: "Конвой",
    path: "/konvoy",
    src: Herbicides30,
    volume: "0,25 кг",
    price: 27.23,
    currency: "USD",
    text: (
      <>
        Опис: Конвой — гербіцид системної дії для захисту посівів зернових
        колосових культур від однорічних і багаторічних дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 300
        г/кг: флорасулам, 105 г/кг: триасульфурон, 95 г/кг.
        <span className="paragraph"></span> Препаративна форма: Водорозчинні
        гранули.
        <span className="paragraph"></span> Переваги: • Ефективність проти широкого
        спектру найпоширеніших дводольних бур’янів. • Тривале «вікно»
        застосування: від фази 3-х листків до фази формування прапорцевого
        листка включно. • Контроль падалиці «хімічних соняшників». • Здатність
        працювати температурах від +5 °С.
        <span className="paragraph"></span> Механізм дії: Діючі речовини
        поглинаються корінням і листям, легко переміщуються в рослинах, блокують
        в рослинах чутливих бур'янів ацетолактатсинтазу - фермент, який бере
        участь в утворенні амінокислот валіну, лейцину та ізолейцину.
        Пригнічення ацетолактатсинтази призводить до зупинки росту, а потім до
        загибелі рослин.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га.
        <span className="paragraph"></span> Культура: • Пшениця озима і яра; •
        Ячмінь озимий і ярий.
        <span className="paragraph"></span> Цільовий об’єкт: • Однорічні дводольні,
        і деякі багаторічні дводольні бур’яни.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        застосовується на ячмені озимому і ярому від фази 2-3 листків до виходу
        в трубку, на озимій пшениці від фази 2-3 листків до появи прапорцевого
        листка. Найвища ефективність дії препарату проти однорічних
        двосім'ядольних бур'янів - фаза 2-4 листки. Найкращим для боротьби з
        багаторічними дводольними бур'янами (будяк щетинистий та жовтий, осот
        польовий) є період їх активного росту — фаза розетки або висота рослини
        10-15 см. Бур'яни, що знаходяться на більш пізніх стадіях, та менш
        чутливі бур'яни можуть не загинути, але їх ріст та розвиток
        припиняються, і вони більше не конкурують з культурними рослинами за
        елементи живлення.{" "}
      </>
    ),
  },
  {
    productTitle: "Легенда",
    path: "/legenda",
    src: Herbicides31,
    volume: "5 л",
    price: 60.92,
    currency: "USD",
    text: (
      <>
        Опис: Легенда - гербіцид системної дії для боротьби з однорічними та
        багаторічними злаковими та дводольними бур’янами у посівах кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Мезотріон, 75 г/л;
        нікосул.
        <span className="paragraph"></span> Препаративна форма: Масляна дисперсія.
        <span className="paragraph"></span> Переваги: • Комбінація діючих речовин
        різного напрямку дії. • Контроль широкого спектру однорічних і
        багаторічних злакових та дводольних бур’янів. • Широке вікно
        застосування. • Відсутність фітотоксичності на культуру.
        <span className="paragraph"></span> Механізм дії: Мезотріон - системна діюча
        речовина групи трикетонів, проникає в рослини через листя та корені,
        пересувається по ксилемі і флоемі, блокує дію ензиму
        п-гідрокси-феніл-піруват-дегідрогенази, що приводить до пригнічення
        біосинтезу каротиноїдів. За рахунок мезотрону препарат має пролонговану
        ґрунтову дію на однорічні дводольні бур’яни. Нікосульфурон – речовина
        групи сульфонілсечовин, є високо селективною по відношенню до кукурудзи,
        володіє системною дією та швидко проникає у рослини бур’янів. Після
        обробки поглинається переважно листками та впродовж кількох годин
        переміщується по рослині, що призводить до зупинки поділу клітин у
        точках росту. Зупиняє ріст та розвиток бур’янів за рахунок блокування
        ферменту ацетолактатсинтази, необхідного для синтезу незамінних
        амінокислот: валіну, лейцину та ізолейцину.
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-250
        л/га.
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні і
        багаторічні злакові та дводольні бур’яни.
        <span className="paragraph"></span> Спосіб та час обробки: • Обприскування
        посівів у фазах від 2 до 8 справжніх листків у культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять лише наземним обприскуванням, під час обробки слід уникати
        перекриття смуг внесення. Норма витрати робочої рідини: 150-250 л/га.
        Препарат ефективно контролює однорічні дводольні бур’яни при обробці їх
        в фазах 2 - 4 листків, та в фазах 2 - 3 листків у злакових бур’янів.{" "}
      </>
    ),
  },
  {
    productTitle: "Лемур",
    path: "/lemur",
    src: Herbicides32,
    volume: "5 л",
    price: 59.59,
    currency: "USD",
    text: (
      <>
        Опис: Лемур - гербіцид системної дії для знищення вегетуючих однорічних
        та багаторічних злакових бур'янів у посівах дводольних культур.
        <span className="paragraph"></span> Діюча речовина: Хізалофоп-п-тефурил, 40
        г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span> Переваги: • Системний механізм дії
        препарату дозволяє знищувати кореневу систему багаторічних злакових
        бур'янів. • Висока селективність та безпечність препарату по відношенню
        до культурних рослин дозволяє використовувати його незалежно від фази
        розвитку рослин. • Швидкий розпад діючої речовини в ґрунті дозволяє не
        накладати жодних обмежень з висіву наступної культури сівозміни.
        <span className="paragraph"></span> Механізм дії: При внесенні препарат
        швидко поглинається вегетативною масою бур’янів, перемішується в точки
        росту рослин, де блокує синтез ліпідів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га.
        <span className="paragraph"></span> Культура: • Ріпак, соняшник; • Соя.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові; •
        Багаторічні злакові.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять лише наземним обприскуванням.{" "}
      </>
    ),
  },
  {
    productTitle: "Майтус",
    path: "/maitus",
    src: Herbicides33,
    volume: "0,25 кг",
    price: 20.42,
    currency: "USD",
    text: (
      <>
        Опис: Майтус - післясходовий гербіцид для контролю основних злакових та
        однорічних дводольних бур’янів у посівах кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Римсульфурон, 250 г/л.
        <span className="paragraph"></span> Препаративна форма: Водорозчинні
        гранули.
        <span className="paragraph"></span> Переваги: • Впливає на ріст бур’янів вже
        через декілька годин після внесення; • Активність препарату не залежить
        від вологи ґрунту, дія через листя бур’янів забезпечує його ефективність
        і в посушливих умовах; • Можливе застосування в два етапи; • Досить
        швидко розкладається в ґрунті, що виключає проникнення в ґрунтові води.
        <span className="paragraph"></span> Механізм дії: Діюча речовина препарату
        має системний характер дії. Вона поглинається надземною частиною рослин,
        транспортується до апікальних меристем, де блокує процеси поділу клітин.
        Перші візуальні ознаки гербіцидної дії препарату на бур’яни
        (знебарвлення, пригнічення росту рослин, відмирання верхівок пагонів)
        проявляються на 3-5 день, що пов’язано зі специфічним механізмом дії
        препарату. Остаточна загибель бур’янів відбувається протягом 2-3 тижнів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га. У посушливих умовах норма витрати робочого розчину має бути не
        меншою ніж 300 л/га.
        <span className="paragraph"></span> Рекомендації щодо внесення:
        <span className="paragraph"></span> Спосіб внесення препарату: • Внесення в
        один етап; • Внесення у два етапи.
        <span className="paragraph"></span> Культура і фаза розвитку: • Кукурудза
        3-5 листків; • Кукурудза 1-7 листків.
        <span className="paragraph"></span> Фаза розвитку бур’янів сприятлива для
        знищення: • Багаторічні злакові при висоті 15-25 см, однорічні злакові
        1-3 листки, однорічні дводольні 2-4 листки; • Однорічні злакові 1-3
        листки, однорічні дводольні 2-4 листки.
        <span className="paragraph"></span> ЗАСТЕРЕЖЕННЯ: • Не рекомендовано
        застосовувати препарат Майтус® на посівах цукрової кукурудзи та посі­вах
        кукурудзи на насіння (розмноження ліній). • Не рекомендовано
        застосовувати препарат при переростанні культурних рослин (більше 7
        листків). • Не застосовуйте препарат Майтус® на полях впродовж 7 днів до
        чи після обробки кукурудзи інсектицидами з групи фосфорорганічних
        сполук, оскільки це може призвести до токсикації культурних рослин •
        Механічні обробки посіву (культивація або боронування) за 7 днів до
        внесення препарату чи впродовж 7 днів після його застосування можуть
        істотно знизити ефективність його дії на бур'яни • Не застосовуйте
        препарат Майтус® якщо pH робочого розчину менше 4, в тому числі і при
        додаванні партнерських компонентів бакової суміші.
      </>
    ),
  },
  {
    productTitle: "Мікадо",
    path: "/mikado",
    src: Herbicides34,
    volume: "5 л",
    price: 144,
    currency: "USD",
    text: (
      <>
        Опис: Мікадо - післясходовий гербіцид для боротьби з дводольними
        бур’янами на посівах ріпаку. 
        <span className="paragraph"></span> Діюча речовина: Клопіралід, 267 г/л;
        піклорам, 67 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Селективний та безпечний для
        хрестоцвітих культур; • Знищує одночасно як осот, вили ромашки, гірчаки,
        так і підмаренник чіпкий; • Завдяки системній дії знищує як надземну
        частину бур’янів, так і кореневу систему багаторічних коренепаросткових
        бур’янів.
        <span className="paragraph"></span> Механізм дії: Клопіралід  та піклорам —
        речовини системної дії. Вони поглинаються надземною вегетативною масою
        бур’янів та швидко поширюються по всій рослині (поширення іде як по
        ксилемі, так і по флоемі). Внаслідок цього інгібується активність
        гормонів росту в рослині, що, у свою чергу, призводить до порушення
        процесів росту та розвитку рослини. Згодом рослина гине.
        <span className="paragraph"></span> Норма витрати робочого розчину: 250-400
        л/га.
        <span className="paragraph"></span> Регламент застосування препарату:
        <span className="paragraph"></span> Культура: • Ріпак озимий та ярий.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні та
        багаторічні дводольні бур’яни.
        <span className="paragraph"></span> Спосіб та час обробки: • Обприскування
        посіву від фази 3-4 листків до появи квіткових бутонів у культури.
        <span className="paragraph"></span> Максимальна кратність обробок: • 1.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять у період, коли культурні рослини утворили 4 справжні листки, і
        до появи квіткових бутонів у культури. Внесення препарату в період до
        утворення 4 справжніх листків у культури або після появи квіткових
        бутонів може спричинити токсикацію культурних рослин.{" "}
      </>
    ),
  },
  {
    productTitle: "Мілафурон",
    path: "/milafuron",
    src: Herbicides35,
    volume: "5 л",
    price: 40.51,
    currency: "USD",
    text: (
      <>
        Опис: Мілафурон - післясходовий гербіцид системної дії для знищення
        однорічних і багаторічних злакових та основних однорічних дводольних
        бур’янів у посівах кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Нікосульфурон, 40 г/л
        <span className="paragraph"></span> Препаративна форма: Концентрат суспензії
        <span className="paragraph"></span> Переваги: • Висока ефективність проти
        злакових та деяких дводольних бур’янів, включаючи злісні багаторічні
        злакові, які проростають як із насіння, так і з кореневищ, наприклад
        гумай, пирій. • Можливість застосування в широкому діапазоні фаз
        розвитку культури. • Короткий період розпаду в ґрунті. • Період
        активності — до 3 тижнів.
        <span className="paragraph"></span> Механізм дії: Гербіцид поглинається
        листками і стеблами бур’янів. Перенесення у меристемні тканини
        здійснюється як флоемою, так і ксилемою — переміщується до кореневої
        системи рослин. Припиняє поділ клітин шляхом блокування синтезу ферменту
        ацетолактатсинтази, який відповідає за біосинтез основних амінокислот.
        Норма витрати робочого розчину: 200-400 л/га.
        <span className="paragraph"></span> Регламент застосування препарату:
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові; •
        Однорічні дводольні; • Багаторічні злакові.
        <span className="paragraph"></span> Час обробки: • 1-3 листки; • 1-4 листки;
        • При висоті 15-25 см.
        <span className="paragraph"></span>- Максимальна норам витрати препарату
        застосовується при значній забур’яненості багаторічними злаковими
        бур’янами та у випадку їхнього переростання, а також за несприятливих
        погодних умов.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять лише наземним обприскуванням, під час обробки слід уникати
        перекриття смуг унесення. Температури повітря від +15 до 25+С, та
        швидкість вітру до 4-5 м/с є оптимальними в період застосування
        препарату. Дощ раніше 4 годин після внесення може істотно знизити
        ефективність його дії на бур’яни.{" "}
      </>
    ),
  },
  {
    productTitle: "Мотор",
    path: "/motor",
    src: Herbicides36,
    volume: "5 л",
    price: 57.26,
    currency: "USD",
    text: (
      <>
        Опис: Мотор - гербіцид системної та ґрунтової дії для знищення
        однорічних дводольних та злакових бур’янів в посівах гороху, сої та
        соняшнику, стійкого до імідазолінонів
        <span className="paragraph"></span> Діюча речовина: Імазамокс, 40 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Ефективний проти широкого
        спектру злакових та дводольних бур’янів; • Володіє грунтовою дією, що
        дозволяє стримувати наступні «хвилі» бур’янів; • Найнижчий ризик прояву
        післядії серед імідізолінонів.
        <span className="paragraph"></span> Механізм дії: Імазамокс має чітко
        виражену системну дію, селективний, адсорбується листками та коріннями,
        може рухатись по рослині. Діюча речовина надходить до меристематичних
        тканин по ксилемі і флоемі, де інгібує синтез ферменту
        ацетолактатсинтази (ALS), який бере участь у синтезі трьох незамінних
        амінокислот (валін, лейцин, ізолейцин), необхідних для продукування
        білка та ДНК і росту клітин. Порушення синтезу білку призводить до
        зупинки в рості та загибелі бур’яну.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-250
        л/га.
        <span className="paragraph"></span> Регламент застосування препарату:
        <span className="paragraph"></span> Культура: • Соняшник (гібриди, стійкі до
        імідазоліонів); • Горох; • Соя.
        <span className="paragraph"></span> Погодні умови: Температура повітря на
        момент застосування від +15 до +22С.
      </>
    ),
  },
  {
    productTitle: "Преміум Голд",
    path: "/premium-gold",
    src: Herbicides37,
    volume: "20 л",
    price: 139.65,
    currency: "USD",
    text: (
      <>
        Опис: Преміум Голд — селективний гербіцид на основі двох діючих речовин
        для захисту соняшника та кукурудзи від широкого спектру однорічних
        дводольних і злакових бур’янів.
        <span className="paragraph"></span> Діюча речовина: С-метолахлор, 312,5 г/л;
        тербутилазин, 185,5 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Контроль однорічних злакових
        та дводольних бур’янів. • Стримування нових сходів бур’янів за рахунок
        ґрунтової дії препарату. • Можливість застосування на декількох
        культурах. • Мінімальні ризики токсикації культури за складних погодних
        умов. • Відсутність негативної післядії на наступну культуру сівозміни.
        • Період захисної дії складає 6–8 тижнів.
        <span className="paragraph"></span> Механізм дії: С-метолахлор є інгібітором
        поділу клітин, більш ефективно контролює злакові бур'яни, діє на бур'яни
        в момент проростання. Проникаючи у тканини шилець злакових бур’янів та
        сім'ядолей дводольних S-метолахлор гальмує розвиток і поділ клітин,
        порушуючи ліпідний обмін, що спричинює зупинку росту і загибель рослин.
        Тербутилазин абсорбується коренями та листям бур'янів, переміщується
        акропетально по ксилемі, накопичуючись в верхівкових меристемах, інгібує
        транспорт електронів в рецепторах фотосистеми II в процесі фотосинтезу,
        що призводить до загибелі бур'янів. Норма витрати робочого розчину:
        300-400 л/га.
        <span className="paragraph"></span> Регламент застосування препарату:
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза.
        <span className="paragraph"></span> Об’єкт проти якого проводиться обробка:
        • Однорічні дводольні та однорічні злакові бур’яни.
        <span className="paragraph"></span> Час обробки: • Обприскування до появи
        сходів культурних рослин; • Обприскування до появи сходів культури або у
        фазі 3-5 листків культури.
        <span className="paragraph"></span> Рекомендації щодо застосування: У
        досходовий період препарат вносять на переважній більшості культур.
        Найбільш оптимальним є допосівне внесення гербіциду із наступним його
        загортанням на глибину 2-5 см.
      </>
    ),
  },
  {
    productTitle: "Пріус",
    path: "/prius",
    src: Herbicides38,
    volume: "5 л",
    price: 35.2,
    currency: "USD",
    text: (
      <>
        Опис: Пріус - двокомпонентний післясходовий гербіцид системної дії для
        боротьби з однорічними та деякими багаторічними бур’янами в посівах
        зернових колосових та кукурудзи.
        <span className="paragraph"></span> Діюча речовина: Флорасулам, 6,25 г/л;
        2,4-Д етилгексиловий ефір, 452,42 г/л.
        <span className="paragraph"></span> Препаративна форма: Суспензійна
        емульсія.
        <span className="paragraph"></span> Переваги: • Можливість застосовувати
        один продукт для боротьби з бур’янами як на зернових колосових, так і на
        кукурудзі. • Швидкість дії на бур’яни. • Широкий спектр дії: контролює
        переважну більшість дводольних бур’янів, у тому числі види осотів,
        амброзію, підмаренник чіпкий (на більш пізніх стадіях розвитку). •
        Широкий термін застосування: на зернових колосових — від фази кущення до
        появи другого міжвузля; на кукурудзі — від 3–7 листків уключно.
        <span className="paragraph"></span> Механізм дії: Флорасулам проникає в
        рослини бур'янів через коріння та листя. Інгібує фермент
        ацетолактатсинтазу, який відповідає за синтез декількох амінокислот.
        Через добу після застосування бур'яни припиняють ріст та розвиток.
        Етилгексиловий ефір 2,4-Д - діюча речовина системної дії. Потрапляє в
        рослини бур'янів через надземну масу рослин. Викликає гальмування
        фотосинтезу та анормальний ріст клітин в рослині, в результаті чого
        відбувається її деформація, потовщення провідних тканин, що
        супроводжується підвищенням тургору та розривами тканин. Порушується
        водний обмін, рослина втрачає тургор, вегетативна маса скручується,
        зупиняється синтезуюча дія кореневої системи, бур’ян в'яне і згодом
        гине. Поєднання двох діючих речовин з різним механізмом дії на бур'яни,
        обумовлює, як високу ефек­тивність препарату Пріус, так і попередження
        виникнення резистентності у бур'янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Регламент застосування:
        <span className="paragraph"></span> Культура: • Пшениця; • Ячмінь; •
        Кукурудза.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні та
        багаторічні дводольні бур’яни.
        <span className="paragraph"></span> Спосіб та час оброблення: •
        Обприскування від фази кущення до появи другого вузла у культурних
        рослин; • Обприскування у фазу від 3 до 7 листків(включно).
        <span className="paragraph"></span> Максимальна кратність обробок: • 1.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        вносять за допомогою наземних обприскувачів в період масової появи
        сходів бур'янів. Максимальну рекомендовану норму витрати препарату слід
        застосовувати у випадках високої засміченості посіву, великої кількості
        в посіві осотів та підмаренника чіпкого, а також у випадках переростання
        бур'янів.
        <span className="paragraph"></span> ЗАСТЕРЕЖЕННЯ: • Препарат не має
        ґрунтової дії і впливає лише на ті бур’яни, сходи яких з’явились на
        момент внесення препарату. На бур’яни, сходи яких з’явились після
        внесення препарату, гербіцид не впливає; • Механічні обробки ґрунту
        (культивація, боронування) дозволяється проводити не раніше 10 днів до
        або після застосування препарату; • Не застосовувати препарат на посівах
        зернових культур з підсівом люцерни, конюшини, буркуна або інших
        дводольних культур; • не допускати зносу препарату на посіви чутливих
        (дводольних) культур під час внесення.
      </>
    ),
  },
  {
    productTitle: "Сабер",
    path: "/saber",
    src: Herbicides39,
    volume: "0,25 кг",
    price: 31.43,
    currency: "USD",
    text: (
      <>
        Опис: Сабер - гербіцид системної дії для захисту посівів зернових
        колосових культур від однорічних та багаторічних дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 310
        г/кг; флорасулам, 95 г/кг; флуметсулам, 95 г /кг.
        <span className="paragraph"></span> Препаративна форма: Вододисперсні
        гранули.
        <span className="paragraph"></span> Переваги: • Не має обмежень для
        наступних культур у сівозміні; • Ефективність проти широкого спектру
        найпоширеніших дводольних бур’янів; • Можливість застосування до
        прапорцевого листка; • Контроль падалиці «хімічних соняшників»; •
        Ефективний при температурах від +5 °С.
        <span className="paragraph"></span> Механізм дії: Діючі речовини
        поглинаються корінням і листям, легко переміщуються в рослинах, блокуючи
        в чутливих бур'янів ацетолактатсинтазу – фермент, який бере участь в
        утворенні амінокислот валіну, лейцину та ізолейцину. Пригнічення
        ацетолактатсинтази призводить до зупинки росту, а потім до загибелі
        рослин. Ріст бур'янів припиняється через кілька годин після
        обприскування.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-250
        л/га. Регламент застосування препарату.
        <span className="paragraph"></span> Культура: • Пшениця озима і яра; •
        Ячмінь озимий і ярий.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні та
        багаторічні дводольні бур’яни; • Однорічні багаторічні дводольні
        бур’яни.
        <span className="paragraph"></span> Рекомендації щодо застосування: Препарат
        застосовується на ячмені озимому і ярому від фази кущення до виходу в
        трубку, на озимій пшениці – від фази кущення до появи прапорцевого
        листка. Найвища ефективність дії препарату проти однорічних дводольних
        бур'янів – фаза 2-4 листки. Найкращим для боротьби з багаторічними
        дводольними бур'янами (будяк щетинистий та жовтий, осот польовий) є
        період їх активного росту – фаза розетки або висота рослини 10-15 см.
        Бур'яни, що знаходяться на більш пізніх стадіях, та менш чутливі бур'яни
        можуть не загинути, але їх ріст та розвиток припиняються, і вони більше
        не конкурують з культурними рослинами за елементи живлення. За умов
        підвищеної густоти стояння рослин та/або великій кількості рослинних
        решток норму витрати робочої рідини слід збільшити.{" "}
      </>
    ),
  },
  {
    productTitle: "Сентинел",
    path: "/sentinel",
    src: Herbicides40,
    volume: "20 л",
    price: 245.1,
    currency: "USD",
    text: (
      <>
        Опис: Сентинел - селективний досходовий гербіцид для боротьби з
        однорічними злаковими та дводольними бур’янами в посівах соняшника,
        кукурудзи, сої, гороху
        <span className="paragraph"></span> Діюча речовина: Пропізохлор, 576 г/л;
        метрибузин, 60 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span> Переваги: • Тривала грунтова дія за
        рахунок двох діючих речовин; • Ефективний проти однорічних злакових та
        дводольних бур’янів; • Не впливає на наступні культури сівозміни; •
        Низький ризик прояву фітотоксичності культурі.
        <span className="paragraph"></span> Механізм дії: Пропізохлор - системна
        діюча речовина. Поглинається корінням і проростками бур'янів, порушує
        азотний обмін і пригнічує поділ клітин шляхом блокування синтезу білка у
        чутливих рослин. Метрибузин - абсорбується корінням та проростками
        рослин, може проникати і через листову поверхню. Переміщується
        акропетально. Пригнічує транспорт електронів, що беруть участь в процесі
        фотосинтезу.
        <span className="paragraph"></span> Норма витрати робочого розчину: 300-400
        л/га. Регламент застосування препарату.
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза; •
        Соя; • Горох.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові та
        однорічні дводольні бур’яни.
        <span className="paragraph"></span> Спосіб та час обробки: • Обприскування
        грунту до посіву, під час посіву, або до появи сходів культури.
        <span className="paragraph"></span> Рекомендації щодо застосовування: В
        залежності від ситуації та культури, на якій вноситься гербіцид,
        обприскування проводять до появи сходів культурних рослин за допомогою
        наземних обприскувачів з можливістю подальшої заробки в ґрунт
        культивацією або боронуванням на глибину 2 - 5см.{" "}
      </>
    ),
  },
  {
    productTitle: "Серп",
    path: "/serp",
    src: Herbicides41,
    volume: "5 л",
    price: 42.05,
    currency: "USD",
    text: (
      <>
        Опис: Серп — системний високоефективний післясходовий гербіцид для
        знищення широкого спектру однорічних злакових та дводольних бур’янів в
        посівах сої.
        <span className="paragraph"></span> Діюча речовина: Імазетапір, 100 г/л.
        <span className="paragraph"></span> Препаративна форма: Водорозчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Завдяки пролонгованій
        ґрунтовій активності препарат знищує не тільки бур’яни, що вегетують,
        але й бур’яни, що проростають, забезпечуючи тим самим надійний захист
        культурних рослин на протязі тривалого періоду. • Не справляє
        негативного впливу на культурні рослини за умов дотримання регламенту
        застосування. • Має широкий вибір способів та терміну використання.
        <span className="paragraph"></span> Механізм дії: Діюча речовина проникає
        через коріння та листя рослин, блокує у чутливих рослин синтез протеїну,
        що веде до припинення росту клітин і загибелі бур'янів. При досходовому
        використанні у дводольних бур'янів ріст зупиняється у фазі двох листків,
        у злакових бур'янів — у фазі колеоптилю. При застосуванні препарату
        після сходів культурних рослин ріст бур'янів зупиняється через декілька
        годин після обробки.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200 до
        400 л/га, в залежності від способу застосування та польових умов на
        момент внесення.
        <span className="paragraph"></span> Регламент застосування препарату:
        <span className="paragraph"></span> Культура: • Соя.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові та
        дводольні бур’яни. Рекомендації щодо застосовування: Препарат можна
        вносити з передпосівною культивацією на глибину не більш 6 см, а також
        після посіву до появи сходів, але в цьому випадку треба пам’ятати, що в
        посушливих умовах препарат треба заробити в грунт боронами. Та найбільш
        ефективним є використання гербіциду СЕРП® на ранніх етапах розвитку
        культури (фаза 3–4 справжні листки), коли дводольні бур’яни мають не
        більше 4, а злакові — 2–3 листків.
        <span className="paragraph"></span> ЗАСТЕРЕЖЕННЯ: • Зелена маса рослин може
        бути використана для годування тварин лише через 45 днів після
        застосування препарату Серп. • Не рекомендовано застосовувати препарат
        Серп на одному полі частіше ніж 1 раз на 3 роки. • Міжрядний обробок
        посіву проводять лише через 7-10 днів після внесення препарату. • Не
        рекомендовано застосовувати на полях, оброблених препаратом Серп,
        гербіциди групи похідних сульфонілсечовин, групи імідазолінонів,
        триазолпіримідинів впродовж усього сезону. • Не рекомендовано
        застосовувати гербіцид  у бакових сумішах з протизлаковими гербі­цидами.
        • Не застосовувати гербіцид у бакових сумішах з інсектицидами групи
        фосфорорганічних сполук або карбаматів, не проводити обробки
        інсектицидами цих груп в поточному році – це може спричинити тимчасову
        токсикацію культурних рослин.
      </>
    ),
  },
  {
    productTitle: "Сонхус",
    path: "/sonhus",
    src: Herbicides42,
    volume: "0,25кг",
    price: 22.36,
    currency: "USD",
    text: (
      <>
        Опис: Сонхус - післясходовий гербіцид системної дії для знищення
        однорічних двосім'ядольних та багаторічних коренепаросткових бур'янів в
        посівах сільськогосподарських культур.
        <span className="paragraph"></span> Діюча речовина: Клопіралід, 750 г/кг.
        <span className="paragraph"></span> Препаративна форма: Водорозчинні
        гранули.
        <span className="paragraph"></span> Переваги: • Висока ефективність у
        боротьбі проти таких багаторічних коренепаросткових бур’янів, як осот
        рожевий, осот жовтий, латук татарський, гірчак повзучий. • Завдяки
        системному механізму дії повністю знищує не тільки надземну частину
        рослин бур’янів, але й кореневу систему, в тому числі бруньки
        вегетативного відновлення для багаторічних коренепаросткових. • Не
        стимулює утворення резистентних форм в популяціях бур’янів. • Висока
        селективність по відношенню до культурних рослин за умови дотримання
        регламенту застосування. • Препарат сумісний у бакових сумішах з
        переважною більшістю пестицидів та рідких добрив, але в кожному окремому
        випадку необхідна попередня перевірка компонентів на сумісність.
        <span className="paragraph"></span> Механізм дії: Проникає в рослину через
        надземну частину та частково з вологою ґрунту через кореневу систему.
        Швидко перено­ситься по рослині до точок росту, де блокує поділ
        меристематичних клітин. Внаслідок цього відбувається відмирання як
        надземної частини, так і кореневої системи бур'янів, в тому числі
        бруньок вегетативного відновлення коренепа­росткових бур'янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га. 
        <span className="paragraph"></span> Регламент застосування препарату.
        <span className="paragraph"></span> Культура: • Буряки цукрові.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні дводольні;
        • Багаторічні коренепаросткові.
        <span className="paragraph"></span> Фаза розвитку бур’яну: • Фаза 2-4
        листки; • Фаза розетки.
        <span className="paragraph"></span> Максимальна кратність обробок: • 1.
      </>
    ),
  },
  {
    productTitle: "Цетодим + ПАР Фофір",
    path: "/cetodim-par-fofir",
    src: Herbicides43,
    volume: "5 л",
    price: 132,
    currency: "USD",
    text: (
      <>
        Опис: Цетодим + ПАР Фофір - післясходовий гербіцид системної дії для
        боротьби з однорічними та багаторічними злаковими бур’янами. 
        <span className="paragraph"></span> Діюча речовина: Клетодим, 240 г/л .
        <span className="paragraph"></span>
        Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span> Переваги: • Ефективність препарату не
        залежить від погодних умов. • Можливість застосування препарату
        незалежно від фази розвитку культурних рослин дозволяє ефективно
        захистити культурні рослини на будь-якому етапі розвитку. • Завдяки
        системному механізму дії препарату знищує не тільки вегетативну масу,
        але й кореневу систему рослин, що є надзвичайно актуальним при боротьбі
        з проблемними злаковими бур’янами, у тому числі такими, як пирій
        повзучий, гумай тощо. • Дощ через 1 годину після обробки не впливає на
        ефективність дії препарату. • Сумісність з іншими пестицидами та рідкими
        добривами дозволяє використовувати гербіцид ЦЕТОДІМ® в комплексних
        системах захисту сільськогосподарських культур.
        <span className="paragraph"></span> Механізм дії: Препарат проникає через
        стебла та листя рослин, швидко розноситься по рослині, концен­трується в
        точках росту, блокуючи біохімічні процеси синтезу ліпідів. Ріст чутливих
        рослин припиняється через декілька годин після обприскування. ПАР ФОФІР
        фіксує гербіцид на поверхні рослин, прискорює проникнення препарату в
        організм рослини за рахунок розчинення кутикули бур'янів. Внаслідок
        цього істотно зменшується вплив погодних умов на ефективність дії
        препарату.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га.
        <span className="paragraph"></span> Регламент застосування препарату.
        <span className="paragraph"></span> Культура: • Буряки цукрові; • Соняшник;
        • Ріпак.
        <span className="paragraph"></span> Шкідливий об’єкт: • Однорічні злакові
        бур’яни; • Багаторічні злакові бур’яни.
        <span className="paragraph"></span> Рекомендації щодо застосовування:
        Препарат ЦЕТОДИМ® застосовують тільки з ПАР ФОФИР® у співвідношенні 1:3.
        Препарат вносять наземним обприскуванням на ранніх стадіях розвитку
        бур’янів (2-6 листків для однорічних злакових і при висоті 10-15 см для
        багаторічних злакових) після появи сходів культурних рослин.{" "}
      </>
    ),
  },

  // третий файл

  {
    productTitle: "Альфа-Дикамба",
    path: "/alfa-dikamba",
    src: Herbicides44,
    volume: "5 л",
    price: 51.22,
    currency: "USD",
    text: (
      <>
        Опис: Альфа-Дикамба - післясходовий селективний гербіцид системної дії
        для знищення однорічних і деяких багаторічних дводольних, в т.ч. стійких
        до 2,4-Д та МЦПА, бур’янів.
        <span className="paragraph"></span> Діюча речовина: Дикамби диметиламінна
        сіль, 480 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Широкий спектр дії: знищує
        понад 200 видів бур’янів, у тому числі берізку польову, види осоту,
        латуки тощо. • Оптимальний партнер для бакових сумішей з препаратами
        груп: 2,4-Д, сульфонілсечовини, триазини, гліфосати. • Проникає в
        рослину як через її зелені частини, так і через кореневу систему. •
        Відсутня післядія на наступні культури в сівозміні, препарат повністю
        розкладається в ґрунті протягом періоду вегетації.
        <span className="paragraph"></span> Механізм дії: Альфа-Дикамба – гербіцид з
        яскраво вираженою системною дією. Діє як інгібітор росту, впливаючи на
        процеси фотосинтезу й поділ клітин у меристемі бур’янів. Препарат
        проникає в рослини як через листя, так і через кореневу систему
        бур’янів, повністю знищуючи листковий апарат і коріння бур’янів. Дія
        починається з моменту контакту з препаратом. Візуально вплив гербіциду
        проявляється через 2-3 години після застосування (залежно від погодних
        умов, фази розвитку, виду бур’янів та норми витрати препарату).
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Озима пшениця, ярий ячмінь;
        • Кукурудза.
        <span className="paragraph"></span> Об’єкти: • Однорічні дводольні, в т.ч.
        стійкі до 2,4-Д і МЦПА, та деякі багаторічні дводольні, включаючи осоти,
        бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів з
        фази кущення до виходу в трубку; • Обприскування посівів у фазі 3-5
        листків культури.
        <span className="paragraph"></span> Загальні рекомендації: Обприскування
        посівів (пшениця, ячмінь) навесні у фазі кущення культури до виходу в
        трубку, у ранні фази росту однорічних бур’янів (2-5 листків) і у фазі
        розетки (діаметром до 5 см) багаторічних бур’янів (до 15 см заввишки).
        Обприскування посівів кукурудзи (як самостійно, так і в суміші) у фазі
        3-5 листків та 15 см висоти у багаторічних бур’янів. Застосування
        бакових сумішей слід уточнювати з регламентом застосування, розробленим
        компанієювиробником, по кожній конкретній культурі: озима пшениця –
        Альфа-Дикамба (0,15-0,2 л/га) + Альфа-Стар (10-15 г/га) + ПАР Альфалип
        Екстра (0,2 - 0,25 л/га); кукурудза – Альфа-Дикамба (0,3-0,5 л/га) +
        Рамзес* (40-50 г/га) + ПАР Бустер (0,1 л/га); для зачистки полів від
        багаторічників – Альфа-Дикамба (0,2 л/га) + Отаман (3-4 л/га),
        Альфа-Дикамба (0,2 л/га) + Отаман Екстра (2-3 л/га). Препарат
        Альфа-Дикамба не впливає на наступні культурні рослини, він повністю
        розкладається протягом вегетації.
      </>
    ),
  },
  {
    productTitle: "Альфа-Маїс",
    path: "/alfa-mais",
    src: Herbicides45,
    volume: "0,1 кг",
    price: 7.7,
    currency: "USD",
    text: (
      <>
        Опис: Альфа-Маїс - післясходовий гербіцид системної дії для знищення
        однорічних дводольних, в т.ч. стійких до 2,4-Д, бур’янів.
        <span className="paragraph"></span> Діюча речовина: Тифенсульфурон-метил,
        750 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Висока системність дає змогу
        препарату швидко (через декілька годин) переміщуватися до зон росту
        коренів та пагонів. • Відсутні обмеження у сівозміні, швидко
        розкладається у ґрунті (період напіврозпаду становить 6 днів). • Висока
        ефективність проти найбільш шкідливих дводольних бур’янів.
        <span className="paragraph"></span> Механізм дії: Альфа-Маїс зупиняє поділ
        клітин бур’янів шляхом впливу на їх ферментну систему. Пригнічує фермент
        АЛС (ацетолактатсинтетазу) та зупиняє ріст бур’янів через кілька годин
        після внесення. У чутливих бур’янів викликає припинення росту, хлороз,
        відмирання точок росту, некроз та повну загибель. Більш стійкі бур’яни
        або ті, що перебувають у більш пізній стадії розвитку на момент обробки,
        можуть призупинити свій ріст у період вегетації, але конкуренції
        культурним рослинам вони вже не становлять. Діє в основному через
        листову поверхню рослин. Видимі симптоми з’являються через 3-7 днів
        після застосування. Загибель чутливих бур’янів може тривати 10-20 днів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Кукурудза.
        <span className="paragraph"></span> Об’єкти: • Однорічні дводольні, в т.ч.
        стійкі до 2,4-Д, бур’яни.
        <span className="paragraph"></span> Строк обробки: • У фазі 3-5 листків
        культури.
        <span className="paragraph"></span> Загальні рекомендації: Найвища
        ефективність препарату спостерігається при обприскуванні однорічних
        бур’янів у фазі 2-4 листків. Для підвищення гербіцидного ефекту
        Альфа-Маїсу слід обов’язково використовувати поверхнево-активні речовини
        (ПАР Альфалип Екстра, ПАР Бустер). Не рекомендується використовувати на
        батьківських лініях кукурудзи, кукурудзи солодкої на попкорн. Не
        використовувати у баковій суміші з фосфорорганічними інсектицидами та не
        вносити впродовж 14 діб до або після обробки фосфорорганічними
        інсектицидами. Обробку грамініцидами слід проводити не раніше ніж через
        5 днів після застосування. Не обробляти культури в період стресу
        (холодна або спекотна погода). Не застосовувати Альфа-Маїс в баковій
        суміші з гербіцидами на основі імазетапіру. Рекомендовані бакові суміші
        на посівах кукурудзи: Альфа-Маїс (10 г/га) + Альфа-Дикамба (0,3-0,4
        л/га) + ПАР Бустер (0,1 л/га); Альфа-Маїс (10 г/га) + Рамзес (40-50
        г/га) + ПАР Бустер (0,1 л/га), Альфа-Маїс (6 г/га) + Альфа-Бентазон*
        (1,5-2,0 л/га) + ПАР Бустер (0,1 л/га ). Можливе застосування в бакових
        сумішах з різними фунгіцидами, інсектицидами та гербіцидами.{" "}
      </>
    ),
  },
  {
    productTitle: "Альфа-Прометрин",
    path: "/alfa-prometrin",
    src: Herbicides46,
    volume: "10 л",
    price: 49.47,
    currency: "USD",
    text: (
      <>
        Опис: Альфа-Прометрин - ґрунтовий гербіцид системної дії проти
        однорічних дводольних та деяких злакових бур’янів. Діюча речовина:
        Прометрин, 500 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Ефективно контролює широкий
        спектр однорічних дводольних і деякі злакові бур’яни. • Не впливає на
        наступні культури в сівозміні. • Тривалий період захисної дії – 6-8
        тижнів (залежно від погодних умов). • Ідеальний партнер для бакових
        сумішей ґрунтових гербіцидів з метою розширення спектра дії.
        <span className="paragraph"></span> Механізм дії: Альфа-Прометрин – гербіцид
        вибіркової дії. Препарат поглинається як проростками, так і корінням
        проростаючих бур’янів при застосуванні препарату до сходів. На бур’яни,
        які зійшли, діє через листя. Діюча речовина блокує процес фотосинтезу в
        рослинах бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник.
        <span className="paragraph"></span> Об’єкти: • Однорічні дводольні та деякі
        злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування ґрунту до
        висіву, одночасно з висіванням або до сходів культури.
        <span className="paragraph"></span> Загальні рекомендації: Альфа-Прометрин
        застосовується в основному до або після посіву – до сходів культури.
        Норма витрати препарату залежить від механічного складу ґрунту та його
        потенційної засміченості. На легких ґрунтах застосовуються низькі норми,
        на важких (високогумусних) збільшується норма витрати до максимальної.
        Після застосування препарату не проводьте міжрядних культивацій,
        оскільки це знизить його гербіцидну дію. В посушливих умовах
        рекомендується загортання на глибину 2-3 см. При досходовому
        застосуванні ґрунт має бути добре підготовленим і зволоженим. Для
        розширення спектра дії та здешевлення вартості обробки посівів соняшнику
        рекомендується застосовувати у бакових сумішах: Еталон* (1,5-2,2 л/га) +
        Альфа-Прометрин (2,0 л/га); в умовах прохолодної весни – Альфа-Гетьман*
        (2,0 л/га) + Альфа-Прометрин (2,0 л/га), Конкур* (0,5 л/га) +
        Альфа-Прометрин (2,0 л/га) . Обробку гербіцидом слід проводити в ранкові
        або вечірні години за температури повітря від +15 °C до +25 °С.{" "}
      </>
    ),
  },
  {
    productTitle: "Антизлак",
    path: "/antizlak",
    src: Herbicides47,
    volume: "5 л",
    price: 94.98,
    currency: "USD",
    text: (
      <>
        Опис: Антизлак - селективний післясходовий грамініцид для знищення
        однорічних та багаторічних злакових бур’янів.
        <span className="paragraph"></span> Діюча речовина: Клетодим, 240 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Препарат має високу
        гербіцидну ефективність проти однорічних і багаторічних злакових
        бур’янів (більше 40 видів). • Не фітотоксичний для дводольних культур. •
        Застосовується незалежно від фази розвитку культурних рослин. • Завдяки
        досконалій формуляції активний інгредієнт надійно утримується на
        поверхні рослин та добре поглинається листками бур’янів.
        <span className="paragraph"></span> Механізм дії: Проникаючи через листя та
        стебла чутливих бур’янів, клетодим концентрується у точках росту,
        блокуючи синтез ліпідів. У толерантних культурах включається в обмін
        речовин та інактивується. Протягом 1-2 днів після обробки ріст бур’янів
        зупиняється, а через 3-7 днів точки росту буріють і відмирають, листки
        набувають хлоротичного забарвлення. Повна загибель настає через 7-12
        днів. Кореневища засихають на 12-20 день після обробки.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–400
        л/га.
        <span className="paragraph"></span> Температурні умови застосуванні. Від +15
        °С до +25 °С.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Буряки цукрові.
        <span className="paragraph"></span> Об’єкти: • Багаторічні злакові бур’яни;
        • Однорічні злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування незалежно
        від фази розвитку культури, за висоти бур’янів 10-15 см; • Обприскування
        незалежно від фази розвитку культури, у фазі 2-4 листків бур’янів.
        <span className="paragraph"></span> Загальні рекомендації: Антизлак
        необхідно використовувати тільки в суміші з ПАР Омега Екстра у
        співвідношенні 1:1. Це забезпечить краще прилипання клетодиму до
        поверхні рослини, швидке розчинення воскового шару кутикули та
        прискорення пересування його до точок росту. Норма витрати препарату та
        ефективність гербіцидної обробки залежать від видового складу бур’янів
        та їх вікового та фізіологічного стану. Найвищий рівень гербіцидної дії
        досягається при обробці однорічних злакових бур’янів у фазі 2-5 листків
        з нормою витрати Антизлак (0,4-0,5 л/га) + ПАР Омега Екстра (0,4-0,5
        л/га). Для контролю багаторічних злакових бур’янів висотою до 10 см,
        препарат застосовують з нормою Антизлак (0,8 л/га) + ПАР Омега Екстра
        (0,8 л/га). Обов’язковою вимогою є забезпечення суцільного покриття
        площі та рясне змочування рослин під час внесення препарату. Для
        знищення «падалиці» культурних злаків слід використовувати норми
        препарату, які рекомендовані для знищення багаторічних видів бур’янів.
        Найбільш чутливі стадії до грамініцидів у озимої пшениці – 1-2 листки, у
        ячменю – до початку кущення.{" "}
      </>
    ),
  },
  {
    productTitle: "Верітан",
    path: "/veritan",
    src: Herbicides48,
    volume: "5 л",
    price: 72.74,
    currency: "USD",
    text: (
      <>
        Опис: Верітан - післясходовий гербіцид для контролю однорічних злакових
        та дводольних бур’янів з ґрунтовою дією.
        <span className="paragraph"></span> Діюча речовина: Імазамокс, 50 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Висока ефективність проти
        широкого спектра однорічних злакових та дводольних бур’янів. • Подвійна
        дія на бур’яни (дія як через ґрунт, так і через листя). • Широке вікно
        застосування.  • Відсутня фітотоксичність до культури.
        <span className="paragraph"></span> Механізм дії: Імазамокс адсорбується
        через коріння та листову поверхню рослин, рухається по ксилемі та
        флоемі, накопичується у точках росту. В бур’янах діюча речовина інгібує
        синтез ензиму ацетогідроксильної кислоти (ALS), яка відповідає за
        утворення аліфатичних амінокислот (валін, лейцин та ізолейцин).
        Порушується синтез протеїну, що веде до порушення синтезу ДНК та
        уповільнення росту рослинних клітин, що призводить до загибелі бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га (залежно від фази розвитку та густоти стояння рослин).
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник (гібриди, стійкі до
        імідазолінонів); • Соя.
        <span className="paragraph"></span> Об’єкти: • Однорічні дводольні та
        злакові бур’яни ; • Однорічні дводольні та злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів у
        фазі 2-8 справжніх листків культури та у ранні фази розвитку бур’янів; •
        Обприскування посівів у фазі 1-3 трійчастих листків культури (злакові
        бур’яни у фазі 1-3 листки, дводольні – 2-4 листки).
        <span className="paragraph"></span> Загальні рекомендації: Оптимальними
        строками обробки Верітаном є період, коли дводольні бур’яни знаходяться
        на ранніх фазах розвитку (до 2-4 листків), а злакові бур’яни – 1-3
        листки. В деяких випадках після застосування гербіциду може з’являтися
        пожовтіння на листках соняшнику або верхівках рослин. Прояв цих
        симптомів не означає негативного впливу гербіциду на рослини соняшнику
        та його врожайність. Як правило, такі симптоми з’являються після обробки
        внаслідок дії на рослину стресових погодних умов (посуха, спека, низькі
        температури тощо). Приблизно на 10-14 день після обробки ці симптоми на
        рослинах зникають. Дощ через 1 годину після застосування не впливає на
        ефективність препарату. Оптимальна температура для застосування – від
        +14°С до +25°С. При застосуванні на площах з мінімальною обробкою ґрунту
        або на площах, де є велика кількість пожнивних решток, можливе зниження
        ґрунтової дії препарату. При застосуванні у бакових сумішах з іншими
        гербіцидами дія препарату підсилюється, що може привести до пригнічення
        рослин соняшнику, тому застосування сумісно з іншими гербіцидами не є
        рекомендованим. Для підсилення дії проти однорічних та багаторічних
        дводольних бур’янів на посівах гороху та сої рекомендуються внесення
        наступної бакової суміші: Верітан (0,6-0,8 л/га) + Альфа-Бентазон (1,5-
        2,0 л/га). Не використовувати в сумішах з протизлаковими гербіцидами та
        сульфонілсечовинами!{" "}
      </>
    ),
  },
  {
    productTitle: "Еталон",
    path: "/etalon",
    src: Herbicides49,
    volume: "20 л",
    price: 112.43,
    currency: "USD",
    text: (
      <>
        Опис: Еталон - ґрунтовий гербіцид системної дії проти однорічних
        злакових та дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Ацетохлор, 900 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Знищення бур’янів ще до
        моменту їх появи на поверхні ґрунту. • Широкий спектр контрольованих
        бур’янів. • Відсутність фітотоксичності у культур (за дотримання
        регламенту застосування). • Висока економічна ефективність, зменшення
        витрат на механічний обробіток ґрунту.
        <span className="paragraph"></span> Механізм дії: Після внесення в ґрунт
        Еталон залишається у верхньому шарі та засвоюється проростаючими
        паростками бур’янів та їхнім корінням. Інгібує синтез білка у чутливих
        рослин. Вплив препарату на бур’яни є незворотним. При дотриманні
        технології внесення забезпечує відсутність бур’янів протягом 4-6 тижнів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Сфера застосування препарату.
        <span className="paragraph"></span> Культура: • Соняшник, кукурудза.
        <span className="paragraph"></span> Об’єкти: • Однорічні злакові та
        дводольні бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування ґрунту до
        висівання, під час висівання або до появи сходів культури.
        <span className="paragraph"></span> Загальні рекомендації: На ефективність
        Еталону впливають такі фактори: 1. 1. Рівень вмісту гумусу в ґрунті.
        Необхідно збільшувати норму витрати препарату при збільшенні рівня
        вмісту гумусу в ґрунті. 2. Структура ґрунту. На важких за механічним
        складом ґрунтах необхідно збільшувати норму витрати препарату, на легких
        – зменшувати. 3. Вміст вологи в ґрунті. Гербіцидна активність Еталону
        проявляється тільки при наявності вологи в ґрунті. У посушливих умовах
        обов’язковою є механічна заробка препарату на невелику глибину. Внесення
        препарату здійснюється на поверхню ґрунту. Ґрунт має бути досить теплим,
        вологим та дрібногрудкуватим. Великі тверді грудки збільшують площу
        поглинання і зменшують ефективність Еталону. Оптимальні умови –
        випадання дощу або зрошення після обприскування (10-15 мм). Найкращий
        спосіб застосування – відразу після посіву, до появи сходів культури,
        під боронування. Без заробки Еталон вноситься за високої вірогідності
        випадання дощу.
      </>
    ),
  },
  {
    productTitle: "Кайман",
    path: "/kaiman",
    src: Herbicides50,
    volume: "5 л",
    price: 53.37,
    currency: "USD",
    text: (
      <>
        Опис: Кайман - інноваційний двокомпонентний грамініцид проти комплексу
        злакових бур'янів з високою селективністю до культурних рослин.
        <span className="paragraph"></span> Діюча речовина: Хізалофоп-п-этил, 65
        г/л: клетодим, 120 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат,
        емульгується.
        <span className="paragraph"></span> Переваги: • Синергія двох діючих речовин
        із різних хімічних класів. • Знищення як надземної частини, які так і
        підземної (кореневища). • Швидка дія на злакові бур’яни. • Висока
        селективність та безпечність для культурних рослин.
        <span className="paragraph"></span> Механізм дії: Клетодим відноситься до
        хімічної групи циклогександіонів, хізалофоп-п-етил — похідних
        арилоксифеноксипропіонових кислот. Обидві діючі речовини, що входять до
        складу препарату, зв’язуються з феноментом ацетил-Со-А-карбоксилазою,
        блокуючи синтез ліпідів, що призводить до зупинки росту та загибелі
        бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник, соя.
        <span className="paragraph"></span> Об’єкти: • Однорічні злакові бур’яни; •
        Багаторічні злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування незалежно
        від фази розвитку культури, але до змикання рядків, у фазі 2-4 листків
        бур’янів; • Обприскування незалежно від фази розвитку культури, але до
        змикання рядків, за висоти бур’янів 10-15 см.
      </>
    ),
  },
  {
    productTitle: "Кампус",
    path: "/kampus",
    src: Herbicides51,
    volume: "20 л",
    price: 111.04,
    currency: "USD",
    text: (
      <>
        Опис: Кампус - ґрунтовий гербіцид системної дії для боротьби з
        однорічними злаковими та широколистяними бур’янами.
        <span className="paragraph"></span> Діюча речовина: Пропізохлор, 720 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Володіє високою ефективністю
        проти бур’янів навіть при пониженій вологості ґрунту. • На відміну від
        інших хлорацетамідів контролює ще й широкий спектр дводольних бур’янів.
        • Тривала захисна дія – до 12 тижнів. • Не впливає на культури у
        сівозміні.
        <span className="paragraph"></span> Механізм дії: Діюча речовина –
        пропізохлор – системна, відноситься до хімічного класу хлорацетамідів.
        Пропізохлор поглинається корінням та проростаючими паростками бур’янів,
        пригнічує поділ клітин шляхом блокування синтезу білка у чутливих
        рослин. При досходовому застосуванні діюча речовина проникає в рослину
        через коріння, при післясходовому застосуванні пропізохлор проникає
        через коріння та листя.
        <span className="paragraph"></span> Властивості: При застосуванні гербіциду
        до сходів культури утворюється гербіцидний «екран». При проростанні
        бур’янів діюча речовина з гербіцидного «екрану» через коріння та стебло
        проникає до рослини, що призводить до її подальшої загибелі. Вплив
        препарату на бур’яни є незворотним. При обробітку вегетуючої небажаної
        рослинності діюча речовина потрапляє до рослини через листя, стебло та
        частково через кореневу систему. Ріст бур’янів зупиняється одразу після
        обробки препаратом. Видимі симптоми гербіцидної дії (зупинка росту,
        хлороз листя) з’являються на 3-7 день, повна загибель через 10-25 днів
        залежно від видового складу та стадії розвитку бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник; • Ріпак.
        <span className="paragraph"></span> Об’єкти: • Однорічні злакові та деякі
        дводольні бур’яни; • Однорічні злакові та деякі дводольні бур’яни.
        <span className="paragraph"></span> Спосіб обробки: • Обприскування ґрунту
        до посіву, після посіву, але до появи сходів культури; • Обприскування
        ґрунту до посіву, після посіву, але до появи сходів культури; •
        Обприскування посівів у фазі до 4-х листків у культури (не більше 2-х
        справжніх листків у бур’яну).
        <span className="paragraph"></span> Загальні рекомендації: Найкращий спосіб
        застосування – відразу після посіву, до появи сходів культури, під
        боронування. Без заробки гербіцид вноситься за високої вірогідності
        випадання дощу. Оптимальні умови – випадання дощу або зрошення після
        обприскування (10-15 мм). Ґрунт повинен бути дрібногрудкуватим – великі
        грудочки та велика кількість рослинних решток збільшують площу
        поглинання препарату і зменшують ефективність внесення. При застосуванні
        по сходах бур’янів найбільш сприятлива фаза у однорічних злакових –
        перша пара листків, у двосім’ядольних – фаза сім’ядоль. Не допускати
        переростання злакових та дводольних бур’янів до фази більше 2-х
        справжніх листків. Обов’язковою вимогою є забезпечення суцільного
        покриття площі під час внесення препарату. Після внесення препарат
        створює «екран» в поверхневому шарі ґрунту, що дозволяє контролювати
        проростаючи бур’яни протягом 6-8 тижнів. Для розширення спектра дії на
        широколистяні бур’яни можливе застосування бакових сумішей гербіцидів:
        Кампус (2,0-3,0 л/га) + Альфа-Прометрин (2,0 л/га); Кампус (2,0-3,0
        л/га) + Конкур (0,5-0,7 л/га); Кампус (2,0-3,0 л/га) + Альфа-Піралід
        (0,3-0,5 л/га); Кампус (2,0-3,0 л/га) + Отаман (2,0-4,0 л/га); Кампус
        (2,0-3,0 л/га) + Отаман Екстра (2,0-3,0 л/га); Кампус (2,0-3,0 л/га) +
        Сокар (2,5-3,0 л/га); Кампус (2,0- 3,0 л/га) + Альфа-Дикамба (0,2-0,5
        л/га); Кампус (2,0- 3,0 л/га) + Рамзес (50 г/га) + ПАР; Кампус (2,0-3,0
        л/га) + Хаммер (20-25 г/га) + ПАР; Кампус (2,0-3,0 л/га) + Хаммер Дуо
        (0,3-0,5 л/га).{" "}
      </>
    ),
  },
  {
    productTitle: "Конкур",
    path: "/konkur",
    src: Herbicides52,
    volume: "5 л",
    price: 116.39,
    currency: "USD",
    text: (
      <>
        Опис: Конкур - селективний системний гербіцид широкого спектра дії для
        контролю широколистих та злакових бур’янів.
        <span className="paragraph"></span> Діюча речовина: Метрибузин, 600 г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат
        суспензії.
        <span className="paragraph"></span> Переваги: • Контроль широкого спектра
        дводольних (у т.ч. щириця, лобода, гірчаки, гірчиця) і злакових (у т.ч.
        вівсюг, мишій, куряче просо) бур’янів. • Вдосконалена формуляція
        препарату. • Високоактивна дія як через ґрунт, так і через листя. •
        Тривалий період захисної дії (ґрунтова дія).
        <span className="paragraph"></span> Механізм дії: Діюча речовина гербіциду
        Конкур – метрибузин – відносится до похідних триазинів. В рослину
        потрапляє через листя й коріння та переміщується акропетально по
        ксилемі. В рослині порушує транспорт електронів, що беруть участь в
        процесі фотосинтезу, внаслідок чого рослина гине. Властивості: При
        застосуванні гербіциду до сходів культури утворюється гербіцидний
        «екран». При проростанні бур’янів діюча речовина з гербіцидного «екрану»
        через коріння та стебло проникає до рослини, що призводить до її
        подальшої загибелі. При обробітку вегетуючої небажаної рослинності діюча
        речовина потрапляє до рослини через листя, стебло та частково через
        кореневу систему. Ріст бур’янів зупиняється одразу після обробки
        препаратом. Видимі симптоми гербіцидної дії (зупинка росту, хлороз
        листя) з’являються на 3-7 день, повна загибель через 10-21 днів в
        залежності від видового складу та стадії розвитку бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соя.
        <span className="paragraph"></span> Об’єкт: • Однорічні дводольні та деякі
        злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування ґрунту до
        появи сходів культури.
        <span className="paragraph"></span> Загальні рекомендації: Найвища
        ефективність препарату досягається при застосуванні на ранніх стадіях
        розвитку бур’янів та за оптимальної вологості і температури ґрунту.
        Оптимальна температура навколишнього середовища для застосування
        препарату знаходиться в межах від +15 до +25 ˚С. Максимальні норми
        препарату вносять на важких за механічним складом ґрунтах, мінімальні
        норми – на легких ґрунтах. На ґрунтах з вмістом гумусу менше 1%
        застосування гербіциду не рекомендовано. На посівах, що знаходяться у
        стресовому стані (наприклад, під час посухи, надмірного зволоження
        ґрунту, при пошкодженні приморозками, шкідниками, хворобами) може
        з’являтися тимчасове пригнічення культури, що зникає протягом 7-10 днів.{" "}
      </>
    ),
  },
  {
    productTitle: "Лобера",
    path: "/lobera",
    src: Herbicides53,
    volume: "5 л",
    price: 49.27,
    currency: "USD",
    text: (
      <>
        Опис: Лобера -селективний висококонцентрований грамініцид проти
        однорічних та багаторічних злакових бур’янів.
        <span className="paragraph"></span> Діюча речовина: Хізалофоп-п-етил, 150
        г/л.
        <span className="paragraph"></span> Препаративна форма: Концентрат, що
        емульгується.
        <span className="paragraph"></span> Переваги: • Найвища концентрація
        хізалофоп-п-етилу серед подібних грамініцидів. • Унікальна комбінація
        емульгаторів забезпечує високу розчинність препарату та надшвидке
        проникнення у бур’яни. • Широкий температурний діапазон застосування. •
        Відсутність обмежень у сівозміні.
        <span className="paragraph"></span> Механізм дії: Гербіцид швидко проникає
        через поверхню листка бур’янів і переміщується до точок росту, коренів і
        кореневищ. Препарат порушує процес фотосинтезу, рослини перестають
        рости, набувають антоціанового забарвлення, в’януть і відмирають.
        <span className="paragraph"></span> Властивості: Протягом 1 години після
        застосування Лобери злакові бур’яни зупиняються в рості. Видимі симптоми
        гербіцидної дії (почервоніння листя, потемніння стебла) з’являються на
        7-8 день. При застосуванні слід урахувати, що повна загибель бур’янів
        настає не раніше ніж через 10-14 днів у однорічних злакових і 14–21 день
        – багаторічних. Але протягом цього часу вони не ростуть і не пригнічують
        культуру. За рахунок «закритої» структури молекули діючої речовини
        забезпечується зменшення молекулярного розпаду в період низької
        активності рослин бур’янів (несприятливі умови). В результаті цього
        після настання сприятливих погодних умов гербіцидна дія препарату
        продовжується, що гарантує загибель злакових бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Ріпак, цукрові буряки.
        <span className="paragraph"></span> Об’єкт: • Однорічні злакові бур’яни; •
        Багаторічні злакові бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів
        незалежно від фази розвитку культури у фазі 2-4 листків однорічних
        бур’янів; • Обприскування посівів незалежно від фази розвитку культури,
        за висоти 10-15 см багаторічних бур’янів.
        <span className="paragraph"></span> Загальні рекомендації: Найбільш
        ефективним для використання грамініциду Лобера є період активного росту
        бур’янів при теплій погоді та достатній вологості ґрунту. Найкраще
        проводити обприскування за наявності 2–3 листків до фази кущення у
        однорічних і 4–6 листків у багаторічних (при висоті рослин 10–15 см)
        бур’янів. За таких умов застосовують мінімальні рекомендовані норми
        витрати препарату. У випадку застосування гербіциду на більш пізніх
        фазах розвитку бур’янів необхідно збільшити норму витрати. Розчин
        препарату потрібно використати протягом кількох годин після
        приготування. Лоберу можна застосовувати у сумішах з іншими засобами для
        захисту рослин (гербіцидами, фунгіцидами, інсектицидами), окрім сильно
        лужних. Для знищення падалиці культурних злаків слід використовувати
        норми препарату, які рекомендовані для знищення багаторічних видів
        бур’янів. Найбільш чутливі стадії до грамініцидів у озимої пшениці – 1-2
        листки, у ячменю – до початку кущення. Температурні умови застосування
        від +10° С до +25°С. Слід уникати обробок гербіцидом під час спеки,
        засухи, заморозків, сильного вітру, інших несприятливих погодних умов та
        за годину до дощу.{" "}
      </>
    ),
  },
  {
    productTitle: "Містард",
    path: "/mistard",
    src: Herbicides54,
    volume: "0,5 кг",
    price: 18.43,
    currency: "USD",
    text: (
      <>
        Опис: Містард - гербіцид для контролю з дводольних бур’янів на посівах
        соняшнику, стійкого до трибенурон-метилу.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 750
        г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Спеціально розроблена
        препаративна форма для застосування на посівах спеціалізованих гібридів
        соняшнику. • Гнучкість застосування: від 2 до 8 листків культури. •
        Широкий спектр контрольованих бур’янів, особливо таких як осот, лобода
        біла, нетреба, дурман звичайний та ін. • Не має обмежень у сівозміні.
        <span className="paragraph"></span> Механізм дії: Після обробки
        трибенурон-метил швидко проникає у листя і переміщується в рослинах до
        точок росту. Дія препарату полягає у пригнічені ферменту
        ацетолактатсинтази та наступному блокуванні утворення валіну,
        ізолейцину, порушуючи процес синтезу білків і нуклеїнових кислот. При
        цьому, протягом 3-х годин після застосування відбувається зупинка поділу
        клітин та росту рослин чутливих видів бур’янів.
        <span className="paragraph"></span> Властивості: Видимі симптоми гербіцидної
        дії з’являються через 5–8 днів, а загибель бур’янів настає через 10-25
        днів. Менш чутливі бур’яни та ті, що перебувають на більш пізній стадії
        росту, можуть не загинути, але їх ріст припиняється і вони більше не
        конкурують з культурою за споживання поживних речовин і води.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник, стійкий до
        трибенурон-метилу.
        <span className="paragraph"></span> Об’єкти: • Однорічні та багаторічні
        дводольні бур’яни.
        <span className="paragraph"></span> Строк обприскування: • Обприскуваня
        культури у фазі від 2 до 8 листків.
        <span className="paragraph"></span> Загальні рекомендації: Для максимальної
        ефективності гербіцид слід вносити в період активного росту бур’янів у
        фазі 2-4 листків однорічних та розетки (10-15 см) – багаторічних. Слід
        звернути увагу, що при засміченні полів амброзією полинолистою у фазі
        від 2-х справжніх листків знижується чутливість до препаратів, які
        містять діючу речовину трибенурон-метил. Гербіцид забезпечує захист
        посівів від моменту обприскування до появи нової хвилі бур’янів. Тепла
        погода підвищує швидкість дії гербіциду, а прохолодна та суха —
        уповільнює її. Завдяки швидкому розпаду препарату у ґрунті відсутні
        обмеження для наступних культур у сівозміні. Препарат не можна
        застосовувати, якщо рослини мокрі від роси або дощу, а також якщо
        протягом 3 годин після обробки очікується дощ. В разі густого стояння
        культури або дуже сильного засмічення слід використовувати максимальний
        об’єм робочої рідини. Не застосовувати на гібридах, у яких немає гена
        стійкості до трибенурон-метилу! Уникати застосування препарату під час
        спеки, засухи, заморозків, сильного вітру та інших несприятливих
        погодних умов. Для боротьби зі злаковими бур’янами внесення грамініцидів
        проводити за 5-7 діб до або після внесення гербіциду Містард. Не
        рекомендується робити бакові суміші гербіциду Містард з грамініцидами та
        фунгіцидами. Робочий розчин препарату необхідно використати впродовж 24
        годин. Обприскування проводити за швидкості вітру не більше 3-4 м/с. Не
        допускайте знесення робочого розчину на сусідні культури, чутливі до
        гербіциду.{" "}
      </>
    ),
  },
  {
    productTitle: "Оскар Пауер",
    path: "/oskar-pauer",
    src: Herbicides55,
    volume: "10 л",
    price: 102.07,
    currency: "USD",
    text: (
      <>
        Опис: Оскар Пауер - новий двокомпонентний ґрунтовий гербіцид системної
        дії проти комплексу однорічних злакових і дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Пропізохлор, 610 г/л +
        тербутилазин, 290 г/л.
        <span className="paragraph"></span> Препаративна форма: Масляна дисперсія.
        <span className="paragraph"></span> Переваги: • Формуляція на основі
        масляної дисперсії забезпечує повне змочування та рівномірне
        розподілення діючих речовин на оброблюваній поверхні. • Масляна основа
        препарату забезпечує формування надійного ґрунтового екрану. • Посилена
        ефективність проти однорічних злакових та ряду найважливіших дводольних
        бур’янів. • Препарат має виключно м’яку дію на культурні рослини – немає
        необхідності у додаванні до препарату антидоту.
        <span className="paragraph"></span> Механізм дії: Діюча речовина
        тербутилазин є системною, належить до хімічного класу триазинів.
        Тербутилазин поглинається корінням проростаючих або вже пророслих
        бур’янів, в подальшому блокує транспорт електронів у клітинах (порушує
        процес фотосинтезу), що веде до загибелі рослини. Під час посухи
        тербутилазин утворює більш стійку захисну плівку, ніж інші діючі
        речовини. Діюча речовина пропізохлор – системна, відноситься до
        хімічного класу хлорацетамідів. Пропізохлор поглинається корінням та
        проростаючими паростками бур’янів, пригнічує поділ клітин шляхом
        блокування синтезу білка у чутливих рослин. Вплив цих двох діючих
        речовин на бур’яни є незворотнім.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник; • Кукурудза; •
        Соя.
        <span className="paragraph"></span> Об’єкти: • Однорічні злакові та
        дводольні бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування ґрунту до
        посіву, після посіву але до сходів культури; • Обприскування ґрунту до
        посіву, після посіву або по сходах у фазах 3-5 листків у культури (не
        більше 2-х справжніх листків у бур’яну); • Обприскування ґрунту до
        сівби, після сівби, але до появи сходів культури.
        <span className="paragraph"></span> Загальні рекомендації: Найкращий спосіб
        застосування у посівах соняшнику, кукурудзи та сої – відразу після
        посіву, до появи сходів культури. Без заробки гербіцид вноситься за
        високої вірогідності випадання дощу. При застосуванні на кукурудзі по
        сходах бур’янів найбільш сприятлива фаза у однорічних злакових – перша
        пара листків, у двосім’ядольних – фаза сім’ядоль. Не допускати
        переростання злакових та дводольних бур’янів до фази більше 2-х
        справжніх листків. Обов’язковою вимогою є забезпечення суцільного
        покриття площі під час внесення препарату. Оптимальні умови – випадання
        дощу або зрошення після обприскування (10-15 мм). Ґрунт повинен бути
        дрібногрудкуватий – великі грудки та велика кількість рослинних решток
        збільшують площу поглинання препарату і зменшують ефективність внесення.
        Після внесення препарат створює екран в поверхневому шарі ґрунту, що
        дозволяє контролювати проростаючі бур’яни протягом 6-8 тижнів.{" "}
      </>
    ),
  },
  {
    productTitle: "Отаман",
    path: "/otaman",
    src: Herbicides56,
    volume: "20 л",
    price: 68,
    currency: "USD",
    text: (
      <>
        Опис: Отаман — системний гербіцид-десикант суцільної дії.
        <span className="paragraph"></span> Діюча речовина: Ізопропіламінна сіль
        гліфосату 480 г/л, у кислотному еквіваленті - 360 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Висока ефективність
        десикації та контролю однорічних і багаторічних бур’янів. • Швидка дія
        на бур’яни. • Тривалий захисний період. • Низька собівартість
        застосування.
        <span className="paragraph"></span> Механізм дії: Після обробки, засіб
        блокує синтез білків і ферментів у клітинах рослин, внаслідок чого
        відбувається порушення утворення хлорофілу, що призводить до подальшого
        відмирання бур'янів. Допоміжна поверхнево-активна речовина у складі
        гербіциду Отаман швидко та ефективно проникає через листя та інші зелені
        частини рослини і розноситься по всіх органах, із ґрунтовим розчином
        може всмоктуватися кореневими волосками і пригнічує багаторічні
        кореневищні та коренепаросткові бур'яни. Спочатку спостерігається
        пожовтіння рослин, повноцінне в'янення бур'янів настає за 10-20 днів
        залежно від погодних умов, виду та фази розвитку бур'янів. 
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-250
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Зернові; • Поля, призначені
        під посіви ярих зернових, овочевих культур, соняшнику та картоплі; •
        Поля, призначені під посів озимої пшениці; • Землі
        несільськогосподарського призначення.
        <span className="paragraph"></span> Об’єкти: • Десикація культури та
        знищення бур'янів; • Однорічні злакові та дводольні бур'яни; •
        Багаторічні злакові та дводольні бур'яни; • Однорічні та багаторічні
        злакові та дводольні бур'яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів
        за 2 тижні до збирання при вологості зерна трохи більше 30%; •
        Обприскування бур'янів, що вегетують, восени після збирання попередника;
        • Обприскування вегетуючих бур'янів за 2 тижні до сівби; • Обприскування
        вегетуючих бур'янів.
        <span className="paragraph"></span> Загальні рекомендації: Для досягнення
        оптимальних результатів рекомендується застосовувати препарат у період
        активного росту бур'янів та за сприятливих погодних умов. Ідеальний етап
        розвитку для обробки: у багаторічних злакових - стадія 4-5 листків
        (10-15 см), у багаторічних дводольних - розетка діаметром 10-20 см, в
        однорічних злакових - коли листки досягають щонайменше 5 см, в
        однорічних дводольних - на стадії 2-3 справжніх листків. Гербіцид
        сумісний з більшістю звичайних засобів захисту на відповідних культурах,
        але завжди перевіряйте сумісність перед використанням. {" "}
      </>
    ),
  },
  {
    productTitle: "Отаман Екстра",
    path: "/otaman-ekstra",
    src: Herbicides57,
    volume: "20 л",
    price: 98,
    currency: "USD",
    text: (
      <>
        Опис: Отаман Екстра - сучасний системний гербіцид-десикант суцільної дії
        на основі калійної солі гліфосату.
        <span className="paragraph"></span> Діюча речовина: Калійна сіль гліфосату,
        663 г/л (у кислотному еквіваленті — 540 г/л).
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Швидке та більш повне
        проникнення в рослини бур’янів завдяки найвищій розчинності серед інших
        солей гліфосату. • Краща ефективність в прохолодних та посушливих
        умовах. • Надзвичайно швидка гербіцидна дія. • Відсутність обмежень в
        сівозміні.
        <span className="paragraph"></span> Механізм дії: Механізм гербіцидної дії
        полягає в інгібуванні біосинтезу ароматичних амінокислот, зокрема
        фенілаланіну і тирозину. На ультраструктурному рівні відбувається
        руйнування оболонок хлоропластів, набубнявіння ендоплазматичного
        ретикулуму і прогресуючий розпад мембран. Гербіцид сприяє накопиченню в
        зелених частинах рослин аміаку, що є сильною клітинною отрутою.
        <span className="paragraph"></span> Властивості: Препарат характеризується
        низьким рівнем піноутворення при приготуванні робочого розчину. Ця
        властивість дуже актуальна при використанні препарату методом
        малооб’ємного обприскування.
        <span className="paragraph"></span> Нора витрати робочого розчину: 150-250
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Зернові (десикація); • Поля,
        призначені під посів сільськогосподарських культур; • Поля, призначені
        під посів кукурудзи, цукрових буряків, сої, картоплі, льону, ріпаку,
        зернових та овочевих культур; • Плодові та виноградники; • Землі
        несільськогосподарського користування (смуги відчуження ліній
        електропередач, газо- та нафтопроводів, узбіччя доріг, залізничні
        насипи, аеродроми).
        <span className="paragraph"></span> Об’єкти: • Однорічні та багаторічні
        злакові та дводольні бур’яни; • Однорічні та багаторічні злакові та
        дводольні бур’яни ; • Однорічні та багаторічні бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів
        за 2 тижні до збирання, за вологості зерна не більше 30%; •
        Обприскування вегетуючих бур’янів восени після збирання попередника; •
        Обприскування вегетуючих бур’янів навесні, за 2 тижні до висівання (до
        обприскування виключити всі механічні обробки, крім ранньовесняного
        закриття вологи); • Направлене обприскування вегетуючих бур’янів навесні
        або влітку; • Обприскування вегетуючих бур’янів.
        <span className="paragraph"></span> Загальні рекомендації: Для досягнення
        найкращих результатів застосовуйте препарат, коли бур’яни знаходяться в
        стадії активного росту за сприятливих погодних умов. Оптимальна для
        обробки фаза розвитку бур’янів: багаторічні злакові – 4-5 листків (10-15
        см), багаторічні дводольні – розетки 10-20 см, однорічні злакові – за
        довжини листків мінімум 5 см, однорічні дводольні – 2-3-х справжніх
        листків. Застосовувати за сухої та безвітряної погоди не пізніше ніж за
        2-3 годин до випадання дощу. Під час обприскування не допускати
        потрапляння гербіциду на листя культурних рослин, дерев та кущів. Не
        застосовувати гербіцид Отаман Екстра в умовах тривалої засухи. Гербіцид
        можна змішувати з іншими загальновживаними пестицидами на відповідних
        культурах. Проте в кожному випадку необхідно перевіряти препарати на
        сумісність. Для підсилення гербіцидної дії на менш чутливі до гліфосатів
        бур’яни, зокрема, багаторічні дводольні, застосовують бакову суміш:
        Отаман Екстра (2-3 л/га) + Альфа-Дикамба (0,2 л/га).
      </>
    ),
  },
  {
    productTitle: "Сокар",
    path: "/sokar",
    src: Herbicides58,
    volume: "20 л",
    price: 85.43,
    currency: "USD",
    text: (
      <>
        Опис: Сокар - системний гербіцид-десикант суцільної дії на основі
        амонійної солі гліфосату.
        <span className="paragraph"></span> Діюча речовина: Гліфосат кислота, 436
        г/л (у формі амонійної солі гліфосату).
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Вдосконалена формуляція
        препарату (нова комбінація сучасних поверхнево-активних речовин). •
        Широкий спектр дії та контроль бур’янів, що важко викорінюються. •
        Ефективний у всіх діапазонах температур, при яких рослини зберігають
        життєздатність. • Швидке проникнення та швидка гербіцидна дія.
        <span className="paragraph"></span> Механізм дії: Сокар проникає в рослини
        через листя і стебла та переноситься по всіх органах, включаючи коріння.
        Діюча речовина блокує синтез ароматичних амінокислот і змінює
        проникність клітинних мембран, що веде до руйнування клітинних структур,
        ураження точок росту і повного відмирання надземних і підземних органів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 150-250
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Поля, призначені під посіви
        сільськогосподарських культур; • Землі несільськогосподарського
        користування (смуги відчуження ліній електропередачі, газо- та
        нафтопроводів, узбіччя доріг, залізничні насипи).
        <span className="paragraph"></span> Об’єкт: • Однорічні злакові та дводольні
        бур’яни; • Багаторічні злакові та дводольні бур’яни; • Однорічні та
        багаторічні бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування
        вегетуючих бур’янів восени після збирання попередника; • Обприскування
        вегетуючих бур’янів.
        <span className="paragraph"></span> Загальні рекомендації: Ефективність дії
        Сокару залежить від стадії розвитку рослини та погодних умов. Бажано,
        щоб рослина знаходилась в стадії активного росту: багаторічні злакові –
        4-5 листків (10-15 см), багаторічні дводольні – розетки 10-20 см,
        однорічні злакові – за довжини листків мінімум 5 см, однорічні дводольні
        – 2-3-х справжніх листків. Рекомендовані погодні умови: суха та
        безвітряна погода, не пізніше, ніж за 2 години до випадання дощу. Під
        час обприскування не допускати потрапляння гербіциду на листя культурних
        рослин, дерев та кущів. Не застосовувати Сокар при температурі повітря
        нижче +10°С та вище +25°С. Гербіцид можна змішувати з іншими
        загальновживаними пестицидами на відповідних культурах. Проте, в кожному
        випадку необхідно перевіряти препарати на сумісність. Для підсилення
        гербіцидної дії на менш чутливі до гліфосатів бур’яни, зокрема,
        багаторічні дводольні, застосовують бакову суміш: Сокар (2,5-3,0 л/га) +
        Альфа-Дикамба (0,2-0,4 л/га).{" "}
      </>
    ),
  },
  {
    productTitle: "Сантал",
    path: "/santal",
    src: Herbicides59,
    volume: "10 л",
    price: 145.5,
    currency: "USD",
    text: (
      <>
        Опис: Сантал - двокомпонентний післясходовий гербіцид системної дії
        проти злакових та дводольних бур’янів на посівах соняшнику, стійкого до
        імідазолінонів.
        <span className="paragraph"></span> Діюча речовина: Імазамокс, 33 г/л +
        імазапір, 15 г/л.
        <span className="paragraph"></span> Препаративна форма: Розчинний
        концентрат.
        <span className="paragraph"></span> Переваги: • Вдосконалена формуляція
        препарату, висока якість покриття та утримання діючих речовин. •
        Посилений захист від комплексу бур’янів та вовчка соняшникового. •
        Високоактивна дія як через ґрунт, так і через листя. • Надійний контроль
        наступних хвиль бур’янів (ґрунтова дія).
        <span className="paragraph"></span> Механізм дії: Імазамокс та імазапір
        адсорбуються через коріння та листову поверхню рослин, рухаються по
        ксилемі та флоемі та накопичуються у точках росту. В бур’янах діючі
        речовини інгібують синтез ензиму ацетогідроксильної кислоти (ALS), яка
        відповідає за утворення аліфатичних амінокислот (валін, лейцин та
        ізолейцин). Порушується синтез протеїну, що веде до порушення синтезу
        ДНК та уповільненню росту рослинних клітин, що призводить до загибелі
        бур’янів.
        <span className="paragraph"></span> Норма витрати робочого розчину: 200-400
        л/га.
        <span className="paragraph"></span> Сфера застосування.
        <span className="paragraph"></span> Культура: • Соняшник (гібриди, стійкі до
        імідазолінонів).
        <span className="paragraph"></span> Об’єкт: • Злакові та дводольні бур’яни.
        <span className="paragraph"></span> Строк обробки: • Обприскування посівів у
        фазі 4-х листків культури та на початкових фазах розвитку бур’янів.
        <span className="paragraph"></span> Загальні рекомендації: Оптимальними
        строками обробки Санталом є період, коли бур’яни знаходяться на ранніх
        фазах розвитку (до 2-4 листків), а злакові бур’яни - 1-3 листки. Не
        рекомендується застосовувати препарат до фази 2-х справжніх листків
        культури. В деяких випадках після застосування гербіциду на листках
        соняшнику може з’являтися пожовтіння листя або верхівки рослин. Прояв
        цих симптомів не означає негативного впливу гербіциду на рослини
        соняшнику та його врожайність. Як правило, такі симптоми з’являються
        після обробки внаслідок дії на рослину стресових погодних умов (посуха,
        спека, низькі температури тощо). Приблизно на 10-14 день після обробки
        ці симптоми на рослинах зникають. Дощ через 1 годину після застосування
        не впливає на ефективність препарату. При застосуванні на площах з
        мінімальною обробкою ґрунту або на площах, де є велика кількість
        пожнивних решток, можливе зниження ґрунтової дії препарату. Протягом 10
        днів після застосування потрібно виключити механічні обробки, які
        порушать гербіцидний «екран» на полі. При застосуванні у бакових сумішах
        з іншими гербіцидами дія препарату підсилюється, що може привести до
        пригнічення рослин соняшника, тому застосування сумісно з іншими
        гербіцидами не є рекомендованим.{" "}
      </>
    ),
  },
  {
    productTitle: "Триатлон",
    path: "/triatlon",
    src: Herbicides60,
    volume: "0,5 кг",
    price: 48.49,
    currency: "USD",
    text: (
      <>
        Опис: Триатлон - перший в Україні трикомпонентний післясходовий гербіцид
        системної дії для захисту посівів зернових колосових культур від
        однорічних та багаторічних дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Тифенсульфурон-метил,
        300 г/кг + трибенурон-метил, 300 г/кг + флорасулам, 100 г/кг.
        <span className="paragraph"></span> Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span> Переваги: • Максимально широкий спектр
        дії проти дводольних бур’янів. • Контроль підмаренника чіпкого у всіх
        фазах розвитку. • Широкі строки застосування. • Відсутність обмежень в
        сівозміні.
        <span className="paragraph"></span> Механізм дії: Після обробки діючі
        речовини препарату швидко проникають в листя і переміщуються в рослинах
        до точок росту. Дія препарату полягає у пригнічені ферменту
        ацетолактатсинтази та наступному блокуванні утворення валіну,
        ізолейцину, порушуючи процес синтезу білків і нуклеїнових кислот. При
        цьому протягом 3-х годин після застосування відбувається зупинка поділу
        клітин та росту рослин чутливих видів бур’янів.
        <span className="paragraph"></span> Властивості: Видимі симптоми дії
        (зупинка росту, хлорози, некрози, почервоніння листя, побіління
        верхівки) з’являються через 4-7 днів, а повна загибель бур’янів настає
        через 14-28 днів. Малочутливі та перерослі бур’яни можуть не загинути,
        проте їх ріст і подальший розвиток значно уповільнюється. Тепла волога
        погода підвищує ефективність гербіциду, а прохолодна та суха уповільнює
        його вплив. 
        <span className="paragraph"></span> Норма витрати робочого розчину: 200–300
        л/га. <span className="paragraph"></span>  Сфера застосування:{" "}
        <span className="paragraph"></span>
         Культура:   • Пшениця озима та яра. <span className="paragraph"></span>{" "}
        Об’єкти: • Однорічні та багаторічні дводольні, в т.ч. стійкі до 2,4-Д,
        бур’яни, падалиця соняшника, стійкого до трибенуронметилу та
        імідазолінонів. <span className="paragraph"></span> Строк обробки: •
        Обприскування посівів починаючи з фази 2-3 листків до появи прапорцевого
        листка <span className="paragraph"></span>
        культури включно. Загальні рекомендації:  Найвища ефективність препарату
        спостерігається при обприскуванні однорічних бур’янів у фазі 2-6
        листків, багаторічних – у фазі розетки (10-15 см). Гербіцид слід
        обов’язково застосовувати у суміші з ПАР Альфалип Екстра. Препарат не
        можна застосовувати, якщо рослини мокрі від роси або дощу, а також якщо
        протягом 3 годин після обробки очікується дощ. Уникати застосування
        препарату під час спеки, засухи, заморозків, сильного вітру та інших
        несприятливих погодних умов. При осінньому застосуванні уникати
        заморозків протягом 14 днів після застосування. Для одержання найвищої
        ефективності необхідно: обприскування проводити при швидкості вітру не
        більше ніж 5 м/с та вологості повітря не нижче 50%; дотримуватися
        рівномірності та якості обприскування по всій площі поля;
        використовувати ПАР Альфалип Екстра; мішалка обприскувача повинна
        працювати протягом всього часу обробки.{" "}
      </>
    ),
  },
  {
    productTitle: "Триатлон Прайм",
    path: "/triatlon-prime",
    src: Herbicides61,
    volume: "0,5 кг",
    price: 48.49,
    currency: "USD",
    text: (
      <>
        Опис: Триатлон Прайм - новий гербіцид системної дії для захисту зернових
        колосових культур від максимальної кількості злісних однорічних та
        багаторічних дводольних бур’янів з додатковим захистом культури.
        <span className="paragraph"></span> Діюча речовина: Трибенурон-метил, 250
        г/кг + тифенсульфурон-метил, 250 г/кг + флорасулам, 100 г/кг + (антидот)
        ізоксадифен-етил, 100 г/кг.
        <span className="paragraph"></span>Препаративна форма: Гранули, що
        диспергуються у воді.
        <span className="paragraph"></span>
         Переваги: • Посилена дія проти дводольних бур’янів. • Відсутність
        фітотоксичної дії на культуру. • Ефективна дія навіть на перерослі
        бур’яни. • Придатний для осіннього застосування. • Відсутність обмежень
        в сівозміні.
        <span className="paragraph"></span>Механізм дії: Після обробки діючі
        речовини препарату швидко проникають в листя і переміщуються в рослинах
        до точок росту. Дія препарату полягає у пригнічені ферменту
        ацетолактатсинтази та наступному блокуванні утворення валіну,
        ізолейцину, порушуючи процес синтезу білків і нуклеїнових кислот. При
        цьому протягом 3-х годин після застосування відбувається зупинка поділу
        клітин та росту рослин чутливих видів бур’янів. Ізоксадифен-етил впливає
        на ферменти, які прискорюють деградацію діючих речовин-гербіцидів в
        зернових культурах.
        <span className="paragraph"></span>
        Властивості: Видимі симптоми дії (зупинка росту, хлорози, некрози,
        почервоніння листя, побіління верхівки) з’являються через 4-7 днів, а
        повна загибель бур’янів настає через 14-28 днів. Малочутливі та
        перерослі бур’яни можуть не загинути, проте їхній ріст і подальший
        розвиток значно уповільнюється. Тепла волога погода підвищує
        ефективність гербіциду, а прохолодна та суха уповільнює його вплив.
        <span className="paragraph"></span>
        Норма витрати робочого розчину: 200-400 л/га.
        <span className="paragraph"></span>
        Сфера застосування.
        <span className="paragraph"></span>
        Культура: • Пшениця озима, ячмінь ярий.
        <span className="paragraph"></span>Об’єкти: • Однорічні та багаторічні
        дводольні, в т.ч. стійкі до 2,4-Д, бур’яни, самосів соняшника, стійкого
        до трибенурон метилу та імідазолінонів.
        <span className="paragraph"></span>Спосіб обробки: • Обприскування посівів
        починаючи з фази 2-3 листків до появи прапорцевого листка культури
        включно.
        <span className="paragraph"></span>Загальні рекомендації: Найвища
        ефективність препарату спостерігається при обприскуванні однорічних
        бур’янів у фазі 2-6 листків, багаторічних – у фазі розетки (10-15 см).
        Гербіцид слід обов’язково застосовувати у суміші з ПАР Альфалип Екстра.
        Препарат не можна застосовувати, якщо рослини мокрі від роси або дощу, а
        також якщо протягом 3 годин після обробки очікується дощ. Уникати
        застосування препарату під час спеки, засухи, заморозків, сильного вітру
        та інших несприятливих погодних умов. При осінньому застосуванні уникати
        заморозків протягом 14 днів після застосування. Для одержання найвищої
        ефективності необхідно: обприскування проводити при швидкості вітру не
        більше ніж 5 м/с та вологості повітря не нижче 50%; дотримуватися
        рівномірності та якості обприскування по всій площі поля;
        використовувати ПАР Альфалип Екстра; мішалка обприскувача повинна
        працювати протягом всього часу обробки.{" "}
      </>
    ),
  },
  {
    productTitle: "Хаммер",
    path: "/hammer",
    src: Herbicides62,
    volume: "0,5 кг",
    price: 37.58,
    currency: "USD",
    text: (
      <>
        Опис: Хаммер - селективний післясходовий гербіцид системної дії для
        контролю однорічних та деяких багаторічних дводольних бур’янів.
        <span className="paragraph"></span> Діюча речовина: Флорасулам, 250 г/кг.
        <span className="paragraph"></span> Препаративна форма: Флорасулам, 250
        г/кг.
        <span className="paragraph"></span> Переваги: • Ефективна дія на бур’яни у
        т.ч. стійкі до 2,4-Д і дикамби. • Контроль падалиці соняшнику всіх
        видів. • Відсутність фітотоксичності завдяки високій селективності. •
        Ідеальний баковий партнер для розширення спектра контрольованих
        бур’янів.
        <span className="paragraph"></span>Механізм дії: Флорасулам належить до
        групи інгібіторів ацетолактатсинтази (ALS) та володіє системною дією.
        Після обробки швидко проникає в рослину через листя і коріння,
        переміщується по провідним пучкам (флоема, ксилема) до точки росту. Дія
        препарату полягає у пригніченні ферменту ацеталактатсинтази, що, в свою
        чергу, блокує утворення валіну, ізолейцину і лейцину. В подальшому
        (протягом 3-х годин після застосування) відбувається зупинка поділу
        клітин та росту чутливих бур’янів.
        <span className="paragraph"></span>
        Властивості: Видимі симптоми гербіцидної дії (зупинка росту, хлорози,
        некрози, побіління верхівки, почервоніння листя) з’являються на 4-7
        день, а повна загибель бур’янів настає через 14-28 днів після
        застосування препарату. У чутливих до флорасуламу бур’янів після обробки
        відбувається зупинка росту з наступним відмиранням точки росту та всієї
        рослини. Малочутливі та перерослі бур’яни можуть не загинути, проте їх
        ріст та подальший розвиток значно уповільнюється чи зупиняється.
        <span className="paragraph"></span>
        Норма витрати робочого розчину: 200–400 л/га.
        <span className="paragraph"></span>
        Сфера застосування.
        <span className="paragraph"></span>
        Культура: • Пшениця озима та яра; • Кукурудза.
        <span className="paragraph"></span>Об’єкти: • Однорічні та деякі багаторічні
        дводольні, в т.ч. стійкі до 2,4-Д, бур’яни, падалиця соняшнику, стійкого
        до трибенуронметилу та імідазолінонів.
        <span className="paragraph"></span>Строк обробки: • Обприскування посівів
        починаючи з фази 2-3 листків до появи прапорцевого листка культури
        включно (на раніх фазах розвитку бур’янів); • Обприскування посівів у
        фазі 3-6 листків культури.
        <span className="paragraph"></span>Загальні рекомендації: Застосовувати
        гербіцид слід по активно вегетуючим бур’янам в інтервалі температур від
        +5 ˚С до +25 ˚С. Препарат діє на бур’яни найкраще у фазі 2-4 листків у
        однорічних та за висоти 10-15 см у багаторічних. Не застосовувати
        препарат на посівах, що знаходяться у стресовому стані (наприклад, під
        час посухи, надмірного зволоження ґрунту, при пошкодженні приморозками,
        шкідниками, хворобами). Для досягнення максимальної ефективності
        препарат необхідно наносити рівномірно на листову поверхню рослин,
        застосовуючи добре відрегульоване обладнання. Гербіцид слід обов’язково
        застосовувати у сіміші з ПАР Альфалип Екстра. Препарат не можна
        застосовувати, якщо рослини мокрі від роси або дощу, а також якщо
        протягом 3 годин після обробки очікується дощ. Гербіцид забезпечує
        захист посівів від моменту обприскування до появи нової хвилі бур’янів.
        Для розширення спектра контрольованих бур’янів можливе застосування
        бакових сумішей на посівах: кукурудзи – Альфа-Маїс (10 г/га) + Хаммер
        (20 г/га) + ПАР Бустер (0,1 л/га); Альфа-Дикамба (0,2-0,3 л/га) + Хаммер
        (15-20 г/га) + ПАР Бустер (0,1 л/га); озимої пшениці – Альфа-Піралід*
        (0,2-0,3 л/га) + Хаммер (15-20 г/га) + ПАР Альфалип Екстра (0,2-0,25
        л/га); Альфа-Стар (15-20 г/га) + Хаммер (15-20 г/га) + ПАР Альфалип
        Екстра (0,2-0,25 л/га).{" "}
      </>
    ),
  },
  {
    productTitle: "Хаммер Дуо",
    path: "/hammer-duo",
    src: Herbicides63,
    volume: "5 л",
    price: 31.54,
    currency: "USD",
    text: (
      <>
        Опис: Хаммер Дуо - новий двокомпонентний системний гербіцид з розширеним
        спектром дії для застосування на зернових культурах.
        <span className="paragraph"></span> Діюча речовина: 2,4 Д-2-етилгексиловий
        ефір, 491,5 г/л + флорасулам, 8,5 г/л.
        <span className="paragraph"></span>Препаративна форма: Суспо-емульсія.
        <span className="paragraph"></span>
        Переваги: • Розширений спектр контрольованих бур’янів, особливо таких,
        як лобода біла, паслін чорний, підмаренник чіпкий та інші. • Висока
        швидкість дії гербіциду. • Застосування при температурі навколишнього
        середовища від +8° С. • Відсутність обмежень для культур у сівозміні.
        <span className="paragraph"></span>Механізм дії: 2,4-Д етилгексиловий ефір
        та флорасулам є системними діючими речовинами. Діюча речовина 2,4-Д
        відноситься до гербіцидів ауксинового типу, які при попаданні до рослини
        накопичуються та порушують процес метаболізму азоту та синтезу
        ферментів. 2,4-Д ефір більш активний, ніж інші солі 2,4-Д
        (диметиламінна, натрієва). Флорасулам належить до групи інгібіторів
        ацетолактатсинтази. Після обробки швидко проникає в рослину через листя
        і коріння, переміщується по провідним пучкам (флоема, ксилема) до точки
        росту. Дія препарату полягає у пригніченні ферменту ацеталактатсинтази,
        що, в свою чергу, блокує утворення валіну, ізолейцину і лейцину. В
        подальшому (протягом 3-х годин після застосування) відбувається зупинка
        поділу клітин.
        <span className="paragraph"></span>
        Властивості: Гербіцид застосовується як післясходовий. Видимі симптоми
        гербіцидної активності (втрата тургору, в’янення листя) з’являються вже
        через 6-7 годин після застосування препарату, а повна загибель бур’янів
        відбувається через 4-14 днів в залежності від видового складу та стадії
        розвитку бур’янів, густоти стояння культури, погодних умов.
        <span className="paragraph"></span>
        Норма витрати робочого розчину: 200–300 л/га.
        <span className="paragraph"></span>
        Сфера застосування.
        <span className="paragraph"></span>
        Культура: • Пшениця озима та яра; • Кукурудза.
        <span className="paragraph"></span>Об’єкти: • Однорічні та багаторічні
        дводольні бур’яни.
        <span className="paragraph"></span>Строк обробки: • Обприскування від фази
        кущення до утворення 2-го міжвузля культури; • Обприскування з фази 3 до
        фази 7 (включно) листків культури.
        <span className="paragraph"></span>Загальні рекомендації: Для максимальної
        ефективності гербіцид слід вносити в період активного росту бур’янів у
        фазі 2-10 листків в однорічних та 10-15 см (розетка) у багаторічних.
        Оптимальна температура навколишнього середовища для застосування
        препарату знаходиться в межах від +8 до +25 ˚С. За посушливих умов
        необхідно застосовувати максимальну норму витрати. Уникайте застосування
        препарату одразу після заморозку або в очікуванні заморозку в ніч після
        обробітку. Для досягнення максимальної ефективності препарат необхідно
        наносити рівномірно на листову поверхню рослин, застосовуючи добре
        відрегульоване обладнання. Препарат також можна змішувати з фунгіцидами,
        інсектицидами. Перед приготуванням робочого розчину з суміші препаратів
        рекомендується перевірити їх фізичну змішуваність в малій ємності.
        Гербіцид стійкий до змивання дощем, що випав через годину після
        застосування.
      </>
    ),
  },
  // четвёртый файл
  {
    productTitle: "Базагран Про",
    path: "/basagran-pro",
    src: Herbicides64,
    volume: "10 л",
    price: 170.85,
    currency: "USD",
    text: (
      <>
        Опис: Базагран Про - високоселективний контактний післясходовий гербіцид
        для контролю однорічних дводольних бур’янів у посівах бобових, який
        поєднав традиції та інновації.
        <span className="paragraph"></span>Діюча речовина: 480 г/л Бентазон.
        <span className="paragraph"></span>
        Препаративна форма: Розчинний концентрат.
        <span className="paragraph"></span>
        Переваги: • Поліпшена формуляція (хімічний склад) Базагран® Про сприяє
        кращому утриманню й поглинанню бентазону під час внесення, що забезпечує
        вищу ефективність і стабільність роботи порівняно з іншими аналогічними
        конкурентними продуктами • Не потребує ад’ювантів та
        гербіцидів-партнерів для підвищення ефективності контролю бур’янів •
        Висока селективність та широке вікно застосування дають змогу легко
        адаптувати технологію захисту під конкретну ситуацію й досягати
        найкращого результату • Відмінний контроль падалиці ріпаку та соняшнику
        виробничих систем Clearfiеld®, Clеarfield® Plus та ExpressSun®
        <span className="paragraph"></span>Особливості продукту
        <span className="paragraph"></span>
        Норма витрати робочої рідини: 200–300 л/га.
        <span className="paragraph"></span>
        Сумісність з іншими препаратами: Базагран® Про не рекомендується
        використовувати у бакових сумішах з протизлаковими гербіцидами та
        інсектицидами на основі фосфорорганічних сполук. Попри хімічну
        сумісність Базагран® Про з більшістю агрохімікатів рекомендується в
        кожному окремому випадку робити тест на сумісність.
        <span className="paragraph"></span>
        Регламент застосування:
        <span className="paragraph"></span>
        Культура: • Горох; • Соя.
        <span className="paragraph"></span>Час застосування: • обприскування посівів
        у фазі 3–6 листків гороху, бур’яни на початкових фазах росту і розвитку:
        сім’ядолі – до 4–6 справжніх листків; • послідовне обприскування посівів
        у фазі 3 листків та у фазі 5–6 листків або через 10–14 днів від
        попереднього внесення за появи сходів наступних хвиль бур’янів; •
        обприскування посівів у період від примордіальних листків до 3-го
        трійчастого листка; • першого трійчастого листка та у фазі 2–3-го
        трійчастого листка або через 10–14 днів від попереднього внесення за
        появи сходів наступних хвиль бур’янів.
        <span className="paragraph"></span>Рекомендації щодо застосування: Базагран®
        Про слід застосовувати на початкових фазах росту і розвитку бур’янів за
        активної їх вегетації Базагран® Про є контактним гербіцидом, механізм
        дії якого полягає в інгібуванні процесу фотосинтезу. Тому ефективність
        значною мірою буде залежати від норми внесення гербіциду та якості
        покриття листків і стебел робочим розчином. Максимальна ефективність
        досягається за застосування по активно вегетуючих дводольних бур’янах у
        ранкові часи за сонячної погоди. Важливо, щоб після внесення бур’яни
        протягом 4–6 годин зазнавали впливу активної сонячної інсоляції.
        Застосування Базагран® Про у вечірні часи та в нічний період може
        супроводжуватися суттєвим зниженням ефективності Оптимальною для
        ефективної роботи вважається температура вище +10–12°C, але не вище
        +25°C. Слід уникати внесення, якщо прогнозується пониження температури
        нижче +8°C Істотні опади або полив протягом 6 годин після внесення
        Базагран® Про здатні знизити його ефективність.
      </>
    ),
  },
  {
    productTitle: "Євро-Лайтінг Плюс",
    path: "/evro-laiting-plus",
    src: Herbicides65,
    volume: "10 л",
    price: 215.66,
    currency: "USD",
    text: (
      <>
        Опис: Євро-Лайтінг Плюс — найефективніший контроль бур’янів + довершені
        гібриди соняшнику=максимум врожай.
        <span className="paragraph"></span>Діюча речовина: Імазапір, 16,5 г/л
        Імазамокс 7,5г/л.
        <span className="paragraph"></span>
        Препаративна форма: Розчинний концентрат.
        <span className="paragraph"></span>
        Переваги: • Гнучкість у виборі норми та часу застосування гербіциду
        залежно від регіональних особливостей • Ефективність практично не
        залежить від кількості опадів – діє через листя та довготривало через
        ґрунт • Можливе використання в системах мінімального та нульового
        (no-till) обробітку ґрунту
        <span className="paragraph"></span>Сумісність з іншими препаратами:
        Використовувати в бакових сумішах з іншими засобами захисту недоцільно
        (високоефективний препарат). Препарат не можна використовувати в бакових
        сумішах з протизлаковими гербіцидами та інсектицидами фосфорорганічної
        групи. Регламенти застосування:
        <span className="paragraph"></span>
        Культура: • Соняшник (гібриди, стійкі до гербіциду Євро-Лайтнінг® Плюс
        виробничої системи Clearfield® Plus).
        <span className="paragraph"></span>Терміни застосування: • обприскування
        посівів у фазу від 2 до 8 справжніх листків культури (на початкових
        стадіях розвитку бур’янів).
        <span className="paragraph"></span>Спектр дії: • однодольні та дводольні
        бур’яни.
        <span className="paragraph"></span>Рекомендації при використанні: Препарати
        групи імідазолінонів (д.р. такі, як імазамокс, імазапір та ін.) не
        рекомендується використовувати на одному полі частіше 1 разу на 3 роки.
        Найкраща ефективність гербіциду досягається за сприйнятливих для
        активної вегетації бур’янів температур. Не рекомендується застосовувати
        при середньодобових температурах нижче +10°С та при перепаді нічних та
        денних температур більше 15°С. Рекомендується застосовувати
        Євро-Лайтнінг® Плюс, коли більшість бур’янів активно ростуть та
        знаходяться на початкових фазах розвитку: дводольні у фазі 2–4 справжні
        листки, однодольні – 1–3 листки.
      </>
    ),
  },
  {
    productTitle: "Євро-Лайтінг",
    path: "/evro-laiting",
    src: Herbicides66,
    volume: "10 л",
    price: 383.71,
    currency: "USD",
    text: (
      <>
        Опис: Євро-Лайтінг — це унікальна можливість знищення бур’янів у посівах
        соняшнику за допомогою післясходового внесення гербіциду.
        <span className="paragraph"></span>Діюча речовина: 15 г/л Імазапір, 33 г/л
        Імазамокс.
        <span className="paragraph"></span>
        Препаративна форма: Водний розчин.
        <span className="paragraph"></span>
        Переваги: • післясходовий гербіцид на соняшнику з широким спектром дії;
        • одна обробка за весь вегетаційний період; • знищує злакові та
        дводольні бур’яни, в тому числі найбільш проблемні (вовчок, осот,
        амброзія тощо); • ефективність практично не залежить від кількості
        опадів – діє через листя та довготривало через ґрунт; • можливе
        використання в системах мінімального та нульового (no-till) обробітку
        ґрунту.
        <span className="paragraph"></span>Сумісність з іншими препаратами:
        використовувати в бакових сумішах з іншими засобами захисту недоцільно
        (високоефективний препарат). Не можна використовувати в бакових сумішах
        з інсектицидами фосфорорганічної групи.
        <span className="paragraph"></span>
        Регламенти застосування:
        <span className="paragraph"></span>
        Культура: • Соняшник гібриди,стійкі до гербіциду Євро-Лайтнінг®
        виробничої системи Clearfield®.
        <span className="paragraph"></span>Терміни застосування: • обприскування у
        фазу 4 листків культури та на початкових фазах розвитку бур’янів.
        <span className="paragraph"></span>Спектр дії: • злакові та дводольні
        бур’яни.
        <span className="paragraph"></span>Рекомендації при використанні: Препарати
        з групи імідазолінонів (д.р. такі, як імазапір, імазамокс та ін.) не
        рекомендується використовувати на одному полі частіше 1 разу на 3 роки.
        Найкраща ефективність гербіциду досягається за сприйнятливих для
        активної вегетації бур’янів температур. Не рекомендується застосовувати
        при середньодобових температурах нижче +10°С та при перепаді нічних та
        денних температур більше 15°С. Рекомендується застосовувати
        Євро-Лайтнінг®, коли більшість бур’янів активно ростуть та знаходяться
        на початкових фазах розвитку: дводольні у фазі 2–4 справжні листки,
        однодольні – 1–3 листки.
      </>
    ),
  },
  {
    productTitle: "Пульсар Флекс",
    path: "/pulsar-flex",
    src: Herbicides67,
    volume: "10 л",
    price: 202.74,
    currency: "USD",
    text: (
      <>
        Опис: Пульсар Флекс — єдиний універсальний соло-Імазамокс гербіцид із
        потужною формуляцією та доступною вартістю на гектар.
        <span className="paragraph"></span>Діюча речовина: 25 г/л Імазамокс.
        <span className="paragraph"></span>
        Препаративна форма: Розчинний концентрат.
        <span className="paragraph"></span>
        Переваги: • Ідеальне рішення для посушливих регіонів. • Доступна
        вартість/га для вирощування соняшнику за технологіями Clearfield® та
        Clearfield® Plus. • Менше пестицидне навантаження. • Універсальний
        продукт – можливість використання на різних культурах (соняшник, соя,
        горох). • Надійний контроль бур’янів та всіх рас вовчку соняшникового.
        <span className="paragraph"></span> Регламент застосування:
        <span className="paragraph"></span>
        Культура: • Соняшник гібриди стійкі до данного гербіциду виробничих
        систем Cleafield® та Clearfield® Plus; • Соя; • Горох.
        <span className="paragraph"></span>Терміни застосування: • обприскування
        посівів у фазу від 2 до 8 справжніх листків культури (на початкових
        стадіях розвитку бур'янів); • Обприскування посівів від першого
        справжнього до п’яти трійчастих листків культури (рані фази росту
        бур’янів). Перше обприскування у фазу 1-2 трійчастих листків культури,
        наступне – у фазу 3-4 трійчастих листків культури; • Обприскування
        посівів від першого справжнього до шести листків культури (рані фази
        росту бур’янів). Перше обприскування у фазу 2-3 справжніх листків
        культури, наступне – у фазу 4-6 листків культури .
        <span className="paragraph"></span>Спектр дії: • однорічні злакові та
        дводольні бур'яни; вовчок соняшниковий; • однорічні злакові та дводольні
        бур'яни; • однорічні злакові та дводольні бур'яни.
        <span className="paragraph"></span>Норма витрати робочого розчину:  250–300
        л/га.
        <span className="paragraph"></span>
        Рекомендації з використання: Препарати групи імідазолінонів (д.р.:
        імазамокс, імазапір та інші) не рекомендується використовувати на одному
        полі частіше 1 разу на 3 роки. Найкраща ефективність гербіциду
        досягається за сприятливих для активної вегетації бур’янів температур.
        Не рекомендується застосовувати за середньодобової температури нижче
        +10°С та при перепаді нічної та денної температур понад 15°С.
        Рекомендується застосовувати Пульсар® Флекс, коли більшість бур’янів
        активно ростуть та знаходяться на початкових фазах розвитку: дводольні –
        у фазі 2–4 справжні листки, однодольні – 1–3 листки.
      </>
    ),
  },
  {
    productTitle: "Стеллар Плюс",
    path: "/stellar-plus",
    src: Herbicides68,
    volume: "5 л",
    price: 192.67,
    currency: "USD",
    text: (
      <>
        Опис: Стеллар Плюс — єдине рішення для ефективного контролю широкого
        спектра бур’янів у зручному форматі.
        <span className="paragraph"></span>Діюча речовина: Розчинний концентрат.
        <span className="paragraph"></span>
        Препаративна форма: 50 г/л топрамезон, 160 г/л дикамба.
        <span className="paragraph"></span>
        Переваги: • Нова покращена формуляція, зменшення витрат на логістику; •
        Широкий спектр дії на бур’яни; • Ефективно знищує надземну частину
        ваточника сирійського; • Швидкий візуальний ефект; • Має тривалу
        ґрунтову дію завдяки топрамезону захист протягом усього сезону.
        <span className="paragraph"></span>Регламенти застосування:
        <span className="paragraph"></span>
        Культура: • Кукурудза.
        <span className="paragraph"></span>Терміни застосування: • обприскування
        посівів у фазу 3–8 листків культури (ранні фази росту бур’янів).
        <span className="paragraph"></span>Спектр дії: • однорічні та багаторічні
        злакові і дводольні бур’яни. Норма витрати робочої рідини: 200–400 л/га.
        <span className="paragraph"></span>
        Рекомендації щодо застосування: За умови самостійного використання норма
        витрати – 1,0–1,25 л/га. При використанні в бакових сумішах слід
        застосовувати 0,8 л/га. Найкращими партнерами для бакової суміші
        є Фронтьєр® Оптіма 0,8 л/га або Акріс® 1,5 л/га. Вони підсилюють листову
        активність Стеллар® Плюс, розширюють спектр чутливих бур’янів і
        подовжують період ефективного ґрунтового захисту до 40 днів. Після
        застосування гербіциду Стеллар® Плюс на наступний рік не рекомендується
        висівати сою, горох та інші бобові культури у зв’язку з можливим проявом
        фітотоксичності.
      </>
    ),
  },
  {
    productTitle: "Стомп 330",
    path: "/stomp-330",
    src: Herbicides69,
    volume: "10 л",
    price: 158.63,
    currency: "USD",
    text: (
      <>
        Опис: Стомп 330 — високоефективний ґрунтовий гербіцид для знищення
        широкого спектра однорічних дводольних і злакових бур’янів у посівах
        більшості сільськогосподарських культур.
        <span className="paragraph"></span>Діюча речовина: 330 г/л Пендиметалін.
        <span className="paragraph"></span>
        Препаративна форма: Концентрат, що емульгується.
        <span className="paragraph"></span>
        Переваги: • Не потребує негайної заробки в ґрунт та механічної обробки
        міжрядь; • Один препарат на багато культур; • Найширший спектр дії серед
        ґрунтових гербіцидів.
        <span className="paragraph"></span>Регламенти застосування:
        <span className="paragraph"></span>
        Культура: • Пшениця озима та яра, ячмінь озимий та ярий; • Кукурудза,
        соняшник; • Петрушка коренева; • Картопля; • Соя; • Морква; •
        Цибуля-ріпак; • Томати та капуста (розсадна культура); • Часник; •
        Горох.
        <span className="paragraph"></span>Терміни застосування: • обприскування
        після сівби до кінця кущення культури (оптимально до появи сходів
        бур’янів за наявності вологи у посівному шарі ґрунту); • обприскування
        ґрунту до появи сходів культури; • обприскування ґрунту протягом 2–3
        днів після посіву (до сходів культури); • обприскування ґрунту після
        останнього підгортання до появи сходів культури; • обприскування ґрунту
        до появи сходів культури; • обприскування ґрунту до висадки розсади.
        <span className="paragraph"></span>Спектр дії: • однорічні злакові та
        дводольні бур’яни.
        <span className="paragraph"></span>Норма робочого розчину: 200-400 л/га.
        <span className="paragraph"></span>
        Характеристика: Стомп® 330 (пендиметалін), селективний гербіцид на
        основі динітроаніліну, має чудову ефективність у боротьбі з широким
        спектром однорічних широколистих бур’янів та злакових трав у посівах
        багатьох сільськогосподарських та плодоовочевих культур. Стомп®330
        здійснює інгібуючий вплив на паростки чутливих бур’янів на ранній стадії
        їх розвитку. Вони гинуть невдовзі після проростання або після появи
        сходів.
        <span className="paragraph"></span>
        Механізм дії: Гербіцид Стомп® 330 (пендиметалін) інгібує (гальмує) як
        поділ клітин, так і їх подовження в меристемах паростків і коренів
        чутливих рослин. Після поглинання коренями гальмується ріст як коренів,
        так і пагонів; в останніх спостерігається вторинний ефект, оскільки
        транслокація гербіциду від кореня до пагона (паростка) носить обмежений
        характер. Ріст пригнічується безпосередньо одразу після поглинання через
        пагін (однодольні рослини) або під сім’ядольне коліно або гіпокотиль
        (дводольні рослини). Рослини, що піддавались обробці, гинуть невдовзі
        після проростання або після появи сходів із ґрунту. Саме проростання не
        інгібується. Загалом внесення гербіциду Стомп® 330 підвищує активність
        проти однодольних рослину разі заробки в ґрунт, однак заробка в ґрунт
        знижує ефективність проти дводольних рослин через різне розташування
        чутливих меристем. Стомп® 330 також ефективний при післясходовому
        внесенні, коли деякі однорічні злакові бур’яни перебувають у стадії –
        1–5 листків і коли широколисті бур’яни мають до двох справжніх листків.
        Стомп® 330 не знищує багаторічні або добре вкорінені бур’яни. Дводольні
        бур’яни: бур’яни, які зійшли, повністю припиняються в рості, мають
        інтенсивний зелений колір та шкіроподібні сім’ядолі. У дводольних
        бур’янів гіпокотиль – найчутливіше місце для поглинання діючої речовини
        гербіциду.
        <span className="paragraph"></span>
        Вибірковість: Сіяні культури: толерантність до гербіциду Стомп® 330 може
        бути фізіологічною або позиційною. У разі фізіологічної селективності
        (вибірковості) дії можна сміливо сіяти сільськогосподарські культури в
        ґрунт, оброблений гербіцидом Стомп® 330 методом передпосівного внесення
        із заробкою в ґрунт. Для випадку позиційної толерантності гербіцид не
        слід заробляти в ґрунт (за винятком дуже дрібної заробки), оскільки
        вибірковість дії заснована на розміщенні насіння нижче шару з гербіцидом
        (цибуля, картопля).
        <span className="paragraph"></span>
        Фітотоксичність для випадку позиційної толерантності спостерігається,
        коли: • насіння внесене в ґрунт на недостатню глибину; • сильні дощові
        опади випадають після обробки легких ґрунтів і зміщують гербіцид нижче,
        доводячи його до рівня розміщення насіння; •  погана підготовка
        насіннєвого ложа (грядок) та/або неадекватні операції посіву (посадки)
        не дозволяють мати достатнє покриття насіння; • пошкодження стійких
        рослин може бути у вигляді утворень наростів та потовщень на коренях,
        ламкості стебла.
      </>
    ),
  },
  {
    productTitle: "Стомп Аква",
    path: "/stomp-akva",
    src: Herbicides70,
    volume: "10 л",
    price: 182.14,
    currency: "USD",
    text: (
      <>
        Опис: Стомп Аква - базовий гербіцид для овочевих та ягідних культур із
        новітньою формуляцією, що забезпечує гнучкість застосування та подовжену
        дію.
        <span className="paragraph"></span> Діюча речовина: 455 г/л Пендиметалін.
        <span className="paragraph"></span> Препаративна форма: Капсульна суспензія.
        <span className="paragraph"></span> Переваги: • Пригнічує широкий спектр
        злакових і дводольних бур'янів; • Тривала дія завдяки безперервному
        вивільненню пендиметаліну з капсул. • Вища концентрація пендиметаліну –
        простота у використанні та зменшення логістичного навантаженння; • Легко
        змивається з робочих поверхонь; • Відсутність токсичності при
        застосуванні по сходам культури.
        <span className="paragraph"></span> Особливості формуляції: • Діюча речовина
        продукту сформована в мікрокапсули, суспендовані у воді. Стінка капсул
        складається з міцного полімерного матеріалу, який добре захищає діючу
        речовину. Цей склад забезпечує високий рівень стійкості препарату навіть
        при тривалому зберіганні. • Мікрокапсули поглинають воду через градієнт
        осмотичного тиску. Їх стінки збільшуються, готуючи їх до подальшого
        вивільнення діючої речовини. • Під час обприскування мікрокапсули
        піддаються підвищеному механічному тиску. Вони частково відкривають і
        вивільняють діючу речовину.
        <span className="paragraph"></span> Регламент застосування:
        <span className="paragraph"></span> Культура: • Морква; • Картопля; •
        Капуста; • Огірки; • Цибуля сіянка, цибуля ріпка, крім на перо; • Томати
        розсадні; • Часник; • Полуниця.
        <span className="paragraph"></span> Терміни застосування: • Обприскування
        ґрунту до появи сходів культури; • Обприскування вегетуючої культури за
        висоти рослин до 5 см (ранні стадії росту бур’янів; • Обприскування
        ґрунту до появи сходів культури або за 8-10 днів до висадки розсади; •
        Обприскування після появи сходів культури у фазі 1-6 листків або після
        висадки розсади (1-6 листків) - (рані стадії росту бур’янів); •
        Обприскування ґрунту до появи сходів культури або за 8-10 днів до
        висадки розсади; • Обприскування в період вегетації у фазі до 2-х
        листків культури (рані стадії росту бур’янів); • До появи сходів
        культури; • На ранніх стадіях розвитку культури; • До моменту
        відновлення вегетації культури навесні; • В період вегетації після збору
        урожаю.
        <span className="paragraph"></span> Спектр дії: • однорічні злакові та
        дводольні бур’яни. Норма витрати робочого розчину: 200-400 л/га.
        <span className="paragraph"></span> Стомп Аква – технічна ефективність: 1.
        Стомп® Аква забезпечує довготривалий захист та високу ефективність; 2.
        Відсутність фітотоксичного впливу та простота використання.
      </>
    ),
  },

  // пятый файл

  {
    productTitle: "Каптора",
    path: "/kaptora",
    src: Herbicides71,
    volume: "20 л",
    price: 625.2,
    currency: "USD",
    text: (
      <>
        Опис: Каптора — гербіцид для соняшнику.
        <span className="paragraph"></span>
        Діюча речовина: 33 г/л імазамоксу, 15 г/л імазапіру.
        <span className="paragraph"></span>
        Препаративна форма: Розчинний концентрат.
        <span className="paragraph"></span>
        Переваги: • Захист соняшнику від усіх рас вовчка; • Надійний захист від
        бур’янів, у тому числі на проблемних площах; • Широкий спектр дії; •
        Контроль наступних хвиль бур’янів (ґрунтова дія).
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Соняшник.
        <span className="paragraph"></span>
        Спектр дії: • Однодольні і дводольні бур’яни.
        <span className="paragraph"></span>
        Фаза внесення: • Обприскування посівів у фазі 4 справжніх листки у
        культури.
        <span className="paragraph"></span>
        Сумісність: Не рекомендується застосовувати в баковій суміші з іншими
        гербіцидами (високоефективний препарат), інсектицидами з групи ФОС та
        мінеральними добривами.
        <span className="paragraph"></span>
        Особливості застосування: Каптора® слід застосовувати тільки на сортах і
        гібридах соняшнику системи виробництва Clearfield® на початкових стадіях
        розвитку бур’янів. Не рекомендується застосовувати препарат, коли
        рослини соняшнику перебувають у стресовому стані від впливу факторів
        навколишнього середовища (низькі або навпаки високі температури, сильна
        посуха, надмірна вологість тощо). Не рекомендується застосовувати
        гербіцид Каптора® при температурі нижче +10-12°С та вище +25°С. Норма
        витрати робочого розчину: 200-400 л/га. За наявності великої кількості
        рослинних решток на ґрунті витрата робочого розчину має бути не менше
        250 л/га. Найефективніше застосовувати, коли більшість бур’янів
        перебуває на початкових фазах розвитку.
        <span className="paragraph"></span>
        Коментарі: В окремих випадках після застосування препарату
        спостерігається зменшення висоти та/або зміна кольору рослин, особливо
        якщо рослини соняшнику перебувають під впливом стресів навколишнього
        середовища (низькі температури, сильна посуха, надмірна вологість). Як
        правило, рослини набирають нормального вигляду й росту протягом 1–2
        тижнів.  {" "}
      </>
    ),
  },
  {
    productTitle: "Каптора Плюс",
    path: "/kaptora-plus",
    src: Herbicides72,
    volume: "10 л",
    price: 178.3,
    currency: "USD",
    text: (
      <>
        Опис: Каптора Плюс — гербіцид для захисту соняшника.
        <span className="paragraph"></span>
        Діюча речовина: 16,5 г/л імазамоксу, 7,5 г/л імазапіру.
        <span className="paragraph"></span>
        Препаративна форма: Розчинний концентрат.
        <span className="paragraph"></span>
        Переваги: • Надійний захист від вовчка і бур’янів; • Посилений контроль
        осотів, амброзії, нетреби та злакових бур’янів; • Широке вікно
        застосування (від 2-го до 8-го листка); • Контроль наступних хвиль
        бур’янів (ґрунтова дія); • Можливість застосовувати в системах з
        енергоощадною технологією обробітку ґрунту.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Соняшник (гібриди, стійкі до Каптора Плюс).
        <span className="paragraph"></span>
        Спектр дії: • Однодольні і дводольні бур’яни. Фаза внесення: •
        Обприскування посівів у фазі від 2 до 8 справжніх листків у культури (
        на початках фазах розвитку бур’янів).
        <span className="paragraph"></span>
        Сумісність: Застосування в бакових сумішах з іншими гербіцидами
        недоцільне (високоефективний препарат). Препарат може бути використано в
        бакових сумішах з багатьма іншими засобами захисту, але перед
        застосуванням слід обов’язково перевірити препарати на сумісність.
        <span className="paragraph"></span>
        Особливості застосуванні: Каптора® Плюс слід застосовувати винятково на
        гібридах соняшнику, призначених для виробничої системи Clearfield ®
        Plus. Найефективніше застосовувати, коли більшість бур’янів перебуває на
        початкових фазах розвитку. Не порушувати температурний режим при
        застосуванні та не застосовувати гербіцид, коли рослини соняшнику
        перебувають у стресовому стані під впливом абіотичних чинників (низькі
        або, навпаки, високі температури, надмірна вологість ґрунту тощо). Норма
        витрати робочого розчину: 200-400 л/га. 1,6 л/га — однорічні дводольні
        до 2-х листків. 2,0 л/га — однорічні дводольні (2–4 листки), в т. ч.
        амброзія полинолиста, лобода біла, нетреба звичайна, однорічні злакові
        та вовчок соняшниковий. 2,5 л/га — однорічні перерослі, багаторічні
        дводольні осоти, березка до 6 листків у бур’янів.
        <span className="paragraph"></span>
        Високотехнологічний препарат: • потребує рівномірного внесення по всій
        площі (перекриття проходів обприскувача треба звести до мінімуму); • не
        допускається знесення робочої рідини на сусідні поля.{" "}
      </>
    ),
  },
  {
    productTitle: "Дербі 175",
    path: "/derbi-175",
    src: Herbicides73,
    volume: "0,5 кг",
    price: 58.02,
    currency: "USD",
    text: (
      <>
        Опис: Дербі — тотальне винищення перерослих бур’янів.
        <span className="paragraph"></span>
        Діюча речовина: 100 г/л флуметсуламу, 75 г/л флорасуламу.
        <span className="paragraph"></span>
        Препаративна форма: Концентрат суспензії.
        <span className="paragraph"></span>
        Переваги: • Неперевершений контроль підмаренника чіпкого навіть у фазі
        14 кілець та інших перерослих бур’янів • Можливість застосування до
        прапорцевого листка зернових • Не має обмежень для наступних культур у
        сівозміні • Контролює слабочутливі до сульфонілсечовин дводольні
        бур’яни: рутку лікарську, волошку синю, сокирки польові, мак (види),
        вероніку (види), ромашки (види) • Ефективно контролює всі види падалиці
        соняшнику.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Пшениця і ячмінь ярі та озимі.
        <span className="paragraph"></span>
        Спектр дії: • Однорічні та багаторічні дводольні бур’яни.
        <span className="paragraph"></span>
        Фаза внесення: • Обприскування під час вегетації від початку кущіння до
        прапорцевого листка культури включно. Норма робочого розчину: 150–200
        л/га при наземному обприскуванні та від 50 л/га при авіаобробці.
        <span className="paragraph"></span>
        Сумісність: У бакових сумішах гербіцид Дербі® сумісний із більшістю
        засобів захисту рослин. При використанні бакових сумішей засобів захисту
        рослин слід дотримуватись рекомендацій компанії-виробника щодо
        сумісності кожного препарату-компонента бакової суміші. В кожному
        конкретному випадку слід перевіряти препарати на сумісність. Для
        комплексного контролю однорічних злакових і дводольних бур’янів
        Дербі® рекомендується змішувати з гербіцидом Аксіал ®.
        <span className="paragraph"></span>
        Можна викорстувувати Дербі® в бакових сумішах з фунгіцидами Амістар ®
        Екстра, Амістар ® Тріо, Тілт ®, Тілт ® Турбо, Альто ® Супер, Елатус ®
        Ріа, інсектицидами Енжіо ®, Карате ® Зеон, регулятором росту Моддус ®.
        <span className="paragraph"></span>
        Особливості застосування: Ефективний контроль перерослих дводольних
        бур’янів, слабочутливих до сульфонілсечовин: волошки синьої, сокирок
        польових, підмаренника чіпкого, фіалки польової і гірчака
        березковидного, падалиці соняшнику, в тому числі стійкої до
        імідазолінонів і сульфонілсечовин. Рекомендується вносити, коли бур’яни
        мають принаймні 2 справжні листки. Вищу з зареєстрованих норм препарату
        застосовувати у таких випадках: • у пізніх фазах розвитку та/або для
        контролю розвиненіших рослин чутливих бур’янів ( > 8 листків) • за
        наявності в посівах багаторічних бур’янів • за умови дуже зріджених
        посівів • за несприятливих погодних умов перед і після обприскування.
        <span className="paragraph"></span>
        При дотриманні рекомендацій з застосування гербіцид Дербі® має високу
        ефективність та швидку дію проти широкого спектра дводольних бур’янів.{" "}
      </>
    ),
  },
  {
    productTitle: "Дуал Голд 960 ЕС",
    path: "/dual-gold-960-es",
    src: Herbicides74,
    volume: "5 л",
    price: 57.26,
    currency: "USD",
    text: (
      <>
        Опис: Дуал Голд — універсальний захисник на варті посівів.
        <span className="paragraph"></span>
        Діюча речовина: 90 г/л S-метолахлору.
        <span className="paragraph"></span>
        Препаративна форма: Концентрат емульсії.
        <span className="paragraph"></span>
        Переваги: • Надзвичайно тривалий захисний період (6–8 тижнів); •
        Ідеальний партнер для бакових сумішей; • Найменш фітотоксичний до
        культури серед усієї групи хлорацетамідів; • Застосовується на багатьох
        культурах.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Буряки цукрові, соняшник, соя; • Кукурудза, ріпак ярий та
        озимий; • Картопля; • Томати (розсада); • Капуста ( розсада); • Горох; •
        Томати ( бозрозсадні); • Тютюн; • Хміль; • Кавуни; • Сорго.
        <span className="paragraph"></span>
        Спектр дії: • Однорічні злакові й деякі дводольні бур’яни. Норма витрати
        робочого розчину: 300-350л/га.
        <span className="paragraph"></span>У разі недостатньої вологи (або її
        відсутності) та при сильних вітрах, які можуть знести верхній шар ґрунту
        разом із препаратом, рекомендовано заробку в ґрунт на глибину 3–5 см.
        <span className="paragraph"></span>
        Сумісність: Щоб розширити спектр контролю дводольних бур’янів, Дуал
        Голд® можна змішувати з іншими гербіцидами на відповідних культурах.
        Нині широко використовують комбінацію Дуал Голд® + Гезагард® (соняшник,
        соя, горох, картопля), Дуал Голд® + препарати на основі ленацилу,
        хлоридазону та метамітрону (цукрові буряки), Дуал Голд® + препарати на
        основі кломазону (ріпак). Не рекомендується застосовувати, якщо
        очікуються приморозки протягом 2–3 днів.{" "}
      </>
    ),
  },
  {
    productTitle: "Елюміс",
    path: "/elymis",
    src: Herbicides75,
    volume: "20 л",
    price: 412.71,
    currency: "USD",
    text: (
      <>
        Опис: Елюміс — комплексне рішення проти бур’янів у кукурудзі.
        <span className="paragraph"></span>
        Діюча речовина: 30 г/л нікосульфурону, 75 г/л мезотріону.
        <span className="paragraph"></span>
        Препаративна форма: Масляна дисперсія.
        <span className="paragraph"></span>
        Переваги: • Широке вікно застосування — 2–8 (10) листків культури; •
        Повний контроль широкого спектру однорічних і багаторічних злакових та
        дводольних бур’янів; • Контроль ваточника сирійського; • Попереджує
        появу кількох наступних хвиль дводольних бур’янів завдяки ґрунтовій дії;
        • Відсутність фітотоксичності на культуру навіть за умови пізнього
        внесення; • Покращена формуляція — не потребує додавання сурфактантів.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Кукурудза.
        <span className="paragraph"></span>
        Спектр дії: • Однорічні і багаторічні злакові та дводольні бур’яни.
        <span className="paragraph"></span>
        Фаза внесення: • Обприскування під час вегетації у фазі від 2 до 8
        листків культури включно. Норма робочого розчину: 150-250 л/га.
        <span className="paragraph"></span>
        Сумісність: Не застосовувати з мікроелементами, зокрема з цинком у
        будь-якій формі. Не рекомендовано змішувати з інсектицидами на основі
        фосфорорганічних сполук. Також не застосовувати на посівах кукурудзи,
        насіння якої оброблено інсектицидами на основі фосфорорганічних сполук
        та тіокарбаматів.
        <span className="paragraph"></span>
        Особливості застосування: Максимальну норму внесення слід застосовувати
        в разі масової появи багаторічних і перерослих бур’янів.
        <span className="paragraph"></span>
        Обмеження щодо сівозміни: Наступного року після застосування препарату
        Елюміс ® не рекомендується вирощувати цукровий буряк, горох, нут й
        овочі. Сою, соняшник та ріпак рекомендовано висівати після оранки.
        Кукурудзу, сорго, пшеницю, ячмінь — без обмежень.{" "}
      </>
    ),
  },
  {
    productTitle: "Гезагард 500 FW",
    path: "/gesagard-500-fw",
    src: Herbicides76,
    volume: "5 л",
    price: 40.91,
    currency: "USD",
    text: (
      <>
        Опис: Гезагард — фахівець у захисті соняшнику й овочів від бур’янів.
        <span className="paragraph"></span>
        Діюча речовина: 500 г/л прометрину.
        <span className="paragraph"></span>
        Препаративна форма: Концентрат суспензії.
        <span className="paragraph"></span>
        Переваги: • Тривалий захисний період; • Ідеальний партнер для бакових
        сумішей; • Нефітотоксичний до культури при дотриманні регламентів.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Пшениця озима, ячмінь озимий, ячмінь ярий.
        <span className="paragraph"></span>
        Спектр дії: • Однорічні бур’яни.
        <span className="paragraph"></span>
        Фаза внесення: • Обприскування посівів від початку кущення до появи
        прапорцевого листка.
        <span className="paragraph"></span>
        Норма витрати робочого розчину: 250-350 л/га.
        <span className="paragraph"></span>
        Сумісність: Можна змішувати з іншими загальновживаними гербіцидами на
        відповідних культурах. Для повного контролю основних однорічних злакових
        і дводольних бур’янів Гезагард® рекомендується змішувати з Дуал
        Голд® або Трофі™ на відповідних культурах. На практиці добре себе
        зарекомендувала комбінація Дуал Голд® + Гезагард® (соняшник, соя, горох,
        картопля).
        <span className="paragraph"></span>
        Особливості застосування: Обприскування проводиться до появи сходів
        культури по добре підготованому і оптимально зволоженому ґрунту (на
        моркві й коріандрі обприскування можна проводити також у фазах від 2 до
        4 листків у культури). Норма витрати препарату залежить від
        забур’яненості та вмісту гумусу в ґрунті. Вищі дози використовують на
        важких за механічним складом ґрунтах та з великим вмістом гумусу.
        Залежно від норми витрати і ґрунтово-кліматичних умов захисний період
        препарату становить від 4 до 12 тижнів. При використанні гербіциду
        згідно з рекомендаціями обмежень у сівозміні немає. Для запобігання
        можливого утворення осаду в жорсткій лужній воді рекомендовано додавати
        пом’якшувачі (підкислювачі) в нормах, рекомендованих для цих препаратів.{" "}
      </>
    ),
  },
  {
    productTitle: "Пік 75 WG",
    path: "/pik-75-wg",
    src: Herbicides77,
    volume: "0,1 гр",
    price: 57.24,
    currency: "USD",
    text: (
      <>
        Опис: Пік — вершина захисту посівів.
        <span className="paragraph"></span>
        Діюча речовина: 750 ш/ кг просульфурону.
        <span className="paragraph"></span>
        Препаративна форма: Гранули, що диспергуються у воді.
        <span className="paragraph"></span>
        Переваги: • Високоефективний проти більшості дводольних бур'янів, у тому
        числі падалиці соняшнику і ріпаку, гірчаків, нетреби звичайної та
        осотів; • Тривалий період застосування — до прапорцевого листка на
        зернових і 8-го листка у кукурудзи; • Ґрунтова дія попереджує появу
        нових сходів бур’янів; • Ефективний при низьких температурах (від +5
        °С). Можна застосовувати восени; • Відмінний партнер для підсилення дії
        проти бур’янів на багатьох культурах.
        <span className="paragraph"></span>
        Застосування препарату.
        <span className="paragraph"></span>
        Культура: • Пшениця яра; • Пшениця озима; • Ячмінь; • Кукурудза; •
        Сорго; • Просо; • Льон; • Рис.
        <span className="paragraph"></span>
        Спектр дії: • Однорічні дводольні, у т.ч. стійкі до 2,4-Д і МЦПА, та
        деякі багаторічні дводольні; • Однорічні та деякі багаторічні дводольні
        бур’яни, в т.ч. стійкі до 2,4-Д і МЦПА; • Дводольні широколисті болотні
        бур’яни.
        <span className="paragraph"></span>
        Норма робочого розчину: 150-200 л/га.
        <span className="paragraph"></span>
        Особливості застосування: Для контролю ширшого спектра дводольних
        бур’янів на зернових культурах, кукурудзі й сорго Пік® можна змішувати з
        іншими гербіцидами, як–от Мілагро®, Діален Супер®, Дербі®. Пік® можна
        змішувати з інсектицидами, регуляторами росту й фунгіцидами. При
        використанні бакових сумішей засобів захисту рослин слід дотримуватись
        рекомендацій компанії-виробника щодо сумісності кожного
        препарату-компонента бакової суміші.
        <span className="paragraph"></span>
        Особливості організації сівозміни: У рік застосування препарату
        допускається пересіван- ня зерновими (пшениця, жито, ячмінь, кукурудза,
        просо) і льоном. Наступного року можна висівати льон, просо, кукурудзу
        та зернові культури без обмежень. Овочеві культури, цукровий буряк,
        соняшник, гірчицю, ріпак і фацелію висівати не раніше, ніж через 18 міс.
        після застосування. Для зниження ризику появи післядії, особливо на
        ґрунтах з pH>7, бажано використовувати гербіцид у суміші з препаратами
        Пріма™ або Дербі®, знизивши норму витрати Пік® удвічі.{" "}
      </>
    ),
  },
  // шестой файл
  {
    productTitle: "Геліантекс, КС (CORTEVA)",
    path: "/heliantex-ks-corteva",
    src: Herbicides78,
    volume: "1 л",
    price: 401.1,
    currency: "USD",
    text: (
      <>
        Опис: Геліантекс - інноваційний гербіцид системної дії для контролю
        проблемних видів дводольних бур’янів у посівах соняшнику.
        <span className="paragraph"></span>
        Діюча речовина: галауксифен-метил, 68,5 г/л (Arylex™ active).
        <span className="paragraph"></span>
        Препаративна форма: Концентрат суспензії.
        <span className="paragraph"></span>
        Переваги: • Створений на основі інноваційної молекули Arylex™ active. •
        Може застосовуватись на всіх типах гібридів соняшнику, а саме – на
        звичайних гібридах та гібридах, стійких до гербіцидів ALS та IMI. •
        Неперевершений контроль амброзії полинолистої (Ambrosia artemisiifolia),
        навіть перерослої. • Контроль проблемних видів дводольних бур’янів,
        зокрема таких як: лобода біла (Chenopodium album), канатник Теофраста
        (Abutilon theophrasti), нетреба звичайна (Xanthium strumarium) та ін. •
        Має широке вікно застосування: від появи 4-х справжніх листків культури
        до початку стадії зірочки (ВВСН 14-50). • Забезпечує максимальну
        гнучкість для оптимального контролю проблемних бур’янів. • Сумісний з
        різними програмами захисту рослин, що використовуються у посівах
        соняшнику. • Демонструє високий рівень безпечності для довкілля. • Не
        має обмежень у сівозміні.
        <span className="paragraph"></span>
         Механізм та швидкість дії. Активний ріст чутливих бур’янів
        припиняється вже через добу після проникнення препарату в рослину. Перші
        видимі ознаки пригнічення бур’янів проявляються через 1-2 дні після
        внесення. Остаточна загибель бур’янів відбувається через 3-5 тижнів
        після обробки і залежить від видового складу та стадії розвитку
        бур’янів, ступеня засміченості, а також кліматичних умов до, під час і
        після обробки. Спектр контрольованих бур’янів. Застосування гербіциду
        Геліантекс™ забезпечує контроль проблемних видів дводольних бур’янів,
        зокрема таких як лобода біла, канатник Теофраста, нетреба звичайна,
        чорнощир нетреболистий, чорнощир звичайний, підмаренник чіпкий.
        Геліантекс™ пригнічує ріст таких бур’янів, як коноплі дикі, паслін
        чорний, щириця загнута, дурман звичайний, за оптимальних умов
        використання.
        <span className="paragraph"></span>
        Рекомендації щодо застосування.
        <span className="paragraph"></span>
        Культура: • Соняшник.
        <span className="paragraph"></span>
        Спектр дії: • Дводольні бур’яни.
        <span className="paragraph"></span>
        Спосіб, час обробки та обмеження: • Обприскування посівів, починаючи від
        фази 4-х справжніх листків до початку стадії «зірочки» у соняшника (ВВСН
        14-50) з додаванням ПАР Віволт.
        <span className="paragraph"></span>
        Сумісність з іншими препаратами. Для розширення спектру контрольованих
        бур’янів гербіцид Геліантекс™ можна застосовувати у бакових сумішах та
        програмах захисту з іншими протидводольними гербіцидами. Слід уникати
        застосування у бакових сумішах з гербіцидами на основі імазамоксу та
        імазапіру, що може призвести до тимчасового прояву фітотоксичності у
        культури. При використанні цих діючих речовин у програмах гербіцидного
        захисту інтервал між обробками має бути не менш як 10 днів. Необхідно
        уникати застосування з фосфорорганічними інсектицидами, а при їх
        використанні дотримуватися інтервалу 12-14 днів. Не рекомендується
        застосовувати Геліантекс™ одночасно з внесенням грамініцидів, інтервал
        між обробками має бути не меншим за 10 днів. Слід уникати одночасного
        застосування з препаратами, що мають рістрегулюючий ефект. Наступні
        культури сівозміни.
        <span className="paragraph"></span>
        Відсутні будь-які обмеження щодо висіву наступних культур.
        <span className="paragraph"></span>
        Додаткові рекомендації: • оптимальна температура навколишнього
        середовища для застосування препарату коливається в межах від +8 до +25
        °С; • не рекомендується застосовувати Геліантекс™, якщо посіви соняшнику
        перебувають в стані стресу (внаслідок ушкодження рослин шкідниками,
        хворобами, нестачі живлення, попередньо застосованих препаратів, дії
        посухи чи спеки, надмірної вологості повітря та ґрунту, а також впливу
        прохолодних погодних умов тощо); • слід уникати перекриття та знесення
        робочого розчину на сусідні культури; дощ, що випав протягом 1-ї години
        після внесення, може знизити ефективність дії препарату; • відповідно до
        сільськогосподарської практики препарат рекомендується застосовувати,
        коли комахи-запилювачі не є активними (рано-вранці або пізно ввечері); •
        забороняється застосування в особистих підсобних господарствах,
        авіаційним методом, у водоохоронній зоні рибогосподарських водойм.
        Запобігання виникненню стійкості до препарату. Завдяки тому, що гербіцид
        Геліантекс™ містить у своєму складі діючу речовину галауксифен-метил,
        ризик виникнення резистентності знижується. Галауксифен-метил
        відноситься до нового класу хімічних сполук – арилпіколінатів, що, за
        міжнародною класифікацією, відноситься до групи ауксиноподібних
        гербіцидів (синтетичні ауксини, Group O, HRAC classification), ризик
        розвитку резистентності у якої визначений як низький.
        <span className="paragraph"></span>
        Однак, щоб унеможливити чи мінімізувати будь-який потенційний ризик,
        слід дотримуватись таких рекомендацій: • Використовувати максимально
        допустимі норми препарату для контролю важкоконтрольованих бур’янів. •
        Забезпечувати ротацію культур у сівозміні. • Здійснювати чергування
        гербіцидів з іншими механізмами дії на одному полі. • Використовувати
        комбіновані програми та/або бакові суміші гербіцидів для посилення дії.
      </>
    ),
  },
];
