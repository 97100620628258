import growthRegulators1 from "../assets/images/GrowthRegulatorsImages/tarder.webp";
import growthRegulators2 from "../assets/images/GrowthRegulatorsImages/trinex.webp";
import growthRegulators3 from "../assets/images/GrowthRegulatorsImages/greyvis.webp";

export const growthRegulatorsArr = [
  {
    productTitle: "Тардер",
    path: "/tarder",
    src: growthRegulators1,
    volume: "5 л",
    price: 37.62,
    currency: "USD",
    text: (<>
    Опис: Тардер - регулятор росту, що запобігає виляганню рослин та підвищує врожайність.
    <span className="paragraph"></span>
Діюча речовина: Етефон, 480 г/л.
<span className="paragraph"></span>
Препаративна форма: Розчинний концентрат. 
<span className="paragraph"></span>
Переваги: 
	•	Запобігає ламкості стебла та виляганню посівів;
	•	Підвищує врожайність продукції за рахунок зміни співвідношення біомаси рослини (зерно/солома) в бік зерна;
	•	Підвищує стійкість рослини до стресів та стимулює утримання вологи в клітинах рослини;
	•	Застосовується на соняшнику і на зернових.
  <span className="paragraph"></span>
Механізм дії: 
Етефон – швидко проникає в рослину і розкладається в тканинах з утворенням етилену (природній фітогромон). Етилен на клітинному рівні виступає як інгібітор транспортування ауксинів та біосинтезу гіберелінів, що призводить до штучної регуляції ростових процесів. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 150-300 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця; 
	•	Ячмінь; 
	•	Соняшник.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Інгібування росту рослин, запобігання виляганню посівів, підвищення врожайності;
	•	Інгібування росту рослин, запобігання виляганню посівів.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Від початку виходу в трубку і до початку колосіння; 
	•	Від початку виходу в трубку і до початку появи;
	•	Від початку видовження стебла до фази утворення зірочки.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
	•	Проводити обприскування при температурі від +15...+25ºС;
	•	Норма внесення Тардеру залежить від інтенсивності росту культури, системи живлення і загрози її вилягання: чим більш інтенсивний ріст культури, тим більша норма застосування препарату. На ранніх фазах росту культури норму внесення збільшують;
	•	Потрібно забезпечити рівномірне покриття рослини робочим розчином;
	•	Тардер сумісний з багатьма інсектицидами, фунгіцидами, мікро- і макродобривами. Перед приготуванням бакової суміші слід перевіряти препарати на сумісність.
  <span className="paragraph"></span>
ЗАСТЕРЕЖЕННЯ:
	•	Не рекомендується застосовувати на: забур'янених посівах, слаборозвинених посівах, які знаходяться в стресі, на ґрунтах з низьким вмістом азоту, в умовах ґрунтової та повітряної посухи; перед заморозками.
	•	За 6 днів до та після внесення Тардеру не рекомендується застосування гербіцидів.
	•	Дія етиленпродуцентів суттєво залежить від температури повітря. Не застосовувати при температурі повітря нижче +12 °С та вище +25 °С.
	•	Не змішувати з препаратами на основі дитіокарбонатів, сірки, міді. Застосовувати через місяць після внесення мідьвмісних препаратів. Перед приготуванням бакової суміші слід перевіряти препарати на сумісність.
	•	Фізична сумісність препаратів в баковій суміші не гарантує відсутність фітотоксичності для культурної рослини. В процесі планування бакових сумішей першим потрібно виключити можливість фітотоксичного впливу за рахунок синергетичної дії препаратів, а вже потім проводити тести на стабільність бакової суміші.</>),
  },
  {
    productTitle: "Трінекс",
    path: "/trinex",
    src: growthRegulators2,
    volume: "5 л",
    price: 132,
    currency: "USD",
    text: (<>
    Опис: Трінекс - регулятор росту, що запобігає виляганню рослин та підвищує врожайність.
    <span className="paragraph"></span>
Діюча речовина: Трінексапак-етил, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Концентрат, що емульгується.
<span className="paragraph"></span>
Переваги: 
	•	Запобігає ламкості стебла та виляганню посівів зернових за рахунок вкорочення та зміцнення стебла; 
	•	Покращує кущіння культури;
	•	Збільшує об'єм та довжину кореневої системи;
	•	Гнучкі умови використання навіть за недостатнього зволоження та за низьких температур (від +8°С);
	•	Підвищує посухостійкість рослин стимулюючи розвиток кореневої системи та зменшуючи транспірацію;
	•	Підвищує врожайність продукції за рахунок зміни співвідношення біомаси рослини (зерно/солома) в бік зерна. Підвищує зимостійкість за рахунок збільшення накопичення цукрів у рослині.
  <span className="paragraph"></span>
Механізм дії: Трінексапак-етил – пригнічує синтез ключового ензиму при утворенні гіберелінової кислоти в рослині, котра активує видовження клітин в рослині.Скорочує міжвузля, потовщує стінки пагонів, що призводить до зменшення вилягання й збільшує об'єм флоеми та ксилеми. Покращує розвиток кореневої системи, що сприяє накопиченню цукрів у рослині.  
<span className="paragraph"></span>
Норма витрати робочого розчину: 100-200 л/га.
<span className="paragraph"></span>
Культура: 
	•	Пшениця;
	•	Ячмінь.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Проти вилягання рослин та підвищення врожайності.
  <span className="paragraph"></span> 
Спосіб та час обробки: 
	•	Від фази кущення до появи прапорцевого листка.
  <span className="paragraph"></span>
Рекомендації щодо застосування:
	•	Для отримання найкращого результату від вилягання рекомендується використовувати препарат в фазу культури 29-32 по ВВСН
	•	За умови високого агрофону та високорослих сортів пшениці рекомендується двократне внесення препарату у фази 29-31 та 37-39 по ВВСН;
	•	На низькорослих сортах пшениці та недостатньої вологості та підвищених температур необхідно використовувати мінімальну норму;
	•	За потреби скорочення під колосового міжвузля у ячменю рекомендується використання препарату у нормі 0,2 л/га у фазу ВВСН 37.
	•	Можливе створення бакових сумішей с інсектицидами, фунгіцидами, та гербіцидами не гормональної дії за умови тестового змішування.
  <span className="paragraph"></span>
ЗАСТЕРЕЖЕННЯ:
	•	Не допускається створення бакових сумішей з препаратами гормональної дії (2,4-д, дикамба, клопіралід та ін), та гербіцидами проти злакових бур'янів.
	•	Не використовувати препарат коли рослини знаходяться у стресі, а також при можливості суттєвого перепаду температур.</>),
  },

  // второй файл

  {
    productTitle: "Грейвіс",
    path: "/greyvis",
    src: growthRegulators3,
    volume: "5 л",
    price: 65.47,
    currency: "USD",
    text: (<>
    Опис: Грейвіс - системний фунгіцид-ретардант для ріпаку.
    <span className="paragraph"></span>
Діюча речовина: Мепікват-хлорид, 250 г/л + тебуконазол, 110 г/л.
<span className="paragraph"></span>
Препаративна форма: Емульсія, олія (масло) у воді.
<span className="paragraph"></span>
Переваги: 
	•	Ефективний за низьких температур (середньодобова температура +5⁰С).
	•	Підвищення морозостійкості рослин в осінній та весняний періоди.
	•	Має сильну профілактичну та оздоровчу дію.
	•	Запобігає переростанню, виляганню та сприяє рівномірному цвітінню.
  <span className="paragraph"></span>
Механізм дії: 
Тебуконазол належить до хімічної групи триазолів та впродовж 1-2 годин після застосування проникає в рослину через асимілюючі частини і рівномірно поширюється ксилемою. Тебуконазол блокує біосинтез ергостерину, який входить до складу клітинної стінки гриба, що призводить до порушень в процесі поділу клітин, а згодом і до загибелі патогена. Мепікват хлорид належить до четвертинних сполук амонію та є регулятором росту. При попаданні в рослину зупиняє синтез гіберелінової кислоти, яка є регулятором росту та розвитку рослин. Це приводить до вкорочення міжвузля, потовщення кореневої шийки, збільшення довжини кореня. 
<span className="paragraph"></span>
Властивості: 
В осінній період при обприскуванні озимого ріпаку у фазі 4-6 листочків культури припиняється наростання наземної маси, в той час як процес фотосинтезу продовжується, що сприяє накопиченню пластичних речовин в кореневій системі та прискорює ріст довгого і добре розгалуженого коріння, що покращує зимостійкість. В залежності від погодних умов, норми застосування препарату та фази розвитку ріпаку на момент обробки товщина кореневої шийки може збільшуватися на 13%, а довжина кореня на 80%. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га.
<span className="paragraph"></span>
Сфера застосування: 
<span className="paragraph"></span>
Культура: 
	•	Ріпак.
  <span className="paragraph"></span>
Призначення: 
	•	Запобігання переростанню культури та покращення перезимівлі, контроль фомозу та альтернаріоз;
	•	Проти вилягання та для оптимізації габітусу.
  <span className="paragraph"></span>
Спосіб обробки: 
	•	Осіннє обприскування посівів у фазі 4–6 листків культури;
	•	Весняне обприскування посівів при висоті рослин 20–30 см.
  <span className="paragraph"></span>
Загальні рекомендації: 
Обов’язковою вимогою є забезпечення суцільного покриття площі і рясного змочування рослин під час внесення препарату. Оптимальне застосування восени – фаза чотирьох листків у ріпаку (ВВСН 13-14). Весняне застосування від фази початку стеблування (ВВСН 31-32) до фази середини бутонізації (до ВВСН 55). Грейвіс не бажано змішувати у бакових сумішах з гербіцидами на основі клопіраліду, піклораму, галоксифен-метилу, що пов’язано з можливістю прояву фітотоксичністі для культури. </>),
  },
];
