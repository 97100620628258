import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOverlayActive: false,
};

const OverlaySearchSlide = createSlice({
  name: "Overlay",
  initialState,
  reducers: {
    setIsOverlayActive: (state, action) => {
      state.isOverlayActive = true;
    },
    setIsOverlayClose: (state, action) => {
      state.isOverlayActive = false;
    },
  },
});

export const {setIsOverlayActive, setIsOverlayClose } = OverlaySearchSlide.actions;
export default OverlaySearchSlide.reducer;
