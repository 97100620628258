import Desiccants1 from "../assets/images/DesiccantsImages/registan-ultra.webp";
import Desiccants2 from "../assets/images/DesiccantsImages/registan-pro.webp";
import Desiccants3 from "../assets/images/DesiccantsImages/skorpion-forte.webp";

export const desiccantsArr = [
  {
    productTitle: "Регістан Ультра",
    path: "/registan-ultra",
    src: Desiccants1,
    volume: "20 л",
    price: 117.27,
    currency: "USD",
    text: (<>Опис: Регістан Ультра - вдосконалений контактний десикант і гербіцид для підсушування культури та часткового знищення бур'янів.
    <span className="paragraph"></span>
    Діюча речовина: РК, дикват (дибромід), 374 г/л.
    <span className="paragraph"></span>
    Препаративна форма: Розчинний концентрат.
    <span className="paragraph"></span>
    Переваги: 
      •	Нижча норма застосування за рахунок підвищеної концентрації діючої речовини;
      •	Економія коштів завдяки збору вже сухого врожаю;
      •	Не впливає на посівні якості насіння;  
      •	Знищення бур'янів забезпечує легке збирання;
      •	Не змивається дощем ( 30 хв після внесення);
      •	Швидко розпадається в рослині, що дає можливість застосувати препарат на насіннєвих посівах.  
      <span className="paragraph"></span>
    Механізм дії: 
    Препарат проникає в зелені частини рослини, діюча речовина препарату перетворюється в перекис водню, що призводить до руйнування мембран клітин і цитоплазми, а це в свою чергу викликає зневоднення клітин і повне висихання рослини. 
    <span className="paragraph"></span>
    Норма витрати робочого розчину: 250-300 л води.
    <span className="paragraph"></span> 
    Культура: 
      •	Соняшник.
      <span className="paragraph"></span>
    Цільові об’єкти: 
      •	Десикація рослин, підсушування культури та часткове знищення бур’янів.
      <span className="paragraph"></span>
    Спосіб та час обробки: 
      •	Обприскування у фазі побуріння. 
      <span className="paragraph"></span>
    Рекомендації щодо застосування:
      •	Збільшення норми виливу робочого розчину покращує дію десиканту.
      •	Температура застосування до 25℃. Обробку рекомендується проводити в похмуру погоду або в нічні години.
      •	Робочий розчин готуйте безпосередньо перед внесенням та використайте протягом доби. Для підвищення ефективності додайте АгроПав Екстра у нормі 0,2-0,3 л/га на 250-300 л води.
      •	Підкислення робочого розчину покращує ефективність роботи десиканту.
      •	Не рекомендовано застосовувати препарат у сонячну погоду.
     </>),
  },
  {
    productTitle: "Регістан ПРО",
    path: "/registan-pro",
    src: Desiccants2,
    volume: "20 л",
    price: 145.21,
    currency: "USD",
    text: (<>
    Опис: Регістан ПРО - сучасний модернізований контактний десикант для підсушування рослин з метою прискорення достигання і полегшення збирання врожаю.
    <span className="paragraph"></span>
Діюча речовина: РК, дикват іон, 250 г/л.
<span className="paragraph"></span>
Препаративна форма: Розчинний концентрат.
<span className="paragraph"></span>
Переваги: 
	•	Висококонцентрований десикант з високим вмістом дикват іону;
	•	Препарат з вбудованими якісними активними інгредієнтами;
	•	Забезпечує швидке та ефективне висихання рослин - управління часом і ефективним збором врожаю;
	•	Знижує вологість насіння - зменшує витрати на сушіння; 
	•	Висушує значну частину бур'янів – полегшує збирання врожаю;
	•	Низька норма використання — зниження транспортних та логістичних витрат;
	•	Не змивається опадами вже через 30 хв. після обробки;
	•	Припиняє розвиток та поширення хвороб;
	•	Не впливає на посівні якості насіння.
  <span className="paragraph"></span>
Механізм дії: 
Після адсорбції діючої речовини в тканини рослин утворюються вільні радикали, які руйнують мембрани клітин, вступаючи в реакції із залишками жирних кислот у складі фосфоліпідів. Як результат – утворюється каскад вільно-радикальних реакцій – перекисне окислення ліпідів. Пошкодження мембран призводить до лізису клітин (води, цитоплазми) в міжклітинний простір. 
<span className="paragraph"></span>
Норма витрати робочого розчину: 
<span className="paragraph"></span>
Культура: 
	•	Соняшник;
	•	Ріпак;
	•	Соя.
  <span className="paragraph"></span>
Цільові об’єкти: 
	•	Десикація.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Обприскування у фазі початку побуріння кошиків;
	•	Обприскування посівів при побурінні 70% стручків у культури;
	•	Обприскування посівів у фазі початку побуріння бобів нижнього й середнього ярусів у культури.
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Покращення дії;Збільшення норми виливу робочого розчину покращує дію десиканту.Температура повітря;При внесенні повинна бути не вищою за +25°C, вологість не менше 60%.Рекомендуємо;Внесення препарату треба проводити в похмуру погоду або в нічні години.Робочий розчин;Готуйте безпосередньо перед внесенням та використайте протягом доби. Для підвищення ефективності додайте АгроПав Екстра у нормі 0,25-0,35 л/га на 250-350 л води.Максимальна ефективність;Ретельне покриття та максимальне проникнення робочого розчину є критичним для ефективності продукту. Використовуйте оптимальні розпилювачі та висоту штанги для десикації.Покращення ефективності;Підкислення робочого розчину Акваглайдом покращує ефективність роботи десиканту.Не рекомендуємо;Застосовувати препарат у сонячну погоду через причину зниження ефективності. 
</>),
  },

  // second файл

  {
    productTitle: "Скорпіон Форте",
    path: "/skorpion-forte",
    src: Desiccants3,
    volume: "20 л",
    price: 99.83,
    currency: "USD",
    text: (<>
    Опис: Скорпіон Форте - десикант контактної дії. Використовується для зниження вологості зерна та припинення розвитку й поширення хвороб за рахунок підсушування рослин перед збиранням.
    <span className="paragraph"></span>
Діюча речовина: Дикват дибромід, 280 г/л.
<span className="paragraph"></span>
Препаративна форма: Розчинний концентрат. 
<span className="paragraph"></span>
Переваги: 
	•	Швидке поглинання препарату рослинами мінімізує змивання дощем і, підвищує ефективність препарату.
	•	Полегшує збирання урожаю : наряду з культурними рослинами висушує й бур’яни.
	•	Запобігає втратам урожаю та зниження його якості за рахунок блокування розвитку та поширення хвороб.
	•	Знижує вологість насіння, зменшуючи, тим самим енерговитрати на сушіння.
	•	Оскільки діюча речовина контактної дії і швидко розкладається в рослинах, застосування препарату Скорпіон Форте®,  є безпечним як на насінневих посівах, так і на призначених для харчових цілей.
  <span className="paragraph"></span>
Механізм дії: 
Препарат контактної дії. Діюча речовина препарату є сильним окисником, при потраплянні в рослину призводить до руйнування клітинних мембран, порушення процесів фотосинтезу та виведення вологи з клітин. Висушує як культурні рослини, так і бур'яни, наявні на площі що піддається обробці.
<span className="paragraph"></span>
Обов'язковою умовою для дії препарату є обробка зелених рослин, з наявністю живих клітин культури. Саме за своєчасної обробки досягається максимально ефективне поглинання діючої речовини. В процесі поглинання препарату листям рослин відбувається відновлення молекули диквату, в результаті чого утворюється стабільний радикал, який може бути вдруге окислений молекулярним киснем. В результаті приєднання електрона кисень перетворюється в високореактивний супероксид-аніон (О2-) і перекис водню (Н2О2), окислюють ненасичені жирні кислоти. Утворений при цьому малоновий діальдегід інактивує електронно-транспортну систему, що стає причиною швидкого руйнування тонопластом, деструкції клітинного вмісту (розрив мітохондрій, руйнування мембран тилакоїдів в хлоропластах) і висушуванні рослини в цілому.
<span className="paragraph"></span>
Норма витрати робочого розчину: 200-300 л/га, при авіаційному застосуванні 50-70 л/га.
<span className="paragraph"></span>
Регламент застосування препарату.
<span className="paragraph"></span>
Культура: 
	•	Соняшник;
	•	Ріпак; 
	•	Соя.
  <span className="paragraph"></span>
Спосіб та час обробки: 
	•	Побуріння кошиків;
	•	70% побуріння стручків середнього ярусу; 
	•	60% побуріння бобів (побуріли ніжній та середній яруси).
  <span className="paragraph"></span>
Рекомендації щодо застосування: 
Обробку посівів препаратом проводять в період фізіологічної стиглості насіння соняшнику (вологість сім’янок 40-36%). Обробка в більш ранні строки може призвести до зниження урожайності. Норма витрати препарату обумовлена густотою стояння рослин, засміченістю посіву бур’янами, погодними умовами під час обробки та періодом до збирання врожаю. Обробку краще проводити у вечірні часи або вдень при хмарній погоді, тому що спекотна сонячна погода на час обробки зменшує ефективність десикації. Після проведення обробки сприятливими є умови, що прискорюють виведення вологи зі зруйнованих клітин рослин – вітер, сонячна погода без опадів. Наявність пилу на поверхні рослин також негативно впливає на ефективність дії препарату. Тому за таких умов застосовувати препарат не рекомендовано. </>),
  },
];
