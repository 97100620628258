import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Footer from "../../components/Footer/Footer";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import {
  setIsActive,
  setSelectedProduct,
} from "../../redux/ModalSlice/ModalSlice";
import "./ProductPage.css";

const ProductPage = ({
  src,
  title,
  currency,
  volume,
  price,
  text,
  usdRate,
  eurRate,
  path,
}) => {
  const [priceInUAH, setPriceInUAH] = useState(null);
  const dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(setIsActive());
    dispatch(setBurgerIsClose());
    dispatch(setSelectedProduct({ title, price: priceInUAH, volume }));
  };

  useEffect(() => {
    if (currency === "USD" && usdRate) {
      setPriceInUAH(price * usdRate);
    } else if (currency === "EUR" && eurRate) {
      setPriceInUAH(price * eurRate);
    }
  }, [currency, price, usdRate, eurRate]);
  return (
    <div className="product__page">
      <title>{`Купити ${title} | Агролада`}</title>
      <meta
    property="og:title"
    content={`Купити ${title} | Агролада`}
  />
<meta property="og:description" content={`Купити ${title}. Низькі ціни, висока якість. Замовляйте ${title} в Агролада. Тел. +380 (50) 259-14-88`} />
<meta
      name="description"
      content={`Купити ${title}. Низькі ціни, висока якість. Замовляйте ${title} в Агролада. Тел. +380 (50) 259-14-88`}
    />
        <meta property="og:image" content={src} />
        <meta property="og:url" content={`https://agrolada.com${path}`} />
        <link rel="canonical" href={`https://agrolada.com${path}`} />

      <div className="product__page-main-content">
        <div className="product__page-main-image-bg">
          <img
            src={src}
            alt={`на фото зображено товар ${title}`}
            className="product__page-main-image"
          />
        </div>
        <div className="product__spec">
          <h1 className="product__title mont-r">{title}</h1>
          {volume && (
            <p className="product__volume mont-sb">
              Об'єм:{" "}
              <span className="product__volume-text mont-r">{volume}</span>
            </p>
          )}
          <p className="product__price mont-sb">
            Вартість:{" "}
            <span className="product__price-text mont-r">
              {priceInUAH ? `${priceInUAH.toFixed(2)} грн` : "Уточнюйте"}
            </span>
          </p>
          <button
            onClick={handleModalOpen}
            className="product__page-button mont-m"
          >
            Замовити
          </button>
        </div>
      </div>
      <p className="product__page-product-text mont-r">{text}</p>
      <Footer />
    </div>
  );
};

export default ProductPage;
