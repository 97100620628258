import './MainPage.css'
import coverImage from '../../assets/images/coverImage.webp'
import coverImageMobile from '../../assets/images/coverImageMobile.webp'
import PopularProducts from './PopularProducts/PopularProducts'
import Ticker from './Ticker/Ticker'
import Footer from '../../components/Footer/Footer'

const MainPage = ({usdRate, eurRate}) => {
  const isMobile = window.innerWidth <= 1199;

  return (
    <div className='page'>
                        <title>Діючі засоби захисту рослин, добрива та насіння | Агролада</title>
        <div className="cover">
            <img src={isMobile ? coverImageMobile : coverImage} alt="обкладинка на якій зображена презентація ТОВ Агролада" className="cover__image" />
            <div className="cover__texts">
            <h1 className="cover__text mont-m">АГРОЛАДА</h1>
            <h2 className="cover__text-two mono-cors-r">З любов’ю до рідної землі</h2>
            </div>
        </div>
        <PopularProducts usdRate={usdRate} eurRate={eurRate} />
        <Ticker />
        <h2 className="main__page-about-company-title mont-r">ТОВ "Агролада"</h2>
        <p className="main__page-about-company-text mont-r">​​Наша компанія розпочала шлях з метою забезпечити фермерів якісними агротоварами. Завдяки сучасним технологіям і експертам у галузі, ми створили онлайн-магазин, де кожен може знайти все необхідне для успішного ведення господарства. </p>
        <p className="main__page-about-company-text mont-r">Наш інтернет-магазин пропонує широкий асортимент агротоварів: насіння, добрива, засоби захисту рослин. Ми забезпечуємо високу якість продукції, конкурентоспроможні ціни та швидку доставку. Підтримуйте свої посіви з нами! </p>
        <h2 className="main__page-about-company-title-two mont-r">Чому з нами зручно працювати: </h2>
        <p className="main__page-about-company-text mont-r">•	Працюємо з безготівковою оплатою з ПДВ </p>
        <p className="main__page-about-company-text mont-r">•	Відправки по всій Україні</p>
        <p className="main__page-about-company-text mont-r">	•	Завжди підберемо саме ту продукцію, що Вам підходить</p>
        <p className="main__page-about-company-text mont-r">•	Якість товару підтверджується сертифіками якості.</p>
        <Footer />
    </div>
  )
}

export default MainPage