import Footer from '../../components/Footer/Footer'
import './AboutPage.css'

const AboutPage = () => {
  return (
    <div className='page'>
       <title>Агролада-про компанію</title>
      <h2 className="aboute_us-page-title mont-r">ТОВ “АГРОЛАДА”</h2>
      <p className="aboute_us-page-text mont-r">Ласкаво просимо до нашого аграрного інтернет-магазину – вашого надійного партнера в світі сільського господарства! Наша компанія «АГРОЛАДА» існує з 2018 року. Ми пропонуємо широкий асортимент продукції для аграріїв, фермерів, що дозволяє задовольнити потреби кожного клієнта.</p>
      <p className="aboute_us-page-text mont-r">Наш магазин спеціалізується на продажу високоякісного насіння, добрив, засобів захисту рослин. Ми працюємо лише з перевіреними виробниками, що гарантує якість та ефективність нашої продукції.</p>
      <p className="aboute_us-page-text mont-r">Ми розуміємо, що кожен аграрій має свої унікальні потреби, тому наші фахівці завжди готові надати консультації та допомогти у виборі найкращих рішень для вашого господарства. У нашому інтернет-магазині ви знайдете:</p>
      <p className="aboute_us-page-text mont-r">• Насіння: широкий вибір сортів кукурудзи, соняшнику.</p>
      <p className="aboute_us-page-text mont-r">• Добрива: мікродобрива, регулятори росту.</p>
      <p className="aboute_us-page-text mont-r">• Засоби захисту рослин: ефективні препарати для боротьби з шкідниками та хворобами.</p>
      <p className="aboute_us-page-text mont-r">
      Ми пропонуємо зручну систему замовлень з доставкою по всій країні, а також регулярні акції та знижки для наших постійних клієнтів. Завдяки простому та зрозумілому інтерфейсу нашого сайту, ви зможете легко знайти потрібні товари та оформити замовлення всього за кілька кліків.
      </p>
      <p className="aboute_us-page-text mont-r">Долучайтеся до нашої спільноти аграріїв та фермерів, і разом ми зробимо ваше господарство ще успішнішим! Ваш успіх – наша мета!</p>
      <p className="aboute_us-page-text mont-r">​​Інтернет-магазин агро продукції пропонує широкий асортимент сільськогосподарських товарів, включаючи насіння, добрива, засоби захисту рослин. Забезпечує зручний онлайн-шопінг, доставку та консультації фахівців для підтримки фермерів у виборі найкращих продуктів. 
</p>
      <Footer />
    </div>
  )
}

export default AboutPage