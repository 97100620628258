
// README! файл поделён на КАТЕГОРИИ, у каждой КАТЕГОРИИ есть тайтл и массив с ПОДКАТЕГОРИЯМИ
// У каждой ПОДКАТЕГОРИИ есть массив с ПРОДУКТАМИ
// Каждый ПРОДУКТ имеет тайтл и path
// При необходимости добавить новую ПОДКАТЕГОРИЮ - смотри в конец КАТЕГОРИИ, в которую нужно добавить, 
// или поиск по слову "новая"(не забудь пересоздать для удобства дальнейшего расширения)
// При необходимости добавить новую КАТЕГОРИЮ, шаблон ниже, добавь в конец файла

//     {
//         categoryTitle: "title primer",
//         subCategoryes: [
//             {
//                 subCategoryTitle: "sub title primer",
//                 products: [
//                     {
//                         productTitle: "product primer",
//                         path: "/nazvanieTranslitom"
//                     }
//                 ]
//             },
//         ]
//     },


// ШАБЛОН ФАЙЛОВОЙ СТРУКТУРЫ
// const navArr = [
//     {
//         categoryTitle: "title primer",
//         subCategoryes: [
//             {
//                 subCategoryTitle: "sub title primer",
//                 products: [
//                     {
//                         productTitle: "product primer",
//                         path: "/nazvanieTranslitom"
//                     }
//                 ]
//             },
//         ]
//     },
// ]

// функция, которую использовал. Обращается к navArr, который ниже.
// Считает кол-во элементов во всем массиве и в подкатегориях по отдельности + проверяет уникальность path.
// Тут уже построены все мапы, вроде адекватно, иначе бы не работало =), перепиши под что нужно 

// function countElem() {
//     let checkerArr = []
//     let itemCounter = 0;
  
//     function uniqueCheck(e) {
//       if (checkerArr.includes(e)) {
//         console.log("dublicated path:" + e);
//       }
//     }
    
//     navArr.forEach((e) => {
//       e.subCategoryes.forEach((subcategory) => {
//         console.log("item amount in" + " " + subcategory.subCategoryTitle + " " + subcategory.products.length);
//         subcategory.products.forEach((product) => {
//           uniqueCheck(product.path)
//           checkerArr.push(product.path)
//           itemCounter++;
//         });
//       });
//     });
  
//     console.log(itemCounter);
//   }
  


export const navArr = [
    {
        // категория 1 
        categoryTitle: "Засоби захисту рослин",
        subCategoryes: [
            {
                // подкатегорія 1.1
                subCategoryTitle: "Гербіциди",
                products: [
                    {
                        productTitle: "Геліос",
                        path: "/helios"
                    },
                    {
                        productTitle: "Геліос Екстра",
                        path: "/helios-ekstra"
                    },
                    {
                        productTitle: "Геліос Максі",
                        path: "/helios-maksi"
                    },
                    {
                        productTitle: "Гренадер",
                        path: "/grenader"
                    },
                    {
                        productTitle: "Гренадер Максі",
                        path: "/grenader-maksi"
                    },
                    {
                        productTitle: "Дисулам",
                        path: "/disulam"
                    },
                    {
                        productTitle: "Ділар",
                        path: "/dilar"
                    },
                    {
                        productTitle: "Етасил",
                        path: "/etastil"
                    },
                    {
                        productTitle: "Клодекс ПРО",
                        path: "/klodeks-pro"
                    },
                    {
                        productTitle: "Метризан",
                        path: "/metrisan"
                    },
                    {
                        productTitle: "Мілафорт",
                        path: "/milafort"
                    },
                    {
                        productTitle: "Мілафорт Екстра",
                        path: "/milafort-ekstra"
                    },
                    {
                        productTitle: "Ореол Максі",
                        path: "/oreol-maksi"
                    },
                    {
                        productTitle: "Основа",
                        path: "/osnova"
                    },
                    {
                        productTitle: "Промекс",
                        path: "/promeks"
                    },
                    {
                        productTitle: "Сульфоніл",
                        path: "/sulfonil"
                    },
                    {
                        productTitle: "Танаїс",
                        path: "/tanais"
                    },
                    {
                        productTitle: "Толазин",
                        path: "/tolasin"
                    },
                    {
                        productTitle: "Толазин Ультра",
                        path: "/tolasin-ultra"
                    },
                    {
                        productTitle: "Трайдент",
                        path: "/traident"
                    },
                    {
                        productTitle: "Унісулам",
                        path: "/unisulam"
                    },

                    // второй файл

                    {
                        productTitle: "Аргумент",
                        path: "/argument"
                    },
                    {
                        productTitle: "Адвокат",
                        path: "/advokat"
                    },
                    {
                        productTitle: "Герб 900",
                        path: "/gerb-900"
                    },
                    {
                        productTitle: "Грізний",
                        path: "/grizniy"
                    },
                    {
                        productTitle: "Грізний Експерт",
                        path: "/grizniy-ekspert"
                    },
                    {
                        productTitle: "Декабрист 480",
                        path: "/dekabrist-480"
                    },
                    {
                        productTitle: "Євро-Ленд",
                        path: "/evro-lend"
                    },
                    {
                        productTitle: "Капрал",
                        path: "/kapral"
                    },
                    {
                        productTitle: "Конвой",
                        path: "/konvoy"
                    },
                    {
                        productTitle: "Легенда",
                        path: "/legenda"
                    },
                    {
                        productTitle: "Лемур",
                        path: "/lemur"
                    },
                    {
                        productTitle: "Майтус",
                        path: "/maitus"
                    },
                    {
                        productTitle: "Мікадо",
                        path: "/mikado"
                    },
                    {
                        productTitle: "Мілафурон",
                        path: "/milafuron"
                    },
                    {
                        productTitle: "Мотор",
                        path: "/motor"
                    },
                    {
                        productTitle: "Преміум Голд",
                        path: "/premium-gold"
                    },
                    {
                        productTitle: "Пріус",
                        path: "/prius"
                    },
                    {
                        productTitle: "Сабер",
                        path: "/saber"
                    },
                    {
                        productTitle: "Сентинел",
                        path: "/sentinel"
                    },
                    {
                        productTitle: "Серп",
                        path: "/serp"
                    },
                    {
                        productTitle: "Сонхус",
                        path: "/sonhus"
                    },
                    {
                        productTitle: "Цетодим + ПАР Фофір",
                        path: "/cetodim-par-fofir"
                    },

                    // третий файл

                    {
                        productTitle: "Альфа-Дикамба",
                        path: "/alfa-dikamba"
                    },
                    {
                        productTitle: "Альфа-Маїс",
                        path: "/alfa-mais"
                    },
                    {
                        productTitle: "Альфа-Прометрин",
                        path: "/alfa-prometrin"
                    },
                    {
                        productTitle: "Антизлак",
                        path: "/antizlak"
                    },
                    {
                        productTitle: "Верітан",
                        path: "/veritan"
                    },
                    {
                        productTitle: "Еталон",
                        path: "/etalon"
                    },
                    {
                        productTitle: "Кайман",
                        path: "/kaiman"
                    },
                    {
                        productTitle: "Кампус",
                        path: "/kampus"
                    },
                    {
                        productTitle: "Конкур",
                        path: "/konkur"
                    },
                    {
                        productTitle: "Лобера",
                        path: "/lobera"
                    },
                    {
                        productTitle: "Містард",
                        path: "/mistard"
                    },
                    {
                        productTitle: "Оскар Пауер",
                        path: "/oskar-pauer"
                    },
                    {
                        productTitle: "Отаман",
                        path: "/otaman"
                    },
                    {
                        productTitle: "Отаман Екстра",
                        path: "/otaman-ekstra"
                    },
                    {
                        productTitle: "Сокар",
                        path: "/sokar"
                    },
                    {
                        productTitle: "Сантал",
                        path: "/santal"
                    },
                    {
                        productTitle: "Триатлон",
                        path: "/triatlon"
                    },
                    {
                        productTitle: "Триатлон Прайм",
                        path: "/triatlon-prime"
                    },
                    {
                        productTitle: "Хаммер",
                        path: "/hammer"
                    },
                    {
                        productTitle: "Хаммер Дуо",
                        path: "/hammer-duo"
                    },

                    // четвёртый файл

                    {
                        productTitle: "Базагран Про",
                        path: "/basagran-pro"
                    },
                    {
                        productTitle: "Євро-Лайтінг Плюс",
                        path: "/evro-laiting-plus"
                    },
                    {
                        productTitle: "Євро-Лайтінг",
                        path: "/evro-laiting"
                    },
                    {
                        productTitle: "Пульсар Флекс",
                        path: "/pulsar-flex"
                    },
                    {
                        productTitle: "Стеллар Плюс",
                        path: "/stellar-plus"
                    },
                    {
                        productTitle: "Стомп 330",
                        path: "/stomp-330"
                    },
                    {
                        productTitle: "Стомп Аква",
                        path: "/stomp-akva"
                    },

                    // пятый файл

                    {
                        productTitle: "Каптора",
                        path: "/kaptora"
                    },
                    {
                        productTitle: "Каптора Плюс",
                        path: "/kaptora-plus"
                    },
                    {
                        productTitle: "Дербі 175",
                        path: "/derbi-175"
                    },
                    {
                        productTitle: "Дуал Голд 960 ЕС",
                        path: "/dual-gold-960-es"
                    },
                    {
                        productTitle: "Елюміс",
                        path: "/elymis"
                    },
                    {
                        productTitle: "Гезагард 500 FW",
                        path: "/gesagard-500-fw"
                    },
                    {
                        productTitle: "Пік 75 WG",
                        path: "/pik-75-wg"
                    },

                    // шестой файл

                    {
                        productTitle: "Геліантекс, КС (CORTEVA)",
                        path: "/heliantex-ks-corteva"
                    },

                ]
            },
            {
                // подкатегорія 1.2
                subCategoryTitle: "Інсектициди",
                products: [
                    {
                        productTitle: "Асистент Максі",
                        path: "/asistent-maksi"
                    },
                    {
                        productTitle: "Атрікс",
                        path: "/atriks"
                    },
                    {
                        productTitle: "Димефос",
                        path: "/dimefos"
                    },
                    {
                        productTitle: "Еміприд ПРО",
                        path: "/emiprid-pro"
                    },
                    {
                        productTitle: "Канонір Дуо",
                        path: "/kanonir-duo"
                    },
                    {
                        productTitle: "Лаготрин",
                        path: "/lagotrin"
                    },
                    {
                        productTitle: "Піризокс",
                        path: "/pirisoks"
                    },
                    {
                        productTitle: "Тіаклотрин-М",
                        path: "/tiaklotrin-m"
                    },
                    {
                        productTitle: "Фосорган ДУО",
                        path: "/fosorhan-duo"
                    },

                    // второй файл

                    {
                        productTitle: "Боксер",
                        path: "/bokser"
                    },
                    {
                        productTitle: "Ветеран",
                        path: "/veteran"
                    },
                    {
                        productTitle: "Готіка",
                        path: "/gotika"
                    },
                    {
                        productTitle: "Інтерн",
                        path: "/intern"
                    },
                    {
                        productTitle: "Контадор Дуо",
                        path: "/kontador-duo"
                    },
                    {
                        productTitle: "Президент",
                        path: "/president"
                    },
                    {
                        productTitle: "Спікер",
                        path: "/spiker"
                    },
                    {
                        productTitle: "Резидент",
                        path: "/resident"
                    },
                    {
                        productTitle: "Торсіда",
                        path: "/torsida"
                    },
                    {
                        productTitle: "Фатрін",
                        path: "/fatrin"
                    },
                    {
                        productTitle: "Шаман",
                        path: "/shaman"
                    },

                    // третий файл

                    {
                        productTitle: "Віарес",
                        path: "/viares"
                    },
                    {
                        productTitle: "Залп",
                        path: "/zalp"
                    },
                    {
                        productTitle: "Логус",
                        path: "/logus"
                    },
                    {
                        productTitle: "Наповал",
                        path: "/napoval"
                    },
                    {
                        productTitle: "Нокаут Екстра",
                        path: "/nokaut-ekstra"
                    },
                    {
                        productTitle: "Разит",
                        path: "/razit"
                    },
                    {
                        productTitle: "Флайп",
                        path: "/flaip"
                    },

                    // четвертий файл

                    {
                        productTitle: "Ампліго 150 ZC",
                        path: "/ampligo-150-zc"
                    },
                    {
                        productTitle: "Енжіо",
                        path: "/enjio"
                    },
                    {
                        productTitle: "Карате Зеон 050 CS",
                        path: "/karate-zeon-050-cs"
                    },
                    {
                        productTitle: "Люфокс 105 ЕС",
                        path: "/lufox-105-es"
                    },
                    {
                        productTitle: "Проклейм 5 SG",
                        path: "/proklaim-5-sg"
                    },
                    {
                        productTitle: "Вертимек Нео 018 SL",
                        path: "/vertimek-neo-018-sl"
                    },
                    {
                        productTitle: "Актеллік",
                        path: "/aktellik"
                    },
                ]
            },
            {
                // подкатегорія 1.3
                subCategoryTitle: "Фунгіциди",
                products: [
                    
                    {
                        productTitle: "Азоксин",
                        path: "/asoksin"
                    },
                    {
                        productTitle: "Ауріс",
                        path: "/auris"
                    },
                    {
                        productTitle: "Вартіс",
                        path: "/vartis"
                    },
                    {
                        productTitle: "Карбезим",
                        path: "/karbesim"
                    },
                    {
                        productTitle: "Маестро",
                        path: "/maestro"
                    },
                    {
                        productTitle: "Полігард",
                        path: "/poligard"
                    },
                    {
                        productTitle: "Полігард Максі",
                        path: "/poligard-maksi"
                    },
                    {
                        productTitle: "Флутривіт",
                        path: "/flutrivit"
                    },
                    {
                        productTitle: "Флутривіт Максі",
                        path: "/flutrivit-maksi"
                    },
                    {
                        productTitle: "Фунгімакс",
                        path: "/fungimaks"
                    },
                    {
                        productTitle: "Фунгісил",
                        path: "/fungisil"
                    },
                    {
                        productTitle: "Цимоксил",
                        path: "/cimoksil"
                    },

                    // Второй файл

                    {
                        productTitle: "Беркут",
                        path: "/berkut"
                    },
                    {
                        productTitle: "Брандер",
                        path: "/brander"
                    },
                    {
                        productTitle: "Віртуоз",
                        path: "/virtuoz"
                    },
                    {
                        productTitle: "Дезал",
                        path: "/dezal"
                    },
                    {
                        productTitle: "Прента",
                        path: "/prenta"
                    },
                    {
                        productTitle: "Рінкоцеб",
                        path: "/rinkoceb"
                    },
                    {
                        productTitle: "Скоразол",
                        path: "/scorasol"
                    },
                    {
                        productTitle: "Тіназол",
                        path: "/tinasol"
                    },
                    {
                        productTitle: "Тіома",
                        path: "/tioma"
                    },
                    {
                        productTitle: "Фіделіс",
                        path: "/fidelis"
                    },
                    {
                        productTitle: "Фонтес",
                        path: "/fontes"
                    },

                    // третий файл

                    {
                        productTitle: "Артіс Плюс",
                        path: "/artis-plus"
                    },
                    {
                        productTitle: "Баррет",
                        path: "/barret"
                    },
                    {
                        productTitle: "Док Про",
                        path: "/dok-pro"
                    },
                    {
                        productTitle: "Болівар Форте",
                        path: "/bolivar-forte"
                    },
                    {
                        productTitle: "Корвізар М",
                        path: "/korvizar-m"
                    },
                    {
                        productTitle: "Кросбі",
                        path: "/krosbi"
                    },
                    {
                        productTitle: "Релевант",
                        path: "/relevant"
                    },
                    {
                        productTitle: "Тезис",
                        path: "/tezis"
                    },
                    {
                        productTitle: "Теламус",
                        path: "/telamus"
                    },
                    {
                        productTitle: "Фенікс Дуо",
                        path: "/fenix-duo"
                    },

                    // четвёртый файл

                    {
                        productTitle: "Абакус",
                        path: "/abakus"
                    },
                    {
                        productTitle: "Піктор",
                        path: "/pictor"
                    },
                    {
                        productTitle: "Рекс ДУО",
                        path: "/reks-duo"
                    },
                    {
                        productTitle: "Рекс Плюс",
                        path: "/reks-plus"
                    },

                    // пятий файл

                    {
                        productTitle: "Амістар Екстра 280 SC",
                        path: "/amistar-ekstra-280-SC"
                    },
                    {
                        productTitle: "Амістар Голд 250 SE",
                        path: "/amistar-gold-250-se"
                    },
                    {
                        productTitle: "Елатус Ріа, 358 КЕ",
                        path: "/elatus-ria-358-ke"
                    },
                    {
                        productTitle: "Міравіс Дуо",
                        path: "/miravis-duo"
                    },
                    {
                        productTitle: "Міравіс Нео",
                        path: "/miravis-neo"
                    },
                    {
                        productTitle: "Міравіс Прайм",
                        path: "/miravis-prime"
                    },
                    {
                        productTitle: "Ревус Топ",
                        path: "/revus-top"
                    },
                    {
                        productTitle: "Ридоміл Голд",
                        path: "/ridomil-gold"
                    },
                    {
                        productTitle: "Світч",
                        path: "/svitch"
                    },
                    {
                        productTitle: "Юніформ",
                        path: "/uniform"
                    },

                ]
            },
            {
                // подкатегорія 1.4
                subCategoryTitle: "Протруйники",
                products: [
                    
                    {
                        productTitle: "Кватрофорс",
                        path: "/kvatrofors"
                    },
                    {
                        productTitle: "Тіатрин",
                        path: "/tiatrin"
                    },

                    // второй файл

                    {
                        productTitle: "Бакаш",
                        path: "/bakash"
                    },
                    {
                        productTitle: "Кайзер",
                        path: "/kaiser"
                    },
                    {
                        productTitle: "Тринаванта",
                        path: "/trinavanta"
                    },
                ]
            },
            {
                // подкатегорія 1.5
                subCategoryTitle: "Десиканти",
                products: [
                    
                    {
                        productTitle: "Регістан Ультра",
                        path: "/registan-ultra"
                    },
                    {
                        productTitle: "Регістан ПРО",
                        path: "/registan-pro"
                    },

                    // second файл

                    {
                        productTitle: "Скорпіон Форте",
                        path: "/skorpion-forte"
                    },

                ]
            },
            {
                // подкатегорія 1.6
                subCategoryTitle: "Прилипачі для рослин",
                products: [
                    
                    {
                        productTitle: "Агропав Екстра",
                        path: "/agropav-ekstra"
                    },
                    {
                        productTitle: "Акваглайд",
                        path: "/akvaglaid"
                    },
                    {
                        productTitle: "Протипен",
                        path: "/protipen"
                    },
                    {
                        productTitle: "Триолат",
                        path: "/triolat"
                    },

                    // second file

                    {
                        productTitle: "Талант",
                        path: "/talant"
                    },

                    // третий файл

                    {
                        productTitle: "Альфалип Екстра",
                        path: "/alfalip-ekstra"
                    },
                    {
                        productTitle: "Піноль",
                        path: "/pinol"
                    },
                    {
                        productTitle: "Сігма",
                        path: "/sigma"
                    },
                    {
                        productTitle: "Цивік",
                        path: "/civik"
                    },

                    // чертвертий файл

                    {
                        productTitle: "Біоприлипач «ТестаЛип»  (Прилипач–носій)",
                        path: "/bioprilipach-testalip"
                    },
                    {
                        productTitle: "MBS Піногасник",
                        path: "/mbs-pinogasnyk"
                    },
                    {
                        productTitle: "MBS pH-коректор",
                        path: "/mbs-ph-korektor"
                    },

                ]
            },
            {
                // подкатегорія 1.7
                subCategoryTitle: "Регулятори росту",
                products: [
                    
                    {
                        productTitle: "Тардер",
                        path: "/tarder"
                    },
                    {
                        productTitle: "Трінекс",
                        path: "/trinex"
                    },

                    // второй файл

                    {
                        productTitle: "Грейвіс",
                        path: "/greyvis"
                    },
                ]
            },
            {
                // подкатегорія 1.8
                subCategoryTitle: "Родентициди",
                products: [
                    
                    {
                        productTitle: "Мишолов",
                        path: "/misholov"
                    },
                ]
            },

            // категория 1.9 пропущена не случайно, в соответствии с файликами


            {
                // подкатегорія 1.10
                subCategoryTitle: "Біоінсектициди",
                products: [
                    
                    {
                        productTitle: "Скарадо-М (Картопля)",
                        path: "/skarado-m"
                    },
                ]
            },
            {
                // подкатегорія 1.11
                subCategoryTitle: "Біопрепарати",
                products: [
                    
                    {
                        productTitle: "Біо-Мінераліс (Інокулянт для Сої+МЕ)",
                        path: "/bio-mineralis-inokulyant-soya"
                    },
                    {
                        productTitle: "Біо-Мінераліс (Деструктор целюлози)",
                        path: "/bio-mineralis-destruktor-cellulosy"
                    },
                    {
                        productTitle: "Біофунгіцид «Цидокс Про»",
                        path: "/biofungicid-cidoks-pro"
                    },
                ]
            },
            // {
            //     // подкатегорія 1.12
            //     subCategoryTitle: "новая",
            //     products: [
                    
            //         {
            //             productTitle: "",
            //             path: "/"
            //         },
            //     ]
            // },
        ]
    },
    {
        // категория 2 
        categoryTitle: "Добрива та стабілізатори",
        subCategoryes: [
            {
                // подкатегорія 2.1
                subCategoryTitle: "Добрива",
                products: [
                    
                    {
                        productTitle: "ФоліГрін Комбі",
                        path: "/foligreen-kombi"
                    },
                    {
                        productTitle: "ФоліГрін Мікро",
                        path: "/foligreen-mikro"
                    },
                    {
                        productTitle: "ФоліГрін Цинк — 90",
                        path: "/foligreen-cink-90"
                    },
                    {
                        productTitle: "ФоліГрін Бор-150",
                        path: "/foligreen-bor-150"
                    },
                    {
                        productTitle: "ФоліГрін ФосФіт",
                        path: "/foligreen-fosfit"
                    },
                    {
                        productTitle: "ФоліГрін Аміно",
                        path: "/foligrin-amino"
                    },
                    {
                        productTitle: "ФолігГрін Фульват",
                        path: "/foligrin-fulvat"
                    },

                    // второй файл


                    {
                        productTitle: "Fulvit Баланс",
                        path: "/fulvit-balance"
                    },
                    {
                        productTitle: "Fulvit СтопСтрес",
                        path: "/fulvit-stopstres"
                    },
                ]
            },
            {
                // подкатегорія 2.2
                subCategoryTitle: "Комплексні полімікродобрива",
                products: [
                    
                    {
                        productTitle: "Мікро-Мінераліс (Зернові)",
                        path: "/mikro-mineralis-zernovi"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Олійні)",
                        path: "/mikro-mineralis-oliyni"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Кукурудза)",
                        path: "/mikro-mineralis-kukurudza"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Універсальний) Плюс",
                        path: "/mikro-mineralis-universal-plus"
                    },
                    {
                        productTitle: "Мікро-Мінераліс «Аміно» + МЕ",
                        path: "/mikro-mineralis-amino-me"
                    },
                ]
            },
            {
                // подкатегорія 2.3
                subCategoryTitle: "Монопрепарати",
                products: [
                    
                    {
                        productTitle: "Мікро-Мінераліс (Нітро)",
                        path: "/mikro-mineralis-nitro"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Фосфор-Калій)",
                        path: "/mikro-mineralis-fosfor-kaliy"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Калій)",
                        path: "/mikro-mineralis-kaliy"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Кремній-Калій)",
                        path: "/mikro-mineralis-kremniy-kaliy"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Марганець)",
                        path: "/mikro-mineralis-marganec"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Молібден)",
                        path: "/mikro-mineralis-molibden"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Цинк)",
                        path: "/micro-mineralis-cink"
                    },
                    {
                        productTitle: "Мікро-Мінераліс (Бор) + МЕ",
                        path: "/mikro-mineralis-bor-me"
                    },
                ]
            },
            // {
            //     // подкатегорія 2.4
            //     subCategoryTitle: "новая",
            //     products: [
                    
            //         {
            //             productTitle: "",
            //             path: "/"
            //         },
            //     ]
            // },
        ]
    },
    {
        // категория 3 
        categoryTitle: "Насіння",
        subCategoryes: [
            {
                // подкатегорія 3.1
                subCategoryTitle: "Соняшник",
                products: [
                    
                    {
                        productTitle: "Алькантара Класичний ALCANTARA соняшник Syngenta",
                        path: "/alkantar-klassic-sonyashnyk-syngenta"
                    },
                    {
                        productTitle: "Сузука HTS SUZUKA Syngenta",
                        path: "/suzuka-hts-syngenta"
                    },
                    {
                        productTitle: "Сурелі HTS SURELI Syngenta",
                        path: "/syreli-hts-syngenta"
                    },
                    {
                        productTitle: "СИ Мічіган КЛП Syngenta",
                        path: "/sy-michigan-clp-syngenta"
                    },
                    {
                        productTitle: "Сурест HTS Syngenta SUREST",
                        path: "/surest-hts-syngenta"
                    },
                    {
                        productTitle: "Сайберік HTS SUBERIC Syngenta",
                        path: "/suberic-hts-syngenta"
                    },
                    {
                        productTitle: "Суомі HTS Syngenta SUOMI",
                        path: "/suomi-hts-syngenta"
                    },
                    {
                        productTitle: "СИ Ласкала Класичний SY_LASCALA Syngenta",
                        path: "/sy-lascala-syngenta"
                    },
                    {
                        productTitle: "СИ Арізона Класичний SY_ARISONA Syngenta",
                        path: "/sy-arisona-syngenta"
                    },
                    {
                        productTitle: "СИ Лазурі Clearfield Plus SY_LAZURI Syngenta",
                        path: "/sy-lazuri-clearfield-syngenta"
                    },
                    {
                        productTitle: "СИ Амазонія Класичний SY_AMAZONIA Syngenta",
                        path: "/sy-amazonia-syngenta"
                    },

                    // второй файл

                    {
                        productTitle: "ЛГ 50450 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50450-kruiser-limagrain"
                    },
                    {
                        productTitle: "Назва: ЛГ 50455 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50455-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50479 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50479-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50480 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50480-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50549 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50549-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50550 КЛП Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50550-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50616 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50616-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50635 КЛП Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50635-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50639 LG Круїзер насіння соняшнику",
                        path: "/lg-50639-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50689 СХ LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50689-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 5542 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-5542-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 5555 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-5555-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 5580 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-5580-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 58390 КЛ LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-58390-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 59580 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-59580-kruiser-limagrain"
                    },
                    {
                        productTitle: "ТУНКА Круїзер насіння соняшнику Лімагрейн",
                        path: "/tunka-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50487 КЛП LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50487-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50510 LG Круїзер насіння соняшнику",
                        path: "/lg-50510-kruiser-limagrain"
                    },
                    {
                        productTitle: "ЛГ 50621 LG Круїзер насіння соняшнику Лімагрейн",
                        path: "/lg-50621-kruiser-limagrain"
                    },
                ]
            },
            {
                // подкатегорія 3.2
                subCategoryTitle: "Кукурудза",
                products: [
                    
                    {
                        productTitle: "30273 ЛГ Форс Зеа Лімагрейн",
                        path: "/lg-30273-fors-zea-limagrain"
                    },
                    {
                        productTitle: "ЛГ 30315 Форс Зеа Лімагрейн",
                        path: "/lg-30315-fors-zea-limagrain"
                    },
                    {
                        productTitle: "31272 ЛГ Форс Зеа Лімагрейн",
                        path: "/lg-30272-fors-zea-limagrain"
                    },
                    {
                        productTitle: "31240 ЛГ Форс Зеа Лімагрейн",
                        path: "/lg-31240-fors-zea-limagrain"
                    },
                    {
                        productTitle: "30215 ЛГ Форс Зеа",
                        path: "/lg-30215-fors-zea"
                    },
                    {
                        productTitle: "31330 ЛГ Форс Зеа Лімагрейн",
                        path: "/lg-31330-fors-zea-limagrain"
                    },
                    {
                        productTitle: "Адевей Форс Зеа насіння кукурудзи Лімагрейн",
                        path: "/adevey-fors-zea-limagrain"
                    },
                ]
            },
            // {
            //     // подкатегорія 3.3
            //     subCategoryTitle: "новая",
            //     products: [
                    
            //         {
            //             productTitle: "",
            //             path: "/"
            //         },
            //     ]
            // },
        ]
    },
]
