import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navArr } from "../../utils/navArr";
import "./AsideNavigation.css";
import plusIcon from '../../assets/icons/plusIcon.png';
import Arrow from '../../assets/icons/Arrow.png';
import ArrowActive from '../../assets/icons/ArrowActive.png';

const AsideNavigation = () => {
  const [activeIndexes, setActiveIndexes] = useState([]); 
  const [activeSubIndexes, setActiveSubIndexes] = useState([]); 
  const location = useLocation();

  const toggleAccordian = (index) => {
    setActiveIndexes((prevState) =>
      prevState.includes(index)
        ? prevState.filter((i) => i !== index)
        : [...prevState, index]
    );
  };

  const toggleSubAccordian = (categoryIndex, subIndex) => {
    setActiveSubIndexes((prevState) => {
      const key = `${categoryIndex}-${subIndex}`;
      if (prevState.includes(key)) {
        return prevState.filter((i) => i !== key);
      } else {
        return [...prevState, key];
      }
    });
  };

  return (
    <aside className="aside__navigation">
      <h1 className="heading mont-r">Каталог товарів</h1>
      <div className="accordion">
        {navArr.map((category, categoryIndex) => (
          <div className="accordion-item" key={categoryIndex}>
            <div
              className="accordion-header"
              onClick={() => toggleAccordian(categoryIndex)}
            >
              <h2
                className={`category-title ${
                  activeIndexes.includes(categoryIndex) ? "active" : ""
                } mont-sb`}
              >
                {category.categoryTitle}
              </h2>
              <img
                src={plusIcon}
                alt="іконка плюсика, натисніть щоб відкрити, або закрити категорію"
                className={`plus__icon ${
                  activeIndexes.includes(categoryIndex) ? "active" : ""
                }`}
              />
            </div>
            {activeIndexes.includes(categoryIndex) && (
              <div className="accordion-content">
                {category.subCategoryes.map((subCategory, subIndex) => {
                  const key = `${categoryIndex}-${subIndex}`;
                  return (
                    <div className="sub-accordion-item" key={subIndex}>
                      <div
                        className="sub-accordion-header"
                        onClick={() => toggleSubAccordian(categoryIndex, subIndex)}
                      >
                        <h3
                          className={`sub-category-title ${
                            activeSubIndexes.includes(key) ? "active" : ""
                          } mont-r`}
                        >
                          {subCategory.subCategoryTitle}
                        </h3>
                        <img
                          src={plusIcon}
                          alt="іконка плюсика, натисніть щоб відкрити, або закрити підкатегорію"
                          className={`plus__icon ${
                            activeSubIndexes.includes(key) ? "active" : ""
                          }`}
                        />
                      </div>
                      {activeSubIndexes.includes(key) && (
                        <div className="sub-accordion-content">
                          <ul>
                            {subCategory.products.map((product, productIndex) => (
                              <Link
                                className="sub__accodion-product"
                                to={product.path}
                                key={productIndex}
                              >
                                <p
                                  className={`sub__accordion-product-title ${
                                    location.pathname === product.path
                                      ? "active"
                                      : ""
                                  } mont-r`}
                                >
                                  {product.productTitle}
                                </p>
                                <img
                                  src={
                                    location.pathname === product.path
                                      ? ArrowActive
                                      : Arrow
                                  }
                                  alt={`перехід на сторінку ${product.productTitle}`}
                                  className="product__arrow"
                                />
                              </Link>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default AsideNavigation;
