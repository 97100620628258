import fertilizers1 from "../assets/images/FertilizersImages/foligreen-kombi.webp";
import fertilizers2 from "../assets/images/FertilizersImages/foligreen-mikro.webp";
import fertilizers3 from "../assets/images/FertilizersImages/foligreen-cink-90.webp";
import fertilizers4 from "../assets/images/FertilizersImages/foligreen-bor-150.webp";
import fertilizers5 from "../assets/images/FertilizersImages/foligreen-fosfit.webp";
import fertilizers6 from "../assets/images/FertilizersImages/foligrin-amino.webp";
import fertilizers7 from "../assets/images/FertilizersImages/foligrin-fulvat.webp";
import fertilizers8 from "../assets/images/FertilizersImages/fulvit-balance.png";
import fertilizers9 from "../assets/images/FertilizersImages/fulvit-stopstres.png";

export const fertilizersArr = [
  {
    productTitle: "ФоліГрін Комбі",
    path: "/foligreen-kombi",
    src: fertilizers1,
    volume: "20 л",
    price: 68.08,
    currency: "USD",
    text: (<>Опис: ФоліГрін -  комплексне добриво-коректор дефіциту мікроелементів, з високим вмістом азоту та магнію. Мікроелементи хелатовані. Особливо ефективно на етапах вегетативного росту, стимулює фотосинтез та ріст рослин.
    <span className="paragraph"></span>
      Особливості, переваги: 
        •	Вивірена та збалансована формула препарату на основі європейського досвіду.
        •	Технологія повного хелатування мікроелементів згідно вимог директив Європейського Союзу.
        •	Повна розчинність у воді та відмінна сумісність в бакових сумішах.
        •	Не містить шкідливих і токсичних для культур сполук.
        •	Склад, % об.:
        •	N-17,0;
        •	MgO-2,50;
        •	Fe-1,40;
        •	Cu-0,70;
        •	Mn-1,40;
        •	Zn-1,40;
        •	Mo-0,025;
        •	B-0,1;
        •	SO3-2,9.
        •	
        <span className="paragraph"></span>
      Норма витрати робочого розчину: Передпосівна обробка насіння: 1-2 л/т.
      <span className="paragraph"></span>
      Позакореневе живлення: 1-2 л/га.
      <span className="paragraph"></span>
      Норма витрати робочого розчину.
      Звичайні оприскувачі – 200-300 л/га.
      Оприскувачі з дрібнодисперсним розпиленням – можливо знизити норму витрати робочого розчину до 50-150 л/га.
      <span className="paragraph"></span>
      Можливо також виконувати авіаційну обробку.
      <span className="paragraph"></span>
      Норма витрати при авіаобробці – 1,5-2,5 л/га на одну обробку, а витрата робочого розчину залежить від типу обладнання (перш за все – від типу форсунок) і може складати від 5 до 50 л/га.
      <span className="paragraph"></span>
      Обприскування проводити зранку або ввечері. Оптимальна температура повітря 10-25 0С. Швидкість вітру до 5 м/с.
      <span className="paragraph"></span>
      Рекомендації по застосуванню.
      <span className="paragraph"></span>
      Культура: 
        •	Зернові колосові; 
        •	Кукурудза; 
        •	Соняшник; 
        •	Цукровий і кормовий буряк; 
        •	Соя; 
        •	Овочеві; 
        •	Плодові; 
        •	Ягідні.
        <span className="paragraph"></span>
      Фаза розвитку: 
        •	Вихід в трубку — молочно-воскова стиглість; 
        •	5-8 листків — початок викидання волотей; 
        •	Цвітіння — ріст плодів — збір врожаю; 
        •	Цвітіння — ріст плодів — збір врожаю.</>),
  },
  {
    productTitle: "ФоліГрін Мікро",
    path: "/foligreen-mikro",
    src: fertilizers2,
    volume: "20 л",
    price: 64.86,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Мікро — високоефективне збалансоване мікродобриво, збагачене азотом, фосфором, калієм та сіркою. Комбінація високодоступних форм цинку, марганцю та міді забезпечує високу зимостійкість озимих культур, сприяє розвитку кореневої системи та стресостійкості на посівах ярих культур. 
    <span className="paragraph"></span>
Особливості, переваги:
	•	Покращує ріст і розвиток культур, дозволяє відкоригувати дефіцит міді, цинку та марганцю;
	•	Азот та сірка попереджують дисбаланс у живленні рослин, активують фотосинтез та приріст вегетативної маси;
	•	Фосфор та калій сприяє розвитку кореневої системи, підвищує зимостійкість (для озимих культур) та посухостійкість;
	•	Високий вміст мікроелементів сприяють ферментативній активності та підвищенню стресостійкості.
  <span className="paragraph"></span>
Особливо ефективне при позакореневому підживленні озимих культур для покращення кущення та підвищення посухостійкості в осінній період, сприяє зимостійкості. Урепродуктивний період розвитку забезпечує поліпшення якісних показників урожайності усіх типів культур.
Zn відіграє важливу роль у рості та розвитку тканин, коріння та судинної системи рослини. Він також важливий для синтезу білка та структурної цілісності.
Cu сприяє формуванню клітинної стінки, синтезу білка, запиленню та утворенню труб,а також вуглеводному та азотному обміну, бере участь в утворенні лігніну вклітинних стінках рослин.
Mn має вирішальне значення для фотосинтезу, метаболізму азоту та утилізації вуглеводів. Він також зміцнює імунну систему та природний захист рослин, сприяє метаболізму гербіцидів.
<span className="paragraph"></span>
Склад %: об.:
	•	N - 1,7%;
	•	SO3 - 3,5%;
	•	P2O5 - 7,0%;
	•	K2O - 2,2%;
	•	Cu - 2,0%;
	•	Zn - 2,3%;
	•	Mn - 1,0%;
	•	B - 1,0%;
	•	Mo - 0,02%;
	•	Co - 0,004%.
  <span className="paragraph"></span>
Норма витрати робочого розчину: Обробка насіння: 1,0–2,0 л/т насіння зернових, зернобобових, технічних та овочевих культур.
<span className="paragraph"></span>
Позакореневеживлення: 1,0–2,0 л/га в залежності від культури та фази розвитку.
<span className="paragraph"></span>
Рекомендовані норми застосування.
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Озимий і ярий ріпак; 
	•	Цукровий і кормовий буряк; 
	•	Соя; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Вихід в трубку — молочно-воскова стиглість;
	•	5-8 листків-початок викидання волостей; 
	•	Весняна розетка — стеблування — бутонізація; 
	•	Цвітіння — ріст плодів — збір врожаю.</>),
  },
  {
    productTitle: "ФоліГрін Цинк — 90",
    path: "/foligreen-cink-90",
    src: fertilizers3,
    volume: "20 л",
    price: 62.56,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Цинк — 90 — концентроване добриво з високою біологічною доступністю для запобігання та корекції дефіциту цинку у польових, овочевих та плодово-ягідних культур. 
    <span className="paragraph"></span>
Переваги: 
	•	Концентроване добриво для корекції нестачі цинку у рослин; 
	•	Органічна форма цинку має високу біологічну активність та швидко засвоюється культурами; 
	•	Додатково містить високу концентрацію азоту, що сприяє активації фотосинтезу та росту культур.
  <span className="paragraph"></span>
Склад об% (г/л): 
	•	N-4,0%; 
	•	Zn-9,0%.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 
Обробка насіння. Норма застосування 1,0-2,0 л/т насіння зернових, зернобобових, технічних та овочевих культур.
<span className="paragraph"></span> 
Позакореневе живлення. Рекомендовані норми витрати при позакореневому підживлення становлять 0,5-1,5 л/га в залежності від культури та фази розвитку. 
<span className="paragraph"></span>
Рекомендації застосування.
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Цукровий і кормовий буряк; 
	•	Озимий і ярий ріпак; 
	•	Соя; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Вихід в трубку-молочно-воскова стиглість; 
	•	4-6 листків- змикання листків в міжряддях-ріст коренеплодів;
	•	3-6 пар листків-фаза зірочки.</>),
  },
  {
    productTitle: "ФоліГрін Бор-150",
    path: "/foligreen-bor-150",
    src: fertilizers4,
    volume: "20 л",
    price: 63.48,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Бор-150  - класичне високодоступне добриво на основі борєтаноламіну з оптимальною підібраною концентрацією бору. 
    <span className="paragraph"></span>
Переваги: 
	•	Концентроване добриво для корекції нестачі бору у рослині; 
	•	Додатково містить високу концентрацію азоту, що сприяє засвоєнню бору, активації фотосинтезу та росту культур. 
  <span className="paragraph"></span>
Склад об%:
	•	N – 6,0%;
	•	B – 15,0%.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 
Ґрунтове внесення. При сумісному застосуванні із рідкими стартовими добривами норма витрати становить 0,5-1,0 л/га.
<span className="paragraph"></span>
Позакореневе живлення. Рекомендовані норми витрати при позакореневому підживленні становить 0,2-2,0 л/га в залежності від культури та фази розвитку.
<span className="paragraph"></span>
Рекомендації по застосуванню.
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Цукровий і кормовий буряк; 
	•	Озимий і ярий ріпак; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	4-6 листків-цвітіння-ріст плодів-збір врожаю; 
	•	Цвітіння — ріст плодів — збір врожаю.</>),
  },
  {
    productTitle: "ФоліГрін ФосФіт",
    path: "/foligreen-fosfit",
    src: fertilizers5,
    volume: "20 л",
    price: 99.36,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Фосфіт — рідке добриво-стимулятор з фунгіцидним ефектом з високим вмістом азоту, калію та фосфору у фосфорній формі. Додатково містить бор для покращення міцності клітинних стінок, стимуляції запилення та накопичення цукрів.
    <span className="paragraph"></span>
Переваги: 
	•	Висока концентрація фосфітів забезпечує виражену фунгіцидну та біостимулюючу дію на розвиток кореневої системи і накопичення цукрів; 
	•	Додатково містить бор, що покращує цвітіння, розвиток насіння та плодів, сприяє синтезу структурних вуглеводів та транслокації цукрів в рослинах.
  <span className="paragraph"></span>
Склад об%:
	•	N - 7,2%;
	•	P2O5 – 52,0%;
	•	К2O – 20,0%;
	•	B – 1,5%.
  <span className="paragraph"></span>
Норма витрати робочого розчину.
Позакореневе живлення. Рекомендовані норма витрати при позакореневому підживленні становлять 1,0-2,0 л/га в залежності від культури та фази розвитку.
<span className="paragraph"></span>
Фертигація. Норма витрат 2-4 л/га, залежно від культури та фітосанітарного стану рослин. Перше внесення з першим весняним поливом, наступні обробки з інтервалом в три місяці або за потребою. Не перевищуйте 2% концентрацію робочого розчину (2 л на 100л води) на одне внесення. 
<span className="paragraph"></span>
Рекомендації по застосуванню. 
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Цукровий і кормовий буряк; 
	•	Озимий і ярий ріпак; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Вихід в трубку-молочно-воскова стиглість;
	•	5-8 листків — початок викидання волостей; 
	•	Цвітіння — ріст плодів - збір врожаю;
	•	4-6 листків — змикання листків в міжряддях — ріст коренеплодів.</>),
  },
  {
    productTitle: "ФоліГрін Аміно",
    path: "/foligrin-amino",
    src: fertilizers6,
    volume: "20 л",
    price: 77.28,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Аміно — комплексне добриво - антистресант з амінокислотами для позакореневого підживлення рослин. Містить збалансований набір макроелементів та L-амінокислоти рослинного походження.
    <span className="paragraph"></span> 
Переваги: 
	•	Висока концентрація L- амінокислот рослинного походження; 
	•	Додатково містить азот, фосфор та калій, які покращують фотосинтез та ріст культур.
  <span className="paragraph"></span>
Склад об%: 
	•	L-амінокислоти 16,0%;
	•	N-6,0%;
	•	P2O5-2,0%;
	•	K2O-2,0%.
  <span className="paragraph"></span>
Норма витрат робочого розчину: 
Обробка насіння. Норма витрати 1-2 л/т насіння зернових, зернобобових, технічних та овочевих культур.
<span className="paragraph"></span>
Фертигація. При фертигації препарат застосовують 3-4 рази в період вегетації в дозі 1,0-5,0 л/га. Рекомендується проводити очищення фільтрів системи зрошення перед застосуванням та після нього, у зв’язку з наявністю органічних компонентів у препараті. Підживлення розсади після висадки — полив розчином 0,5-0,7% ( 0,5-0,7л препарату на 100 л води).
<span className="paragraph"></span>
Позакореневе живлення. Рекомендовані норми витрат при позакореневому підживленні становить 0,5-1,0 л/га в залежності від культури та фази розвитку.
<span className="paragraph"></span>
Рекомендації по застосуванню.
<span className="paragraph"></span>
Культура:
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Цукровий і кормовий буряк; 
	•	Озимий і ярий ріпак; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Вихід в трубку-молочно-воскова стиглість;
	•	5-8 листків — початок викидання волостей; 
	•	Цвітіння — ріст плодів - збір врожаю.</>),
  },
  {
    productTitle: "ФолігГрін Фульват",
    path: "/foligrin-fulvat",
    src: fertilizers7,
    volume: "20 л",
    price: 57.04,
    currency: "USD",
    text: (<>
    Опис: ФоліГрін Фульват — рідкий висококонцентрований гумат калію, з високим вмістом фульвокислот. Стимулює обмінні процеси, покращує поглинання та засвоєння елементів живлення. Особливо ефективний при передпосівній обробці насіння. 
    <span className="paragraph"></span>
Переваги:
	•	Рідкий висококонцентрований гумат калію, виготовлений з якісного леонардиту; 
	•	Високий вміст фульвокислот.
  <span className="paragraph"></span>
Склад: 
	•	15% гумат калію;
	•	Співвідношення солей фульвових/гумінових кислот (60/40 %); 
	•	K2O – 7,0%;
	•	Мікроелементи  - 0,5%.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 
Обробка насіння.  Норма застосування 0,5-1,0 л/т насіння зернових, зернобобових, технічних та овочевих культур.
<span className="paragraph"></span>
Фертигація.  2-4 рази в період вегетації в дозі 1,0-5,0 л/га. Рекомендується проводити очищення фільтрів системи зрошення перед застосуванням та після нього у зв’язку з наявністю органічних компонентів у препараті.
<span className="paragraph"></span>
Позакореневе живлення. 
Рекомендовані норми витрат при позакореневому підживленні становлять 0,5-1,0 л/га в залежності від культури та фази розвитку. 
<span className="paragraph"></span>
Рекомендації по застосуванню. 
<span className="paragraph"></span>
Культура: 
	•	Зернові колосові; 
	•	Кукурудза; 
	•	Соняшник; 
	•	Цукровий і кормовий буряк; 
	•	Озимий і ярий ріпак; 
	•	Овочеві; 
	•	Плодові; 
	•	Ягідні.
  <span className="paragraph"></span>
Фаза розвитку: 
	•	Вихід в трубку-молочно-воскова стиглість;
	•	5-8 листків — початок викидання волостей; 
	•	Цвітіння — ріст плодів - збір врожаю.</>),
  },

  // второй файл

  {
    productTitle: "Fulvit Баланс",
    path: "/fulvit-balance",
    src: fertilizers8,
    volume: "20 л",
    price: 216,
    currency: "EUR",
    text: (<>
    Опис:  Fulvit Баланс -  це не лише адаптогенний засіб, що мінімізує негативний вплив різноманітних стрес-факторів на сільськогосподарські культури та підвищує природній імунітет, а ще й сильний регулятор росту. 
    <span className="paragraph"></span>
    Переваги:
	•	Відмінний вибір для отримання стабільного врожаю;
	•	Підготовка рослин до стресу;
	•	Спрямування енергії рослин на формування своєї продуктивності;
	•	Всебічна дія на ростові процеси та захисні механізми;
	•	Краще живлення рослин поживними елементами – фосфором і азотом;
	•	Ефективний для широкого спектру сільськогосподарських культур.
  <span className="paragraph"></span>
Оптимальний період для застосування:
Обробка «на випередження» перед заморозками, посухою та іншими стресами на почткових фазах росту та розвитку.
<span className="paragraph"></span>
Склад: 
	•	Солі фульвокислот — 90 г/л; 
	•	Солі гумінових кислот — 80 г/л; 
	•	Амінокислоти — 70 г/л; 
	•	N (азот органічних сполук) — 25 г/л;
	•	K₂O (калій органічних сполук) — 20 г/л; 
	•	рН — 8-9 од.
  <span className="paragraph"></span>
Застосування: 
<span className="paragraph"></span>
Передпосівна обробка насіння: 
	•	зернові, технічні та олійні — 0,8 л/т; 
  <span className="paragraph"></span>
Листове живлення: 
2-4 обробки за сезон, в бакових сумішах, з добривами, ЗЗР або самостійно.
	•	Зернові, зернобобові — 0,2-0,4 л/га; 
	•	Кукурудза, ріпак, соняшник, цукрові буряки — 0,2-0,4 л/га;
	•	Овочеві — 0,2-0,5 л/га.
  <span className="paragraph"></span>
Фертигація та закритий ґрунт: 
	•	овочеві — 0,5 л/1 м3 води.</>),
  },
  {
    productTitle: "Fulvit СтопСтрес",
    path: "/fulvit-stopstres",
    src: fertilizers9,
    volume: "20 л",
    price: 257.99,
    currency: "EUR",
    text: (<>
    Опис:  Fulvit СтопСтрес —інноваційна розробка, що покликана реанімувати рослини навіть після значного пошкодження внаслідок стресу. Потужний ефект досягається завдяки тому, що вдалося поєднати у стабільному стані всі найкращі природні регулятори росту, фітогормони та антистресанти.  
    <span className="paragraph"></span>
Переваги: 
	•	Рослина виходить з жорсткого стресу в 2–3 рази швидше;
	•	Стабільна формула;
	•	Найкращі природні компоненти;
	•	Вплив на рослину на генетичному рівні;
	•	Покращує засвоєння фосфору з ґрунту за низьких температур;
	•	Збереження ростових процесів у несприятливих умовах.
  <span className="paragraph"></span>
Оптимальний період для застосування:
	•	Обробка під час заморозку, засухи, пестицидного стресу;
	•	Пошкодження рослин шкідниками та хворобами;
	•	Механічні пошкодження рослин, в т.ч. внаслідок градобою.
  <span className="paragraph"></span>
Склад: 
	•	Солі гумінових кислот — 70 г/л; 
	•	Солі фульвових кислот — 90 г/л; 
	•	Екстракт морських водоростей — 90 г/л; 
	•	Амінокислоти — 70 г/л; 
	•	N (азот органічних сполук) — 40 г/л;
	•	K₂O (калій органічних сполук) — 20 г/л; 
	•	Альгінова кислота — 20 г/л;  
	•	Мікроелементи — 5 г/л; 
	•	рН — 8-9 г/л. 
  <span className="paragraph"></span>
Застосування: 
<span className="paragraph"></span>
Передпосівна обробка насіння: 
	•	зернові, технічні та олійні — 0,8 л/т; 
  <span className="paragraph"></span>
Листове живлення: 
2-4 обробки за сезон, в бакових сумішах, з добривами, ЗЗР або самостійно.
	•	Зернові, зернобобові — 0,2-0,4 л/га; 
	•	Кукурудза, ріпак, соняшник, цукрові буряки — 0,2-0,4 л/га;
	•	Овочеві — 0,2-0,5 л/га.
  <span className="paragraph"></span>
Фертигація та закритий ґрунт: 
	•	овочеві — 0,5 л/1 м3 води.</>),
  },
];
