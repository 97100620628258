import sunFlowerSY from "../assets/images/SunflowerImages/sunFlowerSY.webp";
import sunFlowerLG from "../assets/images/SunflowerImages/sunFlowerLG.webp";

export const sunflowerArr = [
  {
    productTitle: "Алькантара Класичний ALCANTARA соняшник Syngenta",
    path: "/alkantar-klassic-sonyashnyk-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 174,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Гібрид екстенсивного типу з високими темпами зростання на
        початкових етапах розвитку; • Висока посухостійкість та жаростійкість; •
        Високий потенціал урожайності (у посушливих умовах).
        <span className="paragraph"></span>
        Група стиглості: Середньоранній
        <span className="paragraph"></span>• Тип: Екстенсивний;
        <span className="paragraph"></span>• Висота рослини:
        Середня-висока (залежно від вологозабезпечення);
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G; •
        Олійність: до 50%.
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 8
        <span className="paragraph"></span>• Початкові темпи росту: 9
        <span className="paragraph"></span>• Стабільність урожаю: 8
        <span className="paragraph"></span>• Посухостійкість: 9
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        ранні-пізні
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        7<span className="paragraph"></span>• Толерантність до фомопсису: 7
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 7
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ, Лісостеп (Південний)
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - не
        рекомендується
        <span className="paragraph"></span>• Помірне зволоження - 40-50 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження -
        35-40 тис/га
      </>
    ),
  },
  {
    productTitle: "Сузука HTS SUZUKA Syngenta",
    path: "/suzuka-hts-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 185.7,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Середньоранній екстенсивний гібрид зі швидкими темпами
        зростання на початкових етапах органогенезу • Висока врожайність у
        сульфо-сегменті в посушливих умовах за рахунок підвищеної
        посухостійкості та стійкості до вовчка • Комбінація ключових
        агрономічних характеристик для зон із посушливими та жаркими умовами -
        раннє проростання та дозрівання • Оптимізовано для гербіциду Експрес ®
        компанії FMC
        <span className="paragraph"></span>
        Група стиглості: Середньоранній
        <span className="paragraph"></span>• Тип: Екстенсивний
        <span className="paragraph"></span>• Висота рослини: Середня-вища за
        середню (залежно від вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-F
        <span className="paragraph"></span>• Олійність: до 49%
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 8
        <span className="paragraph"></span>• Посухостійкість: 9
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        ранні-пізні
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        7<span className="paragraph"></span>• Толерантність до фомопсису: 6
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 7
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - не
        рекомендується
        <span className="paragraph"></span>• Помірне зволоження - 40-50 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - 35-45
        тис/га
      </>
    ),
  },
  {
    productTitle: "Сурелі HTS SURELI Syngenta",
    path: "/syreli-hts-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 236.78,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Гібрид лінолевого типу з високим потенціалом урожаю •
        Стійкий до посухи • Добра запиленість кошика, зокрема у стресових умовах
        • Толерантний до основних хвороб (фомоз, фомопсис, склеротинія кошика,
        несправжня борошниста роса) • Оптимізовано для гербіциду Експрес®
        компанії FMC
        <span className="paragraph"></span>
        Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Висока (залежно від
        вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 50-54 %
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 9
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні(оптимальні)-пізні
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ (Центральний та Північний)
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 55-60 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - 35-45
        тис/га
      </>
    ),
  },
  {
    productTitle: "СИ Мічіган КЛП Syngenta",
    path: "/sy-michigan-clp-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 233.2,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Новий помірно-інтенсивний Clearfield® Plus гібрид •
        Стабільна врожайність • Висока початкова енергія зростання • Висока
        стійкість до несправжньої мучної роси і стеблового вилягання • Добра
        стійкість до основних хвороб (фомоз, фомопсис, склеротинія кошика)
        <span className="paragraph"></span>
        Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: 50-52 %
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 7
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні(оптимальні) - пізні
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ (Центральний та Північний)
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-50 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - 35-45
        тис/га
      </>
    ),
  },
  {
    productTitle: "Сурест HTS Syngenta SUREST",
    path: "/surest-hts-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 230.81,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Нова генетика — лінолевий гібрид інтенсивного типу з високим
        потенціалом урожайності; • Висока початкова енергія росту на перших
        етапах органогенезу; • Високий вміст олії (на рівні гібрида Суміко); •
        Стійкий до вовчка рас A– G; • Висока стійкість до несправжньої
        борошнистої роси; • Толерантний до основних хвороб соняшнику (фомопсис,
        фомоз, склеротиніоз кошикова і стеблова форми); • Стійкий до стеблового
        вилягання.
        <span className="paragraph"></span>
        Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Інтенсивний
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 55%
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 8
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні(оптимальні) — ранні — пізні.
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ (Північний), лісостеп, полісся.
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 55-60 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - 40-45
        тис/га
      </>
    ),
  },
  {
    productTitle: "Сайберік HTS SUBERIC Syngenta",
    path: "/suberic-hts-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 191.55,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Гібрид лінолевого типу, який максимально розкриває свій
        потенціал за інтенсивної та помірно-інтенсивної технології вирощування.
        • Високий потенціал і стабільна врожайність. Добра запиленість кошика •
        Толерантний до основних хвороб соняшнику (фомоз, несправжня мучниста
        роса, біла гнилизна кошика) • Оптимізовано для гербіциду Експрес ®
        компанії FMC​​
        <span className="paragraph"></span>• Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Середня-вища за
        середню (залежно від вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 51%
        <span className="paragraph"></span>• АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали
        від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні (оптимальні)
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Степ (Північний, Центральний), Лісостеп
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - 35-45
        тис/га
      </>
    ),
  },
  {
    productTitle: "Суомі HTS Syngenta SUOMI",
    path: "/suomi-hts-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 176.58,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Помірно-інтенсивний гібрид лінолевого типу з доброю
        початковою енергією зростання. Добра запиленість кошика. • Толерантний
        до основних хвороб, зокрема до несправжньої мучнистої роси, фомопсису та
        ін. Стійкий до вилягання. • Висока і стабільна врожайність. Стабільно
        високий вміст олії. Пластичний до термінів посіву. • Оптимізовано для
        гербіциду Експрес ® компанії FMC​
        <span className="paragraph"></span>• Група стиглості: Ранньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Середня (залежно
        від вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 52%
        <span className="paragraph"></span>
        АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 8
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 8
        <span className="paragraph"></span>• Посухостійкість: 7
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        ранні-пізні
        <span className="paragraph"></span>
        ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>
        РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА ВИРОЩУВАННЯ
        <span className="paragraph"></span>
        Зони вирощування: Північний Степ, Лісостеп, Полісся
        <span className="paragraph"></span>
        Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження - не
        рекомендується
      </>
    ),
  },
  {
    productTitle: "СИ Ласкала Класичний SY_LASCALA Syngenta",
    path: "/sy-lascala-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 190.36,
    currency: "USD",
    text: (
      <>
        Опис: Ласкала - стабільно висока врожайність та стійкість до вовчка і
        хвороб.
        <span className="paragraph"></span>
        Переваги: • Високоврожайний гібрид помірно-інтенсивного типу з високою
        початковою енергією росту; • Високий потенціал урожайності; • Пластичний
        до різних способів обробітку ґрунту.
        <span className="paragraph"></span>• Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Середня (залежно
        від вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 52%
        <span className="paragraph"></span>• АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали
        від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні (оптимальні)
        <span className="paragraph"></span>• ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>• РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА
        ВИРОЩУВАННЯ
        <span className="paragraph"></span>• Зони вирощування: Степ (Центральній
        і Північний) , Лісостеп, Полісся
        <span className="paragraph"></span>• Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження — 40-45
        тис/га.
      </>
    ),
  },
  {
    productTitle: "СИ Арізона Класичний SY_ARISONA Syngenta",
    path: "/sy-arisona-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 221.29,
    currency: "USD",
    text: (
      <>
        Опис: Арізона - найвищий потенціал урожайності в умовах нових рас
        вовчка, відмінна толерантність до посухи.
        <span className="paragraph"></span>
        Переваги: • Помірно-інтенсивний гібрид лінолевого типу, відмінно
        розкриває потенціал на родючих ґрунтах та при високому рівні
        агротехніки; • Добра запиленість кошика; • Висока стабільна врожайність
        та олійність.
        <span className="paragraph"></span>• Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Висока (залежно від
        вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 52-54%
        <span className="paragraph"></span>• АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали
        від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні (оптимальні)-пізні
        <span className="paragraph"></span>• ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>• РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА
        ВИРОЩУВАННЯ
        <span className="paragraph"></span>• Зони вирощування: Степ (Центральній
        і Північний) , Лісостеп.
        <span className="paragraph"></span>• Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження — 35-45
        тис/га.
      </>
    ),
  },
  {
    productTitle: "СИ Лазурі Clearfield Plus SY_LAZURI Syngenta",
    path: "/sy-lazuri-clearfield-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 183.23,
    currency: "USD",
    text: (
      <>
        Опис: Лазурі — потужний врожай у кожній насінині.
        <span className="paragraph"></span>
        Переваги: • Лінолевий Clearfield® Plus гібрид помірно-інтенсивного типу;
        • Висока початкова енергія росту на перших етапах органогенезу; • Добра
        запиленість кошика; • Високий і стабільний урожай у ранньому й
        середньоранньому сегменті; • Високий вихід олії; • Стійкий до іржі,
        вовчка рас A–G; • Толерантний до основних хвороб соняшнику (фомопсис,
        фомоз, склеротиніоз кошикова і стеблова форми).
        <span className="paragraph"></span>• Група стиглості: Середньоранній
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Висота рослини: Середня (175–185 см
        залежно від вологозабезпечення)
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 51-53%
        <span className="paragraph"></span>• АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали
        від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 8
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        ранні-пізні
        <span className="paragraph"></span>• ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>• РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА
        ВИРОЩУВАННЯ
        <span className="paragraph"></span>• Зони вирощування: Степ (Центральній
        і Північний) , Лісостеп, Полісся
        <span className="paragraph"></span>• Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження — 40-45
        тис/га.
      </>
    ),
  },
  {
    productTitle: "СИ Амазонія Класичний SY_AMAZONIA Syngenta",
    path: "/sy-amazonia-syngenta",
    src: sunFlowerSY,
    volume: "",
    price: 228.44,
    currency: "USD",
    text: (
      <>
        Опис:
        <span className="paragraph"></span>
        Переваги: • Добра посухостійкість; • Стійкий до стеблового вилягання; •
        Стійкий до несправжньої борошнистої роси; • Толерантний до основних
        хвороб соняшнику (фомопсису, фомозу, склеротинії, іржі); • Добра
        запиленість кошика в стресових умовах.
        <span className="paragraph"></span>• Група стиглості: Середньостиглий
        <span className="paragraph"></span>• Тип: Помірно-інтенсивний
        <span className="paragraph"></span>• Стійкість до вовчка, раси: A-G
        <span className="paragraph"></span>• Олійність: до 52-54%
        <span className="paragraph"></span>• АГРОНОМІЧНІ ХАРАКТЕРИСТИКИ (бали
        від 1 до 9)
        <span className="paragraph"></span>• Потенціал урожайності: 9
        <span className="paragraph"></span>• Початкові темпи росту: 8
        <span className="paragraph"></span>• Стабільність урожаю: 9
        <span className="paragraph"></span>• Посухостійкість: 8
        <span className="paragraph"></span>• Адаптивність до термінів посіву:
        середні (оптимальні)
        <span className="paragraph"></span>• ТОЛЕРАНТНІСТЬ (бали від 1 до 9)
        <span className="paragraph"></span>• Комплексна толерантність до хвороб:
        8<span className="paragraph"></span>• Толерантність до фомопсису: 8
        <span className="paragraph"></span>• Толерантність до склеротиніозу: 8
        <span className="paragraph"></span>• РЕКОМЕНДОВАНА ГУСТОТА ТА ЗОНА
        ВИРОЩУВАННЯ
        <span className="paragraph"></span>• Зони вирощування: Степ (Центральній
        і Північний) , Лісостеп.
        <span className="paragraph"></span>• Густота: 
        <span className="paragraph"></span>• Достатнє зволоження - 50-55 тис/га
        <span className="paragraph"></span>• Помірне зволоження - 45-55 тис/га
        <span className="paragraph"></span>• Недостатнє зволоження — 35-45
        тис/га.
      </>
    ),
  },

  // второй файл

  {
    productTitle: "ЛГ 50450 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50450-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 157,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній-ранній
      <span className="paragraph"></span>
      Тип рослини : середньорослий для своєї групи стиглості
      <span className="paragraph"></span>
      Нова генетика для отримання високих ранніх врожаїв, за різних умов вирощування.
      <span className="paragraph"></span>
      - середньоранній-ранній високопродуктивний гібрид
      <span className="paragraph"></span>- Високотолерантний до посухи
      <span className="paragraph"></span>- висока енергія початкового росту
      <span className="paragraph"></span>- гібрид пластичний до умов вирощування
      <span className="paragraph"></span>- стійкий до вовчку рас A-G
      <span className="paragraph"></span>- толерантний до основних хвороб
      <span className="paragraph"></span>- стійкий до нових рас несправжньої борошнистої роси
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 150
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 17
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 69.5 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники:
      <span className="paragraph"></span>
      Потенціал урожайності — 9 
      <span className="paragraph"></span>
      Стабільність урожаю — 9  
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту  - 8 
      <span className="paragraph"></span>
      Стійкість до стресових умов — 9 
      <span className="paragraph"></span>
      Холодостійкість — 8 
      <span className="paragraph"></span>
      Стійкість до полягання — 8 
      <span className="paragraph"></span>
      Толерантність до захворювань: 
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 8 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 8 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 7 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - нова генетика Лімагрейн для Степу та Лісостепу України
      <span className="paragraph"></span>
      - максимально розкриває свій потенціал при інтенсивній технології вирощування
      <span className="paragraph"></span>
      - завдяки короткому періоду вегетації, формує ранній стабільний врожай навіть за дуже посушливих умов, можливо використовувати для пізніх посівів
      <span className="paragraph"></span>
      - за цвітінням та збиранням утворює виробничий конвеєр, гарно гармонізує в парі з ЛГ5580 та Тункою
      <span className="paragraph"></span>
      - рекомендується дотримуватись рекомендованої норми висіву
      <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50455 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50455-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 171.04,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості:Ранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>
      - ранньостиглий гібрид з високим потенціалом врожайності
      <span className="paragraph"></span>- найвищий урожай в ранньому КЛП сегменті
      <span className="paragraph"></span>- широкорайонований гібрид з гарною посухостійкістю та толлерантністю до хвороб
      <span className="paragraph"></span>- пластичний до термінів сівби
      <span className="paragraph"></span>- високий вихід олії
      <span className="paragraph"></span>- стійкий до вовчку рас А-G
      <span className="paragraph"></span>- стійкий до нових рас несправжньої борошнистої роси 
      <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів:
      <span className="paragraph"></span>
      Потенціал урожайності — 8 
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 8
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Холодостійкість — 9 
      <span className="paragraph"></span>
      Стійкість до полягання — 8 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань:
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 8 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 8
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) -7 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 8 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для Степу, Лісостепу та Полісся
      <span className="paragraph"></span>
      - рекомендується для зон, де є необхідність в гібридах з коротким вегетаційним періодом
      <span className="paragraph"></span>
      - рано звільняє поля, ідеально підходить для посіву озимої пшениці
      <span className="paragraph"></span>
      - рекомендований для інтенсивних та класичних технологій вирощування
      <span className="paragraph"></span>
      - рекомендується дотримуватись рекомендованої норми висіву
      <span className="paragraph"></span>
      - завдяки короткому періоду вегетації, можливо використовувати для пізніх та пожнивних посівів
      <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50479 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50479-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 176.77,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній-ранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>
      Топ Експрес гібрид!Максимальний потенціал в інтенсивних зонах!
      <span className="paragraph"></span>
      - високий врожай та вихід олії
      <span className="paragraph"></span> - стійкий до гербіциду Експрес тм та Експрестм Голд 75 ВГ компанії FMC
      <span className="paragraph"></span>- гібрид інтенсивного типу з високою енергією початкового росту
      <span className="paragraph"></span>- найвищий потенціал урожайності в Express™ сегменті
      <span className="paragraph"></span>- стійкий до вовчку рас А-F
      <span className="paragraph"></span>- гарно озернений кошик з виповненим центром
      <span className="paragraph"></span>- комплексна толерантність до основних хвороб
      <span className="paragraph"></span>- стійкий до нових рас несправжньої борошнистої роси **
      <span className="paragraph"></span>
      ** Стійкість до відомих рас несправжньої борошнистої роси у континентальній Європі на момент реєстрації гібрида
      <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 9 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Холодостійкість — 8 
      <span className="paragraph"></span>
      Стійкість до полягання — 8 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 8 
      <span className="paragraph"></span>
      Толерантність до захворювань:
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 9 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia)  - 8 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina)  - 8 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 7 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 8 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для Лісостепу,Полісся та півночі Степу України
      <span className="paragraph"></span>
        •	- рекомендований для інтенсивних та напівінтенсивних технологій вирощування
        <span className="paragraph"></span>
        •	- максимально розкриває свій потенціал на родючих грунтах при дотриманні сбалансованного живлення
        <span className="paragraph"></span>
        •	- дотримуватись рекомендованої норм висіву
        <span className="paragraph"></span>
        •	- рекомендована доза гербіциду Express™ в післясходовий період до 50 г/га або Експрес тм Голд 75 ВГ у нормі 40 г/га
        <span className="paragraph"></span>
      - рекомендується додавати ПАР Тренд 90
      <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50480 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50480-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 146.04,
    currency: "EUR",
    text: (<>
    Опис: 
    <span className="paragraph"></span>
Група стиглості: Ранній
<span className="paragraph"></span>Тип рослини : низькорослий для своєї групи стиглості*
<span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
<span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
<span className="paragraph"></span>
Ранній, низькорослий! Забезпечить максимальний урожай в посушливих умовах та зона поширення вірулентних рас вовчку!
<span className="paragraph"></span>
- Ранній високопродуктивний гібрид
<span className="paragraph"></span>- Високотолерантний до посухи гібрид
<span className="paragraph"></span>- Високий вихід олії
<span className="paragraph"></span>- Гібрид для зон поширення вірулентних рас вовчка
<span className="paragraph"></span>- Стійкий до вовчка рас A-G+
<span className="paragraph"></span>- Толерантний до основних хвороб
<span className="paragraph"></span>- Стійкий до нових рас несправжньої борошнистої роси**
<span className="paragraph"></span>** Стійкість до відомих рас несправжньої борошнистої роси у континентальній Європі на момент реєстрації гібрида
<span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 140
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 17
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 67 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники: 
<span className="paragraph"></span>
Потенціал урожайності — 8
<span className="paragraph"></span>
Стабільність урожаю — 9 
<span className="paragraph"></span>
Вміст олії — 8
<span className="paragraph"></span>
Енергія початкового росту — 9 
<span className="paragraph"></span>
Холодостійкість — 8 
<span className="paragraph"></span>
Стійкість до полягання — 9 
<span className="paragraph"></span>
Толерантність до посухи та інших стресових факторів — 9 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 8 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 9 
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 8 
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) — 7 
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 8 
<span className="paragraph"></span>
Фомоз (Phoma) — 8 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
• рекомендований для Півдня та Сходу України
<span className="paragraph"></span>
	•	• пластичний до різних технологій вирощування
  <span className="paragraph"></span>
	•	• завдяки короткому періоду вегетації, можливо використовувати для пізніх та пожнивних посівів
  <span className="paragraph"></span>
	•	• придатний для культивації на зрошенні в умовах Півдня та Південного Сходу України
  <span className="paragraph"></span>
	•	• рекомендується дотримуватись рекомендованої норми висіву
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 55 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50549 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50549-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 170.57,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості
      <span className="paragraph"></span>
      - Гібрид стійкий до вовчка рас А-G
      <span className="paragraph"></span>- Висока енергія початкового росту
      <span className="paragraph"></span>- Гибрид під технологію Express™
      <span className="paragraph"></span>- Високоврожайний у своїй групі стиглості, стабільна олійність
      <span className="paragraph"></span>- Високо толерантний до фомопсису, фомозу
      <span className="paragraph"></span>- Толерантний до склеротинії корня
      <span className="paragraph"></span>- Толерантний до посушливих умов
      <span className="paragraph"></span>- Пластичний до умов вирощування (висока посухостійкість)
      <span className="paragraph"></span>- Польові перспективи: посухостійкий, невибагливий гібрид, виступає партнером для ЛГ59580 та ЛГ50529 СХ, створений для посушливих, степних умов України, та зон поширення вовчка
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
        •	 Вирощувати по класичному та глибокому обробітку ґрунту, максимально розкриває потенціал при вирощуванні на родючих ґрунтах
        <span className="paragraph"></span>
        •	 Не рекомендуємо ранні посіви в непрогрітий ґрунт.
        <span className="paragraph"></span>
        •	Великий гарно озернений кошик, рекомендуємо дотримуватись оптимальної густоти посіву- не загущувати посіви.
        <span className="paragraph"></span>
        •	Рекомендована доза гербіциду Express™ в післясходовий період до 50 г/га або Експрес тм Голд 75 ВГ у нормі 40 г/га
        <span className="paragraph"></span>
        •	Рекомендується додавати ПАР Тренд 90 
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 54 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50550 КЛП Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50550-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 173.68,
    currency: "EUR",
    text: (<>
    Опис: 
Група стиглості: Середньоранній
<span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості
<span className="paragraph"></span>
- Гібрид стійкий до вовчка рас А-G , технологія SUNEO
<span className="paragraph"></span>- Пластичний до різних зон вирощування завдяки толерантності до хвороб та посухостійкості
<span className="paragraph"></span>- Гібрид з максимальним потенціалом та стабільністю у своїй групі стиглості, висока олійність
<span className="paragraph"></span>- Генетичний Mildew Control -MGP
<span className="paragraph"></span>- Висока толерантність до фомозу, фомопсису, склеротинії корня та кошика
<span className="paragraph"></span>- Польові перспективи: посухостійкий гібрид з високим потенціалом та вмістом олії
<span className="paragraph"></span>- В степній зоні виступає партнером разом з ЛГ5542 КЛ та ЛГ5555 КЛП
<span className="paragraph"></span>- В центральних та північних регіонах є партнером разом з ЛГ50455 КЛП, ЛГ50635 КЛП та ЛГ58630 КЛ 
<span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 55 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га
</>),
  },
  {
    productTitle: "ЛГ 50616 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50616-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 162.34,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості
      <span className="paragraph"></span>
      Високий потенціал врожайності! Найкраще генетичне рішення в зонах поширення іржі, та вовчка A-G рас!!!
      <span className="paragraph"></span>
      - Середньоранній високопродуктивний гібрид
      <span className="paragraph"></span>- Потужний гібрид для насичених сівозмін соняшником (висока толерантність до іржі соняшникової)
      <span className="paragraph"></span>- висока енергія початкового росту
      <span className="paragraph"></span>- Пластичний до умов вирощування (висока посухостійкість)
      <span className="paragraph"></span>- стійкий до вовчку рас A-G
      <span className="paragraph"></span>- толерантний до основних хвороб
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 163
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 17,3
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 60 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9 
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 9 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Стійкість до стресових умов — 8 
      <span className="paragraph"></span>
      Холодостійкість — 8  
      <span className="paragraph"></span>
      Стійкість до полягання — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань:
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 9 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 8 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 8 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - нова генетика Лімагрейн для Степу та Лісостепу України
      <span className="paragraph"></span>
        •	- максимально розкриває свій потенціал при інтенсивній технології вирощування
        <span className="paragraph"></span>
        •	- ідеально підходить для зон , з насиченою сівозміною соняшником
        <span className="paragraph"></span>
        •	- гарна пропозиція для господарств для отримання стабільно високого врожаю та додаткового бонуса за олійність, при дотриманні збалансованого живлення
        <span className="paragraph"></span>
        •	- рекомендується дотримуватись рекомендованої норми висіву
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га
      </>),
  },
  {
    productTitle: "ЛГ 50635 КЛП Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50635-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 163.60,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>
      Стабільний та пластичний гібрид для умов достатнього зволоження з дуже високою толерантністю до хвороб та високим потенціалом урожайності!
      <span className="paragraph"></span>
      - середньоранній гібрид стійкий до гербіциду Євро-Лайтнінг® Плюс виробничої системи Clearfield® Plus
      <span className="paragraph"></span>- гібрид стійкий до вовчка рас А-G
      <span className="paragraph"></span>- високий вихід олії
      <span className="paragraph"></span>- гібрид інтенсивного напрямку
      <span className="paragraph"></span>- високотолерантний до основних хвороб
      <span className="paragraph"></span>- висока толерантність до іржі соняшника
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 147
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 17
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 73 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 8 
      <span className="paragraph"></span>
      Стійкість до стресових умов — 8 
      <span className="paragraph"></span>
      Холодостійкість — 9 
      <span className="paragraph"></span>
      Стійкість до полягання — 8 
      <span className="paragraph"></span>
      Толерантність до захворювань: 
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 9
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 8 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 8 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 8 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
        •	- широкорайонованний гібрид інтенсивного типу
        <span className="paragraph"></span>
        •	- добре відзивається на інтенсивні технології вирощування
        <span className="paragraph"></span>
        •	- придатний для культивації на зрошенні в умовах Півдня та Південного Сходу України
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 55-60 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: до 50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50639 LG Круїзер насіння соняшнику",
    path: "/lg-50639-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 175.85,
    currency: "EUR",
    text: (<>
    Опис: 
	•	Група стиглості: Середньоранній
  <span className="paragraph"></span>Тип рослини : вище середнього*
  <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
  <span className="paragraph"></span>
	•	- високопродуктивний гібрид, середньо-інтенсивного типу
  <span className="paragraph"></span>- висока посухостійкість та пластичність
  <span className="paragraph"></span>- толерантний до основних хвороб
  <span className="paragraph"></span>- висока толерантність до фомопсису
  <span className="paragraph"></span>- стійкий до вовчку рас A-G
  <span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 171
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 18,2
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 65 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники: 
<span className="paragraph"></span>
Потенціал урожайності — 9 
<span className="paragraph"></span>
Стабільність урожаю — 9
<span className="paragraph"></span>
Вміст олії — 8 
<span className="paragraph"></span>
Енергія початкового росту — 8 
<span className="paragraph"></span>
Стійкість до стресових умов — 8
<span className="paragraph"></span>
Холодостійкість — 7 
<span className="paragraph"></span>
Стійкість до полягання — 7 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 8 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 7
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 7
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) —8
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 8
<span className="paragraph"></span>
Фомоз (Phoma) — 7 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
- Рекомендований для степної та лісостепної зони України
<span className="paragraph"></span>
	•	- Рекомендований для інтенсивної та класичної технології вирощування
  <span className="paragraph"></span>
	•	- Найкраще поєднання високої врожайності, посухостійкості та пластичності
  <span className="paragraph"></span>
	•	- Рекомендується дотримуватись рекомендованої норми висіву
  <span className="paragraph"></span>
	•	- Рекомендуємо висівати в оптимальні строки посіву
  <span className="paragraph"></span>
	•	- Пластичний до технології вирощування, стабільний гібрид з високою посухостійкістю та стійкістю до вовчка A-G рас
  <span className="paragraph"></span>
	•	- Рекомендована доза гербіциду Express™ в післясходовий період до 50 г/га або Експрес тм Голд 75 ВГ у нормі 40 г/га
  <span className="paragraph"></span>
	•	- Рекомендується додавати ПАР Тренд 90
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 55 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50689 СХ LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50689-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 177.47,
    currency: "EUR",
    text: (<>
    Опис: 
Група стиглості: Середньостиглий
<span className="paragraph"></span>Тип рослини : вище середнього*
<span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
<span className="paragraph"></span>
- високопродуктивний гібрид, інтенсивного типу
<span className="paragraph"></span>- високий вихід олії
<span className="paragraph"></span>- найкраще рішення в зоні поширення бурої іржі
<span className="paragraph"></span>- стійкий до вовчку рас A-F+
<span className="paragraph"></span>- толерантний до основних хвороб
<span className="paragraph"></span>- стійкій до несправжньої борошнистої роси- MGP
<span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 175
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 17
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 74 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники: 
<span className="paragraph"></span>
Потенціал урожайності — 8 
<span className="paragraph"></span>
Стабільність урожаю — 9 
<span className="paragraph"></span>
Вміст олії — 9 
<span className="paragraph"></span>
Енергія початкового росту — 8 
<span className="paragraph"></span>
Стійкість до стресових умов — 8 
<span className="paragraph"></span>
Холодостійкість — 8 
<span className="paragraph"></span>
Стійкість до полягання — 8 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 8 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 7 
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 8 
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) — 8 
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 7 
<span className="paragraph"></span>
Фомоз (Phoma) — 9 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
	•	- Рекомендований для північно степної та лісостепної зони України
  <span className="paragraph"></span>
	•	- Рекомендований для інтенсивної та класичної технології вирощування
  <span className="paragraph"></span>
	•	- Найкраще генетичне рішення для боротьби з несправжньою борошнистою росою та бурою їржею
  <span className="paragraph"></span>
	•	- Рекомендується дотримуватись рекомендованої норми висіву
  <span className="paragraph"></span>
	•	- Пластичний до технології вирощування, високорентабельний гібрид завдяки комплексній толерантності до основних хвороб та високому вмісту олії
  <span className="paragraph"></span>
	•	- Рекомендована доза гербіциду Express™ в післясходовий період до 50 г/га або Експрес тм Голд 75 ВГ у нормі 40 г/га
  <span className="paragraph"></span>
	•	- Рекомендується додавати ПАР Тренд 90
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 55 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 5542 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-5542-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 160,
    currency: "EUR",
    text: (<>
    Опис: 
Група стиглості: Середньоранній
<span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
<span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
<span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів
<span className="paragraph"></span>
Спеціально розроблений для вирощування в важких умовах Півдня. Краще комплексне рішення в важких ґрунтово-кліматичних умовах і зонах поширення вовчка.
<span className="paragraph"></span>
- гібрид зі стійкістю до гербіциду Євро-Лайтнінг® виробничої системи Clearfield®
<span className="paragraph"></span>- стійкий до вовчка рас А-G
<span className="paragraph"></span>- пластичний до умов вирощування
<span className="paragraph"></span>- високотолерантний до посухи- придатний до різних технологій вирощування 
<span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 152
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 15.9
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 71 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники:
<span className="paragraph"></span>
Потенціал урожайності — 8
<span className="paragraph"></span>
Стабільність урожаю — 8 
<span className="paragraph"></span>
Вміст олії — 8 
<span className="paragraph"></span>
Енергія початкового росту — 8 
<span className="paragraph"></span>
Холодостійкість — 7 
<span className="paragraph"></span>
Стійкість до полягання — 7 
<span className="paragraph"></span>
Толерантність до посухи та інших стресових факторів — 9 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 7 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 7 
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 7 
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) — 7 
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 7 
<span className="paragraph"></span>
Фомоз (Phoma) — 7 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
- рекомендований для Півдня та Сходу України для зон, уражених вовчком
<span className="paragraph"></span>
	•	- потрібно дотримуватись рекомендованої густоти на момент збирання
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 53 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 5555 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-5555-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 162.65,
    currency: "EUR",
    text: (<>
    Опис: 
Група стиглості: Середньоранній-ранній
<span className="paragraph"></span>Тип рослини : низькорослий для своєї групи стиглості*
<span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
<span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
<span className="paragraph"></span>
- гібрид зі стійкістю до гербіциду Євро-Лайтнінг® Плюс виробничої системи Clearfield® Plus
<span className="paragraph"></span>- екологічно-пластичний до умов вирощування
<span className="paragraph"></span>- високотолерантний до посухи
<span className="paragraph"></span>- пластичний для різних технологий вірощування
<span className="paragraph"></span>- стійкий до вовчка рас A-G
<span className="paragraph"></span>- гібрид технології SUNEO® для півдня України, економно використовує запас накопиченої грунтової вологи
<span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 140
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 15.8
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 71 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники: 
<span className="paragraph"></span>
Потенціал урожайності — 8 
<span className="paragraph"></span>
Стабільність урожаю — 8 
<span className="paragraph"></span>
Вміст олії — 8 
<span className="paragraph"></span>
Енергія початкового росту — 8 
<span className="paragraph"></span>
Холодостійкість — 7 
<span className="paragraph"></span>
Стійкість до полягання — 7 
<span className="paragraph"></span>
Толерантність до посухи та інших стресових факторів — 8 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 7 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 7 
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 7
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) — 7 
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 7 
<span className="paragraph"></span>
Фомоз (Phoma) — 7 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
- рекомендований для Півдня та Сходу України для зон, уражених вовчком
<span className="paragraph"></span>
	•	- придатний для культивації на зрошенні в умовах Півдня та Південного Сходу України
  <span className="paragraph"></span>
	•	- рекомендується дотримуватись рекомендованої густоти на момент збирання
  <span className="paragraph"></span>
	•	- завдяки короткому періоду вегетації, можливо використовувати для пізніх та пожнивних посівів
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 53 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 5580 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-5580-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 139.57,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>
      Високоврожайний та пластичний для зон ураження вовчком.
      <span className="paragraph"></span>
      - стійкий до вовчка рас А-G
      <span className="paragraph"></span>- високопродуктивний гібрид
      <span className="paragraph"></span>- пластичний до умов вирощування
      <span className="paragraph"></span>- висока енергія початкового росту
      <span className="paragraph"></span>- відмінна стійкість до посухи
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 155
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 15.9
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 73 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9 
      <span className="paragraph"></span>
      Стабільність урожаю — 8 
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Холодостійкість — 9 
      <span className="paragraph"></span>
      Стійкість до полягання — 7 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань: 
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 7 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 7 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 7 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для степової та лісостепової зони України, зон заражених вовчком
      <span className="paragraph"></span>
        •	- не рекомендується загущення посівів
        <span className="paragraph"></span>
        •	- рекомендуємо на Півдні для зрошення
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 58390 КЛ LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-58390-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 172.10,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Ранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості
      <span className="paragraph"></span>
      - Гібрид стійкий до вовчка рас А-G , технологія SUNEO
      <span className="paragraph"></span>- Стабільний врожай в екстремальних умовах Півдня
      <span className="paragraph"></span>- Стабільний урожай в ранньому КЛ сегменті!
      <span className="paragraph"></span>- Рано звільняє поля, гарний попередник під пшеницю
      <span className="paragraph"></span>- Високоврожайний у своїй групі стиглості, висока олійність
      <span className="paragraph"></span>- Пластичний до умов вирощування (висока посухостійкість)
      <span className="paragraph"></span>- Генетичний Mildew Control -MGP
      <span className="paragraph"></span>- Висока толерантність до фомозу
      <span className="paragraph"></span>- Польові перспективи: посухостійкий гібрид з високим потенціалом та вмістом олії.
      <span className="paragraph"></span>- На Півдні виступає партнером разом з ЛГ5542 КЛ та ЛГ5555 КЛП
      <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 59580 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-59580-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 163,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий*
      <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>Лідер по площам посіву серед всіх Експрес гібридів в Україні в 2020 році- стійкий до гербіциду Експрес тм та Експрестм Голд 75 ВГ компанії FMC
      <span className="paragraph"></span>- стійкий до вовчка рас А–G
      <span className="paragraph"></span>- високотолерантний до посухи
      <span className="paragraph"></span>- високопродуктивний і пластичний до умов вирощування
      <span className="paragraph"></span>- eталон, по пластичності та стабільності в посушливих регіонах у Експрес сегменті
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 155
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 15,8
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 72 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9 
      <span className="paragraph"></span>
      Стабільність урожаю — 8 
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Холодостійкість — 8 
      <span className="paragraph"></span>
      Стійкість до полягання — 7 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань: 
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 7 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 7 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 7 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для Півдня та Сходу України
      <span className="paragraph"></span>
        •	- Рекомендована доза гербіциду Express™ в післясходовий період до 50 г/га або Експрес тм Голд 75 ВГ у нормі 40 г/га
        <span className="paragraph"></span>
        •	- Рекомендується додавати ПАР Тренд 90
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ТУНКА Круїзер насіння соняшнику Лімагрейн",
    path: "/tunka-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 134,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.Лідер по жаростійкості та стабільності, пластичний до кліматичних змін та таехнологій вирощування
      <span className="paragraph"></span>
        •	- жаростійкий високопродуктивний гібрид
        <span className="paragraph"></span>- перший та найкращий гібрид зі стійкістю до вовчка рас А–G
        <span className="paragraph"></span>- пластичний до умов та технологій вирощування
        <span className="paragraph"></span>- високотолерантний до посушливих умов
        <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 150
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 15.9
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 73 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 8 
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Холодостійкість — 8 
      <span className="paragraph"></span>
      Стійкість до полягання — 7 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань: Фомопсис (Phomopsis) — 7 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 7 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 7 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для зони Степу
      <span className="paragraph"></span>
        •	- найкраще рішення для полів, заражених вовчком
        <span className="paragraph"></span>
        •	Рекомендована густота на момент збирання:
        <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50487 КЛП LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50487-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 125.86,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* висота може змінюватись від азотного живлення, кліматичних умов та вологозабезпечення
      <span className="paragraph"></span>
      Рішення для фермерів, в зонах поширення бурої іржі з бонусом за олійність.
      <span className="paragraph"></span>
        •	- Стабільний з високим потенціалом
        <span className="paragraph"></span>- толерантність до посушливих умов, гарний фітосанітарний профіль
        <span className="paragraph"></span>- висока олійність!
        <span className="paragraph"></span>- стійкий до вовчку рас А-F
        <span className="paragraph"></span>- пластичний до різних зон вирощування
        <span className="paragraph"></span>- найвища толерантність до бурої іржі
        <span className="paragraph"></span>- стійкий до нових рас несправжньої борошнистої роси
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 8 
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 9 
      <span className="paragraph"></span>
      Енергія початкового росту — 8 
      <span className="paragraph"></span>
      Стійкість до стресових умов — 9 
      <span className="paragraph"></span>
      Холодостійкість — 8 
      <span className="paragraph"></span>
      Стійкість до полягання — 9 
      <span className="paragraph"></span>
      Толерантність до захворювань:
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 8 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 7 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 8
      <span className="paragraph"></span>
      Фомоз (Phoma) — 7 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для лісостепової та півночі степової зони України
      <span className="paragraph"></span>
        •	- максимально розкриває потенціал при дотриманні класичної інтенсивної технології вирощування
        <span className="paragraph"></span>
        •	- найкраще генетичне рішення для боротьби з бурою іржею та несправжньою борошнистою росою
        <span className="paragraph"></span>
        •	- гарна пропозиція для господарств для отримання стабільно високого врожаю та додаткового бонуса за олійність при дотриманні збалансованого живлення
        <span className="paragraph"></span>
        •	- рекомендується дотримуватись рекомендованої норми висіву - має потужний листовий апарат, швидко накриває ґрунт не даючи випаровуватись волозі та домінувати злісним бур’янам
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 45-50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50510 LG Круїзер насіння соняшнику",
    path: "/lg-50510-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 156.27,
    currency: "USD",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип рослини : сердньорослий для своєї групи стиглості*
      <span className="paragraph"></span>* прояв інших фенотипічних та морфологічних ознак залежить від зовнішніх факторів.
      <span className="paragraph"></span>
      Високоврожайний гібрид для інтенсивних умов та високотехнологічних господарств Центру, Півночі та Заходу України
      <span className="paragraph"></span>- високий потенціал урожайності
      <span className="paragraph"></span>
      - добрий вихід олії
      <span className="paragraph"></span>- платсичний
      <span className="paragraph"></span>- стійкий до вовчка рас A-F, толерантність до G
      <span className="paragraph"></span>- висока толерантність до більшості хвороб
      <span className="paragraph"></span>- рослина має здоровий вигляд до кінця вегетації
      <span className="paragraph"></span>- стійкий до нових рас несправжньої борошнистої роси**
      <span className="paragraph"></span>** Стійкість до відомих рас несправжньої борошнистої роси у континентальній Європі на момент реєстрації гібрида
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Cередня висота рослини, см: 160
        <span className="paragraph"></span>
        •	Середній діаметр кошика, см: 17.5
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 68 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів.
      <span className="paragraph"></span>
      Загальні показники: 
      <span className="paragraph"></span>
      Потенціал урожайності — 9 
      <span className="paragraph"></span>
      Стабільність урожаю — 9 
      <span className="paragraph"></span>
      Вміст олії — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 8 
      <span className="paragraph"></span>
      Холодостійкість — 9 
      <span className="paragraph"></span>
      Стійкість до полягання — 8 
      <span className="paragraph"></span>
      Толерантність до посухи та інших стресових факторів — 8 
      <span className="paragraph"></span>
      Толерантність до захворювань: 
      <span className="paragraph"></span>
      Фомопсис (Phomopsis) — 8 
      <span className="paragraph"></span>
      Біла гниль коріння (Sclerotinia) — 7 
      <span className="paragraph"></span>
      Біла гниль кошика (Sclerotinia) — 8 
      <span className="paragraph"></span>
      Вугільна гниль (Macrophomina) — 8 
      <span className="paragraph"></span>
      Суха гниль (Rhizopus) — 8 
      <span className="paragraph"></span>
      Фомоз (Phoma) — 8 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендований для Лісостепу, Полісся та півночі Степу України
      <span className="paragraph"></span>
        •	- рекомендований для інтенсивних технологій вирощування- дотримуватись рекомендованої норми висіву
        <span className="paragraph"></span>
        •	Рекомендована густота на момент збирання:
        <span className="paragraph"></span>
        •	зона достатнього зволоження: до 55 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 50 тис./га</>),
  },
  {
    productTitle: "ЛГ 50621 LG Круїзер насіння соняшнику Лімагрейн",
    path: "/lg-50621-kruiser-limagrain",
    src: sunFlowerLG,
    volume: "",
    price: 161.43,
    currency: "USD",
    text: (<>
    Опис:
    <span className="paragraph"></span>Група стиглості: Середньостиглий
    <span className="paragraph"></span>Тип рослини : середньорослий для своєї групи стиглості
    <span className="paragraph"></span>- середньостиглий
    <span className="paragraph"></span>- високотолерантний до посухи гібрид
    <span className="paragraph"></span>- високий вихід олії
    <span className="paragraph"></span>- Спеціалізований гібрид для вирішення проблем з поширенням вірулентних рас вовчка
    <span className="paragraph"></span>- стійкий до вовчку рас/OR-X
    <span className="paragraph"></span>- стійкій до несправжньої борошнистої роси- MGP
    <span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Cередня висота рослини, см: 173
  <span className="paragraph"></span>
	•	Середній діаметр кошика, см: 15,7
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен: 61 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Загальні показники: 
<span className="paragraph"></span>
Потенціал урожайності — 9 
<span className="paragraph"></span>
Стабільність урожаю — 8 
<span className="paragraph"></span>
Вміст олії — 8 
<span className="paragraph"></span>
Енергія початкового росту — 8 
<span className="paragraph"></span>
Стійкість до стресових умов — 9 
<span className="paragraph"></span>
Холодостійкість — 7 
<span className="paragraph"></span>
Стійкість до полягання — 7 
<span className="paragraph"></span>
Толерантність до захворювань: 
<span className="paragraph"></span>
Фомопсис (Phomopsis) — 7 
<span className="paragraph"></span>
Біла гниль коріння (Sclerotinia) — 7 
<span className="paragraph"></span>
Біла гниль кошика (Sclerotinia) — 7 
<span className="paragraph"></span>
Вугільна гниль (Macrophomina) — 7 
<span className="paragraph"></span>
Суха гниль (Rhizopus) — 8 
<span className="paragraph"></span>
Фомоз (Phoma) — 8 
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
	•	- рекомендований для Півдня та Сходу України
  <span className="paragraph"></span>
	•	- Гібрид з максимальною стійкістю до вовчка та генетичною стійкістю до несправжньої борошнистої роси, ідеально підходить для господарств з насиченою соняшником сівозміни
  <span className="paragraph"></span>
	•	- Пластичний до різних технологій вирощування
  <span className="paragraph"></span>
	•	- Рекомендується дотримуватись рекомендованої норми висіву
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
	•	зона достатнього зволоження: до 55 тис./га
  <span className="paragraph"></span>
	•	зона недостатнього зволоження: 45-50 тис./га</>),
  },
];
