import complexMicronutrientFertilizers1 from "../assets/images/ComplexMicronutrientFertilizersImages/mikro-mineralis-zernovi.jpg";
import complexMicronutrientFertilizers2 from "../assets/images/ComplexMicronutrientFertilizersImages/mikro-mineralis-oliyni.jpg";
import complexMicronutrientFertilizers3 from "../assets/images/ComplexMicronutrientFertilizersImages/mikro-mineralis-kukurudza.jpg";
import complexMicronutrientFertilizers4 from "../assets/images/ComplexMicronutrientFertilizersImages/mikro-mineralis-universal-plus.jpg";
import complexMicronutrientFertilizers5 from "../assets/images/ComplexMicronutrientFertilizersImages/mikro-mineralis-amino-me.jpg";

export const complexMicronutrientFertilizersArr = [
  {
    productTitle: "Мікро-Мінераліс (Зернові)",
    path: "/mikro-mineralis-zernovi",
    src: complexMicronutrientFertilizers1,
    volume: "10 л",
    price: 34.76,
    currency: "USD",
    text: (<>
    Опис: Мікро-Мінераліс (Зернові) - це рідке комплексне мікродобриво, вироблене за новітніми нанотехнологіями, яке містить мікро- та макроелементи в легкодоступній формі необхідні для росту і розвитку зернових культур.
    <span className="paragraph"></span> 
СКЛАД мікродобрива Мікро-Мінераліс (Зернові), амонійно-карбоксилатних комплексонів, %;
	•	Mo – 0,08
	•	Mg – 4,2
	•	Cu – 2,2
	•	Co – 0,017
	•	Fe – 1,8
	•	Zn – 2,1
	•	B – 0,2
	•	N – 4,0
  <span className="paragraph"></span>
Переваги: 
	•	Завдяки новітній технології виробництва, мікро- та макро- елементи, які входять до складу Мікро-Мінераліс (Зернові), суттєво  прискорюють ферментні реакції, пожвавлюють обмін речовин, покращують інтенсивність дихання та біо- і фотосинтез;
	•	Поверхневий електричний заряд активних частинок металів забезпечує відмінне прилипання до поверхонь і швидке проникнення їх у клітини рослин;
	•	Позакоренева обробка мікродобривом Мікро-Мінераліс (Зернові) підвищує морозостійкість та зимостійкість рослин, стимулює їх ріст та розвиток, посилює резистентність та стресостійкість, збільшує урожайність та якість отриманої продукції.
  <span className="paragraph"></span>
Застосування: 
Позакореневе підживлення Мікро-Мінераліс (Зернові) краще проводити заздалегідь, ще до появи симптомів дефіциту елементів мінерального живлення та в критичні фази росту та розвитку рослин, коли дефіцит елементів живлення може призвести до найбільших втрат врожаю та погіршення показників його якості.
<span className="paragraph"></span>
Норми витрати препарату.
<span className="paragraph"></span>
Культура: 
	•	Пшениця, ячмінь, жито, овес та інші.
  <span className="paragraph"></span>
Фаза розвитку культури: 
	•	фаза кущення, фаза виходу в трубку, початок молочної стиглості зерна.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 250-300 л/га.</>),
  },
  {
    productTitle: "Мікро-Мінераліс (Олійні)",
    path: "/mikro-mineralis-oliyni",
    src: complexMicronutrientFertilizers2,
    volume: "10 л",
    price: 35.24,
    currency: "USD",
    text: (<>Опис: Мікро-Мінераліс (Олійні) - спеціальне рідке комплексне мікродобриво для позакореневого підживлення олійних культур. Введення в технологію вирощування олійних культур позакореневого підживлення Мікро-Мінераліс (Олійні) позитивно впливає на вміст жирів в рослині. Крім комплексу мікро- та макроелементів мікродобриво Мікро-Мінераліс (Олійні) містить бор і мідь, які підвищують вміст олії в насінні, та цинк, який позитивно впливає на вміст фосфоліпідів в рослині. 
    <span className="paragraph"></span>
    СКЛАД мікродобрива Мікро-Мінераліс (Олійні), амонійно-карбоксилатних комплексонів, %;
      •	Mg – 3,4
      •	Mn – 1,3
      •	Cu – 0,5
      •	Zn – 2,1
      •	Fe – 1,7
      •	B – 0,5
      •	K – 2,8
      •	N – 4,0
      <span className="paragraph"></span>
    Переваги: 
      •	Застосування Мікро-Мінераліс (Олійні) на посівах олійних культур значно підвищує стійкість рослин до несприятливих погодних умов та патогенних організмів;
      •	Мікро-Мінераліс (Олійні) значно підвищує імунітет та зимостійкість ріпаку озимого, стимулює розвиток його кореневої системи, покращує водний баланс;
      •	Підвищує якісні (олійність) та кількісні показники врожаю.
      <span className="paragraph"></span>
    Застосування.
    <span className="paragraph"></span>
    Норма витрати препарату.
    <span className="paragraph"></span>
    Культура: 
      •	Ріпак; 
      •	Соняшник.
      <span className="paragraph"></span>
    Фаза розвитку культури: 
      •	восени, 5-7 листків (озимий ріпак), початок стеблування, початок бутонізації; 
      •	фаза 2-3 пари листків, фаза 5-6 пар листків.
      <span className="paragraph"></span>
    Норма витрати робочого розчину: 250-300 л/га. </>),
  },
  {
    productTitle: "Мікро-Мінераліс (Кукурудза)",
    path: "/mikro-mineralis-kukurudza",
    src: complexMicronutrientFertilizers3,
    volume: "10 л",
    price: 35.24,
    currency: "USD",
    text: (<>
    Опис: Мікро-Мінераліс (Кукурудза) - це рідке комплексне мікродобриво, яке містить мікро- та макроелементи, що відповідають всім фізіологічним вимогам кукурудзи і є найбільш необхідними для її росту та розвитку.
    <span className="paragraph"></span>
СКЛАД мікродобрива Мікро-Мінераліс (Кукурудза), амонійно-карбоксилатних комплексонів, %;
	•	Mg – 2,5
	•	Mn – 1,78
	•	Cu – 0,75
	•	Fe – 2,0
	•	Zn – 3,1
	•	B – 0,5
	•	N – 4,0
  <span className="paragraph"></span>
Переваги: 
	•	Поверхневий електричний заряд активних частинок металів забезпечує відмінне прилипання до поверхонь і швидке проникнення їх у клітини рослин;
	•	До складу мікродобрива Мікро-Мінераліс (Кукурудза) входить комплекс мікроелементів без сторонніх домішок, які не створюють фітотоксичного впливу на рослину та повністю нею засвоюються;
	•	Позакоренева обробка кукурудзи мікродобривом Мікро-Мінераліс (Кукурудза) забезпечить її збалансоване живлення, зменшить наслідки стресу після обробки ЗЗР, підвищить врожайність культури на 10-20 % та покращить його якісні характеристики.
  <span className="paragraph"></span>
Застосування: 
Найважливіший період у розвитку кукурудзи – фаза 3-5 справжніх листків. Незбалансованість живлення в цей період майже неможливо компенсувати у майбутні фази розвитку рослини, оскільки саме в цей період формуються генеративні органи, що визначають майбутню врожайність. Наступна критична фаза – це 7-9 листків, і саме в цей період проведення підживлення покращує озерненість качанів кукурудзи.
<span className="paragraph"></span>
Норма витрати препарату.
<span className="paragraph"></span>
Культура: 
	•	Кукурудза.
  <span className="paragraph"></span>
Фаза розвитку культури: 
	•	фаза 3-5 листків, фаза 7-9 листків.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 250-300 л/га.</>),
  },
  {
    productTitle: "Мікро-Мінераліс (Універсальний) Плюс",
    path: "/mikro-mineralis-universal-plus",
    src: complexMicronutrientFertilizers4,
    volume: "10 л",
    price: 44.22,
    currency: "USD",
    text: (<>
    Опис: Мікро-Мінераліс (Універсальний) Плюс - рідке комплексне мікродобриво для позакореневого обробітку широкого спектру польових і овочевих культур. 
    <span className="paragraph"></span>
СКЛАД мікродобрива Мікро-Мінераліс (Універсальний) Плюс, амонійно-карбоксилатних комплексонів, %;
	•	Mo – 0,08
	•	Mn – 0,4
	•	Cu – 0,4
	•	Co – 0,05
	•	Fe – 1,6
	•	Zn – 2,0
	•	Mg – 1,6
	•	B – 0,5
	•	SO3  - 8,8
	•	P2O5  - 6,2
	•	K2O – 4,1
	•	N – 2,7
  <span className="paragraph"></span>
Переваги: 
	•	Можливе використання на більшості сільськогосподарських культур;
	•	Підвищує імунітет рослин (стійкість до несприятливих погодних умов та хвороб);
	•	Повна відсутність фітотоксичності;
	•	Позакоренева обробка мікродобривом Мікро-Мінераліс (Універсальний) Плюс підвищує морозостійкість та зимостійкість рослин, стимулює їх ріст та розвиток, посилює резистентність та стресостійкість, збільшує урожайність та якість отриманої продукції;
	•	Підвищує стійкість до хвороб та подовжує період якісного збереження продукції.
  <span className="paragraph"></span>
Порівняння різних форм сірки (органічної та неорганічної) за їх впливом на рослину:
	•	Швидкість проникнення у рослину; 
	•	Сумісність з іншими мікроелементами в комплексних добривах; 
	•	Фізіологічна доступність для рослини. 
  <span className="paragraph"></span>
Неорганічна (у формі SO2, SO32-, SO42-):
	•	Швидкість проникнення у рослину залежить від багатьох факторів: кислотність ґрунту, вологозабезпечення, активність мікрофлори в ґрунті та на рослині та ін.;
	•	При поєднанні з іншими елементами можуть утворюватися важкорозчинні сполуки; 
	•	Для поглинання та засвоєння неорганічної сірки рослина витрачає багато енергії. Наприклад, аніон сірчаної кислоти SO42-, перш ніж бути використаним для утворення органічних сполук в рослині, повинен бути відновлений. На цей процес рослинна повинна витратити 496 кДж/моль енергії. 
  <span className="paragraph"></span>
Органічна (S):
	•	Повністю засвоюється рослиною через 2 години після внесення; 
	•	Не утворює важкорозчинних сполук. Завдяки позитивному впливу на проникність клітинних мембран, органічна сірка є відмінним транспортуючим засобом для інших мікроелементів. 
	•	Органічна сірка відразу бере участь у формуванні амінокислот та білків, без надмірних витрат енергії на відновлення. 
  <span className="paragraph"></span>
Застосування.
<span className="paragraph"></span>
Норма витрати препарату.
<span className="paragraph"></span>
Культура: 
	•	Польові та овочеві культури.
  <span className="paragraph"></span>
Фаза розвитку культури:
	•	Позакореневе підживлення в період вегетації.
Норма витрати робочого розчину: 250-300 л/га для польових та овочевих культур та 600–1000 л/га для плодово-ягідних насаджень садів та виноградників.</>),
  },
  {
    productTitle: "Мікро-Мінераліс «Аміно» + МЕ",
    path: "/mikro-mineralis-amino-me",
    src: complexMicronutrientFertilizers5,
    volume: "10 л",
    price: 50.84,
    currency: "USD",
    text: (<>
    Опис: Мікро-Мінераліс «Аміно» + МЕ -  високоефективне рідке мікродобриво-антистресант з амінокислотами, призначене для позакореневого підживлення сільськогосподарських культур. 
    <span className="paragraph"></span>
Склад: N - 6,2%, B – 0,17%. L- Амінокислоти – 20%. Нанокарбоксилати Fe, Zn, Cu, Mn, Mo, Co, Mg. 
<span className="paragraph"></span>
Переваги: 
	•	Легкодоступні L – амінокислоти, що входять до складу добрива швидко відновлюють вегетацію культур після стресів викликаних природними чинниками (градом, посухою, заморозками, хворобами, шкідниками) та стресів викликаних застосуванням засобів захисту рослин.
	•	Нанокарбоксилати мікроелементів, що є у добриві суттєво прискорюють ферментативні реакції: пришвидшують обмін речовин, покращують інтенсивність дихання, підвищують резистентність рослин.
  <span className="paragraph"></span>
Норми витрати препарату Мікро-Мінераліс «Аміно» + МЕ.
<span className="paragraph"></span>
Культура: 
	•	Пшениця озима, ярий ячмінь; 
	•	Соняшник;
	•	Кукурудза; 
	•	Ріпак; 
	•	Соя, горох; 
	•	Цукровий буряк; 
	•	Овочеві; 
	•	Плодово-ягідні культури.
  <span className="paragraph"></span>
Фаза розвитку культури: 
	•	фаза кущення, фаза виходу в трубку –прапорцевого листка;
	•	фаза 2-3 пари листків, фаза 5-6 пар листків;
	•	фаза 3-5 листків, фаза 7-9 листків;
	•	фаза 5-8 листків, фази стеблування; фаза бутонізації; 
	•	3-5 справжніх листків, фаза бутонізації, фаза формування бобів; 
	•	до змикання в рядах, до змикання в міжряддях;
	•	до цвітіння, повторне внесення через кожні 10-12 днів; 
	•	до цвітіння, повторне внесення через кожні 10-12 днів.
  <span className="paragraph"></span>
Норма витрати робочого розчину: 250-300 л/га для польових та овочевих культур та 300-1000 л/га для плодово-ягідних та овочевих.  
<span className="paragraph"></span>
Мікро-Мінераліс «Аміно» + МЕ сумісне з усіма зареєстрованими пестицидами та агрохімікатами, проте в кожному окремому випадку рекомендуємо робити попередню перевірку компонентів бакової суміші на сумісність.
<span className="paragraph"></span>
Увага!!! Не використовувати разом з гербіцидами, оскільки виникає ризик зниження ефекту. Несумісний з препаратами, які містять мідь та алюміній.</>),
  },
];
