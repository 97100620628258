import Footer from '../../components/Footer/Footer'
import './ContactsPage.css'

const ContactsPage = () => {
  return (
    <div className='page'>
                        <title>Агролада-контаки</title>
      <h2 className="contact__page-title mont-r">Контактна інформація</h2>
      <p className="contact__page-text mont-r">Дніпропетровська область, м.Павлоград.</p>
     <a className="contact__page-text mont-r" href="tel:+380 (50) 259-14-88">+380 (50) 259-14-88 - Олександра</a>
     <a className="contact__page-text mont-r" href="mailto:agroladamarket@gmail.com">agroladamarket@gmail.com</a>
      <p className="contact__page-text mont-r">Графік роботи:</p>
      <p className="contact__page-text mont-r">Пн-Пт з 9.00-17.00</p>
      <p className="contact__page-text mont-r">Сб-Нд Вихідний</p>
     <div className="map">
     <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2643.7326481662676!2d35.957164199999994!3d48.50001839999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40deb99baf476b6f%3A0x7d2973b006f09079!2z0JDQk9Cg0J7Qm9CQ0JTQkA!5e0!3m2!1sru!2sua!4v1737203790721!5m2!1sru!2sua"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
     </div>
     <Footer />
    </div>
  )
}

export default ContactsPage

