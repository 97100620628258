import rodenticides1 from "../assets/images/RodenticidesImages/misholov.webp";

export const rodenticidesArr = [
  {
    productTitle: "Мишолов",
    path: "/misholov",
    src: rodenticides1,
    volume: "1 л",
    price: 19.90,
    currency: "USD",
    text: (<>
    Опис: Мишолов - засіб для виготовлення отруйних принад, які застосовуються в боротьбі з мишоподібними гризунами.  <span className="paragraph"></span>
Діюча речовина: Бродіфакум, 0,25 г/л.
<span className="paragraph"></span>
Препаративна форма: Розчин.
<span className="paragraph"></span>
Переваги: 
	•	Завдяки специфічному механізму дії препарат не викликає занепокоєння у гризунів — шкідники не можуть попередити інших особин про існуючу небезпеку.
	•	Готова принада може готуватися з використанням різної кормової основи з урахуванням харчових пристрастей шкідника.
	•	Рідка препаративна форма препарату полегшує його використання у виробничих умовах: відсутність пилу, хороший розподіл препарату по всій харчової основі тощо.
	•	Готова отруйна приманка за токсичністю відноситься до малонебезпечних засобів.
  <span className="paragraph"></span>
Механізм дії: 
Діюча речовина препарату - бродіфакум, відноситься до антикоагулянтів крові другого поколі­ння. При його надходженні до організму нейтралізується дія вітаміну К та порушується утво­рення протромбіну в крові. Вплив бродіфакуму на організм гризунів проявляється порушенням процесів згортання крові, кровотечею, крововиливами, які поступово призводить до загибелі тварин.
Уповільнений розвиток симптомів отруєння запобігає виникненню у гризунів побоювання до сприйняття принади, захисні реакції у тварин не формуються, перестороги до принад не вини­кає.
<span className="paragraph"></span>
Після вживання готової отруйної принади (препарат Мишолов + харчова основа) загибель гризунів відбувається в продовж 4 - 7 днів.
<span className="paragraph"></span>
Норма витрати робочого розчину: на 1 кг — 20мл із 980 г кормової основи.
<span className="paragraph"></span>
Рекомендації щодо застосування: 
Препарат призначений лише для виготовлення отруйних принад. До роботи з препаратом допускаються фахівці організацій, що мають відповідні дозвільні документи на право займатись дератизаційними роботами.
<span className="paragraph"></span>
Принади виготовляють згідно нормативної документації, затвердженої в установленому порядку в приміщеннях, що відповідають вимогам безпеки при роботі з хімічними речовинами відпо­відного класу небезпеки, забезпечені вентиляцією, належним технологічним обладнанням, на використання яких для зазначених цілей повинен бути наданий дозвіл відповідною територ­іальною санітарно-епідеміологчіною станцією.
<span className="paragraph"></span>
Отруйні принади з родентицидом Мишолов готують з використанням різного кормового напов­нювача (очищене зерно, крупи, гранульований комбікорм, каші, овочі, м'ясний або рибний фарш, інші привабливі для гризунів продукти) та атрактивних речовин (рослинна олія, цукор пісок тощо). Для приготування принад атрактанти змішують з препаратом Мишолов, а потім отри­ману суміш вносять у кормову основу і ретельно перемішують до рівномірного насичення забар­влення по всій масі принади. Склад кормового наповнювача та інших інгредієнтів підбирають у залежності від виду гризуна, його харчових уподобань та специфіки кормової бази на конкрет­ному об'єкті. У принадах для мишей краще використовувати подрібнене зерно або крупи.
<span className="paragraph"></span>
Розрахунок для приготування принад.
<span className="paragraph"></span>
Для приготування 1 кг отруйної принади необхідно змішати 20 мл родентициду МЫШЕЛОВ®  з 980г кормової основи.
<span className="paragraph"></span>
З метою підвищення ефективності дератизаційних заходів до складу кормової основи рекомендується вводити атрактанти: рослинна олія - до 3% від маси готової принади або цукор пісок - до 10% від маси готової принади. При цьому кількість кормової основи зменшують на відповідну кількість використаного атрактанту.
<span className="paragraph"></span>
Методика застосування принад з метою дератизації.
<span className="paragraph"></span>
Перед застосуванням отруйних принад, виготовлених на основі препарату Мишолов, необхідно провести облік чисельності гризунів методом маршрутних обстежень, що дозволить визначити необхідну кількість препарату до використання.
<span className="paragraph"></span>
На полі виділяють маршрут по діагоналі поля довжиною 1000 м та шириною 5 м (площею 0,5 га). Рухаючись за маршрутом, спостерігач підраховує кількість колоній в полі зору. Колонії позна­чають віхами, які за висотою перевищують висоту рослин на 20-25 см. У другій половині дня на маршруті притоптують всі нори в колоніях. Через 24 години підраховують кількість відкритих (жилих) нір на маршруті.
<span className="paragraph"></span>
Після визначення кількості жилих нір проводять розкладку принад. При розкладанні принади використовують дозуючі мірки (ложки, совочки, тощо). Працівник розкладає принаду в жилі нори мишоподібних гризунів в залежності від форми принад: зернову суміш - до 100 г в нору, тістопо­дібну речовину - 1-2 пакетики (вагою 10 г) в кожну нору з послідуючим засипанням нір. Повторну розкладку засобу проводять через 5-7 днів.
<span className="paragraph"></span>
Відмінною для мишей вважається ефективність знешкодження вище 85%, доброю - 70-80%, задовільненою - 70%. Якщо ефективність нижча за 50%, дератизаційні роботи необхідно повто­рити.
</>),
  },
];
