import cornLG from "../assets/images/CornImages/cornLG.webp";

export const cornArr = [
  {
    productTitle: "30273 ЛГ Форс Зеа Лімагрейн",
    path: "/lg-30273-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 55.15,
    currency: "EUR",
    text: (<>Опис:
    <span className="paragraph"></span>
      Призначення: зерно
      <span className="paragraph"></span>
      Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип зерна: кременисто-зубоподібний
      <span className="paragraph"></span>
      - вологовіддача на рівні зубоподібних конкурентів
      <span className="paragraph"></span>- стабільний за різних умов
      <span className="paragraph"></span>- швидкий стартовий ріст
      <span className="paragraph"></span>- використовується на крупу промисловістю Польщі і Франції
      <span className="paragraph"></span>- швидка вологовіддача й висока продуктивність
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Середня висота рослини(максимальна), см: 270 (340)
        <span className="paragraph"></span>
        •	Середня кiлькiсть зерен в ряду: 32
        <span className="paragraph"></span>
        •	Середня кiлькiсть рядiв у качанi: 14-16
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен : 310 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів:
      <span className="paragraph"></span>
        •	Потенцiал урожайностi зерна в своїй групi стиглостi  - 9 
        <span className="paragraph"></span>
        •	Швідкiсть вологовiддачi — 8
        <span className="paragraph"></span>
        •	Толерантність до пухирчатої сажки — 9 
        <span className="paragraph"></span>
        •	Стійкість до вилягання — 8 
        <span className="paragraph"></span>
        •	Толерантнiсть до посухи та інших стресових факторів — 9 
        <span className="paragraph"></span>
        •	Толерантність до фузаріозу — 8 
        <span className="paragraph"></span>
        •	Енергія початкового росту — 8 
        <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
        •	- добре реагує на внесення добрив
        <span className="paragraph"></span>
        •	- рекомендуємо для класичних технологій
        <span className="paragraph"></span>
        •	- не рекомендується застосовувати надто велике навантаження систем захисту
        <span className="paragraph"></span>
      Рекомендована густота на моментзбирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 70-80 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 60-70 тис./га
        <span className="paragraph"></span>
        •	посушлива зона: 50-55 тис./га</>),
  },
  {
    productTitle: "ЛГ 30315 Форс Зеа Лімагрейн",
    path: "/lg-30315-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 55.80,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Призначення: зерно
      <span className="paragraph"></span>Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип зерна: зубовидний
      <span className="paragraph"></span>
      - високотолерантний до посушливих умов
      <span className="paragraph"></span>- стабільний результат в екологічних випробуваннях 2015 року
      <span className="paragraph"></span>- швидка вологовіддача
      <span className="paragraph"></span>- толерантність до летючої сажки
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Середня висота рослини(максимальна), см: 250 (340)
        <span className="paragraph"></span>
        •	Середня кiлькiсть зерен в ряду: 35
        <span className="paragraph"></span>
        •	Середня кiлькiсть рядiв у качанi: 14-16
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен : 250 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів:
      <span className="paragraph"></span>
        •	Потенцiал урожайностi зерна в своїй групi стиглостi — 9  
        <span className="paragraph"></span>
        •	Швідкiсть вологовiддачi — 9 
        <span className="paragraph"></span>
        •	Толерантність до пухирчатої сажки — 9 
        <span className="paragraph"></span>
        •	Стійкість до вилягання — 8 
        <span className="paragraph"></span>
        •	Толерантнiсть до посухи та інших стресових факторів — 9 
        <span className="paragraph"></span>
        •	Толерантність до фузаріозу — 8 
        <span className="paragraph"></span>
        •	Енергія початкового росту — 7 
        <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
        •	- Добре реагує на інтенсивні технології
        <span className="paragraph"></span>- Хороші результати показав у посушливих умовах
        <span className="paragraph"></span>- Стабільний результат в екологічних випробуваннях 2020 року
        <span className="paragraph"></span>
      Рекомендована густота на моментзбирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 70-80 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 60-70 тис./га
        <span className="paragraph"></span>
        •	посушлива зона: 50-55 тис./га</>),
  },
  {
    productTitle: "31272 ЛГ Форс Зеа Лімагрейн",
    path: "/lg-30272-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 91.63,
    currency: "EUR",
    text: (<>
    Опис: 
    <span className="paragraph"></span>
Призначення: зерно, висококрохмалистий силос
<span className="paragraph"></span>Група стиглості: Середньоранній
<span className="paragraph"></span>Тип зерна: кременисто-зубоподібний
<span className="paragraph"></span>
• Шалена популярність в Європі
<span className="paragraph"></span>• Раннє цвітіння
<span className="paragraph"></span>• Стабільний в різних грунтово-кліматичних умовах
<span className="paragraph"></span>• Пластичний до технологій вирощування
<span className="paragraph"></span>
Агрономічні характеристики:
<span className="paragraph"></span>
	•	Середня висота рослини(максимальна), см: 260(360)
  <span className="paragraph"></span>
	•	Середня кiлькiсть зерен в ряду: 32
  <span className="paragraph"></span>
	•	Середня кiлькiсть рядiв у качанi: 14-16
  <span className="paragraph"></span>
	•	Середня маса 1000 зерен : 335 г
  <span className="paragraph"></span>
Оцінка за шкалою від 1 до 9 балів.
<span className="paragraph"></span>
Потенцiал урожайностi зерна в своїй групi стиглостi — 9
<span className="paragraph"></span>
Швідкiсть вологовiддачi — 8
<span className="paragraph"></span>
Толерантність до пухирчатої сажки — 9
<span className="paragraph"></span>
Толерантність до фузаріозу — 8
<span className="paragraph"></span>
Енергія початкового росту — 8
<span className="paragraph"></span>
Стійкість до вилягання — 9
<span className="paragraph"></span>
Толерантнiсть до посухи та інших стресових факторів — 9
<span className="paragraph"></span>
Рекомендації:
<span className="paragraph"></span>
	•	Рекомендується для усіх зон вирощування 
  <span className="paragraph"></span>
	•	Придантий для використання в монокультурі 
  <span className="paragraph"></span>
	•	Можливе використання на силос із високим вмістом крохмалю 
  <span className="paragraph"></span>
Рекомендована густота на момент збирання:
<span className="paragraph"></span>
зона достатнього зволоження: 85-95 тис./га
<span className="paragraph"></span>
	•	зона недостатнього зволоження: 60-70 тис./га
  <span className="paragraph"></span>
	•	посушлива зона: 50-55 тис./га</>),
  },
  {
    productTitle: "31240 ЛГ Форс Зеа Лімагрейн",
    path: "/lg-31240-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 104.20,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Призначення: зерно
      <span className="paragraph"></span>Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип зерна: зубоподібний
      <span className="paragraph"></span>
       Висока енергія початкового росту
      <span className="paragraph"></span>• Відмінна стабільність
      <span className="paragraph"></span>• Висока врожайність
      <span className="paragraph"></span>• Відмінні стресостійкість
      <span className="paragraph"></span>• Толерантний до посухи
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Середня висота рослини(максимальна), см: 281(365)
        <span className="paragraph"></span>
        •	Середня кiлькiсть зерен в ряду: 30-40
        <span className="paragraph"></span>
        •	Середня кiлькiсть рядiв у качанi: 14-18
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів:
      <span className="paragraph"></span>
        •	Потенцiал урожайностi зерна в своїй групi стиглостi — 9 
        <span className="paragraph"></span>
        •	Швідкiсть вологовiддачi — 9 
        <span className="paragraph"></span>
        •	Толерантність до пухирчатої сажки — 9 
        <span className="paragraph"></span>
        •	Стійкість до вилягання — 8 
        <span className="paragraph"></span>
        •	Толерантнiсть до посухи та інших стресових факторів — 9 
        <span className="paragraph"></span>
        •	Толерантність до фузаріозу — 8 
        <span className="paragraph"></span>
        •	Енергія початкового росту — 8 
        <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
        •	Рекомендований для зон Полісся і Лісостепу та півночі Степу 
        <span className="paragraph"></span>
        •	Краще висівати в прогрітий ґрунт 
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 65-85 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 55-65 тис./га
        <span className="paragraph"></span>
        •	посушлива зона: 45-55 тис./га</>),
  },
  {
    productTitle: "30215 ЛГ Форс Зеа",
    path: "/lg-30215-fors-zea",
    src: cornLG,
    volume: "",
    price: 66.58,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Призначення: зерно
      <span className="paragraph"></span>Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип зерна: кременисто-зубоподібний
      <span className="paragraph"></span>
      - високотолерантний до посушливих умов
      <span className="paragraph"></span>- висока адаптивність до сезонних стресових умов
      <span className="paragraph"></span>- висока якість зерна з високим виходом крупи
      <span className="paragraph"></span>- толерантність до летючої сажки
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Середня висота рослини(максимальна), см: 260 (320)
        <span className="paragraph"></span>
        •	Середня кiлькiсть зерен в ряду: 30
        <span className="paragraph"></span>
        •	Середня кiлькiсть рядiв у качанi: 14-16
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен : 250 г
        <span className="paragraph"></span>
      Оцінка за шкалою від 1 до 9 балів:
      <span className="paragraph"></span>
        •	Потенцiал урожайностi зерна в своїй групi стиглостi — 9 
        <span className="paragraph"></span>
        •	Швідкiсть вологовiддачi  - 8 
        <span className="paragraph"></span>
        •	Толерантність до пухирчатої сажки — 9 
        <span className="paragraph"></span>
        •	Стійкість до вилягання — 8 
        <span className="paragraph"></span>
        •	Толерантнiсть до посухи та інших стресових факторів — 9 
        <span className="paragraph"></span>
        •	Толерантність до фузаріозу — 8 
        <span className="paragraph"></span>
        •	Енергія початкового росту — 8 
        <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - рекомендується для класичних технологій
      <span className="paragraph"></span>
        •	- стабільний результат у посушливих умовах 2020 року
        <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 70-85 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 60-70 тис./га
        <span className="paragraph"></span>
        •	посушлива зона: 50-55 тис./га</>),
  },
  {
    productTitle: "31330 ЛГ Форс Зеа Лімагрейн",
    path: "/lg-31330-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 92.92,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      •	Призначення: зерно
      <span className="paragraph"></span>Група стиглості: Середньостиглий
      <span className="paragraph"></span>Тип зерна: зубовидний
      <span className="paragraph"></span>Сума ефективних T° сума до 32 % H2O: 1860°C (6/30 база)
      <span className="paragraph"></span>Сума ефективних T° до цвітіння: 910°C (6/30 база)
      <span className="paragraph"></span>
      •	- висока адаптивність і стабільність
      <span className="paragraph"></span>- висока толерантність до основних хвороб
      <span className="paragraph"></span>- висока толерантність до посухи
      <span className="paragraph"></span>- нова зірка в сегменті
      <span className="paragraph"></span>
    Агрономічні характеристики:
    <span className="paragraph"></span>
      •	Середня висота рослини(максимальна), см: 255 (320)
      <span className="paragraph"></span>
      •	Середня кiлькiсть зерен в ряду: 38-40
      <span className="paragraph"></span>
      •	Середня кiлькiсть рядiв у качанi: 16-18
      <span className="paragraph"></span>
      •	Середня маса 1000 зерен : висока
      <span className="paragraph"></span>
    Оцінка за шкалою від 1 до 9 балів: 
    <span className="paragraph"></span>
      •	Потенцiал урожайностi зерна в своїй групi стиглостi — 9 
      <span className="paragraph"></span>
      •	Швідкiсть вологовiддачi — 9 
      <span className="paragraph"></span>
      •	Толерантність до пухирчатої сажки  - 8 
      <span className="paragraph"></span>
      •	Стійкість до вилягання — 9 
      <span className="paragraph"></span>
      •	Толерантнiсть до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      •	Толерантність до фузаріозу — 8
      <span className="paragraph"></span>
      •	Енергія початкового росту — 9 
      <span className="paragraph"></span>
    Рекомендації:
    <span className="paragraph"></span>
    - Можливе використання на повторних посівах
    <span className="paragraph"></span>
    Рекомендована густота на момент збирання:
    <span className="paragraph"></span>
      •	зона достатнього зволоження: 70-75 тис./га
      <span className="paragraph"></span>
      •	зона недостатнього зволоження: 60-70 тис./га
      <span className="paragraph"></span>
      •	посушлива зона: 45-55 тис./га</>),
  },
  {
    productTitle: "Адевей Форс Зеа насіння кукурудзи Лімагрейн",
    path: "/adevey-fors-zea-limagrain",
    src: cornLG,
    volume: "",
    price: 62.70,
    currency: "EUR",
    text: (<>Опис: 
    <span className="paragraph"></span>
      Призначення: зерно
      <span className="paragraph"></span>Група стиглості: Середньоранній
      <span className="paragraph"></span>Тип зерна: кременисто-зубоподібний
      <span className="paragraph"></span>Тип рослини : стей грін
      <span className="paragraph"></span>- стабільно високий потенціал урожайності за різних умов
      <span className="paragraph"></span>
      - розкриває високий потенціал продуктивності за інтенсивної технології вирощування
      <span className="paragraph"></span>- придатний для ранніх посівів з високою початковою енергією росту
      <span className="paragraph"></span>- придатний для виробництва класичного силосу
      <span className="paragraph"></span>- толерантний до посухи
      <span className="paragraph"></span>
      Агрономічні характеристики:
      <span className="paragraph"></span>
        •	Середня висота рослини(максимальна), см: 270 (340)
        <span className="paragraph"></span>
        •	Середня кількість зерен в ряду: 34
        <span className="paragraph"></span>
        •	Середня кiлькiсть рядiв у качанi: 14-16
        <span className="paragraph"></span>
        •	Середня маса 1000 зерен: 350 г
        <span className="paragraph"></span>
        •	Оцінка за шкалою від 1 до 9 балів:
        <span className="paragraph"></span>
      Потенцiал урожайностi зерна в своїй групi стиглостi — 9 
      <span className="paragraph"></span>
      Швідкiсть вологовiддачi — 7 
      <span className="paragraph"></span>
      Толерантність до пухирчатої сажки — 8
      <span className="paragraph"></span>
      Стійкість до вилягання — 9 
      <span className="paragraph"></span>
      Толерантнiсть до посухи та інших стресових факторів — 9 
      <span className="paragraph"></span>
      Толерантність до фузаріозу — 8 
      <span className="paragraph"></span>
      Енергія початкового росту — 9 
      <span className="paragraph"></span>
      Рекомендації:
      <span className="paragraph"></span>
      - використовуйте засоби захисту згідно регламенту
      <span className="paragraph"></span>- враховуйте високу енергію росту при внесення хімічних засобів
      <span className="paragraph"></span>- краще вирощувати в сівозміні придатний для повторних посівів
      <span className="paragraph"></span>
      Рекомендована густота на момент збирання:
      <span className="paragraph"></span>
        •	зона достатнього зволоження: 70-80 тис./га
        <span className="paragraph"></span>
        •	зона недостатнього зволоження: 60-70 тис./га
        <span className="paragraph"></span>
        •	посушлива зона: 50-60 тис./га
      </>),
  },
];
