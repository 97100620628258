import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  isFormSended: false,
  selectedProduct: null,
};

const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    setIsActive: (state, action) => {
      state.isActive = true;
    },
    setIsClose: (state, action) => {
      state.isActive = false;
    },
    setIsFormSended: (state) => {
      state.isFormSended = true;
    },
    setClearIsFormSended: (state) => {
      state.isFormSended = false;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload; 
    },
  },
});

export const { setIsActive, setIsClose, setIsFormSended, setClearIsFormSended, setSelectedProduct } = ModalSlice.actions;
export default ModalSlice.reducer;
