import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setClearIsFormSended,
  setIsClose,
} from "../../redux/ModalSlice/ModalSlice";
import { useTelegramMessage } from "../../utils/request";
import CloseIcon from "./CloseIcon/CloseIcon";
import "./ModalForm.css";
import resImg from "../../assets/icons/response-icon.png";
import { InputMask } from "@react-input/mask";

const ModalForm = () => {
  const isActive = useSelector((state) => state.modal.isActive);
  const response = useSelector((state) => state.modal.isFormSended);
  const selectedProduct = useSelector((state) => state.modal.selectedProduct);
  const [isResponseActive, setIsResponseActive] = useState(false);
  let timeoutId;
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const { sendTelegramMessage } = useTelegramMessage();

  useEffect(() => {
    setIsResponseActive(response);

    if (isResponseActive) {
      timeoutId = setTimeout(() => {
        dispatch(setClearIsFormSended());
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [response, isResponseActive, dispatch]);

  const handleNameChange = (e) => {
    setUserName(e.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (e) => {
    setUserPhone(e.target.value);
    setPhoneError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let nameErrorFlag = false;
    let phoneErrorFlag = false;

    if (userName.length < 1) {
      setNameError(true);
      nameErrorFlag = true;
    } else {
      setNameError(false);
    }

    if (userPhone && !userPhone.includes("_")) {
      setPhoneError(false);
    } else {
      setPhoneError(true);
      phoneErrorFlag = true;
    }

    if (nameErrorFlag || phoneErrorFlag) {
      return;
    }

    if (!nameErrorFlag && !phoneErrorFlag) {
      const message = `
      \u{1FAB4}Заявка з сайту\u{1FAB4}\nІм'я: ${userName}
Номер телефону: ${userPhone}\n${selectedProduct?.title ? `Назва товару: ${selectedProduct.title}`
: "Заявка на консультацію"}\n${selectedProduct?.price ? `Ціна: ${selectedProduct.price.toFixed(2)} грн` : "Ціна: Уточнюйте (проблема з інтернетом)"}\n${selectedProduct?.volume ? `Об’єм: ${selectedProduct.volume}` : ""}
      `;

      await sendTelegramMessage({ userName, userPhone, message });

      setUserName("");
      setUserPhone("");
    }
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const handleModalClose = () => {
    dispatch(setIsClose());
    dispatch(setClearIsFormSended());
  };

  return (
    <div
      onClick={handleModalClose}
      className={`modal__form ${isActive ? "active" : ""}`}
    >
      <div onClick={handlePropagation} className={`modal__form-wrapper`}>
        <div onClick={handleModalClose} className="close__icon-modal">
          <CloseIcon />
        </div>
        <div className={`modal__form-body ${isResponseActive ? "hidden" : ""}`}>
          <h2 className="modal__form-body-title mont-r">
            Заявка на консультацію
          </h2>
          <p className="modal__form-body-description mont-r">
            Зв’яжемось з Вами
            <span className={`brand-span`}> найближчим часом</span>
          </p>
          <form onSubmit={handleSubmit} className="modal__form-form">
            <input
              placeholder="Ім’я"
              value={userName}
              onChange={handleNameChange}
              className={`modal__form-input ${nameError ? "error" : ""} mont-m`}
              type="text"
            />
            <InputMask
              mask="+38 (___) ___-__-__"
              replacement={{ _: /\d/ }}
              showMask={true}
              onFocus={(e) => {
                if (e.target.value.length < 5) {
                  e.target.value = "+38 (___) ___-__-__";
                } else if (e.target.value.length > 5) {
                  return;
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "+38 (___) ___-__-__") {
                  setUserPhone("");
                }
              }}
              value={userPhone}
              onChange={handlePhoneNumberChange}
              placeholder="Номер телефону"
              className={`modal__form-input ${
                PhoneError ? "error" : ""
              } text-base-r`}
              type="phone"
            />

            <button
              onClick={handleSubmit}
              className={`modal__form-button mont-m`}
              type="submit"
            >
              Передзвоніть мені
            </button>
          </form>
        </div>
        <div className={`response-body ${isResponseActive ? "visible" : ""}`}>
          <img src={resImg} alt="іконка галочки" className="response-image" />
          <h2 className="response-title mont-r">Заявка успішно відправлена</h2>
          <p className="response-description mont-r">
            Дякуємо за довіру! Ми зв’яжемось з Вами найближчим часом.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
