import bioinsecticides1 from '../assets/images/BioinsecticidesImages/skarado-m.jpg'

export const bioinsecticidesArr = [
    {
        productTitle: "Скарадо-М (Картопля)",
        path: "/skarado-m",
        src: bioinsecticides1,
        volume: "1 л",
        price: 11.67,
        currency: "USD",
        text: (<>
        Опис: Скарадо-М - біоінсектицидний препарат кишково-контактної дії для захисту культур родини пасльонові (картопля, томати, баклажани та ін..) від личинок колорадського жука (Leptinotarsa decemlineata).
        <span className="paragraph"></span>
Склад: Bacillus thuringiensis var.thuringiensis титр 5х109 КУО/см3. 
<span className="paragraph"></span>
Переваги Скарадо-М (Картопля):
	•	Безпечний  для навколишнього середовища та людей.
	•	Не викликає резистентності у шкідників.
	•	Можливий обробіток в будь-якій фазі розвитку культури.
	•	Знищення личинок різного віку.
    <span className="paragraph"></span>
Норми витрати препарату.
<span className="paragraph"></span>
Культура: 
	•	Картопля.
    <span className="paragraph"></span>
Цільовий шкідник:
	•	личинки листогризучих шкідників.
    <span className="paragraph"></span>
Спосіб та час обробки: 
	•	обприскування по вегетації.
    <span className="paragraph"></span>
Витрата робочого розчину для позакореневого обприскування - 5 л/сотку.
<span className="paragraph"></span>
Примітка: Існує досвід застосування біоінсектициду Скарадо-М (Картопля) в закритому ґрунті проти шкідників родини Aleyrodide, закрема оранжерейна білокрилка Trialeurodes vaporariorum; шкідників родини Aphididoe, зокрема баштанна попелиця Aphis gossypii.
Робочий розчин не підлягає тривалому зберіганню, тому його необхідно готувати  безпосередньо перед застосуванням та в кількостях, що не перевищують потреби.
<span className="paragraph"></span>
Сумісність з іншими препаратами: Сумісний з усіма відомими добривами, пестицидами та агрохімікатами.
<span className="paragraph"></span>
Фітотоксичність: відсутня за умов дотримання регламенту використання.
<span className="paragraph"></span>
Зберігання: препарат зберігають за температури +5...+20 оС
</>),
    },
]