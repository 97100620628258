import biopreparations1 from "../assets/images/BiopreparationsImages/bio-mineralis-inokulyant-soya.jpg";
import biopreparations2 from "../assets/images/BiopreparationsImages/bio-mineralis-destruktor-cellulosy.jpg";
import biopreparations3 from "../assets/images/BiopreparationsImages/biofungicid-cidoks-pro.jpg";

export const biopreparationsArr = [
  {
    productTitle: "Біо-Мінераліс (Інокулянт для Сої+МЕ)",
    path: "/bio-mineralis-inokulyant-soya",
    src: biopreparations1,
    volume: "10 л",
    price: 111.14,
    currency: "USD",
    text: (<>
    Опис: Біо-Мінераліс (Інокулянт для сої+МЕ) -  сприяє біологічному оздоровленню ґрунтів та знижує шкідливу дію нітратів від тривалого застосування хімічних добрив, дозволяє більш економно та ефективно витрачати мінеральні добрива. Азотфіксуючі бактерії, розвиваючись у прикореневій зоні бобових культур, збагачують ґрунт біологічним азотом, який максимально фіксується з атмосферного повітря, таким чином покращуючи азотне живлення рослин. Мікроелементи, які містяться в інокулянті підвищують енергію проростання, сприяють активному росту і розвитку кореневої системи, забезпечують ранні і дружні сходи культури. 
    <span className="paragraph"></span>
СКЛАД: гелевий препарат жовтого кольору зі специфічним запахом живильного дріжджового середовища, яке являє собою рідку концентровану суміш на основі культури азотфіксуючих бульбочкових бактерій Rhizobium japonicum (титр 5х109 КУО/см3), активованих нанокарбоксилатами Со, Мо, Mg. 
<span className="paragraph"></span>
ПРИЗНАЧЕННЯ. Дія Біо-Мінераліс (Інокулянт для Сої+МЕ) базується на здатності корисних мікроорганізмів, що містяться в ньому, засвоювати азот з атмосферного повітря та переводити його в доступні для рослин форми, за рахунок симбіотичних зв’язків з рослиною забезпечувати їх дешевим екологічно чистим азотом, формувати більш розвинену кореневу систему, синтезувати ріст-стимулюючі речовини, придушувати ріст фітопатогенної мікрофлори (збудників хвороб рослин) внаслідок конкурентного домінування. Бульбочкові бактерії препарату Біо-Мінераліс (Інокулянт для Сої+МЕ) мають унікальну симбіотичну спорідненість до сої, завдяки чому досягається гарантовано стабільний ефект. 
ОСОБЛИВОСТІ ПРЕПАРАТУ:
<span className="paragraph"></span>
	•	Підвищує урожайність зернобобових культур на 15-30%;
	•	Стимулює поглинання макро- і мікроелементів з ґрунту;
	•	Відновлює та підвищує родючість ґрунтів, збагачуючи їх дешевим біологічно-фіксованим атмосферним азотом без додаткового внесення азотних добрив;
	•	Позитивно впливає на врожай наступних культур у сівозміні;
	•	Насичує ґрунт бульбочковими бактеріями, специфічними лише для сої та необхідними для її повноцінного росту та розвитку;
	•	Поліпшує якість вирощеної продукції, збільшує вміст білків, вітамінів, вуглеводів;
	•	Підвищує стійкість рослин до хвороб;
	•	Не вимивається з ґрунту.
  <span className="paragraph"></span>
Препарат використовується для передпосівної інокуляції насіння сої шляхом механізованої (бетономішалки чи машин для протруювання насіння типу ПС-10) або ручної обробки посівного матеріалу. Бактеризація проводиться в день посіву. На одну тонну насіння витрачається 1,5 л препарату, який розводять на 8,0-10,0 л робочого розчину. Для кращого нанесення інокулянта на поверхню насінини в складі препарату містяться біологічні прилипачі. Незалежно від способу бактеризації, після обробки насіння потрібно підсушити, щоб уникнути зниження норми висіву.
<span className="paragraph"></span>
Дозволяється при обробці насіння поєднувати Біо-Мінераліс (Інокулянт для Сої+МЕ) з інсектицидами, фунгіцидами (крім тих, що містять в складі мідь (Сu)) та екологічно чистими мікроелементами Нано-Мінераліс, РК.
<span className="paragraph"></span>
Увага!!! Не рекомендується зберігати робочий розчин більше 1 доби. Обробку проводити, уникаючи дії прямих сонячних променів, краще вранці або ввечері! Після обробки насіння необхідно висіяти впродовж 2 днів.
<span className="paragraph"></span>
Зберігання. Препарат зберігати при t° від +3°С до +15°С в темному, захищеному від прямих сонячних променів місці, окремо від отрутохімікатів. Підвищення температури при зберіганні призводить до зменшення терміну придатності препарату.
<span className="paragraph"></span>
Гарантійний термін зберігання: 6 місяців від дати виготовлення.</>),
  },
  {
    productTitle: "Біо-Мінераліс (Деструктор целюлози)",
    path: "/bio-mineralis-destruktor-cellulosy",
    src: biopreparations2,
    volume: "10 л",
    price: 63.85,
    currency: "USD",
    text: (<>
    Опис:  Біо-Мінераліс (Деструктор целюлози) - препарат призначений для покращення розкладу пожнивних решток та поліпшення родючості ґрунту. 
    <span className="paragraph"></span>
СКЛАД: Гриби роду Trichoderma reesei (загальне число життєздатних спор, не менше 1*105 – 1*108 млрд. спор/см3) та продукти його життєдіяльності (ферменти целюлази та геміцелюлази). 
<span className="paragraph"></span>
Призначення.
<span className="paragraph"></span>
Біо-Мінераліс (Деструктор целюлози) призначений для обробки стерні та ґрунту після збирання врожаю сільськогосподарських культур. Прискорює розкладання рослинних решток,поліпшує ґрунтову родючість за рахунок збагачення його живильними речовинами та розвитку специфічної мікрофлори. Знищує патогени, що передаються через  рослинні рештки у ґрунт. Підвищує продуктивність сільськогосподарських культур на 10 – 30%. 
<span className="paragraph"></span>
Норма витрати препарату  Біо-Мінераліс (Деструктор целюлози).
<span className="paragraph"></span>
Культура: 
	•	Зернові (пшениця, жито, ячмінь, гречка, просо) суха маса; 
	•	Соняшник (суха маса);
	•	Кукурудза (суха маса);
	•	Ріпак (суха маса).
  <span className="paragraph"></span>
Розрахунок витрати препарату: 
	•	1-1,5 л/соломи; 
	•	2-3 л/т соломи; 
	•	1,5-2 л/т соломи; 
	•	2-3 л/т соломи.
  <span className="paragraph"></span>
Норма витрати азотних добрив: 
	•	5 кг д.р./т соломи;
	•	10 кг д.р./т соломи; 
	•	10 д.р./т соломи; 
	•	10 кг д.р./т соломи.
  <span className="paragraph"></span>
Загальні рекомендації.
 Визначену кількість препарату залити у бак обприскувача наповненого на третину чистою водою, включити змішувач і через 15 хвилин, не виключаючи, долити ще третину  води і додати необхідну кількість, заздалегідь приготований розчин аміачної селітри або КАС та довести до повного баку обприскувач водою. Продовжувати перемішувати під час обробки для забезпечення однорідності робочого розчину.
<span className="paragraph"></span>
	•	Перед застосуванням збовтати!
	•	Робочий розчин препарату готувати безпосередньо перед заправкою обприскувача.
	•	Розчин препарату зберігати не більше доби!
	•	Для покращення деструкції,  рекомендується, відразу після внесення препарату, заробити рослинні рештки в грунт.
	•	Обробка повинна проводитися  при температурі не нижче +50С.
  <span className="paragraph"></span>
Заходи безпеки: препарат Біо-Мінераліс (Деструктор целюлози) нетоксичний для людей, тварин та комах! При попаданні препарату на шкіру або в очі – промити водою.
<span className="paragraph"></span>
Зберігання: препарат Біо-Мінераліс (Деструктор целюлози) зберігають у герметичній упаковці за температури від + 3 0С до + 20 0С., у відкритій упаковці термін зберігання 30 днів.
<span className="paragraph"></span>
Гарантійний термін зберігання: 1 рік від дати виготовлення.</>),
  },
  {
    productTitle: "Біофунгіцид «Цидокс Про»",
    path: "/biofungicid-cidoks-pro",
    src: biopreparations3,
    volume: "10 л",
    price: 92.23,
    currency: "USD",
    text: (<>
    Опис: Біофунгіцид «Цидокс Про» - біофунгіцид, призначений для обробки сільськогосподарських культур та насіння, який має антимікробну та протигрибкову дію.
    <span className="paragraph"></span> 
Діюча речовина – бактерії роду Streptomyces sp., МБС-1.
<span className="paragraph"></span>
Механізм дії – препарат застосовується для захисту сільськогосподарських культур від таких хвороб:
<span className="paragraph"></span>
	•	грибкових: снігова пліснява – Microdochium nivale, іржасті хвороби злаків – Puccinia spp., борошниста роса – Erysiphe graminis, септоріоз листя – Septoria tritici, фузаріоз колоса – Fusarium spp., церкоспороз – Cercospora beticola, альтернаріоз – Alternaria alternata, пероноспороз – Peronospora spp., кореневі гнилі – Fusarium spp.;
	•	бактеріальних: бактеріози озимих – Pseudomonas syringae, Xanthomonas translucens, бактеріальний опік сої – Pseudomonas glycinea, коренеїд сходів буряків – Pseudomonas fluorescens, Erwinia sp., кільцева гниль картоплі – Clavibacter sepedonicum, бактеріальний рак томатів – Clavibacter michiganensis, кутаста плямистість – Pseudomonas syringae, судинні бактеріози – Xanthomonas campestris, бактеріальний опік плодових – Erwinia amylovora, тощо.
  <span className="paragraph"></span>
Переваги препарату:
	1.	У рекомендованих дозах не справляє токсичного впливу на людей, комах та рослини.
	2.	Ефективно знищує широкий спектр фітопатогенних бактерій та грибів.
	3.	Виключає утворення резистентності патогенів до дії препарату.
	4.	Швидко зупиняє ріст та розвиток фітопатогенних грибів та мікроміцетів.
	5.	Змішується та не втрачає своєї ефективності з усіма зареєстрованими в Україні засобами захисту рослин (крім тих, що містять мідь (Cu), алюміній (Al), срібло (Ag)) та добривами.
	6.	Не фітотоксичний, ефективність дії препарату коливається в межах 80-90 %, при багаторічному використанні резистентність не проявляється.
	7.	Покращує живлення рослин шляхом фіксації атмосферного азоту, мобілізації важкодоступних форм фосфору і калію. Містить амінокислоти – Гліцин, Л-пролін, Аланін, Л-валін, Л-ізолейцин, Л-лейцин, Л-тирозин, Л-фенілаланін і Л-лізин.
  <span className="paragraph"></span>
Сфера застосування. Застосовується на полях, садах, виноградниках, присадибних ділянках та у теплицях.
<span className="paragraph"></span>
Регламент застосування. Обприскування посівів слід проводити в період вегетації профілактично або за появи перших ознак хвороби.
<span className="paragraph"></span>
Норма витрати препарату  "Цидокс Про".
<span className="paragraph"></span>
Культура: 
	•	Озима пшениця, ячмінь, жито; 
	•	Соя, горох; 
	•	Соняшник; 
	•	Кукурудза; 
	•	Томати; 
	•	Картопля;
	•	Цукровий буряк; 
	•	Ріпак;
	•	Баклажани, перець, капуста, огірки; 
	•	Виноград; 
	•	Плодово-ягідні насадження.
  <span className="paragraph"></span>
* Інтервал між обробками 10-15 діб та в залежності від даних моніторингу хвороб.
<span className="paragraph"></span>* Захисна дія препарату триває 14-20 діб, в залежності від умов розвитку хвороб.
<span className="paragraph"></span>
Норма витрати робочого розчину. Препарат застосовується на сільськогосподарських культурах шляхом використання наземних та ранцевих обприскувачів, з нормою витрати робочої рідини 100-1000 л/га.
<span className="paragraph"></span>
Загальні рекомендації. Препарат застосовується в діапазоні температур від +10 0С до +30С, у ранкові та вечірні години.</>),
  },
];
